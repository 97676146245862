import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GoogleMap, Marker, Polyline } from "@react-google-maps/api";
import {
  getActiveOrder,
  subscribeToActiveOrder,
  fetchDistanceInMilesAndDurationInMinutes,
  getRider,
  getScheduledOrder,
} from "../../../Firebase/order";
import bike from "../../../Icons/bike.svg";
import box from "../../../Icons/box.svg";
import dropoff from "../../../Icons/dropoff.svg";
import bikeBox from "../../../Icons/bike_box.svg";
import { Location } from "../../types/Location";
import { BlitzoOrder, OrderStatus } from "../../types/BlitzoOrder";
import BusinessNavbar from "../../components/BusinessNavbar";
import {
  Calendar,
  Clock,
  Phone,
  Search,
  Bike,
  Package,
  CheckCircle,
  Route,
  MapPin,
  XCircle,
} from "lucide-react";
import { getScheduledOrders, refundAndCancelOrder } from "../../firebase/order";
import ShareButton from "../../components/resuable/ShareButton";
import CancelModal from "../../components/resuable/CancelModal";

const defaultCenter = { lat: 51.509865, lng: -0.118092 };
const googleMapsAPIKey = "AIzaSyDc8ZE2eaxXND5WTbOeZ5BZc008_ykqv2M";
const MINIMUM_DISTANCE_FOR_UPDATE = 0.05; // 50 meters in kilometers
const SUPPORT_NUMBER = "+447810778980";

const calculateDistance = (point1: Location, point2: Location) => {
  if (!point1 || !point2) return 0;

  const R = 6371; // Earth's radius in kilometers
  const dLat = ((point2.latitude - point1.latitude) * Math.PI) / 180;
  const dLon = ((point2.longitude - point1.longitude) * Math.PI) / 180;

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((point1.latitude * Math.PI) / 180) *
      Math.cos((point2.latitude * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};

interface MetricsType {
  distance: number;
  duration: number;
  lastFetchTime: number;
  lastStatus: OrderStatus | null;
  lastLocation: Location | null;
  displayDistance: number;
  displayDuration: number;
}

const LiveTracker = () => {
  const { orderId, view } = useParams();
  const navigate = useNavigate();
  const isAdmin = view === "admin";
  const [order, setOrder] = useState<BlitzoOrder | null>(null);
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [metrics, setMetrics] = useState<MetricsType>({
    distance: 0,
    duration: 0,
    lastFetchTime: 0,
    lastStatus: null,
    lastLocation: null,
    displayDistance: 0, // Add separate display values that only update on significant changes
    displayDuration: 0,
  });
  const [rider, setRider] = useState<any>(null);
  const mapRef = useRef<google.maps.Map | null>(null);
  const lastFetchRef = useRef<number | null>(null);

  // Cancel Modal
  const [showCancelModal, setShowCancelModal] = useState(false);

  const handleCancelOrder = async (reason: string) => {
    if (order) {
      await refundAndCancelOrder(order.id, "activeOrders");
      navigate("/business/track", { replace: true });
    }
  };

  const onLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
  }, []);

  const getStatusText = (status: OrderStatus) => {
    switch (status) {
      case "pending":
        return "Finding a Blitzer";
      case "accepted":
        return "Heading to Pickup";
      case "pickedUp":
        return "En Route to Dropoff";
      case "delivered":
        return "Delivered";
      default:
        return "Loading...";
    }
  };

  const shouldUpdate = (
    currentLocation: Location,
    lastLocation: Location | null,
    status: OrderStatus,
    lastStatus: OrderStatus | null
  ) => {
    if (!lastStatus) return true;
    // Always update if status has changed
    if (status !== lastStatus) return true;

    // Always update if we don't have a last location
    if (!lastLocation) return true;

    // Check if we've moved enough distance
    const distance = calculateDistance(currentLocation, lastLocation);
    return distance >= MINIMUM_DISTANCE_FOR_UPDATE;
  };

  const fetchMetrics = useCallback(
    async (orderData: BlitzoOrder) => {
      if (
        !orderData?.assignment?.blitzerLocation ||
        !orderData?.pickupLocation ||
        !orderData?.dropoffLocation
      ) {
        return;
      }

      const currentLocation = orderData.assignment.blitzerLocation;
      const currentStatus = orderData.status;

      // Check if we should update based on movement or status change
      if (
        !shouldUpdate(
          currentLocation,
          metrics.lastLocation,
          currentStatus,
          metrics.lastStatus
        )
      ) {
        return;
      }

      try {
        const currentTime = Date.now();

        // Prevent multiple fetches within 5 seconds
        if (lastFetchRef.current && currentTime - lastFetchRef.current < 5000) {
          return;
        }

        lastFetchRef.current = currentTime;

        const destination =
          currentStatus === "accepted"
            ? orderData.pickupLocation
            : orderData.dropoffLocation;

        const newMetrics: {
          distance: number;
          duration: number;
        } = (await fetchDistanceInMilesAndDurationInMinutes(
          currentLocation,
          destination
        )) as any;

        if (newMetrics.duration || newMetrics.distance) {
          setMetrics((prev) => ({
            ...prev,
            lastFetchTime: currentTime,
            lastStatus: currentStatus,
            lastLocation: currentLocation,
            // Only update display values when we have new data
            displayDistance: newMetrics.distance || prev.displayDistance,
            displayDuration: newMetrics.duration || prev.displayDuration,
            // Keep the raw values for comparison
            distance: newMetrics.distance,
            duration: newMetrics.duration,
          }));
        }
      } catch (error) {
        console.error("Error fetching metrics:", error);
      }
    },
    [metrics.lastLocation, metrics.lastStatus]
  );

  const updateMapBounds = useCallback((orderData: BlitzoOrder) => {
    if (!mapRef.current || !orderData) return;

    const bounds = new window.google.maps.LatLngBounds();

    // Always add pickup
    if (orderData.pickupLocation) {
      bounds.extend(
        new window.google.maps.LatLng(
          orderData.pickupLocation.latitude,
          orderData.pickupLocation.longitude
        )
      );
    }

    // Add dropoff if you like
    if (orderData.dropoffLocation) {
      bounds.extend(
        new window.google.maps.LatLng(
          orderData.dropoffLocation.latitude,
          orderData.dropoffLocation.longitude
        )
      );
    }

    // If we do have a rider location
    if (orderData.assignment?.blitzerLocation) {
      bounds.extend(
        new window.google.maps.LatLng(
          orderData.assignment.blitzerLocation.latitude,
          orderData.assignment.blitzerLocation.longitude
        )
      );
    }

    // If bounds is empty, fallback to a default center/zoom
    if (bounds.isEmpty()) {
      mapRef.current.setCenter(defaultCenter);
      mapRef.current.setZoom(12);
    } else {
      mapRef.current.fitBounds(bounds, {
        top: 50,
        bottom: 50,
        left: 50,
        right: 50,
      });
    }
  }, []);

  // Add support button handler
  const handleSupportClick = useCallback(() => {
    window.location.href = `tel:${SUPPORT_NUMBER}`;
  }, []);

  const fetchAndSetRider = useCallback(async () => {
    if (order?.assignment?.blitzerId) {
      const riderData = await getRider(order.assignment.blitzerId);
      setRider(riderData as any);
    }
  }, [order]);

  useEffect(() => {
    let unsubscribe: any;

    const fetchOrderAndSubscribe = async () => {
      if (!orderId) return;
      let orderData = await getActiveOrder(orderId);
      setOrder(orderData as BlitzoOrder);
      if (orderData) {
        fetchMetrics(orderData as BlitzoOrder);
      }
      unsubscribe = subscribeToActiveOrder(
        orderId,
        (updatedOrderData: BlitzoOrder) => {
          setOrder(updatedOrderData);
          fetchAndSetRider();
          fetchMetrics(updatedOrderData);
        }
      );
    };

    fetchOrderAndSubscribe();

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [orderId, fetchMetrics]);

  useEffect(() => {
    if (order) {
      updateMapBounds(order);
    }
  }, [order, updateMapBounds]);

  const riderLocation = order?.assignment?.blitzerLocation;
  const pickupLocation = order?.pickupLocation;
  const dropoffLocation = order?.dropoffLocation;

  const getEtaText = () => {
    if (!order || order.status === "delivered") return "Delivered";
    if (metrics.displayDuration === 0) return "Pickup in 15-30 minutes";

    const minutes = Math.max(1, Math.ceil(metrics.displayDuration));
    if (order.status === "accepted") {
      return `${minutes} minute${minutes !== 1 ? "s" : ""} to pickup`;
    } else if (order.status === "pickedUp") {
      return `${minutes} minute${minutes !== 1 ? "s" : ""} to delivery`;
    }
    return `${minutes} minute${minutes !== 1 ? "s" : ""}`;
  };

  const getCurvedPath = useCallback(
    (start: Location | undefined, end: Location | undefined) => {
      if (!start || !end) return [];

      // Convert to LatLng objects
      const startPoint = { lat: start.latitude, lng: start.longitude };
      const endPoint = { lat: end.latitude, lng: end.longitude };

      // Calculate the distance between points to adjust curve height dynamically
      const distance = Math.sqrt(
        Math.pow(endPoint.lat - startPoint.lat, 2) +
          Math.pow(endPoint.lng - startPoint.lng, 2)
      );

      // Make curve height proportional to distance
      const offsetDistance = distance * 0.2; // 20% of the total distance

      // Calculate midpoint
      const midPoint = {
        lat: (startPoint.lat + endPoint.lat) / 2,
        lng: (startPoint.lng + endPoint.lng) / 2,
      };

      // Calculate angle between points
      const angle = Math.atan2(
        endPoint.lat - startPoint.lat,
        endPoint.lng - startPoint.lng
      );

      // Create control point with increased offset
      const controlPoint = {
        lat: midPoint.lat + Math.cos(angle + Math.PI / 2) * offsetDistance,
        lng: midPoint.lng + Math.sin(angle + Math.PI / 2) * offsetDistance,
      };

      // Generate curve points using quadratic Bezier
      const curvePoints = [];
      const steps = 50; // Increased steps for smoother curve

      for (let i = 0; i <= steps; i++) {
        const t = i / steps;

        // Quadratic Bezier curve formula
        const lat =
          Math.pow(1 - t, 2) * startPoint.lat +
          2 * (1 - t) * t * controlPoint.lat +
          Math.pow(t, 2) * endPoint.lat;

        const lng =
          Math.pow(1 - t, 2) * startPoint.lng +
          2 * (1 - t) * t * controlPoint.lng +
          Math.pow(t, 2) * endPoint.lng;

        curvePoints.push({ lat, lng });
      }

      return curvePoints;
    },
    []
  );

  const getPathOptions = useCallback(
    () => ({
      geodesic: true,
      strokeColor: "#E54C4C", // Updated to Blitzo primary color
      strokeOpacity: 0,
      strokeWeight: 2,
      icons: [
        {
          icon: {
            path: "M 0,-1 0,1",
            strokeOpacity: 1,
            strokeWeight: 2.5,
            scale: 3,
          },
          offset: "0",
          repeat: "10px",
        },
      ],
    }),
    []
  );

  if (order && !order?.schedule.isNow) {
    return (
      <>
        <BusinessNavbar />
        <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-900 to-gray-800">
          <div className="max-w-3xl mx-auto px-4 pt-24">
            {/* Scheduled Order Header */}
            <div className="bg-gray-800/50 backdrop-blur-lg rounded-2xl border border-gray-700 p-6 mb-6">
              <div className="flex items-center gap-3 mb-6">
                <div className="p-3 rounded-xl bg-blue-500/10 border border-blue-500/20">
                  <Calendar className="text-blue-400" size={24} />
                </div>
                <div>
                  <h1 className="text-2xl font-bold text-white mb-1">
                    Scheduled Delivery
                  </h1>
                  <p className="text-gray-400">
                    Order #{order?.id.slice(0, 8)}
                  </p>
                </div>
              </div>

              {/* Schedule Details */}
              <div className="grid grid-cols-2 gap-4 mb-6">
                <div className="p-4 rounded-xl bg-gray-800/50 border border-gray-700">
                  <div className="flex items-center gap-2 mb-2">
                    <Clock size={18} className="text-gray-400" />
                    <span className="text-sm font-medium text-gray-300">
                      Delivery Time
                    </span>
                  </div>
                  <p className="text-lg font-medium text-white">
                    {order?.schedule.scheduledFor?.toLocaleString("en-GB", {
                      weekday: "short",
                      day: "numeric",
                      month: "short",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </p>
                </div>
                <div className="p-4 rounded-xl bg-gray-800/50 border border-gray-700">
                  <div className="flex items-center gap-2 mb-2">
                    <Route size={18} className="text-gray-400" />
                    <span className="text-sm font-medium text-gray-300">
                      Estimated Distance
                    </span>
                  </div>
                  <p className="text-lg font-medium text-white">
                    {metrics.displayDistance === 0
                      ? "-"
                      : `${metrics.displayDistance.toFixed(2)} mi`}
                  </p>
                </div>
              </div>

              {/* Locations */}
              <div className="space-y-4">
                <div className="p-4 rounded-xl bg-gray-800/50 border border-gray-700">
                  <div className="flex items-start gap-3">
                    <div className="p-2 rounded-lg bg-emerald-500/10 mt-1">
                      <MapPin size={18} className="text-emerald-400" />
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-400 mb-1">
                        Pickup Location
                      </p>
                      <h3 className="text-white font-medium mb-1">
                        {order?.pickupLocation.locationName}
                      </h3>
                      <p className="text-sm text-gray-500">
                        {order?.pickupLocation.detailedLocation}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="p-4 rounded-xl bg-gray-800/50 border border-gray-700">
                  <div className="flex items-start gap-3">
                    <div className="p-2 rounded-lg bg-blue-500/10 mt-1">
                      <MapPin size={18} className="text-blue-400" />
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-400 mb-1">
                        Dropoff Location
                      </p>
                      <h3 className="text-white font-medium mb-1">
                        {order?.dropoffLocation.locationName}
                      </h3>
                      <p className="text-sm text-gray-500">
                        {order?.dropoffLocation.detailedLocation}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Status Message */}
            <div className="bg-blue-500/10 border border-blue-500/20 rounded-xl p-4 text-center">
              <p className="text-blue-400">
                We'll notify you when your delivery starts
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="relative min-h-screen bg-gray-900">
      <BusinessNavbar />

      {/* Map Container */}
      <div className="absolute inset-0 pt-16">
        <GoogleMap
          options={{
            styles: mapStyle,
            mapTypeControlOptions: { mapTypeIds: [] },
            streetViewControl: false,
            fullscreenControl: false,
            zoomControl: false,
            mapTypeControl: false,
            disableDefaultUI: true,
          }}
          onLoad={onLoad}
          mapContainerStyle={{
            height: "100%",
            width: "100%",
          }}
          center={mapCenter}
        >
          {/* Curved path */}
          {riderLocation && order && (
            <Polyline
              path={getCurvedPath(
                riderLocation,
                order?.status === "accepted" ? pickupLocation : dropoffLocation
              )}
              options={getPathOptions()}
            />
          )}

          {/* Markers with updated styling */}
          {riderLocation && (
            <Marker
              position={{
                lat: riderLocation.latitude,
                lng: riderLocation.longitude,
              }}
              icon={{
                url: order.status === "accepted" ? bike : bikeBox,
                scaledSize: new window.google.maps.Size(40, 40),
              }}
            />
          )}
          {pickupLocation && order.status !== "pickedUp" && (
            <Marker
              position={{
                lat: pickupLocation.latitude,
                lng: pickupLocation.longitude,
              }}
              icon={{
                url: box,
                scaledSize: new window.google.maps.Size(40, 40),
              }}
            />
          )}
          {dropoffLocation && (
            <Marker
              position={{
                lat: dropoffLocation.latitude,
                lng: dropoffLocation.longitude,
              }}
              icon={{
                url: dropoff,
                scaledSize: new window.google.maps.Size(40, 40),
              }}
            />
          )}
        </GoogleMap>
      </div>
      {order?.status === "pending" && (
        <div className="fixed top-24 left-4 z-20 flex gap-2">
          <button
            onClick={() => setShowCancelModal(true)}
            className="px-4 py-2 rounded-lg bg-red-500/10 border border-red-500/20
        hover:bg-red-500/20 transition-colors text-red-400 flex items-center gap-2"
          >
            <XCircle size={18} />
            <span>Cancel</span>
          </button>
        </div>
      )}
      {/* Support and Share Buttons */}
      <div className="fixed top-24 right-4 z-20 flex gap-2">
        <ShareButton />
        <button
          onClick={handleSupportClick}
          className="px-4 py-2 rounded-lg bg-gray-800 
    hover:bg-gray-700 transition-colors text-white flex items-center gap-2"
        >
          <Phone size={18} />
          <span>Support</span>
        </button>
      </div>

      {/* Support Button */}
      <button
        onClick={handleSupportClick}
        className="fixed top-24 right-4 z-20 px-4 py-2 rounded-lg bg-gray-800 
        hover:bg-gray-700 transition-colors text-white flex items-center gap-2"
      >
        <Phone size={18} />
        <span>Support</span>
      </button>

      {/* Rider Info */}
      {rider && order && <RiderInfo rider={rider} order={order} />}

      {/* Info Overlay */}
      {order && (
        <InfoOverlay order={order} metrics={metrics} getEtaText={getEtaText} />
      )}

      <CancelModal
        isOpen={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        onConfirm={handleCancelOrder}
      />
    </div>
  );
};

export default LiveTracker;

// InfoOverlay component for the tracking details
const InfoOverlay: React.FC<{
  order: BlitzoOrder;
  metrics: MetricsType;
  getEtaText: () => string;
}> = ({ order, metrics, getEtaText }) => {
  const isScheduled = order.schedule && !order.schedule.isNow;

  if (isScheduled) {
    return (
      <div className="fixed bottom-0 left-0 right-0 p-4">
        <div className="max-w-3xl mx-auto">
          <div className="bg-gray-900/95 backdrop-blur-lg rounded-2xl border border-gray-700 p-6 shadow-xl">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-3">
                <div className="p-2 rounded-xl bg-blue-500/10 border border-blue-500/20">
                  <Calendar className="text-blue-400" size={24} />
                </div>
                <div>
                  <h2 className="text-lg font-medium text-white">
                    Scheduled Delivery
                  </h2>
                  <p className="text-sm text-gray-400">
                    Order #{order.id.slice(0, 8)}
                  </p>
                </div>
              </div>
              <div className="px-3 py-1 rounded-full bg-blue-500/10 border border-blue-500/20">
                <span className="text-sm font-medium text-blue-400">
                  Scheduled
                </span>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 mb-4">
              <div className="p-4 rounded-xl bg-gray-800/50 border border-gray-700">
                <div className="flex items-center gap-2 mb-2">
                  <Clock size={18} className="text-gray-400" />
                  <span className="text-sm font-medium text-gray-300">
                    Scheduled For
                  </span>
                </div>
                <p className="text-lg font-medium text-white">
                  {(order.schedule.scheduledFor as any)
                    .toDate()
                    .toLocaleString("en-GB", {
                      weekday: "short",
                      day: "numeric",
                      month: "short",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                </p>
              </div>
              <div className="p-4 rounded-xl bg-gray-800/50 border border-gray-700">
                <div className="flex items-center gap-2 mb-2">
                  <Route size={18} className="text-gray-400" />
                  <span className="text-sm font-medium text-gray-300">
                    Distance
                  </span>
                </div>
                <p className="text-lg font-medium text-white">
                  {metrics.displayDistance === 0
                    ? "-"
                    : `${metrics.displayDistance.toFixed(2)} mi`}
                </p>
              </div>
            </div>

            <div className="flex items-center justify-between text-sm text-gray-400">
              <span>We'll notify you when your delivery starts</span>
              <button
                className="px-4 py-2 rounded-lg bg-gray-800 hover:bg-gray-700 
                transition-colors text-white"
              >
                Track Another Order
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 p-4">
      <div className="max-w-3xl mx-auto">
        <div className="bg-gray-900/95 backdrop-blur-lg rounded-2xl border border-gray-700 shadow-xl">
          <div className="p-4 border-b border-gray-800">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <div
                  className={`p-2 rounded-xl ${getStatusColor(order.status)}`}
                >
                  {getStatusIcon(order.status)}
                </div>
                <div>
                  <h2 className="text-lg font-medium text-white">
                    {getStatusText(order.status)}
                  </h2>
                  <p className="text-sm text-gray-400">
                    Order #{order.id.slice(0, 8)}
                  </p>
                </div>
              </div>
              {order.status === "delivered" && (
                <div className="px-3 py-1 rounded-full bg-emerald-500/10 border border-emerald-500/20">
                  <span className="text-sm font-medium text-emerald-400">
                    Completed
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className="grid grid-cols-3 divide-x divide-gray-800">
            <div className="p-4">
              <span className="text-sm text-gray-400">Distance</span>
              <p className="text-lg font-medium text-white mt-1">
                {metrics.displayDistance === 0
                  ? "-"
                  : `${metrics.displayDistance.toFixed(2)} mi`}
              </p>
            </div>
            <div className="p-4">
              <span className="text-sm text-gray-400">ETA</span>
              <p className="text-lg font-medium text-white mt-1">
                {getEtaText()}
              </p>
            </div>
            <div className="p-4">
              <span className="text-sm text-gray-400">Status</span>
              <p className="text-lg font-medium text-white mt-1">
                {getProgressText(order.status)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Updated RiderInfo component
const RiderInfo: React.FC<{
  rider: any;
  order: BlitzoOrder;
}> = ({ rider, order }) => {
  if (!rider) return null;

  return (
    <div className="fixed top-20 left-0 right-0 p-4 z-10">
      <div className="max-w-3xl mx-auto">
        <div className="bg-gray-900/95 backdrop-blur-lg rounded-2xl border border-gray-700 p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <div
                className="w-12 h-12 rounded-full bg-blue-500/10 border border-blue-500/20
                flex items-center justify-center text-lg font-medium text-blue-400"
              >
                {rider.personalInfo.name.charAt(0)}
              </div>
              <div>
                <h3 className="font-medium text-white">
                  {rider.personalInfo.name.split(" ")[0]}
                </h3>
                <p className="text-sm text-gray-400">Your Blitzer</p>
              </div>
            </div>
            <button
              onClick={() =>
                (window.location.href = `tel:${rider.personalInfo.phoneNumber}`)
              }
              className="flex items-center gap-2 px-4 py-2 rounded-lg bg-gray-800 
                hover:bg-gray-700 transition-colors text-white"
            >
              <Phone size={18} />
              <span>Call Rider</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Helper functions for status colors and icons
const getStatusColor = (status: OrderStatus) => {
  switch (status) {
    case "pending":
      return "bg-red-500/10 border border-red-500/20";
    case "accepted":
      return "bg-blue-500/10 border border-blue-500/20";
    case "pickedUp":
      return "bg-purple-500/10 border border-purple-500/20";
    case "delivered":
      return "bg-emerald-500/10 border border-emerald-500/20";
    default:
      return "bg-gray-500/10 border border-gray-500/20";
  }
};

const getStatusIcon = (status: OrderStatus) => {
  switch (status) {
    case "pending":
      return <Search className="text-red-400" size={20} />;
    case "accepted":
      return <Bike className="text-blue-400" size={20} />;
    case "pickedUp":
      return <Package className="text-purple-400" size={20} />;
    case "delivered":
      return <CheckCircle className="text-emerald-400" size={20} />;
    default:
      return <Clock className="text-gray-400" size={20} />;
  }
};

const getProgressText = (status: OrderStatus) => {
  switch (status) {
    case "pending":
      return "Finding a Blitzer";
    case "accepted":
      return "Heading To Pickup";
    case "pickedUp":
      return "Picked up. Heading to Dropoff";
    case "delivered":
      return "Delivered";
    default:
      return "Unknown";
  }
};

const getStatusText = (status: OrderStatus) => {
  switch (status) {
    case "pending":
      return "Finding a Blitzer";
    case "accepted":
      return "Heading to Pickup";
    case "pickedUp":
      return "En Route to Dropoff";
    case "delivered":
      return "Delivered";
    default:
      return "Loading...";
  }
};

export const mapStyle = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.local",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
];
