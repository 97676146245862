import React, { useContext, useEffect, useState } from "react";
import {
  ArrowUpRight,
  CheckCircle,
  ChevronRight,
  Calendar,
  Clock,
  CreditCard,
  MapPin,
  Bike,
  Car,
  Truck,
  CloudLightning,
  Shield,
  FileCheck,
  UserCheck,
} from "lucide-react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
import DeliveryMan from "../Images/delivery-man.png";
import googlePlay from "../Images/google-play-badge.png";
import appleStore from "../Images/app-store-badge.png";
import WaitlistContext from "../Contexts/WaitlistContext";

// Styled components for gradient text effects
const GradientText = styled.span`
  background: linear-gradient(90deg, #e54c4c 0%, #ff6b6b 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
`;

const BlueGradient = styled.span`
  background: linear-gradient(90deg, #4ecafc 0%, #6a8cff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
`;

// Animated gradient border
const AnimatedBorder = styled.div`
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #0f121b;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-image: conic-gradient(
      transparent,
      rgba(229, 76, 76, 0.3),
      transparent 30%
    );
    animation: rotate 7s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 2px;
    top: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background: #0f121b;
    border-radius: 14px;
  }

  @keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }
`;

// Network Animation Component
const NetworkAnimation = () => {
  return (
    <div className="absolute inset-0 z-0 overflow-hidden opacity-40">
      <div className="absolute inset-0 opacity-10">
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `
              linear-gradient(to right, rgba(255,255,255,0.05) 1px, transparent 1px),
              linear-gradient(to bottom, rgba(255,255,255,0.05) 1px, transparent 1px)
            `,
            backgroundSize: "20px 20px",
          }}
        ></div>
      </div>

      {/* Animated dots */}
      <div className="absolute inset-0">
        {[...Array(25)].map((_, i) => (
          <div
            key={`red-${i}`}
            className="absolute w-1 h-1 rounded-full bg-[#E54C4C]/40"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animation: `float ${
                3 + Math.random() * 4
              }s infinite ease-in-out ${Math.random() * 2}s`,
            }}
          ></div>
        ))}
        {[...Array(20)].map((_, i) => (
          <div
            key={`blue-${i}`}
            className="absolute w-1 h-1 rounded-full bg-[#4ECAFC]/40"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animation: `float ${
                2 + Math.random() * 3
              }s infinite ease-in-out ${Math.random() * 2}s`,
            }}
          ></div>
        ))}
      </div>

      {/* Radial gradients */}
      <div
        className="absolute inset-0 opacity-40"
        style={{
          background:
            "radial-gradient(50% 50% at 50% 50%, rgba(229,76,76,0.08) 0%, rgba(10,13,20,0) 100%)",
        }}
      ></div>
      <div
        className="absolute inset-0 opacity-20"
        style={{
          background:
            "radial-gradient(70% 70% at 30% 30%, rgba(78,202,252,0.1) 0%, rgba(10,13,20,0) 100%)",
        }}
      ></div>

      {/* Glowing orbs */}
      <div
        className="absolute w-96 h-96 rounded-full"
        style={{
          background:
            "radial-gradient(circle, rgba(229,76,76,0.1) 0%, rgba(10,13,20,0) 70%)",
          left: "30%",
          top: "40%",
          filter: "blur(50px)",
          animation: "pulseGlow 8s infinite ease-in-out",
        }}
      ></div>
      <div
        className="absolute w-96 h-96 rounded-full"
        style={{
          background:
            "radial-gradient(circle, rgba(78,202,252,0.08) 0%, rgba(10,13,20,0) 70%)",
          right: "25%",
          top: "30%",
          filter: "blur(50px)",
          animation: "pulseGlow 10s infinite ease-in-out 2s",
        }}
      ></div>

      <style jsx>{`
        @keyframes float {
          0%,
          100% {
            transform: translateY(0) translateX(0);
          }
          50% {
            transform: translateY(-15px) translateX(8px);
          }
        }
        @keyframes pulseGlow {
          0%,
          100% {
            opacity: 0.3;
            transform: scale(1);
          }
          50% {
            opacity: 0.8;
            transform: scale(1.3);
          }
        }
      `}</style>
    </div>
  );
};

// Vehicle Type Component
const VehicleType = ({ icon: Icon, name }) => (
  <div className="flex flex-col items-center group cursor-pointer">
    <div className="relative mb-3">
      <div className="absolute -inset-2 bg-gradient-to-r from-[#E54C4C]/20 to-[#4ECAFC]/20 rounded-full blur-md opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
      <div className="relative w-14 h-14 bg-[#0F121B] rounded-full flex items-center justify-center border border-[#262D40] transform group-hover:scale-110 transition-all duration-500">
        <Icon
          className="text-gray-400 group-hover:text-[#E54C4C] transition-colors"
          size={24}
        />
      </div>
    </div>
    <span className="text-sm text-gray-400 group-hover:text-white transition-colors">
      {name}
    </span>
  </div>
);

// Benefit Card Component
const BenefitCard = ({ icon: Icon, title, description, delay = 0 }) => (
  <div
    className="bg-[#0F121B]/80 p-6 rounded-xl border border-[#262D40] hover:border-[#414D75] transition-all duration-300 h-full"
    data-aos="fade-up"
    data-aos-delay={delay}
  >
    <div className="w-12 h-12 bg-[#E54C4C]/10 rounded-xl flex items-center justify-center mb-4 text-[#E54C4C]">
      <Icon size={24} />
    </div>
    <h3 className="text-lg font-bold text-white mb-2">{title}</h3>
    <p className="text-gray-300 text-sm">{description}</p>
  </div>
);

// Compliance Step Component
const ComplianceStep = ({ number, title, description, delay = 0 }) => (
  <div
    className="flex items-start gap-4"
    data-aos="fade-up"
    data-aos-delay={delay}
  >
    <div className="w-8 h-8 rounded-full bg-[#E54C4C]/10 flex items-center justify-center flex-shrink-0 mt-1 text-[#E54C4C] font-bold">
      {number}
    </div>
    <div>
      <h3 className="text-lg font-semibold text-white mb-1">{title}</h3>
      <p className="text-gray-300 text-sm">{description}</p>
    </div>
  </div>
);

const JoinBlitzo = () => {
  const { setModalOpen } = useContext(WaitlistContext);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease-out",
    });
  }, []);

  return (
    <div className="min-h-screen bg-[#080A12] text-white">
      {/* Hero Section */}
      <div className="relative pt-40 pb-24 overflow-hidden">
        <NetworkAnimation />

        <div className="max-w-7xl mx-auto px-6 lg:px-8 relative z-10">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
            <div data-aos="fade-right">
              <div className="mb-4 inline-block px-4 py-1.5 rounded-full bg-[#E54C4C]/10 border border-[#E54C4C]/20 text-[#E54C4C] text-sm">
                Become a Blitzo Rider
              </div>
              <h1 className="text-4xl md:text-6xl font-bold text-white mb-6 leading-tight">
                Join Our <GradientText>Rider</GradientText> Network
              </h1>
              <p className="text-xl text-gray-300 leading-relaxed mb-8">
                Embrace the opportunity to earn on your terms. Select jobs that
                suit your schedule and get paid well for your dedication. We
                support all vehicle types.
              </p>

              <div className="flex flex-wrap gap-6 mb-12">
                <a
                  href="https://play.google.com/store/apps/details?id=com.blitzorider"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="group transition-transform hover:scale-105 duration-300"
                >
                  <img src={googlePlay} alt="Google Play" className="h-14" />
                </a>
                <a
                  href="https://apps.apple.com/co/app/blitzo-rider/id6504489093?l=en-GB"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="group transition-transform hover:scale-105 duration-300"
                >
                  <img src={appleStore} alt="App Store" className="h-14" />
                </a>
              </div>
            </div>

            <div data-aos="fade-left" className="hidden lg:block">
              <AnimatedBorder>
                <div className="bg-[#0F121B]/80 rounded-xl p-8">
                  <img
                    src={DeliveryMan}
                    alt="Delivery Person"
                    className="w-full h-auto rounded-lg object-cover transform hover:scale-[1.02] transition-transform duration-500"
                  />
                </div>
              </AnimatedBorder>
            </div>
          </div>
        </div>

        {/* Glowing orbs */}
        <div className="absolute top-0 left-0 w-64 h-64 rounded-full bg-[#4ECAFC]/10 blur-3xl opacity-30 animate-pulse-slow"></div>
        <div className="absolute bottom-0 right-0 w-64 h-64 rounded-full bg-[#E54C4C]/10 blur-3xl opacity-30 animate-pulse-slow-delay"></div>
      </div>

      {/* Vehicle Types Section */}
      <div className="py-24 bg-[#0A0D16] relative">
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top,_#E54C4C08,transparent_50%)]"></div>

        <div className="max-w-7xl mx-auto px-6 lg:px-8 relative z-10">
          <div className="text-center mb-16" data-aos="fade-up">
            <div className="mb-4 inline-block text-[#E54C4C] text-sm font-medium tracking-wider uppercase">
              All Vehicle Types Welcome
            </div>
            <h2 className="text-3xl md:text-5xl font-bold text-white mb-6">
              Delivery <GradientText>Your Way</GradientText>
            </h2>
            <p className="text-gray-300 max-w-2xl mx-auto">
              No matter what vehicle you drive or ride, there's a place for you
              in our network. Choose the delivery types that best suit your
              vehicle and preferences.
            </p>
          </div>

          <div
            className="flex justify-center flex-wrap gap-16 mb-12"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <VehicleType icon={Bike} name="Bicycle" />
            <VehicleType icon={CloudLightning} name="E-Bike" />
            <VehicleType icon={Car} name="Car" />
            <VehicleType icon={Truck} name="Van" />
          </div>

          <div className="text-center mt-12">
            <a
              href="#apply-now"
              className="px-8 py-3.5 bg-gradient-to-r from-[#E54C4C] to-[#FF6B6B] text-white rounded-lg font-medium inline-flex items-center shadow-lg shadow-[#E54C4C]/20 hover:shadow-xl hover:shadow-[#E54C4C]/30 transition-all duration-300"
            >
              Apply Now
              <ChevronRight
                className="ml-2 group-hover:translate-x-1 transition-transform"
                size={18}
              />
            </a>
          </div>
        </div>
      </div>

      {/* Benefits Section */}
      <div className="py-24 bg-[#080A12] relative overflow-hidden">
        <NetworkAnimation />

        <div className="max-w-7xl mx-auto px-6 lg:px-8 relative z-10">
          <div className="text-center mb-16" data-aos="fade-up">
            <div className="mb-4 inline-block px-4 py-1.5 rounded-full bg-[#4ECAFC]/10 border border-[#4ECAFC]/20 text-[#4ECAFC] text-sm">
              Why Choose Blitzo
            </div>
            <h2 className="text-3xl md:text-5xl font-bold text-white mb-6">
              Benefits That <BlueGradient>Make a Difference</BlueGradient>
            </h2>
            <p className="text-gray-300 max-w-2xl mx-auto">
              We've designed our rider program to provide the support,
              flexibility, and compensation you deserve.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            <BenefitCard
              icon={CreditCard}
              title="Top Industry Pay"
              description="Competitive rates that truly match your efforts, ensuring you're rewarded fairly for your work."
              delay={100}
            />

            <BenefitCard
              icon={Clock}
              title="Total Flexibility"
              description="Choose when you work and which deliveries to accept, making it easy to balance work with your lifestyle."
              delay={200}
            />

            <BenefitCard
              icon={MapPin}
              title="Preference-Based Selection"
              description="Take on deliveries that align with your preferences and location, maximizing efficiency and earnings."
              delay={300}
            />

            <BenefitCard
              icon={CreditCard}
              title="Instant Withdrawals"
              description="Request and receive payments swiftly, giving you quick access to your earnings when you need them."
              delay={400}
            />
          </div>
        </div>
      </div>

      {/* Application Process Section */}
      <div className="py-24 bg-[#0A0D16] relative" id="apply-now">
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top,_#4ECAFC08,transparent_50%)]"></div>

        <div className="max-w-7xl mx-auto px-6 lg:px-8 relative z-10">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
            <div data-aos="fade-right">
              <div className="mb-4 inline-block text-[#4ECAFC] text-sm font-medium tracking-wider uppercase">
                Getting Started
              </div>
              <h2 className="text-3xl md:text-4xl font-bold text-white mb-6">
                Simple <BlueGradient>Application</BlueGradient> Process
              </h2>

              <p className="text-gray-300 mb-8">
                Joining the Blitzo rider network is straightforward. We've
                designed a streamlined process to verify your eligibility and
                get you earning as quickly as possible.
              </p>

              <div className="space-y-8 mb-8">
                <ComplianceStep
                  number="1"
                  title="Download the App"
                  description="Get the Blitzo Rider app from Google Play or the App Store and create your account."
                  delay={100}
                />

                <ComplianceStep
                  number="2"
                  title="Right to Work Verification"
                  description="Upload your proof of right to work in the UK through our secure verification system."
                  delay={200}
                />

                <ComplianceStep
                  number="3"
                  title="DBS Check"
                  description="Complete a standard Disclosure and Barring Service check to ensure rider and customer safety."
                  delay={300}
                />

                <ComplianceStep
                  number="4"
                  title="KYC Process"
                  description="Complete our Know Your Customer process to verify your identity and set up your payment details."
                  delay={400}
                />

                <ComplianceStep
                  number="5"
                  title="Start Delivering"
                  description="Once approved, you can immediately start accepting delivery requests that match your preferences."
                  delay={500}
                />
              </div>

              <div className="flex gap-6">
                <a
                  href="https://play.google.com/store/apps/details?id=com.blitzorider"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="px-8 py-3.5 bg-gradient-to-r from-[#4ECAFC] to-[#6A8CFF] text-white rounded-lg font-medium flex items-center shadow-lg shadow-[#4ECAFC]/20 hover:shadow-xl hover:shadow-[#4ECAFC]/30 transition-all duration-300"
                >
                  Download Android App
                  <ArrowUpRight className="ml-2" size={18} />
                </a>

                <a
                  href="https://apps.apple.com/co/app/blitzo-rider/id6504489093?l=en-GB"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="px-8 py-3.5 bg-[#0F121B] border border-[#4ECAFC]/30 text-[#4ECAFC] rounded-lg font-medium flex items-center hover:border-[#4ECAFC] transition-all duration-300"
                >
                  Download iOS App
                  <ArrowUpRight className="ml-2" size={18} />
                </a>
              </div>
            </div>

            <div data-aos="fade-left">
              <div className="lg:pl-8">
                <div className="bg-[#0F121B] rounded-xl p-6 mb-8 border border-[#262D40]">
                  <div className="flex items-start gap-4">
                    <div className="w-10 h-10 rounded-full bg-[#4ECAFC]/10 flex items-center justify-center flex-shrink-0 mt-1">
                      <Shield className="text-[#4ECAFC]" size={20} />
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold text-white mb-1">
                        Compliance Requirements
                      </h3>
                      <p className="text-gray-300 text-sm">
                        We take safety and compliance seriously. Our
                        verification processes help ensure a secure and
                        trustworthy delivery experience for everyone.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                  <div className="bg-[#0F121B] rounded-xl p-6 border border-[#262D40]">
                    <div className="flex flex-col h-full">
                      <div className="w-10 h-10 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mb-4">
                        <FileCheck className="text-[#E54C4C]" size={20} />
                      </div>
                      <h3 className="text-lg font-semibold text-white mb-2">
                        Document Requirements
                      </h3>
                      <ul className="text-gray-300 text-sm space-y-2 mt-2">
                        <li className="flex items-start">
                          <CheckCircle
                            className="text-[#E54C4C] mr-2 flex-shrink-0 mt-1"
                            size={14}
                          />
                          <span>Valid photo ID (Passport/Driving license)</span>
                        </li>
                        <li className="flex items-start">
                          <CheckCircle
                            className="text-[#E54C4C] mr-2 flex-shrink-0 mt-1"
                            size={14}
                          />
                          <span>Proof of address (less than 3 months old)</span>
                        </li>
                        <li className="flex items-start">
                          <CheckCircle
                            className="text-[#E54C4C] mr-2 flex-shrink-0 mt-1"
                            size={14}
                          />
                          <span>Vehicle insurance (if applicable)</span>
                        </li>
                        <li className="flex items-start">
                          <CheckCircle
                            className="text-[#E54C4C] mr-2 flex-shrink-0 mt-1"
                            size={14}
                          />
                          <span>Bank account details for payments</span>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="bg-[#0F121B] rounded-xl p-6 border border-[#262D40]">
                    <div className="flex flex-col h-full">
                      <div className="w-10 h-10 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mb-4">
                        <UserCheck className="text-[#E54C4C]" size={20} />
                      </div>
                      <h3 className="text-lg font-semibold text-white mb-2">
                        Verification Timeline
                      </h3>
                      <p className="text-gray-300 text-sm mb-4">
                        Our streamlined verification process is designed to get
                        you on the road quickly while ensuring compliance.
                      </p>
                      <div className="mt-auto text-center py-2 px-4 bg-[#E54C4C]/10 rounded-lg">
                        <span className="text-[#E54C4C] font-medium">
                          Average approval time: 1-3 business days
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Final CTA Section */}
      <div className="relative bg-[#080A12] py-24 overflow-hidden">
        <NetworkAnimation />

        <div className="max-w-7xl mx-auto px-6 lg:px-8 relative z-10">
          <div className="text-center" data-aos="fade-up">
            <div className="mb-4 inline-block text-[#E54C4C] text-sm font-medium tracking-wider uppercase">
              Ready to Join?
            </div>
            <h2 className="text-3xl md:text-5xl font-bold text-white mb-6">
              Start Your <GradientText>Blitzo</GradientText> Journey Today
            </h2>
            <p className="text-gray-300 max-w-2xl mx-auto mb-10">
              Join our growing network of riders and enjoy the benefits of
              flexible work with competitive pay. Download the app now to get
              started.
            </p>

            <div className="flex flex-wrap justify-center gap-6">
              <a
                href="https://play.google.com/store/apps/details?id=com.blitzorider"
                target="_blank"
                rel="noopener noreferrer"
                className="group transition-transform hover:scale-105 duration-300"
              >
                <img src={googlePlay} alt="Google Play" className="h-14" />
              </a>
              <a
                href="https://apps.apple.com/co/app/blitzo-rider/id6504489093?l=en-GB"
                target="_blank"
                rel="noopener noreferrer"
                className="group transition-transform hover:scale-105 duration-300"
              >
                <img src={appleStore} alt="App Store" className="h-14" />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Animations for fade and pulse effects */}
      <style jsx>{`
        @keyframes pulse-slow {
          0%,
          100% {
            transform: scale(1);
            opacity: 0.3;
          }
          50% {
            transform: scale(1.1);
            opacity: 0.5;
          }
        }

        @keyframes pulse-slow-delay {
          0%,
          100% {
            transform: scale(1);
            opacity: 0.3;
          }
          50% {
            transform: scale(1.15);
            opacity: 0.6;
          }
        }
      `}</style>
    </div>
  );
};

export default JoinBlitzo;
