import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { ArrowRight, Users, Clock, Package, Zap } from "lucide-react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

// Gradient text for emphasis in headings
const GradientText = styled.span`
  background: linear-gradient(90deg, #e54c4c 0%, #ff6b6b 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
`;

// Impact Card Component (simplified)
const ImpactCard = ({
  number,
  suffix,
  title,
  description,
  color = "#E54C4C",
}) => (
  <div className="bg-[#0F121B] rounded-xl p-6 border border-[#262D40] hover:border-[#414D75] hover:shadow-lg transition-all">
    <div className={`text-3xl md:text-4xl font-bold mb-2 text-[${color}]`}>
      {number}
      {suffix}
    </div>
    <h4 className="text-lg font-semibold text-white mb-1">{title}</h4>
    <p className="text-gray-400 text-sm">{description}</p>
  </div>
);

// Improved Sequential T-Shaped Connector Animation
const SequentialConnector = () => {
  // Animation states: 0 = start/reset, 1-3 = each path
  const [activePathState, setActivePathState] = useState(0);

  // Cycle through the animation states
  useEffect(() => {
    const interval = setInterval(() => {
      setActivePathState((prev) => (prev + 1) % 4); // Cycle through states 0-3
    }, 3000); // 3 seconds per animation state

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="my-6 py-2 relative" data-aos="fade-up" data-aos-delay="300">
      {/* Message Box */}
      <div className="bg-[#0C0E18] border border-[#1E2436] rounded-xl shadow-lg max-w-3xl mx-auto px-8 py-4 text-center z-10 relative mb-5">
        <p className="text-white text-lg">
          Our Businesses adopting{" "}
          <span className="text-[#E54C4C] font-semibold">
            Same-Hour Delivery
          </span>{" "}
          see this impact
        </p>
      </div>

      {/* Connector lines container */}
      <div className="relative h-32">
        {/* Always-visible gray base lines */}

        {/* 1. Main vertical stem */}
        <div className="absolute left-1/2 top-0 w-[1px] h-16 bg-[#262D40] transform -translate-x-1/2">
          {/* Top dot */}
          <div className="absolute -top-1 left-1/2 w-2 h-2 rounded-full bg-[#262D40] transform -translate-x-1/2"></div>

          {/* Center intersection dot */}
          <div className="absolute bottom-0 left-1/2 w-2 h-2 rounded-full bg-[#262D40] transform -translate-x-1/2"></div>
        </div>

        {/* 2. Horizontal crossbar */}
        <div className="absolute left-[16.666%] right-[16.666%] top-16 h-[1px] bg-[#262D40]"></div>

        {/* 3. Left vertical drop */}
        <div className="absolute left-[16.666%] top-16 w-[1px] h-16 bg-[#262D40] transform -translate-x-1/2">
          {/* Bottom dot */}
          <div className="absolute bottom-0 left-1/2 w-2 h-2 rounded-full bg-[#262D40] transform -translate-x-1/2"></div>
        </div>

        {/* 4. Center vertical drop */}
        <div className="absolute left-1/2 top-16 w-[1px] h-16 bg-[#262D40] transform -translate-x-1/2">
          {/* Bottom dot */}
          <div className="absolute bottom-0 left-1/2 w-2 h-2 rounded-full bg-[#262D40] transform -translate-x-1/2"></div>
        </div>

        {/* 5. Right vertical drop */}
        <div className="absolute left-[83.333%] top-16 w-[1px] h-16 bg-[#262D40] transform -translate-x-1/2">
          {/* Bottom dot */}
          <div className="absolute bottom-0 left-1/2 w-2 h-2 rounded-full bg-[#262D40] transform -translate-x-1/2"></div>
        </div>

        {/* Animated Glowing Paths */}

        {/* First path - To left card */}
        {activePathState === 1 && (
          <>
            {/* Main stem */}
            <div className="absolute left-1/2 top-0 w-[1px] h-16 bg-gradient-to-b from-[#E54C4C] to-[#E54C4C] transform -translate-x-1/2 animate-glow-line">
              {/* Top glowing dot */}
              <div className="absolute -top-1 left-1/2 w-3 h-3 rounded-full bg-[#E54C4C] transform -translate-x-1/2 shadow-glow-red"></div>

              {/* Center glowing dot */}
              <div className="absolute bottom-0 left-1/2 w-3 h-3 rounded-full bg-[#E54C4C] transform -translate-x-1/2 shadow-glow-red"></div>
            </div>

            {/* Left section of horizontal bar */}
            <div className="absolute left-[16.666%] right-1/2 top-16 h-[1px] bg-gradient-to-r from-[#E54C4C] to-[#E54C4C] animate-glow-line"></div>

            {/* Left drop */}
            <div className="absolute left-[16.666%] top-16 w-[1px] h-16 bg-gradient-to-b from-[#E54C4C] to-[#E54C4C] transform -translate-x-1/2 animate-glow-line">
              {/* Bottom glowing dot */}
              <div className="absolute bottom-0 left-1/2 w-3 h-3 rounded-full bg-[#E54C4C] transform -translate-x-1/2 shadow-glow-red"></div>
            </div>
          </>
        )}

        {/* Second path - To middle card */}
        {activePathState === 2 && (
          <>
            {/* Main stem */}
            <div className="absolute left-1/2 top-0 w-[1px] h-16 bg-gradient-to-b from-[#E54C4C] to-[#4ECAFC] transform -translate-x-1/2 animate-glow-line">
              {/* Top glowing dot */}
              <div className="absolute -top-1 left-1/2 w-3 h-3 rounded-full bg-[#E54C4C] transform -translate-x-1/2 shadow-glow-red"></div>

              {/* Center glowing dot */}
              <div className="absolute bottom-0 left-1/2 w-3 h-3 rounded-full bg-[#4ECAFC] transform -translate-x-1/2 shadow-glow-blue"></div>
            </div>

            {/* Center drop */}
            <div className="absolute left-1/2 top-16 w-[1px] h-16 bg-gradient-to-b from-[#4ECAFC] to-[#4ECAFC] transform -translate-x-1/2 animate-glow-line">
              {/* Bottom glowing dot */}
              <div className="absolute bottom-0 left-1/2 w-3 h-3 rounded-full bg-[#4ECAFC] transform -translate-x-1/2 shadow-glow-blue"></div>
            </div>
          </>
        )}

        {/* Third path - To right card */}
        {activePathState === 3 && (
          <>
            {/* Main stem */}
            <div className="absolute left-1/2 top-0 w-[1px] h-16 bg-gradient-to-b from-[#E54C4C] to-[#E54C4C] transform -translate-x-1/2 animate-glow-line">
              {/* Top glowing dot */}
              <div className="absolute -top-1 left-1/2 w-3 h-3 rounded-full bg-[#E54C4C] transform -translate-x-1/2 shadow-glow-red"></div>

              {/* Center glowing dot */}
              <div className="absolute bottom-0 left-1/2 w-3 h-3 rounded-full bg-[#E54C4C] transform -translate-x-1/2 shadow-glow-red"></div>
            </div>

            {/* Right section of horizontal bar */}
            <div className="absolute left-1/2 right-[16.666%] top-16 h-[1px] bg-gradient-to-r from-[#E54C4C] to-[#E54C4C] animate-glow-line"></div>

            {/* Right drop */}
            <div className="absolute left-[83.333%] top-16 w-[1px] h-16 bg-gradient-to-b from-[#E54C4C] to-[#E54C4C] transform -translate-x-1/2 animate-glow-line">
              {/* Bottom glowing dot */}
              <div className="absolute bottom-0 left-1/2 w-3 h-3 rounded-full bg-[#E54C4C] transform -translate-x-1/2 shadow-glow-red"></div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const BusinessImpactNarrative = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  const navigate = useNavigate();

  return (
    <section className="py-20 bg-[#0A0C16] relative overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        {/* Section Header */}
        <div className="text-center mb-14 max-w-3xl mx-auto" data-aos="fade-up">
          <h2 className="text-3xl md:text-5xl font-bold text-white mb-4 leading-tight">
            Why <GradientText>Same‑Hour</GradientText> is the Future
          </h2>
          <p className="text-gray-300">
            85% of UK customers expect deliveries within an hour—same-day isn't
            enough. Missing this mark can cost brands dearly.
          </p>
        </div>

        {/* Sequential Connector Animation */}
        <SequentialConnector />

        {/* Impact Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-1">
          <div data-aos="fade-up">
            <ImpactCard
              number={23}
              suffix="%"
              title="Higher Cart Conversions"
              description="Instant fulfillment options boosts cart success. Instant gratification leads to improved customer retention,"
              color="#E54C4C"
            />
          </div>
          <div data-aos="fade-up" data-aos-delay="100">
            <ImpactCard
              number={100}
              suffix="%"
              title="Delivery Success Rate"
              description="From order to doorstep, we ensure all variables - traffic, weather, and more - are accounted for."
              color="#4ECAFC"
            />
          </div>
          <div data-aos="fade-up" data-aos-delay="200">
            <ImpactCard
              number={160}
              suffix="%"
              title="Increased Processing Capacity"
              description="Our AI Agents run and scale your operations without extra overhead."
              color="#E54C4C"
            />
          </div>
        </div>

        {/* Benefit Summary & CTA */}
        <div
          className="relative pt-6 border-t border-[#262D40]"
          data-aos="fade-up"
        >
          <div className="max-w-3xl mx-auto text-center pb-4">
            <p className="text-gray-300 mb-8">
              By reducing overhead and delivering within the hour, our clients
              enjoy higher satisfaction, repeat purchases, and streamlined
              efficiency.
            </p>
            <div className="relative inline-block">
              <button
                onClick={() => navigate("/blog")}
                className="relative inline-flex items-center px-6 py-3 bg-gradient-to-r from-[#E54C4C] to-[#ff6b6b] text-white rounded-lg hover:from-[#ff6b6b] hover:to-[#E54C4C] transition-all duration-300 shadow-lg shadow-[#E54C4C]/20 font-medium"
              >
                <span>See Success Stories</span>
                <ArrowRight className="ml-2 transition-transform" size={16} />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Background Grid and Glows */}
      <div className="absolute inset-0 z-0 opacity-5">
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `
              linear-gradient(to right, rgba(255,255,255,0.05) 1px, transparent 1px),
              linear-gradient(to bottom, rgba(255,255,255,0.05) 1px, transparent 1px)
            `,
            backgroundSize: "40px 40px",
          }}
        ></div>
      </div>
      <div className="absolute top-0 left-0 w-64 h-64 rounded-full bg-[#4ECAFC]/10 blur-3xl opacity-30 animate-pulse-slow"></div>
      <div className="absolute bottom-0 right-0 w-64 h-64 rounded-full bg-[#E54C4C]/10 blur-3xl opacity-30 animate-pulse-slow-delay"></div>

      {/* Extra Animations */}
      <style jsx>{`
        @keyframes pulse-slow {
          0%,
          100% {
            transform: scale(1);
            opacity: 0.3;
          }
          50% {
            transform: scale(1.1);
            opacity: 0.5;
          }
        }

        @keyframes pulse-slow-delay {
          0%,
          100% {
            transform: scale(1);
            opacity: 0.3;
          }
          50% {
            transform: scale(1.15);
            opacity: 0.6;
          }
        }

        @keyframes glow-line {
          0% {
            opacity: 0.4;
          }
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0.4;
          }
        }

        .animate-pulse-slow {
          animation: pulse-slow 8s infinite ease-in-out;
        }

        .animate-pulse-slow-delay {
          animation: pulse-slow-delay 12s infinite ease-in-out;
        }

        .animate-glow-line {
          animation: glow-line 2s infinite ease-in-out;
        }

        .shadow-glow-red {
          box-shadow: 0 0 6px 2px rgba(229, 76, 76, 0.8);
        }

        .shadow-glow-blue {
          box-shadow: 0 0 6px 2px rgba(78, 202, 252, 0.8);
        }
      `}</style>
    </section>
  );
};

export default BusinessImpactNarrative;
