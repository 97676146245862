import React, { useEffect, useState } from "react";
import DarkNavbar from "../components/DarkNavbar";
import Footer from "../components/footer";
import BottomCTA from "../components/BottomCta";
import {
  Truck,
  Clock,
  Leaf,
  Users,
  ArrowRight,
  Droplet,
  Building,
  ShoppingBag,
  Star,
  MapPin,
  HeadphonesIcon,
  ClipboardCheck,
  Check,
  ChevronRight,
} from "lucide-react";
import AOS from "aos";
import "aos/dist/aos.css";
import styled from "styled-components";
import SpeakToSalesModal from "../components/SpeakToSalesModal";

// Gradient text component
const GradientText = styled.span`
  background: linear-gradient(90deg, #e54c4c 0%, #ff6b6b 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
`;

// Blue gradient text component
const BlueGradient = styled.span`
  background: linear-gradient(90deg, #4ecafc 0%, #6a8cff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
`;

// Animated Gradient Border
const AnimatedBorder = styled.div`
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #0f121b;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-image: conic-gradient(
      transparent,
      rgba(229, 76, 76, 0.3),
      transparent 30%
    );
    animation: rotate 7s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 2px;
    top: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background: #0f121b;
    border-radius: 14px;
  }

  @keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }
`;

// Blue Animated Gradient Border
const BlueAnimatedBorder = styled(AnimatedBorder)`
  &::before {
    background-image: conic-gradient(
      transparent,
      rgba(78, 202, 252, 0.3),
      transparent 30%
    );
  }
`;

// Network Animation
const NetworkAnimation = () => {
  return (
    <div className="absolute inset-0 z-0 overflow-hidden opacity-40">
      <div className="absolute inset-0 opacity-10">
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `
              linear-gradient(to right, rgba(255,255,255,0.05) 1px, transparent 1px),
              linear-gradient(to bottom, rgba(255,255,255,0.05) 1px, transparent 1px)
            `,
            backgroundSize: "20px 20px",
          }}
        ></div>
      </div>

      {/* Animated dots */}
      <div className="absolute inset-0">
        {[...Array(25)].map((_, i) => (
          <div
            key={`red-${i}`}
            className="absolute w-1 h-1 rounded-full bg-[#E54C4C]/40"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animation: `float ${
                3 + Math.random() * 4
              }s infinite ease-in-out ${Math.random() * 2}s`,
            }}
          ></div>
        ))}
        {[...Array(20)].map((_, i) => (
          <div
            key={`blue-${i}`}
            className="absolute w-1 h-1 rounded-full bg-[#4ECAFC]/40"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animation: `float ${
                2 + Math.random() * 3
              }s infinite ease-in-out ${Math.random() * 2}s`,
            }}
          ></div>
        ))}
      </div>

      {/* Radial gradients */}
      <div
        className="absolute inset-0 opacity-40"
        style={{
          background:
            "radial-gradient(50% 50% at 50% 50%, rgba(229,76,76,0.08) 0%, rgba(10,13,20,0) 100%)",
        }}
      ></div>
      <div
        className="absolute inset-0 opacity-20"
        style={{
          background:
            "radial-gradient(70% 70% at 30% 30%, rgba(78,202,252,0.1) 0%, rgba(10,13,20,0) 100%)",
        }}
      ></div>

      {/* Glowing orbs */}
      <div
        className="absolute w-96 h-96 rounded-full"
        style={{
          background:
            "radial-gradient(circle, rgba(229,76,76,0.1) 0%, rgba(10,13,20,0) 70%)",
          left: "30%",
          top: "40%",
          filter: "blur(50px)",
          animation: "pulseGlow 8s infinite ease-in-out",
        }}
      ></div>
      <div
        className="absolute w-96 h-96 rounded-full"
        style={{
          background:
            "radial-gradient(circle, rgba(78,202,252,0.08) 0%, rgba(10,13,20,0) 70%)",
          right: "25%",
          top: "30%",
          filter: "blur(50px)",
          animation: "pulseGlow 10s infinite ease-in-out 2s",
        }}
      ></div>

      <style jsx>{`
        @keyframes float {
          0%,
          100% {
            transform: translateY(0) translateX(0);
          }
          50% {
            transform: translateY(-15px) translateX(8px);
          }
        }
        @keyframes pulseGlow {
          0%,
          100% {
            opacity: 0.3;
            transform: scale(1);
          }
          50% {
            opacity: 0.8;
            transform: scale(1.3);
          }
        }
      `}</style>
    </div>
  );
};

const MilkRuns = () => {
  const [showSalesModal, setShowSalesModal] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <div className="min-h-screen bg-[#080A12] text-white">
      <SpeakToSalesModal
        isOpen={showSalesModal}
        onClose={() => setShowSalesModal(false)}
      />
      <DarkNavbar />

      {/* Hero Section */}
      <div className="relative pt-32 pb-24 overflow-hidden">
        <NetworkAnimation />

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="text-center" data-aos="fade-up">
            <div className="mb-8 max-w-max mx-auto px-4 py-1.5 rounded-full bg-[#E54C4C]/10 border border-[#E54C4C]/20 text-[#E54C4C] text-center text-sm">
              Farm to Customer Delivery
            </div>
            <h1 className="text-4xl md:text-6xl font-bold text-white mb-6">
              Farm-Fresh Milk
              <br />
              <GradientText>Delivered Same Day</GradientText>
            </h1>
            <p className="mt-6 max-w-2xl mx-auto text-xl text-gray-300">
              Enabling dairy businesses to deliver farm-fresh milk to London
              customers on the same day
            </p>
          </div>
        </div>

        {/* Glowing orbs */}
        <div className="absolute top-0 left-0 w-64 h-64 rounded-full bg-[#4ECAFC]/10 blur-3xl opacity-30 animate-pulse-slow"></div>
        <div className="absolute bottom-0 right-0 w-64 h-64 rounded-full bg-[#E54C4C]/10 blur-3xl opacity-30 animate-pulse-slow-delay"></div>
      </div>

      {/* How It Works Section */}
      <div className="py-24 bg-[#0A0D16] relative">
        <div className="absolute inset-0 bg-[linear-gradient(30deg,#E54C4C05_12%,transparent_12.5%,transparent_87%,#E54C4C05_87.5%)] bg-[length:20px_20px]"></div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <h2
            className="text-3xl md:text-5xl font-bold text-center mb-16"
            data-aos="fade-up"
          >
            How It <GradientText>Works</GradientText>
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <AnimatedBorder>
              <div
                className="relative p-8 bg-[#0F121B]/80 rounded-xl h-full"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="absolute -top-0 -left-0 w-12 h-12 bg-gradient-to-r from-[#E54C4C] to-[#FF6B6B] rounded-xl flex items-center justify-center text-white font-bold shadow-lg shadow-[#E54C4C]/20">
                  1
                </div>
                <div className="pt-6">
                  <Truck className="w-10 h-10 text-[#E54C4C] mb-4" />
                  <h3 className="text-xl font-semibold mb-2 text-white">
                    Morning Collection
                  </h3>
                  <p className="text-gray-300">
                    We collect fresh milk from local dairy farms in the early
                    morning
                  </p>
                </div>
              </div>
            </AnimatedBorder>

            <BlueAnimatedBorder>
              <div
                className="relative p-8 bg-[#0F121B]/80 rounded-xl h-full"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="absolute -top-0 -left-0 w-12 h-12 bg-gradient-to-r from-[#4ECAFC] to-[#6A8CFF] rounded-xl flex items-center justify-center text-white font-bold shadow-lg shadow-[#4ECAFC]/20">
                  2
                </div>
                <div className="pt-6">
                  <Building className="w-10 h-10 text-[#4ECAFC] mb-4" />
                  <h3 className="text-xl font-semibold mb-2 text-white">
                    London Hub
                  </h3>
                  <p className="text-gray-300">
                    Milk is brought to our London hub and prepared for local
                    distribution
                  </p>
                </div>
              </div>
            </BlueAnimatedBorder>

            <AnimatedBorder>
              <div
                className="relative p-8 bg-[#0F121B]/80 rounded-xl h-full"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="absolute -top-0 -left-0 w-12 h-12 bg-gradient-to-r from-[#E54C4C] to-[#FF6B6B] rounded-xl flex items-center justify-center text-white font-bold shadow-lg shadow-[#E54C4C]/20">
                  3
                </div>
                <div className="pt-6">
                  <ShoppingBag className="w-10 h-10 text-[#E54C4C] mb-4" />
                  <h3 className="text-xl font-semibold mb-2 text-white">
                    Same-Day Delivery
                  </h3>
                  <p className="text-gray-300">
                    Efficient delivery to your customers across London
                  </p>
                </div>
              </div>
            </AnimatedBorder>
          </div>
        </div>
      </div>

      {/* Benefits Section */}
      <div className="py-24 bg-[#080A12] relative overflow-hidden">
        <NetworkAnimation />

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="text-center mb-16" data-aos="fade-up">
            <div className="mb-4 inline-block px-4 py-1.5 rounded-full bg-[#E54C4C]/10 border border-[#E54C4C]/20 text-[#E54C4C] text-sm">
              Milk Run Solutions
            </div>
            <h2 className="text-4xl font-bold mb-4 text-white">
              Optimize Your <GradientText>Daily Milk Runs</GradientText>
            </h2>
            <p className="text-lg text-gray-300 max-w-2xl mx-auto">
              Transform your milk delivery operations with our specialized milk
              run service, helping you collect from farms and deliver across
              London in a single day
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              {
                icon: Clock,
                title: "Same-Day Freshness",
                desc: "From farm to customer within hours",
                color: "#E54C4C",
              },
              {
                icon: Leaf,
                title: "Eco-Friendly",
                desc: "Sustainable delivery using electric vehicles",
                color: "#4ECAFC",
              },
              {
                icon: Droplet,
                title: "Temperature Controlled",
                desc: "Maintained at optimal temperature throughout",
                color: "#E54C4C",
              },
              {
                icon: Users,
                title: "Customer Satisfaction",
                desc: "Reliable and consistent delivery service",
                color: "#4ECAFC",
              },
            ].map((item, index) => (
              <div
                key={index}
                className="bg-[#0F121B]/80 p-8 border border-[#262D40] rounded-xl hover:border-[#414D75] transition-all duration-300 group"
                data-aos="fade-up"
                data-aos-delay={index * 100}
              >
                <div className="relative mb-8">
                  <div
                    className={`w-16 h-16 bg-[${item.color}]/10 rounded-xl flex items-center justify-center group-hover:scale-110 transition-transform duration-300`}
                  >
                    <item.icon className={`w-8 h-8 text-[${item.color}]`} />
                  </div>
                </div>
                <h3
                  className={`text-xl font-semibold mb-3 text-white group-hover:text-[${item.color}] transition-colors`}
                >
                  {item.title}
                </h3>
                <p className="text-gray-400 leading-relaxed">{item.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Service Features Section */}
      <div className="py-24 bg-[#0A0D16] relative overflow-hidden">
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top,_#E54C4C08,transparent_50%)]"></div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="text-center mb-16" data-aos="fade-up">
            <div className="mb-4 inline-block text-[#E54C4C] text-sm font-medium tracking-wider uppercase">
              Benefits
            </div>
            <h2 className="text-4xl md:text-5xl font-bold text-white mb-6">
              Exceptional <GradientText>Service</GradientText>, Every Step
            </h2>
            <div className="w-20 h-1 bg-gradient-to-r from-[#E54C4C] to-[#FF6B6B] mx-auto mb-6 rounded-full"></div>
            <p className="text-lg text-gray-300 max-w-2xl mx-auto">
              We go above and beyond to ensure your milk delivery operations run
              smoothly
            </p>
          </div>

          <div className="relative">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {[
                {
                  icon: Star,
                  title: "White Glove Service",
                  desc: "Premium handling of your dairy products with utmost care and professionalism",
                },
                {
                  icon: MapPin,
                  title: "Live GPS Tracking",
                  desc: "Real-time visibility of your deliveries with precise location tracking",
                },
                {
                  icon: HeadphonesIcon,
                  title: "24/7 Customer Support",
                  desc: "Round-the-clock assistance for all your delivery needs",
                },
                {
                  icon: ClipboardCheck,
                  title: "Proof of Delivery",
                  desc: "Digital confirmation and documentation for every successful delivery",
                },
                {
                  icon: Truck,
                  title: "Same Day Delivery",
                  desc: "Swift farm-to-customer delivery within London's boundaries",
                },
              ].map((feature, index) => (
                <div
                  key={index}
                  className="group relative bg-[#0F121B]/80 border border-[#262D40] rounded-xl p-6 transition-all duration-300 hover:border-[#414D75] hover:shadow-lg"
                  data-aos="zoom-in"
                  data-aos-delay={index * 100}
                >
                  <div className="relative">
                    <div className="w-14 h-14 bg-[#E54C4C]/10 rounded-xl flex items-center justify-center mb-6">
                      <feature.icon className="w-7 h-7 text-[#E54C4C]" />
                    </div>

                    <h3 className="text-lg font-semibold text-white mb-3 group-hover:text-[#E54C4C] transition-colors duration-300">
                      {feature.title}
                    </h3>

                    <p className="text-gray-400 text-sm leading-relaxed">
                      {feature.desc}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-12 py-8 border-t border-[#262D40]">
              <div className="flex flex-col md:flex-row items-center md:justify-between gap-6">
                <p className="text-lg text-white max-w-lg">
                  Our dedicated team ensures your deliveries are handled with
                  precision and care, maintaining the freshness and quality of
                  your dairy products.
                </p>
                <div className="space-y-4">
                  {[
                    "Track milk movements in real-time",
                    "Streamlined collection to delivery",
                    "Specialized dairy handling",
                  ].map((item, i) => (
                    <div
                      key={i}
                      className="flex items-center text-gray-300 text-base"
                    >
                      <div className="w-6 h-6 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mr-3">
                        <Check className="text-[#E54C4C]" size={14} />
                      </div>
                      <span>{item}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Use BottomCTA component */}
      <BottomCTA
        title="Ready to Optimize <GradientText>Milk Delivery</GradientText>?"
        description="Join London's most reliable milk delivery network and delight your customers with same-day farm-fresh deliveries."
        businessOption={{
          title: "Milk Run Solution",
          description:
            "Perfect for dairy businesses and farms looking to deliver fresh milk to customers across London within hours of collection.",
          features: [
            "Same-day farm-to-customer delivery",
            "Temperature-controlled transport",
            "Real-time tracking and updates",
          ],
          buttonText: "Try Milk Runs",
        }}
        enterpriseOption={{
          title: "Custom Dairy Logistics",
          description:
            "Tailored solutions for large dairy operations with complex distribution needs and specialized handling requirements.",
          features: [
            "Custom collection schedules",
            "Multi-location drop-offs",
            "Volume-based pricing",
          ],
          buttonText: "Speak to Sales",
        }}
        onBusinessClick={() => setShowSalesModal(true)}
        onEnterpriseClick={() => setShowSalesModal(true)}
      />

      <Footer />

      {/* Extra Animations */}
      <style jsx>{`
        @keyframes pulse-slow {
          0%,
          100% {
            transform: scale(1);
            opacity: 0.3;
          }
          50% {
            transform: scale(1.1);
            opacity: 0.5;
          }
        }
        @keyframes pulse-slow-delay {
          0%,
          100% {
            transform: scale(1);
            opacity: 0.3;
          }
          50% {
            transform: scale(1.15);
            opacity: 0.6;
          }
        }
        .animate-pulse-slow {
          animation: pulse-slow 15s infinite ease-in-out;
        }
        .animate-pulse-slow-delay {
          animation: pulse-slow-delay 20s infinite ease-in-out 2s;
        }
      `}</style>
    </div>
  );
};

export default MilkRuns;
