import React from "react";
import logoImage from "../Images/logo.png";
import { ArrowUpRight } from "lucide-react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const handleBookNow = () => {
    navigate("/business/book");
  };

  return (
    <footer className="bg-[#0E1420] text-white pt-16 pb-8 border-t border-[#2A3143]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Top section with logo and call to action */}
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-12">
          <div className="mb-8 md:mb-0">
            <div className="flex items-center mb-4">
              <img src={logoImage} alt="Blitzo" className="h-10 w-auto" />
              <span className="text-2xl font-semibold text-white ml-3">
                Blitzo
              </span>
            </div>
            <p className="text-gray-300 max-w-md mb-6">
              London's fastest same-day delivery service. We can do same day or
              next day, but we'd rather do{" "}
              <span className="text-[#E54C4C]">same-hour</span>.
            </p>
          </div>
          <button
            onClick={handleBookNow}
            className="bg-[#E54C4C] text-white px-8 py-3 rounded-lg hover:bg-[#E54C4C]/90 transition-all shadow-lg shadow-[#E54C4C]/10 flex items-center font-medium"
          >
            Book Now <ArrowUpRight className="ml-2" size={18} />
          </button>
        </div>

        {/* Divider */}
        <div className="border-t border-[#2A3143] mb-12"></div>

        {/* Navigation Links - Synced with navbar structure */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 mb-12">
          <div>
            <h4 className="text-lg font-semibold mb-6 text-white">Solutions</h4>
            <ul className="space-y-3">
              <li>
                <Link
                  to="/doorstep-interactions"
                  className="text-gray-300 hover:text-[#E54C4C] transition-colors"
                >
                  Doorstep Interactions
                </Link>
              </li>
              <li>
                <Link
                  to="/retail"
                  className="text-gray-300 hover:text-[#E54C4C] transition-colors"
                >
                  High Value Retail
                </Link>
              </li>
              <li>
                <Link
                  to="/local-business"
                  className="text-gray-300 hover:text-[#E54C4C] transition-colors"
                >
                  Local Business
                </Link>
              </li>
              <li>
                <Link
                  to="/milk-runs"
                  className="text-gray-300 hover:text-[#E54C4C] transition-colors"
                >
                  Milk Rounds
                </Link>
              </li>
              <li>
                <Link
                  to="/fashion"
                  className="text-gray-300 hover:text-[#E54C4C] transition-colors"
                >
                  For Fashion
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="text-lg font-semibold mb-6 text-white">Company</h4>
            <ul className="space-y-3">
              <li>
                <Link
                  to="/how-we-work"
                  className="text-gray-300 hover:text-[#E54C4C] transition-colors"
                >
                  How We Work
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  className="text-gray-300 hover:text-[#E54C4C] transition-colors"
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="text-gray-300 hover:text-[#E54C4C] transition-colors"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="text-lg font-semibold mb-6 text-white">Resources</h4>
            <ul className="space-y-3">
              <li>
                <Link
                  to="/blog"
                  className="text-gray-300 hover:text-[#E54C4C] transition-colors"
                >
                  Blog
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="text-lg font-semibold mb-6 text-white">
              Contact Us
            </h4>
            <ul className="space-y-3">
              <li>
                <a
                  href="mailto:support@blitzo.co.uk"
                  className="text-gray-300 hover:text-[#E54C4C] transition-colors flex items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  support@blitzo.co.uk
                </a>
              </li>
            </ul>

            {/* Social Links */}
            <div className="flex space-x-4 mt-6">
              <a
                href="https://www.linkedin.com/company/blitzouk/"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-[#192032] hover:bg-[#E54C4C]/10 p-2 rounded-full text-gray-300 hover:text-[#E54C4C] transition-all"
              >
                <svg
                  className="h-5 w-5"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M19 0H5a5 5 0 00-5 5v14a5 5 0 005 5h14a5 5 0 005-5V5a5 5 0 00-5-5zM8 19H5V8h3v11zM6.5 6.732c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zM20 19h-3v-5.604c0-3.368-4-3.113-4 0V19h-3V8h3v1.765c1.396-2.586 7-2.777 7 2.476V19z" />
                </svg>
              </a>
              <a
                href="https://www.instagram.com/blitzouk/"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-[#192032] hover:bg-[#E54C4C]/10 p-2 rounded-full text-gray-300 hover:text-[#E54C4C] transition-all"
              >
                <svg
                  className="h-5 w-5"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                </svg>
              </a>
              <a
                href="https://www.tiktok.com/blitzouk/"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-[#192032] hover:bg-[#E54C4C]/10 p-2 rounded-full text-gray-300 hover:text-[#E54C4C] transition-all"
              >
                <svg
                  className="h-5 w-5"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M19.59 6.69a4.83 4.83 0 01-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 01-5.2 1.74 2.89 2.89 0 012.31-4.64 2.93 2.93 0 01.88.13V9.4a6.84 6.84 0 00-1-.05A6.33 6.33 0 005 20.1a6.34 6.34 0 0011.14-4.02v-7a8.16 8.16 0 004.65 1.49v-3.89a4.83 4.83 0 01-1.2.01z" />
                </svg>
              </a>
            </div>
          </div>
        </div>

        {/* Bottom section with legal links */}
        <div className="border-t border-[#2A3143] pt-8 flex flex-col md:flex-row justify-between items-center">
          <p className="text-gray-400 text-sm mb-4 md:mb-0">
            &copy; {new Date().getFullYear()} Blitzo. All rights reserved.
          </p>
          <div className="flex space-x-8">
            <a
              href="/terms"
              className="text-gray-400 hover:text-[#E54C4C] text-sm transition-colors"
            >
              Terms of Service
            </a>
            <a
              href="https://www.privacypolicies.com/live/0c0b870e-c126-4cbe-b8ab-9a25b9108610"
              className="text-gray-400 hover:text-[#E54C4C] text-sm transition-colors"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
