import React, { useEffect, useState } from "react";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import type {
  PaymentMethod,
  StripeCardElementChangeEvent,
} from "@stripe/stripe-js";
import PricingBreakdown from "../../components/pricing/PricingBreakdown";
import { getPaymentMethods } from "../../firebase/billing";
import { CreditCard, Check, Plus } from "lucide-react";

interface PaymentSectionProps {
  priceDetails: PriceDetails;
  onPaymentSuccess: () => Promise<void>;
  isPaymentComplete: boolean;
  setIsPaymentComplete: (complete: boolean) => void;
}

const stripePromise =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? loadStripe(
        "pk_test_51O8qV4IJxIKoMeqTrRkJNzvVRLQdSYFQQeeE98Z0XwcxpwZi1zP15rEcTyMrrlnlyXHSKLMphYvOWerCyjkpQ6dc00ZusGyWsT"
      )
    : loadStripe(
        "pk_live_51O8qV4IJxIKoMeqTam8YI8YTiGuNgO76L84S1TxfUxLBnLHmckD06etSjOTkHhyaamYNa4IasNUejkzRXq4UE8zm007NyDnv5w"
      );

const PaymentSection: React.FC<PaymentSectionProps> = ({
  priceDetails,
  onPaymentSuccess,
  isPaymentComplete,
  setIsPaymentComplete,
}) => {
  const [savedCards, setSavedCards] = useState<PaymentMethod[]>([]);
  const [isLoadingCards, setIsLoadingCards] = useState(true);
  const [selectedCardId, setSelectedCardId] = useState<string | null>(null);
  const [showNewCardForm, setShowNewCardForm] = useState(false);

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const cards = await getPaymentMethods();
        setSavedCards(cards);
        // If there's a card, select the default card
        if (cards.length > 0) setSelectedCardId(cards[0].id);
      } catch (error) {
        console.error("Error fetching cards:", error);
      } finally {
        setIsLoadingCards(false);
      }
    };

    fetchCards();
  }, []);
  return (
    <div className="space-y-6">
      <PricingBreakdown
        total={priceDetails.total}
        baseFee={priceDetails.baseFee}
        distanceFee={priceDetails.distanceFee}
        serviceFee={priceDetails.serviceFee}
        sizeFee={priceDetails.sizeFee}
        riderFee={priceDetails.riderFee}
      />

      <div className="space-y-4">
        <h3 className="text-sm font-medium text-gray-300">Payment Method</h3>

        {/* Saved Cards */}
        {isLoadingCards ? (
          <div className="space-y-3">
            {[1, 2].map((i) => (
              <div
                key={i}
                className="h-16 bg-gray-800/50 animate-pulse rounded-lg border border-gray-700"
              />
            ))}
          </div>
        ) : (
          <div className="space-y-3">
            {savedCards.map((card) => (
              <button
                key={card.id}
                onClick={() => {
                  setSelectedCardId(card.id);
                  setShowNewCardForm(false);
                }}
                className={`w-full p-4 rounded-lg border text-left transition-colors flex items-center justify-between
                ${
                  selectedCardId === card.id
                    ? "bg-blue-500/10 border-blue-500/20"
                    : "bg-gray-800/50 border-gray-700 hover:bg-gray-800"
                }`}
              >
                <div className="flex items-center gap-3">
                  <CreditCard
                    size={20}
                    className={
                      selectedCardId === card.id
                        ? "text-blue-400"
                        : "text-gray-400"
                    }
                  />
                  <div>
                    <p className="font-medium">•••• {card.card?.last4}</p>
                    <p className="text-sm text-gray-400">
                      Expires {card.card?.exp_month.toString().padStart(2, "0")}
                      /{card.card?.exp_year}
                    </p>
                  </div>
                </div>
                {selectedCardId === card.id && (
                  <Check size={20} className="text-blue-400" />
                )}
              </button>
            ))}

            {/* Add New Card Button */}
            <button
              onClick={() => {
                setShowNewCardForm(true);
                setSelectedCardId(null);
              }}
              className={`w-full p-4 rounded-lg border text-left transition-colors flex items-center gap-3
              ${
                showNewCardForm
                  ? "bg-blue-500/10 border-blue-500/20"
                  : "bg-gray-800/50 border-gray-700 hover:bg-gray-800"
              }`}
            >
              <Plus
                size={20}
                className={showNewCardForm ? "text-blue-400" : "text-gray-400"}
              />
              <span>Add New Card</span>
            </button>
          </div>
        )}
      </div>

      {/* New Card Form or Payment Button */}
      <Elements stripe={stripePromise}>
        {showNewCardForm ? (
          <PaymentForm
            price={priceDetails.total}
            priceDetails={priceDetails}
            onPaymentSuccess={onPaymentSuccess}
            isPaymentComplete={isPaymentComplete}
            setIsPaymentComplete={setIsPaymentComplete}
          />
        ) : (
          <PayWithSavedCard
            price={priceDetails.total}
            priceDetails={priceDetails}
            onPaymentSuccess={onPaymentSuccess}
            isPaymentComplete={isPaymentComplete}
            setIsPaymentComplete={setIsPaymentComplete}
            paymentMethodId={selectedCardId}
          />
        )}
      </Elements>
    </div>
  );
};

interface PaymentFormProps {
  price: number;
  priceDetails: PriceDetails;
  onPaymentSuccess: () => Promise<void>;
  isPaymentComplete: boolean;
  setIsPaymentComplete: (complete: boolean) => void;
}

const PaymentForm: React.FC<PaymentFormProps> = ({
  price,
  priceDetails,
  onPaymentSuccess,
  isPaymentComplete,
  setIsPaymentComplete,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isCardComplete, setIsCardComplete] = useState(false);

  const handleCardChange = (event: StripeCardElementChangeEvent) => {
    setIsCardComplete(event.complete);
    setError(event.error ? event.error.message : null);
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) return;

    setIsProcessing(true);
    setError(null);

    try {
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) throw new Error("Card element not found");

      const { error: stripeError } = await stripe.confirmCardPayment(
        priceDetails.clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              address: {
                country: "GB",
              },
            },
          },
        }
      );

      if (stripeError) {
        setError(stripeError.message || "Payment failed");
        return;
      }

      setIsPaymentComplete(true);
      await onPaymentSuccess();
    } catch (error) {
      setError(error instanceof Error ? error.message : "Payment failed");
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="space-y-4">
      <div className="p-4 bg-gray-800/50 rounded-lg border border-gray-700">
        <label className="text-sm font-medium text-gray-300 block mb-3">
          Card Details
        </label>
        <div className="p-3 bg-white/5 rounded-lg border border-gray-700">
          <CardElement
            onChange={handleCardChange}
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#ffffff",
                  "::placeholder": {
                    color: "#6b7280",
                  },
                },
              },
            }}
          />
        </div>
      </div>

      {error && (
        <div className="p-3 rounded-lg bg-red-500/10 border border-red-500/20">
          <p className="text-sm text-red-400">{error}</p>
        </div>
      )}

      <button
        onClick={handleSubmit}
        disabled={
          !stripe || isProcessing || isPaymentComplete || !isCardComplete
        }
        className={`w-full px-4 py-3 rounded-lg text-white font-medium
          ${
            !stripe || isProcessing || isPaymentComplete || !isCardComplete
              ? "bg-gray-600 cursor-not-allowed"
              : "bg-blue-500 hover:bg-blue-600"
          }`}
      >
        {isProcessing ? "Processing..." : `Book Delivery Now`}
      </button>

      {!process.env.NODE_ENV || process.env.NODE_ENV === "development" ? (
        <p className="text-xs text-center text-gray-500 mt-2">
          Test card: 4242 4242 4242 4242 • Any future date • Any 3 digits
        </p>
      ) : null}
    </div>
  );
};

const PayWithSavedCard: React.FC<
  PaymentFormProps & { paymentMethodId: string | null }
> = ({
  price,
  priceDetails,
  onPaymentSuccess,
  isPaymentComplete,
  setIsPaymentComplete,
  paymentMethodId,
}) => {
  const stripe = useStripe();
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async () => {
    if (!stripe || !paymentMethodId) return;

    setIsProcessing(true);
    setError(null);

    try {
      const { error: stripeError } = await stripe.confirmCardPayment(
        priceDetails.clientSecret,
        {
          payment_method: paymentMethodId,
        }
      );

      if (stripeError) {
        setError(stripeError.message || "Payment failed");
        return;
      }

      setIsPaymentComplete(true);
      await onPaymentSuccess();
    } catch (error) {
      setError(error instanceof Error ? error.message : "Payment failed");
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="space-y-4">
      {error && (
        <div className="p-3 rounded-lg bg-red-500/10 border border-red-500/20">
          <p className="text-sm text-red-400">{error}</p>
        </div>
      )}

      <button
        onClick={handleSubmit}
        disabled={
          !stripe || !paymentMethodId || isProcessing || isPaymentComplete
        }
        className={`w-full px-4 py-3 rounded-lg text-white font-medium
            ${
              !stripe || !paymentMethodId || isProcessing || isPaymentComplete
                ? "bg-gray-600 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-600"
            }`}
      >
        {isProcessing ? "Processing..." : `Pay £${price.toFixed(2)}`}
      </button>
    </div>
  );
};

export default PaymentSection;
