import {
  doc,
  getDoc,
  setDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  collection,
  query,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

// Import your initialized Firebase instances
import { db, auth, functions } from "../../Firebase/firebase-config";
import { Location } from "../types/Location";

const storeAddress = async (address: Location) => {
  try {
    const user = auth.currentUser;
    if (user) {
      // get existing savedAddresses
      const addressessRef = doc(db, "savedAddresses", user.uid);
      const addressessDoc = await getDoc(addressessRef);
      const addresses = addressessDoc.data()?.addresses as
        | Location[]
        | undefined;
      if (addresses) {
        // add new address to existing addresses
        const newAddresses = [...addresses, address];
        await setDoc(addressessRef, { addresses: newAddresses });
      } else {
        // create new savedAddresses document
        console.log("Creating new savedAddresses document");
        await setDoc(addressessRef, { addresses: [address] });
      }
    }
  } catch (error) {
    console.error("Error saving address:", error);
    throw error;
  }
};

const getSavedAddresses = async (): Promise<Location[]> => {
  try {
    const user = auth.currentUser;
    if (user) {
      const addressessRef = doc(db, "savedAddresses", user.uid);
      const addressessDoc = await getDoc(addressessRef);
      const addresses = addressessDoc.data()?.addresses as
        | Location[]
        | undefined;
      console.log("addresses", addresses);
      return addresses || [];
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error getting saved addresses:", error);
    throw error;
  }
};

const storeOnboardingInfo = async (
  businessName: string,
  businessSector: string,
  monthlyVolume: string
) => {
  try {
    const user = auth.currentUser;
    if (user) {
      // store it in user/uid/businessInfo: { businessName, businessSector, monthlyVolume }
      const businessInfoRef = doc(db, "users", user.uid);
      await setDoc(
        businessInfoRef,
        {
          businessInfo: { businessName, businessSector, monthlyVolume },
          personalInfo: {
            name: businessName,
          },
        },
        { merge: true }
      );

      //Now create a stripe id using functions
      const createStripeAccountFunction = httpsCallable(
        functions,
        "createStripeCustomer"
      );
      await createStripeAccountFunction();
    }
  } catch (error) {
    console.error("Error storing onboarding info:", error);
    throw error;
  }
};

const hasOnboardingInfo = async (): Promise<boolean> => {
  try {
    const user = auth.currentUser;
    if (user) {
      const userRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userRef);
      return (
        userDoc.data()?.businessInfo !== undefined &&
        userDoc.data()?.stripeCustomerId !== undefined
      );
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error checking onboarding info:", error);
    throw error;
  }
};

export {
  storeAddress,
  getSavedAddresses,
  storeOnboardingInfo,
  hasOnboardingInfo,
};
