import React, { useState } from "react";
import { X } from "lucide-react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { addPaymentMethod } from "../../firebase/billing";

interface AddCardModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  setupIntentSecret: string;
}

const AddCardModal: React.FC<AddCardModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  setupIntentSecret,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 300);
  };

  const handleSubmit = async () => {
    if (!stripe || !elements) return;

    setIsProcessing(true);
    setError(null);

    try {
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) throw new Error("Card element not found");

      const { setupIntent, error: setupError } = await stripe.confirmCardSetup(
        setupIntentSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              address: {
                country: "GB",
              },
            },
          },
        }
      );

      if (setupError) {
        setError(setupError.message || "Failed to add card");
        return;
      }

      if (setupIntent?.payment_method) {
        await addPaymentMethod(setupIntent.payment_method as string);
        onSuccess();
        handleClose();
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : "Failed to add card");
    } finally {
      setIsProcessing(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className={`absolute inset-0 bg-black transition-opacity duration-300
          ${isClosing ? "bg-opacity-0" : "bg-opacity-60"}`}
        onClick={handleClose}
      />

      <div
        className={`relative w-full max-w-md bg-gradient-to-br from-gray-900 to-gray-800 
          text-white rounded-2xl shadow-2xl transition-all duration-300 transform
          ${isClosing ? "scale-95 opacity-0" : "scale-100 opacity-100"}`}
      >
        <div className="p-6">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-xl font-semibold text-white">
              Add Payment Method
            </h2>
            <button
              onClick={handleClose}
              className="p-2 hover:bg-gray-700 rounded-full transition-colors"
            >
              <X size={20} className="text-gray-400 hover:text-white" />
            </button>
          </div>

          <div className="space-y-4">
            <div className="p-4 bg-gray-800/50 rounded-lg border border-gray-700">
              <label className="text-sm font-medium text-gray-300 block mb-3">
                Card Details
              </label>
              <div className="p-3 bg-white/5 rounded-lg border border-gray-700">
                <CardElement
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        color: "#ffffff",
                        "::placeholder": {
                          color: "#6b7280",
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>

            {error && (
              <div className="p-3 rounded-lg bg-red-500/10 border border-red-500/20">
                <p className="text-sm text-red-400">{error}</p>
              </div>
            )}

            <div className="flex justify-end gap-3 mt-6">
              <button
                onClick={handleClose}
                className="px-4 py-2 rounded-lg border border-gray-600 hover:bg-gray-700 
                  transition-all text-sm font-medium"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                disabled={!stripe || isProcessing}
                className={`px-4 py-2 rounded-lg transition-all text-sm font-medium
                  ${
                    isProcessing || !stripe
                      ? "bg-gray-600 cursor-not-allowed"
                      : "bg-blue-500 hover:bg-blue-600"
                  }`}
              >
                {isProcessing ? "Adding..." : "Add Card"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCardModal;
