import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import {
  Phone,
  MessageSquare,
  Clock,
  TrendingUp,
  Truck,
  AlertTriangle,
  CheckCircle,
  BarChart2,
  Zap,
  ArrowRight,
  Play,
  Pause,
  ChevronRight,
  Users,
  Calendar,
  RefreshCw,
  ArrowUpRight,
  BarChart,
} from "react-feather";
import AthenaNavbar from "../components/AthenaNavbar";
import AthenaFooter from "../components/AthenaFooter";
import AOS from "aos";
import "aos/dist/aos.css";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../Firebase/firebase-config";

// Import the audio file
const DemoForCustomerNotHome = require("../Audio/Athena/DemoForCustomerNotHome.WAV");

// Import integration logos using require to avoid TypeScript errors
const Slack = require("../Images/Integrations/Freight/Slack.png");
const Twilio = require("../Images/Integrations/Freight/Twilio.png");
const Whatsapp = require("../Images/Integrations/Freight/Whatsapp.png");
const Teams = require("../Images/Integrations/Freight/Teams.png");
const Gmail = require("../Images/Integrations/Freight/Gmail.png");
const Hubspot = require("../Images/Integrations/Freight/Hubspot.png");
const Ringcentral = require("../Images/Integrations/Freight/Ringcentral.png");
const Turvo = require("../Images/Integrations/Freight/Turvo.png");
const OnFleet = require("../Images/Integrations/LastMile/OnFleet.png");
const Samsara = require("../Images/Integrations/LastMile/Samsara.jpeg");

// Placeholder for audio files
const demoAudio1 = "/path/to/audio1.mp3";
const demoAudio2 = DemoForCustomerNotHome;

// Text Communication Animation Component
const TextCommunicationAnimation = () => {
  const [activeChat, setActiveChat] = useState("whatsapp");

  useEffect(() => {
    // Toggle between WhatsApp and iMessage every 5 seconds
    const interval = setInterval(() => {
      setActiveChat((prev) => (prev === "whatsapp" ? "imessage" : "whatsapp"));
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  // WhatsApp Chat Content
  const whatsappChat = (
    <div className="bg-[#111418] rounded-lg p-4">
      <div className="flex items-center mb-3">
        <div className="w-8 h-8 rounded-full bg-green-600 flex items-center justify-center flex-shrink-0">
          <MessageSquare size={14} className="text-white" />
        </div>
        <div className="ml-3">
          <p className="text-white font-medium">WhatsApp</p>
          <p className="text-gray-400 text-xs">Rider Communication</p>
        </div>
      </div>

      <div className="space-y-3">
        <div className="flex items-start">
          <div className="ml-1 max-w-[80%] bg-[#202C33] p-3 rounded-lg rounded-tl-none">
            <p className="text-white text-sm">
              Customer has a dog and won't let me deliver to the porch. What
              should I do?
            </p>
            <p className="text-gray-400 text-xs mt-1 text-right">10:42 AM</p>
          </div>
        </div>

        <div className="flex items-start justify-end">
          <div className="mr-1 max-w-[80%] bg-[#005C4B] p-3 rounded-lg rounded-tr-none">
            <p className="text-white text-sm">
              I'll contact the customer to arrange a safe handoff. Please wait a
              moment.
            </p>
            <p className="text-gray-400 text-xs mt-1 text-right">10:43 AM</p>
          </div>
        </div>

        <div className="flex items-start justify-end">
          <div className="mr-1 max-w-[80%] bg-[#005C4B] p-3 rounded-lg rounded-tr-none">
            <p className="text-white text-sm">
              Customer will secure the dog and meet you at the gate in 2
              minutes. They've been notified.
            </p>
            <p className="text-gray-400 text-xs mt-1 text-right">10:45 AM</p>
          </div>
        </div>

        <div className="flex items-start">
          <div className="ml-1 max-w-[80%] bg-[#202C33] p-3 rounded-lg rounded-tl-none">
            <p className="text-white text-sm">Perfect, I'll wait. Thanks!</p>
            <p className="text-gray-400 text-xs mt-1 text-right">10:46 AM</p>
          </div>
        </div>
      </div>
    </div>
  );

  // iMessage Chat Content
  const imessageChat = (
    <div className="bg-[#111418] rounded-lg p-4">
      <div className="flex items-center mb-3">
        <div className="w-8 h-8 rounded-full bg-blue-500 flex items-center justify-center flex-shrink-0">
          <MessageSquare size={14} className="text-white" />
        </div>
        <div className="ml-3">
          <p className="text-white font-medium">iMessage</p>
          <p className="text-gray-400 text-xs">Rider Communication</p>
        </div>
      </div>

      <div className="space-y-3 bg-black/30 p-3 rounded-lg">
        <div className="flex items-start">
          <div className="ml-1 max-w-[80%] bg-[#303030] p-3 rounded-2xl">
            <p className="text-white text-sm">
              The store is closed now, can't complete the delivery.
            </p>
            <p className="text-gray-400 text-xs mt-1">6:32 PM</p>
          </div>
        </div>

        <div className="flex items-start justify-end">
          <div className="mr-1 max-w-[80%] bg-[#0B84FE] p-3 rounded-2xl">
            <p className="text-white text-sm">
              I'll check the store hours and contact the customer about
              rescheduling.
            </p>
            <p className="text-gray-400 text-xs mt-1">6:33 PM</p>
          </div>
        </div>

        <div className="flex items-start justify-end">
          <div className="mr-1 max-w-[80%] bg-[#0B84FE] p-3 rounded-2xl">
            <p className="text-white text-sm">
              I've updated the delivery for tomorrow at 9:00 AM. The customer
              has confirmed they'll be available.
            </p>
            <p className="text-gray-400 text-xs mt-1">6:35 PM</p>
          </div>
        </div>

        <div className="flex items-start">
          <div className="ml-1 max-w-[80%] bg-[#303030] p-3 rounded-2xl">
            <p className="text-white text-sm">Got it!</p>
            <p className="text-gray-400 text-xs mt-1">6:36 PM</p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="relative h-[450px] overflow-hidden">
      <div
        className={`absolute inset-0 transition-opacity duration-1000 ${
          activeChat === "whatsapp" ? "opacity-100" : "opacity-0"
        }`}
      >
        {whatsappChat}
      </div>
      <div
        className={`absolute inset-0 transition-opacity duration-1000 ${
          activeChat === "imessage" ? "opacity-100" : "opacity-0"
        }`}
      >
        {imessageChat}
      </div>
    </div>
  );
};

// Gradient Text Component
const GradientText = ({ children, className = "" }) => (
  <span
    className={`bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600 ${className}`}
  >
    {children}
  </span>
);

// Integration Logos Section
const IntegrationSection = ({ integrations }) => {
  return (
    <div className="py-16 bg-[#080A12]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-12">
          <div className="max-w-xl mb-8 md:mb-0">
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
              <GradientText>Easy integration</GradientText> with <br />
              your existing platforms
            </h2>
            <p className="text-lg text-gray-300 mb-2">
              Syncs with your TMS, messaging platforms, and customer service
              tools for real-time data access.
            </p>
            <p className="text-gray-400">
              Integrate with existing solutions to amplify your current
              operations.
            </p>
          </div>
          <button
            onClick={() => {
              const contactSection = document.getElementById("contact-section");
              if (contactSection) {
                contactSection.scrollIntoView({ behavior: "smooth" });
              }
            }}
            className="px-6 py-4 bg-gradient-to-r from-blue-600 to-purple-600 text-white rounded-lg hover:from-blue-700 hover:to-purple-700 transition-all duration-300 shadow-lg shadow-blue-600/20 flex items-center justify-center font-medium"
          >
            <span>Missing any tools? Let's talk</span>
            <ArrowUpRight className="ml-2" size={18} />
          </button>
        </div>

        {/* Enhanced brand showcase */}
        <div className="mb-10">
          <div className="relative">
            {/* Logos container */}
            <div className="relative bg-[#0F121B]/90 backdrop-blur-lg rounded-xl border border-[#262D40] p-8 md:p-10">
              <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 gap-6 md:gap-10 items-center">
                {integrations.map((brand, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-center group relative py-4"
                    data-aos="fade-up"
                    data-aos-delay={100 + index * 50}
                  >
                    <div className="absolute inset-0 bg-gradient-to-br from-[#E54C4C]/0 to-[#4ECAFC]/0 group-hover:from-[#E54C4C]/10 group-hover:to-[#4ECAFC]/10 rounded-xl -m-3 transition-all duration-300 opacity-0 group-hover:opacity-100 shadow-xl"></div>
                    <div
                      className={`transform transition-all duration-700 ease-in-out hover:translate-y-[-8px] ${brand.scale}`}
                    >
                      <div className="w-full h-16 flex items-center justify-center group-hover:opacity-100 transition-all duration-500">
                        <img
                          src={brand.logo}
                          alt={brand.name}
                          className="max-h-10 max-w-full object-contain"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* VISUAL CONNECTOR: Animated data flow between logo section and metrics */}
        <div className="relative py-6 my-4">
          <div className="absolute inset-0 flex items-center justify-center">
            {/* Animated data flow visualization */}
            <div className="w-1/2 h-0.5 bg-gradient-to-r from-[#E54C4C]/20 via-[#FFF]/10 to-[#4ECAFC]/20"></div>
          </div>

          {/* Connector text with icon */}
          <div className="relative bg-[#0A0D16] px-6 mx-auto w-max rounded-full border border-[#262D40] shadow-lg">
            <div className="flex items-center space-x-2 py-2">
              <BarChart className="text-[#E54C4C] w-6 h-6" />
              <p className="text-white text-xl font-medium">
                Get going in <GradientText>minutes</GradientText>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Performance Stats Section
// const PerformanceStats = () => {
//   // CountUp component for animated numbers
//   const CountUp = ({ end, prefix = "", suffix = "" }) => {
//     const [count, setCount] = useState(0);

//     useEffect(() => {
//       let startTime;
//       let animationFrame;
//       const duration = 2000; // 2 seconds duration

//       const animate = (timestamp) => {
//         if (!startTime) startTime = timestamp;
//         const progress = timestamp - startTime;
//         const percentage = Math.min(progress / duration, 1);
//         setCount(Math.floor(percentage * end));

//         if (percentage < 1) {
//           animationFrame = requestAnimationFrame(animate);
//         }
//       };

//       animationFrame = requestAnimationFrame(animate);
//       return () => cancelAnimationFrame(animationFrame);
//     }, [end]);

//     return (
//       <span>
//         {prefix}
//         {count}
//         {suffix}
//       </span>
//     );
//   };

//   return (
//     <div className="py-16 bg-[#0A0C16]">
//       <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//         <div className="text-center mb-10">
//           <h2 className="text-3xl md:text-4xl font-bold text-white mb-3">
//             Real results from our pilot with{" "}
//             <span className="bg-gradient-to-r from-[#E54C4C] to-[#FF6B6B] inline bg-clip-text text-transparent">
//               Top UK Logistics Companies
//             </span>
//           </h2>
//           <p className="text-gray-300 max-w-3xl mx-auto">
//             Proven metrics from logistics companies using our AI assistant.
//           </p>
//         </div>

//         <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
//           <div className="bg-[#0F121B] rounded-xl p-8 border border-[#262D40] text-center">
//             <div className="text-5xl font-bold text-white mb-4">
//               <CountUp end={85} suffix="%" />
//             </div>
//             <p className="text-lg text-[#4ECAFC] font-medium mb-2">
//               Delivery success rate
//             </p>
//             <p className="text-gray-400 text-sm">
//               Improved delivery success through proactive issue resolution
//             </p>
//           </div>

//           <div className="bg-[#0F121B] rounded-xl p-8 border border-[#262D40] text-center">
//             <div className="text-5xl font-bold text-white mb-4">
//               <CountUp end={30} suffix=" min" />
//             </div>
//             <p className="text-lg text-[#4ECAFC] font-medium mb-2">
//               Time saved daily
//             </p>
//             <p className="text-gray-400 text-sm">
//               Per manager through automated query handling and issue resolution
//             </p>
//           </div>

//           <div className="bg-[#0F121B] rounded-xl p-8 border border-[#262D40] text-center">
//             <div className="text-5xl font-bold text-white mb-4">
//               <CountUp prefix="+" end={95} suffix="%" />
//             </div>
//             <p className="text-lg text-[#4ECAFC] font-medium mb-2">
//               Query resolution rate
//             </p>
//             <p className="text-gray-400 text-sm">
//               Queries resolved without human intervention
//             </p>
//           </div>
//         </div>

//         <div className="mt-12 text-center">
//           <p className="text-gray-400 text-m italic">
//             "Athena has transformed our delivery operations. It's like having an
//             expert logistics coordinator available 24/7, handling all the
//             complex scenarios that typically cause failed deliveries."
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };

// Demo Request Form Section
const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    phone: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [countdown, setCountdown] = useState(5);

  // Reset form and success message after delay with countdown
  useEffect(() => {
    let resetTimer;
    let countdownTimer;

    if (submitSuccess) {
      setCountdown(5);

      countdownTimer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(countdownTimer);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      resetTimer = setTimeout(() => {
        setSubmitSuccess(false);
      }, 5000); // Show success message for 5 seconds
    }

    return () => {
      clearTimeout(resetTimer);
      clearInterval(countdownTimer);
    };
  }, [submitSuccess]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitError("");

    // Set a timeout to prevent the form from being stuck in submitting state
    const timeoutId = setTimeout(() => {
      setIsSubmitting(false);
      setSubmitError("Request timed out. Please try again later.");
    }, 10000); // 10 second timeout

    try {
      // Try to add form data to Firestore
      try {
        await addDoc(collection(db, "AthenaWaitlist"), {
          ...formData,
          timestamp: serverTimestamp(),
        });
        console.log("Form data successfully submitted to Firestore");
      } catch (firestoreError) {
        // Log the Firestore error but don't fail the form submission
        console.error("Firestore error:", firestoreError);

        // Send data to a backup service or log it for manual processing
        console.log("Form data for manual processing:", formData);
      }

      // Clear the timeout since we've handled the submission
      clearTimeout(timeoutId);

      // Show success message regardless of Firestore success
      // This ensures users get feedback even if there's a backend issue
      setSubmitSuccess(true);
      resetForm();
    } catch (error) {
      // Clear the timeout since we caught the error
      clearTimeout(timeoutId);

      console.error("Error in form submission process:", error);
      setSubmitError(
        "There was an error submitting your request. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      company: "",
      phone: "",
      message: "",
    });
  };

  return (
    <div className="py-20 bg-[#0A0C14]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <div>
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-6">
              Ready to transform your <GradientText>logistics</GradientText>{" "}
              operations?
            </h2>
            <p className="text-xl text-gray-300 mb-8">
              Get in touch with our team to learn how Athena can transform your
              last-mile delivery operations.
            </p>
            <div className="bg-[#161A25] rounded-xl p-6 border border-[#262D40]">
              <h3 className="text-xl font-semibold text-white mb-4 flex items-center">
                <CheckCircle className="text-blue-400 mr-2" size={20} />
                What to expect
              </h3>
              <ul className="space-y-3">
                <li className="flex items-start">
                  <div className="w-6 h-6 rounded-full bg-blue-600/20 flex items-center justify-center mt-0.5 mr-3 flex-shrink-0">
                    <CheckCircle size={14} className="text-blue-400" />
                  </div>
                  <p className="text-gray-300">
                    Discussion of Athena's capabilities for your business
                  </p>
                </li>
                <li className="flex items-start">
                  <div className="w-6 h-6 rounded-full bg-blue-600/20 flex items-center justify-center mt-0.5 mr-3 flex-shrink-0">
                    <CheckCircle size={14} className="text-blue-400" />
                  </div>
                  <p className="text-gray-300">
                    Analysis of your specific delivery challenges
                  </p>
                </li>
                <li className="flex items-start">
                  <div className="w-6 h-6 rounded-full bg-blue-600/20 flex items-center justify-center mt-0.5 mr-3 flex-shrink-0">
                    <CheckCircle size={14} className="text-blue-400" />
                  </div>
                  <p className="text-gray-300">
                    Custom integration possibilities with your existing systems
                  </p>
                </li>
                <li className="flex items-start">
                  <div className="w-6 h-6 rounded-full bg-blue-600/20 flex items-center justify-center mt-0.5 mr-3 flex-shrink-0">
                    <CheckCircle size={14} className="text-blue-400" />
                  </div>
                  <p className="text-gray-300">
                    Pricing and implementation timeline
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div className="bg-[#0F121B] rounded-xl border border-[#262D40] p-8">
            <h3 className="text-2xl font-bold text-white mb-6">Contact Us</h3>

            {submitSuccess ? (
              <div className="bg-blue-900/30 border border-blue-500/50 rounded-lg p-6 text-center">
                <CheckCircle size={48} className="text-blue-400 mx-auto mb-4" />
                <h4 className="text-xl font-semibold text-white mb-2">
                  Thank You!
                </h4>
                <p className="text-gray-300 mb-4">
                  Your message has been submitted successfully. Our team will
                  contact you shortly.
                </p>
                <p className="text-sm text-gray-400 mb-4">
                  Form will reset in {countdown} seconds
                </p>
                <button
                  onClick={() => {
                    setSubmitSuccess(false);
                    resetForm();
                  }}
                  className="px-5 py-2 border border-blue-500/30 text-blue-400 hover:bg-blue-500/10 rounded-lg transition-all duration-300"
                >
                  Submit another enquiry
                </button>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="space-y-5">
                <div>
                  <label htmlFor="name" className="block text-gray-300 mb-2">
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    className="w-full bg-[#161A25] border border-[#262D40] rounded-lg px-4 py-3 text-white focus:outline-none focus:border-blue-500"
                    placeholder="Your name"
                  />
                </div>

                <div>
                  <label htmlFor="email" className="block text-gray-300 mb-2">
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="w-full bg-[#161A25] border border-[#262D40] rounded-lg px-4 py-3 text-white focus:outline-none focus:border-blue-500"
                    placeholder="your.email@company.com"
                  />
                </div>

                <div>
                  <label htmlFor="company" className="block text-gray-300 mb-2">
                    Company
                  </label>
                  <input
                    type="text"
                    id="company"
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                    required
                    className="w-full bg-[#161A25] border border-[#262D40] rounded-lg px-4 py-3 text-white focus:outline-none focus:border-blue-500"
                    placeholder="Your company name"
                  />
                </div>

                <div>
                  <label htmlFor="phone" className="block text-gray-300 mb-2">
                    Phone Number (optional)
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="w-full bg-[#161A25] border border-[#262D40] rounded-lg px-4 py-3 text-white focus:outline-none focus:border-blue-500"
                    placeholder="Your phone number"
                  />
                </div>

                <div>
                  <label htmlFor="message" className="block text-gray-300 mb-2">
                    Tell us about your delivery challenges (optional)
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows={4}
                    className="w-full bg-[#161A25] border border-[#262D40] rounded-lg px-4 py-3 text-white focus:outline-none focus:border-blue-500"
                    placeholder="What specific challenges are you facing with your deliveries?"
                  ></textarea>
                </div>

                {submitError && (
                  <div className="bg-red-900/30 border border-red-500/50 rounded-lg p-4 text-red-200">
                    {submitError}
                  </div>
                )}

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`w-full px-6 py-3 rounded-lg transition-all duration-300 shadow-lg flex items-center justify-center font-medium ${
                    isSubmitting
                      ? "bg-gray-600 cursor-not-allowed"
                      : "bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 shadow-blue-600/20 text-white"
                  }`}
                >
                  {isSubmitting ? (
                    <div className="flex items-center">
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      <span>Submitting...</span>
                    </div>
                  ) : (
                    <>
                      Submit <ArrowUpRight className="ml-2" size={16} />
                    </>
                  )}
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const BlitzoAthena = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentAudio, setCurrentAudio] = useState<string | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [stats, setStats] = useState([
    { value: 0, target: 90, suffix: "%" },
    { value: 0, target: 34, suffix: "%" },
    { value: 0, target: 92, suffix: "%" },
  ]);
  // Add state for animation
  const [waveformPhase, setWaveformPhase] = useState(0);

  // Initialize AOS animation library
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: false,
      mirror: true,
    });
  }, []);

  // Animation for stats
  useEffect(() => {
    const interval = setInterval(() => {
      setStats((prevStats) =>
        prevStats.map((stat) => ({
          ...stat,
          value: stat.value < stat.target ? stat.value + 1 : stat.target,
        }))
      );
    }, 30);

    return () => clearInterval(interval);
  }, []);

  // Add animation loop for waveform - making it faster (25ms instead of 50ms)
  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (isPlaying && currentAudio === demoAudio2) {
      // Use interval to update phase for waveform animation
      interval = setInterval(() => {
        setWaveformPhase((prev) => (prev + 1) % 100);
      }, 25); // Faster animation
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isPlaying, currentAudio]);

  // Update current time for audio playback
  useEffect(() => {
    let timeUpdateInterval: NodeJS.Timeout;

    if (isPlaying && audioRef.current) {
      timeUpdateInterval = setInterval(() => {
        setCurrentTime(audioRef.current?.currentTime || 0);
      }, 100);
    }

    return () => {
      if (timeUpdateInterval) clearInterval(timeUpdateInterval);
    };
  }, [isPlaying]);

  // Format time in MM:SS format
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const handleAudioMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  const resetAudio = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
      setCurrentTime(0);
      if (!isPlaying) {
        togglePlay(currentAudio || "");
      }
    }
  };

  const togglePlay = (audioFile: string) => {
    if (isPlaying && currentAudio === audioFile) {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      setIsPlaying(false);
    } else {
      if (audioRef.current) {
        if (currentAudio !== audioFile) {
          audioRef.current.src = audioFile;
          audioRef.current.load();
        }
        audioRef.current
          .play()
          .then(() => {
            setIsPlaying(true);
            setCurrentAudio(audioFile);
          })
          .catch((error) => {
            console.error("Error playing audio:", error);
          });
      }
    }
  };

  const handleAudioEnd = () => {
    setIsPlaying(false);
  };

  const useCases = [
    {
      title: "Store Closure Management",
      description:
        "Proactively identifies store closure conflicts with delivery schedules and notifies all stakeholders to prevent failed deliveries.",
      icon: Clock,
    },
    {
      title: "Customer Absence Resolution",
      description:
        "Handles scenarios when customers aren't home, coordinating alternative delivery options or rescheduling.",
      icon: Users,
    },
    {
      title: "Delivery Obstacle Navigation",
      description:
        "Resolves issues like access problems, pets, or security concerns that prevent standard delivery completion.",
      icon: AlertTriangle,
    },
    {
      title: "Smart Routing & Optimization",
      description:
        "Dynamically adjusts routes based on real-time traffic, weather, and delivery constraints. Intelligently rebalances workloads across your fleet for maximum efficiency.",
      icon: RefreshCw,
    },
    {
      title: "Stakeholder Communication",
      description:
        "Automatically updates customers, stores, and logistics partners about delivery status changes.",
      icon: MessageSquare,
    },
    {
      title: "Performance Analytics",
      description:
        "Provides insights on delivery success rates, common issues, and optimization opportunities.",
      icon: BarChart2,
    },
  ];

  const integrations = [
    { name: "Slack", logo: Slack, scale: "scale-90" },
    { name: "Twilio", logo: Twilio, scale: "scale-90" },
    { name: "WhatsApp", logo: Whatsapp, scale: "scale-125" },
    { name: "Teams", logo: Teams, scale: "scale-120" },
    { name: "Gmail", logo: Gmail, scale: "scale-125" },
    { name: "Hubspot", logo: Hubspot, scale: "scale-90" },
    { name: "Ringcentral", logo: Ringcentral, scale: "scale-150" },
    { name: "Samsara", logo: Samsara, scale: "scale-90" },
    { name: "Turvo", logo: Turvo, scale: "scale-90" },
    { name: "OnFleet", logo: OnFleet, scale: "scale-90" },
  ];

  const scrollToDemo = () => {
    const contactSection = document.getElementById("contact-section");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Helmet>
        <title>Blitzo Athena | Last Mile AI Assistant</title>
        <meta
          name="description"
          content="Blitzo Athena - Advanced AI agent that handles rider queries and optimizes logistics operations to reduce missed deliveries."
        />
      </Helmet>

      <AthenaNavbar />

      <div className="min-h-screen bg-[#080A12]">
        {/* Hero Section */}
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 z-0 opacity-30">
            <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-blue-900/20 to-purple-900/20"></div>
            <div className="absolute top-1/4 left-1/4 w-64 h-64 rounded-full bg-blue-600/20 filter blur-3xl"></div>
            <div className="absolute bottom-1/4 right-1/4 w-96 h-96 rounded-full bg-purple-600/20 filter blur-3xl"></div>
          </div>

          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24 md:py-32 relative z-10">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
              <div>
                <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white leading-tight">
                  Meet <GradientText>Athena</GradientText>, Your AI Logistics
                  Assistant For Last Mile
                </h1>
                <p className="mt-6 text-xl text-gray-300 leading-relaxed">
                  Revolutionize your logistics operations with an AI agent that
                  handles rider queries, optimizes routes in real-time, prevents
                  missed deliveries, and streamlines communication between all
                  stakeholders.
                </p>
                <div className="mt-10 flex flex-wrap gap-4">
                  <button
                    onClick={scrollToDemo}
                    className="px-12 py-4 bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg text-white font-medium hover:from-blue-700 hover:to-purple-700 transition-all duration-300 shadow-lg shadow-blue-600/20 w-full sm:w-auto"
                  >
                    Speak To Us
                  </button>
                </div>
              </div>
              <div className="relative">
                <div className="absolute inset-0 bg-gradient-to-r from-blue-600/20 to-purple-600/20 rounded-3xl filter blur-xl"></div>
                <div className="relative bg-[#0F121B] border border-[#262D40] rounded-3xl p-6 shadow-xl">
                  <div className="flex items-center mb-4">
                    <div className="w-3 h-3 rounded-full bg-red-500 mr-2"></div>
                    <div className="w-3 h-3 rounded-full bg-yellow-500 mr-2"></div>
                    <div className="w-3 h-3 rounded-full bg-green-500"></div>
                    <div className="ml-4 text-gray-400 text-sm">
                      Athena Agent Interface
                    </div>
                  </div>
                  <div className="space-y-4">
                    <div className="bg-[#161A25] rounded-lg p-4">
                      <div className="flex items-start">
                        <div className="w-8 h-8 rounded-full bg-blue-600 flex items-center justify-center flex-shrink-0">
                          <Truck size={16} className="text-white" />
                        </div>
                        <div className="ml-3">
                          <p className="text-gray-400 text-sm">Rider</p>
                          <p className="text-white mt-1">
                            The store is closed now, can't complete the
                            delivery.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="bg-[#161A25] rounded-lg p-4">
                      <div className="flex items-start">
                        <div className="w-8 h-8 rounded-full bg-purple-600 flex items-center justify-center flex-shrink-0">
                          <Zap size={16} className="text-white" />
                        </div>
                        <div className="ml-3">
                          <p className="text-gray-400 text-sm">Athena</p>
                          <p className="text-white mt-1">
                            I'll handle this. Checking store hours and
                            contacting the customer to arrange an alternative
                            delivery time.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="bg-[#161A25] rounded-lg p-4">
                      <div className="flex items-start">
                        <div className="w-8 h-8 rounded-full bg-purple-600 flex items-center justify-center flex-shrink-0">
                          <Zap size={16} className="text-white" />
                        </div>
                        <div className="ml-3">
                          <p className="text-gray-400 text-sm">Athena</p>
                          <p className="text-white mt-1">
                            I've notified the customer and updated the delivery
                            for tomorrow morning at 9:00 AM. The customer has
                            confirmed availability.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="bg-[#161A25] rounded-lg p-4">
                      <div className="flex items-start">
                        <div className="w-8 h-8 rounded-full bg-blue-600 flex items-center justify-center flex-shrink-0">
                          <Truck size={16} className="text-white" />
                        </div>
                        <div className="ml-3">
                          <p className="text-gray-400 text-sm">Rider</p>
                          <p className="text-white mt-1">
                            Thanks, I see it has been marked in tomorrow's
                            route!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Free Up Your Time Section */}
        <div id="free-up-your-time" className="bg-[#0A0C14] py-20">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h2 className="text-3xl md:text-4xl font-bold text-white">
                Maximize Operational <GradientText>ROI</GradientText>
              </h2>
              <p className="mt-4 text-xl text-gray-300 max-w-3xl mx-auto">
                Transform your logistics operations with AI that drives
                measurable business outcomes. Our enterprise solutions deliver
                immediate impact to your bottom line.
              </p>
              <div className="mt-6 mx-auto max-w-3xl">
                <div className="bg-gradient-to-r from-blue-900/30 to-purple-900/30 rounded-lg px-6 py-4 border border-blue-500/20">
                  <p className="text-lg font-semibold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-400">
                    Enable Your Operational Team To Handle More Workload Without
                    Additional Resources
                  </p>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {/* Voice Communication Demo */}
              <div className="bg-[#0F121B] rounded-xl border border-[#262D40] hover:border-[#414D75] transition-all duration-300 p-6">
                <div className="flex items-center mb-6">
                  <div className="w-12 h-12 rounded-full bg-blue-600/10 flex items-center justify-center mr-4">
                    <Phone className="text-blue-500" size={24} />
                  </div>
                  <h3 className="text-xl font-semibold text-white">
                    Voice Communication
                  </h3>
                </div>

                <div className="mb-6">
                  <p className="text-gray-300">
                    Athena handles phone calls with riders, resolving issues in
                    real-time without human intervention. Our advanced voice AI
                    understands context, accents, and complex logistics
                    terminology to provide immediate solutions to delivery
                    challenges, reducing the need for dispatcher involvement and
                    minimizing failed deliveries.
                  </p>
                </div>

                {/* Enhanced futuristic Delivery Location Resolution demo */}
                <div className="bg-gradient-to-r from-[#161A25] to-[#1E2A44] rounded-xl p-6 shadow-lg relative overflow-hidden">
                  {/* Decorative elements for futuristic feel */}
                  <div className="absolute top-0 right-0 w-32 h-32 bg-blue-500/10 rounded-full filter blur-2xl"></div>
                  <div className="absolute bottom-0 left-0 w-24 h-24 bg-purple-500/10 rounded-full filter blur-xl"></div>

                  <div className="relative z-10">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <button
                          onClick={() => togglePlay(demoAudio2)}
                          className="w-12 h-12 rounded-full bg-gradient-to-r from-blue-600 to-purple-600 flex items-center justify-center hover:from-blue-700 hover:to-purple-700 transition-all duration-300 shadow-lg shadow-blue-600/20"
                        >
                          {isPlaying && currentAudio === demoAudio2 ? (
                            <Pause size={20} className="text-white" />
                          ) : (
                            <Play size={20} className="text-white ml-1" />
                          )}
                        </button>
                        <div className="ml-4">
                          <div className="flex items-center">
                            <h4 className="text-white font-medium text-lg">
                              Delivery Location Resolution
                            </h4>
                            <span className="ml-3 px-2 py-0.5 bg-blue-500/20 text-blue-400 text-xs rounded-full border border-blue-500/30">
                              Live Demo
                            </span>
                          </div>
                          <div className="flex items-center text-gray-400 text-sm mt-1">
                            <span>
                              {currentAudio === demoAudio2
                                ? `${formatTime(currentTime)} / ${formatTime(
                                    duration || 110
                                  )}`
                                : "01:50"}
                            </span>
                            {currentAudio === demoAudio2 && (
                              <button
                                onClick={resetAudio}
                                className="ml-2 text-blue-400 hover:text-blue-300 transition-colors"
                                title="Restart audio"
                              >
                                <RefreshCw size={14} />
                              </button>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="hidden md:flex items-center space-x-2">
                        <div className="h-2 w-2 rounded-full bg-blue-500 animate-pulse"></div>
                        <span className="text-blue-400 text-xs">AI Voice</span>
                      </div>
                    </div>

                    {/* Animated waveform visualization */}
                    <div className="mt-6 w-full h-16 bg-[#0A0C14]/50 backdrop-blur-sm rounded-lg relative overflow-hidden border border-[#2A3555]/50">
                      <div className="absolute inset-0 flex items-center">
                        <div className="w-full h-8 flex items-center justify-center space-x-1 px-4">
                          {/* Continuously animated waveform visualization during playback */}
                          {[...Array(40)].map((_, i) => {
                            // Set different heights for different bars in a pattern
                            const barHeight = 4 + Math.floor(((i % 3) + 1) * 8);

                            // Calculate dynamic height based on animation phase - increased amplitude
                            const dynamicHeight =
                              isPlaying && currentAudio === demoAudio2
                                ? barHeight +
                                  Math.sin(waveformPhase / 8 + i * 0.3) * 12
                                : barHeight;

                            return (
                              <div
                                key={i}
                                className="w-1 bg-gradient-to-t from-blue-500 to-purple-500 rounded-full"
                                style={{
                                  height: `${dynamicHeight}px`,
                                  opacity:
                                    isPlaying && currentAudio === demoAudio2
                                      ? 1
                                      : 0.5,
                                }}
                              ></div>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    {/* Conversation summary */}
                    <div className="mt-4 bg-[#0A0C14]/50 backdrop-blur-sm p-3 rounded-lg border border-[#2A3555]/30">
                      <p className="text-gray-300 text-sm">
                        <span className="text-blue-400 font-medium">AI:</span>{" "}
                        Athena resolves a delivery exception when the rider is
                        unable to locate the place of delivery and is unable to
                        contact the customer, thus reducing the chance of a
                        failed delivery.
                      </p>
                    </div>
                  </div>
                </div>
                {/* End of enhanced Delivery Location Resolution demo */}
              </div>

              {/* Text Communication Demo */}
              <div className="bg-[#0F121B] rounded-xl border border-[#262D40] hover:border-[#414D75] transition-all duration-300 p-6">
                <div className="flex items-center mb-6">
                  <div className="w-12 h-12 rounded-full bg-purple-600/10 flex items-center justify-center mr-4">
                    <MessageSquare className="text-purple-500" size={24} />
                  </div>
                  <h3 className="text-xl font-semibold text-white">
                    Text Communication
                  </h3>
                </div>

                <div className="mb-6">
                  <p className="text-gray-300">
                    Athena manages text conversations with riders via WhatsApp,
                    iMessage and any channel of your preference coordinating
                    solutions efficiently without human intervention.
                  </p>
                </div>

                <TextCommunicationAnimation />
              </div>
            </div>

            {/* Smart Routing Section */}
            <div className="mt-24 text-center mb-12">
              <h3 className="text-2xl md:text-3xl font-bold text-white">
                <GradientText>Smart Routing</GradientText> & Dynamic Fleet
                Management
              </h3>
              <p className="mt-4 text-lg text-gray-300 max-w-3xl mx-auto">
                Athena optimizes routes in real-time, adapting to changing
                conditions and maximizing fleet efficiency
              </p>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
              {/* Smart Rerouting Section */}
              <div className="bg-[#0F121B] rounded-xl border border-[#262D40] hover:border-[#414D75] transition-all duration-300 p-8">
                <div className="flex items-center mb-6">
                  <div className="w-12 h-12 rounded-full bg-green-600/10 flex items-center justify-center mr-4">
                    <RefreshCw className="text-green-500" size={24} />
                  </div>
                  <h3 className="text-xl font-semibold text-white">
                    Smart Rerouting
                  </h3>
                </div>

                {/* Visual feature grid for Smart Rerouting */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="bg-[#161A25] rounded-lg p-4 border-l-2 border-green-500 hover:bg-[#1A1F2E] transition-all duration-300">
                    <div className="flex items-center mb-2">
                      <div className="w-8 h-8 rounded-full bg-[#0F121B] flex items-center justify-center mr-3">
                        <TrendingUp size={16} className="text-green-400" />
                      </div>
                      <h4 className="text-white text-sm font-medium">
                        Real-Time Analysis
                      </h4>
                    </div>
                    <p className="text-gray-300 text-sm">
                      Traffic, weather & historical data optimization
                    </p>
                  </div>

                  <div className="bg-[#161A25] rounded-lg p-4 border-l-2 border-purple-500 hover:bg-[#1A1F2E] transition-all duration-300">
                    <div className="flex items-center mb-2">
                      <div className="w-8 h-8 rounded-full bg-[#0F121B] flex items-center justify-center mr-3">
                        <MessageSquare size={16} className="text-purple-400" />
                      </div>
                      <h4 className="text-white text-sm font-medium">
                        Proactive Updates
                      </h4>
                    </div>
                    <p className="text-gray-300 text-sm">
                      Automatic customer notifications for changes
                    </p>
                  </div>

                  <div className="bg-[#161A25] rounded-lg p-4 border-l-2 border-yellow-500 hover:bg-[#1A1F2E] transition-all duration-300">
                    <div className="flex items-center mb-2">
                      <div className="w-8 h-8 rounded-full bg-[#0F121B] flex items-center justify-center mr-3">
                        <Clock size={16} className="text-yellow-400" />
                      </div>
                      <h4 className="text-white text-sm font-medium">
                        Business Hours
                      </h4>
                    </div>
                    <p className="text-gray-300 text-sm">
                      Intelligent sequencing based on operating hours
                    </p>
                  </div>

                  <div className="bg-[#161A25] rounded-lg p-4 border-l-2 border-red-500 hover:bg-[#1A1F2E] transition-all duration-300">
                    <div className="flex items-center mb-2">
                      <div className="w-8 h-8 rounded-full bg-[#0F121B] flex items-center justify-center mr-3">
                        <AlertTriangle size={16} className="text-red-400" />
                      </div>
                      <h4 className="text-white text-sm font-medium">
                        Disruption Handling
                      </h4>
                    </div>
                    <p className="text-gray-300 text-sm">
                      Instant rerouting when unexpected closures occur
                    </p>
                  </div>
                </div>

                <div className="mt-6">
                  <div className="bg-[#161A25] rounded-lg p-5">
                    <h4 className="text-white font-medium mb-3 flex items-center">
                      <CheckCircle size={16} className="text-green-400 mr-2" />
                      Delivery Success Benefits
                    </h4>
                    <div className="space-y-4">
                      <div className="bg-[#0F121B] rounded-lg p-4">
                        <div className="flex items-center">
                          <div className="w-8 h-8 rounded-full bg-[#161A25] flex items-center justify-center mr-3">
                            <CheckCircle size={16} className="text-green-400" />
                          </div>
                          <div>
                            <h5 className="text-white text-sm font-medium">
                              Reduced Failed Deliveries
                            </h5>
                            <p className="text-gray-400 text-sm mt-1">
                              Significantly fewer missed deliveries due to
                              business closures
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="bg-[#0F121B] rounded-lg p-4">
                        <div className="flex items-center">
                          <div className="w-8 h-8 rounded-full bg-[#161A25] flex items-center justify-center mr-3">
                            <CheckCircle size={16} className="text-green-400" />
                          </div>
                          <div>
                            <h5 className="text-white text-sm font-medium">
                              Improved First-Attempt Success
                            </h5>
                            <p className="text-gray-400 text-sm mt-1">
                              Higher rate of deliveries completed on first
                              attempt
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Dynamic Fleet Management Section */}
              <div className="bg-[#0F121B] rounded-xl border border-[#262D40] hover:border-[#414D75] transition-all duration-300 p-8">
                <div className="flex items-center mb-6">
                  <div className="w-12 h-12 rounded-full bg-blue-600/10 flex items-center justify-center mr-4">
                    <Truck className="text-blue-500" size={24} />
                  </div>
                  <h3 className="text-xl font-semibold text-white">
                    Dynamic Fleet Management
                  </h3>
                </div>

                {/* Visual feature grid for Dynamic Fleet Management */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="bg-[#161A25] rounded-lg p-4 border-l-2 border-blue-500 hover:bg-[#1A1F2E] transition-all duration-300">
                    <div className="flex items-center mb-2">
                      <div className="w-8 h-8 rounded-full bg-[#0F121B] flex items-center justify-center mr-3">
                        <RefreshCw size={16} className="text-blue-400" />
                      </div>
                      <h4 className="text-white text-sm font-medium">
                        Automated Fleet Dispatch
                      </h4>
                    </div>
                    <p className="text-gray-300 text-sm">
                      Assign additional tasks to drivers already en route
                    </p>
                  </div>

                  <div className="bg-[#161A25] rounded-lg p-4 border-l-2 border-green-500 hover:bg-[#1A1F2E] transition-all duration-300">
                    <div className="flex items-center mb-2">
                      <div className="w-8 h-8 rounded-full bg-[#0F121B] flex items-center justify-center mr-3">
                        <BarChart2 size={16} className="text-green-400" />
                      </div>
                      <h4 className="text-white text-sm font-medium">
                        Utilization Optimization
                      </h4>
                    </div>
                    <p className="text-gray-300 text-sm">
                      Maximize driver efficiency and minimize idle time
                    </p>
                  </div>

                  <div className="bg-[#161A25] rounded-lg p-4 border-l-2 border-orange-500 hover:bg-[#1A1F2E] transition-all duration-300">
                    <div className="flex items-center mb-2">
                      <div className="w-8 h-8 rounded-full bg-[#0F121B] flex items-center justify-center mr-3">
                        <Users size={16} className="text-orange-400" />
                      </div>
                      <h4 className="text-white text-sm font-medium">
                        Driver Retention
                      </h4>
                    </div>
                    <p className="text-gray-300 text-sm">
                      Minimize driver churn with balanced workloads
                    </p>
                  </div>

                  <div className="bg-[#161A25] rounded-lg p-4 border-l-2 border-purple-500 hover:bg-[#1A1F2E] transition-all duration-300">
                    <div className="flex items-center mb-2">
                      <div className="w-8 h-8 rounded-full bg-[#0F121B] flex items-center justify-center mr-3">
                        <Zap size={16} className="text-purple-400" />
                      </div>
                      <h4 className="text-white text-sm font-medium">
                        Customer Satisfaction
                      </h4>
                    </div>
                    <p className="text-gray-300 text-sm">
                      Faster deliveries with optimized driver assignments
                    </p>
                  </div>
                </div>

                <div className="mt-6">
                  <div className="bg-[#161A25] rounded-lg p-5">
                    <h4 className="text-white font-medium mb-3 flex items-center">
                      <CheckCircle size={16} className="text-blue-400 mr-2" />
                      Financial Benefits
                    </h4>
                    <div className="space-y-4">
                      <div className="bg-[#0F121B] rounded-lg p-4">
                        <div className="flex items-center">
                          <div className="w-8 h-8 rounded-full bg-[#161A25] flex items-center justify-center mr-3">
                            <CheckCircle size={16} className="text-blue-400" />
                          </div>
                          <div>
                            <h5 className="text-white text-sm font-medium">
                              Reduced Operational Costs
                            </h5>
                            <p className="text-gray-400 text-sm mt-1">
                              Lower expenses from fewer redelivery attempts
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="bg-[#0F121B] rounded-lg p-4">
                        <div className="flex items-center">
                          <div className="w-8 h-8 rounded-full bg-[#161A25] flex items-center justify-center mr-3">
                            <CheckCircle size={16} className="text-blue-400" />
                          </div>
                          <div>
                            <h5 className="text-white text-sm font-medium">
                              Enhanced Fleet Efficiency
                            </h5>
                            <p className="text-gray-400 text-sm mt-1">
                              Better resource utilization across your entire
                              fleet
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Impact Section */}
        <div id="impact-section" className="py-20">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h2 className="text-3xl md:text-4xl font-bold text-white">
                Impact Of <GradientText>Athena</GradientText>
              </h2>
              <p className="mt-4 text-xl text-gray-300 max-w-3xl mx-auto">
                Athena delivers measurable improvements to your last-mile
                operations
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="bg-[#0F121B] rounded-xl border border-[#262D40] p-8 text-center">
                <h3 className="text-5xl font-bold text-white">
                  {stats[0].value}
                  {stats[0].suffix}
                </h3>
                <p className="mt-4 text-xl text-gray-300">
                  Reduction in Failed Deliveries
                </p>
              </div>

              <div className="bg-[#0F121B] rounded-xl border border-[#262D40] p-8 text-center">
                <h3 className="text-5xl font-bold text-white">
                  {stats[1].value}
                  {stats[1].suffix}
                </h3>
                <p className="mt-4 text-xl text-gray-300">
                  Improvement in Fleet Utilization
                </p>
              </div>

              <div className="bg-[#0F121B] rounded-xl border border-[#262D40] p-8 text-center">
                <h3 className="text-5xl font-bold text-white">
                  {stats[2].value}
                  {stats[2].suffix}
                </h3>
                <p className="mt-4 text-xl text-gray-300">
                  Query Resolution Rate
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Use Cases Section */}
        <div id="use-cases-section" className="bg-[#0A0C14] py-20">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h2 className="text-3xl md:text-4xl font-bold text-white">
                How <GradientText>Athena</GradientText> Transforms Last Mile
                Delivery
              </h2>
              <p className="mt-4 text-xl text-gray-300 max-w-3xl mx-auto">
                Explore how Athena solves the unique challenges of last-mile
                delivery operations
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <div className="bg-[#0F121B] rounded-xl border border-[#262D40] hover:border-[#414D75] transition-all duration-300 p-6">
                <div className="w-12 h-12 rounded-full bg-blue-600/10 flex items-center justify-center mb-6">
                  <Clock className="text-blue-500" size={24} />
                </div>
                <h3 className="text-xl font-semibold text-white mb-4">
                  Intelligent Delivery Scheduling
                </h3>
                <p className="text-gray-300">
                  <span className="font-medium text-blue-400">
                    Adjusts routes in real-time
                  </span>{" "}
                  and{" "}
                  <span className="font-medium text-blue-400">
                    intelligently schedules deliveries within optimal time
                    windows
                  </span>{" "}
                  based on recipient availability, traffic patterns, and
                  business hours to maximize efficiency.
                </p>
              </div>

              <div className="bg-[#0F121B] rounded-xl border border-[#262D40] hover:border-[#414D75] transition-all duration-300 p-6">
                <div className="w-12 h-12 rounded-full bg-blue-600/10 flex items-center justify-center mb-6">
                  <Users className="text-blue-500" size={24} />
                </div>
                <h3 className="text-xl font-semibold text-white mb-4">
                  Access & Delivery Handling
                </h3>
                <p className="text-gray-300">
                  <span className="font-medium text-blue-400">
                    Manages complex delivery scenarios
                  </span>{" "}
                  including secure access and special handling requirements.
                </p>
              </div>

              <div className="bg-[#0F121B] rounded-xl border border-[#262D40] hover:border-[#414D75] transition-all duration-300 p-6">
                <div className="w-12 h-12 rounded-full bg-blue-600/10 flex items-center justify-center mb-6">
                  <AlertTriangle className="text-blue-500" size={24} />
                </div>
                <h3 className="text-xl font-semibold text-white mb-4">
                  Delivery Challenge Resolution
                </h3>
                <p className="text-gray-300">
                  <span className="font-medium text-blue-400">
                    Automatically resolves
                  </span>{" "}
                  delivery exceptions like{" "}
                  <span className="font-medium text-blue-400">
                    address errors
                  </span>
                  ,{" "}
                  <span className="font-medium text-blue-400">
                    recipient unavailability
                  </span>
                  , and unexpected access issues
                  <span className="font-medium text-blue-400">
                    {" "}
                    without dispatcher intervention
                  </span>
                  .
                </p>
              </div>

              <div className="bg-[#0F121B] rounded-xl border border-[#262D40] hover:border-[#414D75] transition-all duration-300 p-6">
                <div className="w-12 h-12 rounded-full bg-blue-600/10 flex items-center justify-center mb-6">
                  <ArrowRight className="text-blue-500" size={24} />
                </div>
                <h3 className="text-xl font-semibold text-white mb-4">
                  Seamless System Integration
                </h3>
                <p className="text-gray-300">
                  <span className="font-medium text-blue-400">
                    Connects with your existing TMS
                  </span>{" "}
                  and other logistics tools to create a
                  <span className="font-medium text-blue-400">
                    {" "}
                    unified workflow with real-time data synchronization
                  </span>
                  .
                </p>
              </div>

              <div className="bg-[#0F121B] rounded-xl border border-[#262D40] hover:border-[#414D75] transition-all duration-300 p-6">
                <div className="w-12 h-12 rounded-full bg-blue-600/10 flex items-center justify-center mb-6">
                  <MessageSquare className="text-blue-500" size={24} />
                </div>
                <h3 className="text-xl font-semibold text-white mb-4">
                  Customer Query Management
                </h3>
                <p className="text-gray-300">
                  Provides{" "}
                  <span className="font-medium text-blue-400">
                    personalized delivery updates
                  </span>
                  ,{" "}
                  <span className="font-medium text-blue-400">
                    manages delivery instructions
                  </span>
                  , and{" "}
                  <span className="font-medium text-blue-400">
                    handles recipient questions
                  </span>{" "}
                  through their preferred channels.
                </p>
              </div>

              <div className="bg-[#0F121B] rounded-xl border border-[#262D40] hover:border-[#414D75] transition-all duration-300 p-6">
                <div className="w-12 h-12 rounded-full bg-blue-600/10 flex items-center justify-center mb-6">
                  <BarChart2 className="text-blue-500" size={24} />
                </div>
                <h3 className="text-xl font-semibold text-white mb-4">
                  Rider Query Handling
                </h3>
                <p className="text-gray-300">
                  <span className="font-medium text-blue-400">
                    Resolves driver questions and issues in real-time
                  </span>
                  , providing
                  <span className="font-medium text-blue-400">
                    {" "}
                    guidance on delivery challenges without dispatcher
                    intervention
                  </span>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Integration Section */}
        <div id="integration-section">
          <IntegrationSection integrations={integrations} />
        </div>

        {/* Contact Form Section */}
        <div id="contact-section">
          <ContactForm />
        </div>
      </div>

      <AthenaFooter />

      {/* Audio element for demos */}
      <audio
        ref={audioRef}
        onEnded={handleAudioEnd}
        onLoadedMetadata={handleAudioMetadata}
        style={{ display: "none" }}
      />
    </>
  );
};

export default BlitzoAthena;
