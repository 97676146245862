// In firebase/functions/stripe.ts

import { httpsCallable } from "firebase/functions";
import { functions } from "../../Firebase/firebase-config";
import { PaymentMethod } from "@stripe/stripe-js";

// Get all saved payment methods for a customer
export const getPaymentMethods = async (): Promise<PaymentMethod[]> => {
  try {
    const getPaymentMethodsFunction = httpsCallable(
      functions,
      "getPaymentMethods"
    );
    const result = await getPaymentMethodsFunction();
    return result.data as PaymentMethod[];
  } catch (error) {
    console.error("Error fetching payment methods:", error);
    throw error;
  }
};

// Add a new payment method
export const addPaymentMethod = async (
  paymentMethodId: string
): Promise<void> => {
  try {
    const addPaymentMethodFunction = httpsCallable(
      functions,
      "addPaymentMethod"
    );
    await addPaymentMethodFunction({ paymentMethodId });
  } catch (error) {
    console.error("Error adding payment method:", error);
    throw error;
  }
};

// Remove a payment method
export const removePaymentMethod = async (
  paymentMethodId: string
): Promise<void> => {
  try {
    const removePaymentMethodFunction = httpsCallable(
      functions,
      "removePaymentMethod"
    );
    await removePaymentMethodFunction({ paymentMethodId });
  } catch (error) {
    console.error("Error removing payment method:", error);
    throw error;
  }
};

// Set default payment method
export const setDefaultPaymentMethod = async (
  paymentMethodId: string
): Promise<void> => {
  try {
    const setDefaultPaymentMethodFunction = httpsCallable(
      functions,
      "setDefaultPaymentMethod"
    );
    await setDefaultPaymentMethodFunction({ paymentMethodId });
  } catch (error) {
    console.error("Error setting default payment method:", error);
    throw error;
  }
};

// Create setup intent for adding a new card
export const createSetupIntent = async (): Promise<{
  clientSecret: string;
}> => {
  try {
    const createSetupIntentFunction = httpsCallable(
      functions,
      "createSetupIntent"
    );
    const result = await createSetupIntentFunction();
    return result.data as { clientSecret: string };
  } catch (error) {
    console.error("Error creating setup intent:", error);
    throw error;
  }
};
