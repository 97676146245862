// components/common/Tooltip.tsx
import React, { useState } from "react";

interface TooltipProps {
  text: string | React.ReactNode;
  children: React.ReactNode;
  position?: "top" | "bottom";
}

const Tooltip: React.FC<TooltipProps> = ({
  text,
  children,
  position = "top",
}) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div
      className="relative inline-block"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {isVisible && (
        <div
          className={`absolute z-50 w-max max-w-xs px-3 py-2 text-sm text-white 
            bg-gray-900 border border-gray-700 rounded-lg shadow-lg transform
            ${position === "top" ? "bottom-full mb-2" : "top-full mt-2"}
            left-1/2 -translate-x-1/2`}
        >
          {text}
          <div
            className={`absolute left-1/2 -translate-x-1/2 border-4 border-transparent
              ${
                position === "top"
                  ? "top-full border-t-gray-900"
                  : "bottom-full border-b-gray-900"
              }`}
          />
        </div>
      )}
      {children}
    </div>
  );
};

export default Tooltip;
