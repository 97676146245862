import React, { useState, useEffect } from "react";
import { Menu, X, ArrowUpRight, ChevronDown } from "lucide-react";
import { getStoreLink } from "../utils/deviceDetection";
import logoImage from "../Images/logo.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentRoute, setCurrentRoute] = useState(window.location.pathname);
  const [isUseCasesOpen, setIsUseCasesOpen] = useState(false);
  const navigate = useNavigate();

  const handleBookNow = () => {
    navigate("/business/book");
  };

  useEffect(() => {
    const handleRouteChange = () => {
      setCurrentRoute(window.location.pathname);
    };

    window.addEventListener("popstate", handleRouteChange);
    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, []);

  const linkClasses = (path) =>
    `text-gray-600 hover:text-[#E54C4C] transition-colors ${
      currentRoute === path ? "underline text-primary" : ""
    }`;

  return (
    <nav className="fixed w-full bg-white/80 backdrop-blur-lg z-50 border-b border-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-20">
          <div className="flex items-center">
            <a href="/" className="flex items-center">
              <img src={logoImage} alt="Blitzo" className="h-10 w-auto" />
              <span className="text-4xl font-semibold font-poppins text-[#E54C4C] ml-3">
                Blitzo
              </span>
            </a>
          </div>
          <div className="hidden md:flex items-center space-x-8">
            <div className="relative group">
              <button className="flex items-center text-gray-600 hover:text-[#E54C4C] transition-colors">
                Industries <ChevronDown className="ml-1" size={16} />
              </button>
              <div className="absolute invisible group-hover:visible hover:visible top-[calc(100%-10px)] left-0 pt-[10px] w-48">
                <div className="bg-white rounded-lg shadow-lg py-2">
                  <Link
                    to="/milk-runs"
                    className="block px-4 py-2 text-gray-600 hover:text-[#E54C4C] hover:bg-gray-50"
                  >
                    Milk Rounds
                  </Link>
                  <Link
                    to="/fashion"
                    className="block px-4 py-2 text-gray-600 hover:text-[#E54C4C] hover:bg-gray-50"
                  >
                    For Fashion
                  </Link>
                </div>
              </div>
            </div>
            <a href="/how-we-work" className={linkClasses("/how-we-work")}>
              How we work
            </a>
            <a href="/blog" className={linkClasses("/blog")}>
              Blog
            </a>
            <a href="/about" className={linkClasses("/about")}>
              About
            </a>
            <a href="/contact" className={linkClasses("/contact")}>
              Contact
            </a>
            <div className="flex items-center space-x-4">
              <Link to="/track-order" className={linkClasses("/track-order")}>
                Track Your Order
              </Link>
              <button
                onClick={handleBookNow}
                className="bg-[#E54C4C] text-white px-6 py-2 rounded-lg hover:bg-[#E54C4C]/90 transition-all shadow-lg shadow-[#E54C4C]/10 flex items-center"
              >
                Book Now <ArrowUpRight className="ml-2" size={16} />
              </button>
            </div>
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-gray-600"
            >
              {isMenuOpen ? <X size={32} /> : <Menu size={32} />}
            </button>
          </div>
        </div>
      </div>

      {isMenuOpen && (
        <div
          className={`
          md:hidden bg-white border-t fixed w-full top-20 left-0 transform transition-transform duration-300
          ${isMenuOpen ? "translate-y-0" : "-translate-y-full"}
        `}
        >
          <div className="px-4 py-4 space-y-4">
            <div className="block px-3 py-2">
              <button
                onClick={() => setIsUseCasesOpen(!isUseCasesOpen)}
                className="text-lg text-gray-600 hover:text-[#E54C4C] flex items-center"
              >
                Use Cases <ChevronDown className="ml-1" size={16} />
              </button>
              {isUseCasesOpen && (
                <div className="pl-4 mt-2">
                  <Link
                    to="/milk-runs"
                    className="block py-2 text-lg text-gray-600 hover:text-[#E54C4C]"
                  >
                    Milk Rounds
                  </Link>
                  <Link
                    to="/fashion"
                    className="block py-2 text-lg text-gray-600 hover:text-[#E54C4C]"
                  >
                    For Fashion
                  </Link>
                </div>
              )}
            </div>
            <a
              href="/how-we-work"
              className="block px-3 py-2 text-lg text-gray-600 hover:text-[#E54C4C]"
            >
              How we work
            </a>
            <a
              href="/blog"
              className="block px-3 py-2 text-lg text-gray-600 hover:text-[#E54C4C]"
            >
              Blog
            </a>
            <a
              href="/about"
              className="block px-3 py-2 text-lg text-gray-600 hover:text-[#E54C4C]"
            >
              About
            </a>
            <a
              href="/contact"
              className="block px-3 py-2 text-lg text-gray-600 hover:text-[#E54C4C]"
            >
              Contact
            </a>
            <a
              href="/track-order"
              className="block px-3 py-2 text-lg text-gray-600 hover:text-[#E54C4C]"
            >
              Track Your Order
            </a>
            <button
              onClick={handleBookNow}
              className="w-full text-center px-4 py-3 bg-[#E54C4C] text-white rounded-lg text-lg"
            >
              Book Now
            </button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
