import { db, functions } from "./firebase-config";
import {
  collection,
  doc,
  addDoc,
  onSnapshot,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const RIDERS_COLLECTION = "riders";
const DOCUMENT_COLLECTION = "manualVerification";

const getRiders = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, RIDERS_COLLECTION));
    const riders = [];
    querySnapshot.forEach((doc) => {
        riders.push(doc);
    });
    return riders;
  } catch (e) {
    console.error('Error fetching riders:', e);
    return [];
  }
};

const getRiderInfo = async (riderID) => {
  try {
    const riderRef = doc(db, RIDERS_COLLECTION, riderID);
    const riderDoc = await getDoc(riderRef);
    if (riderDoc.exists()) {
      const personalInfo = riderDoc.data().personalInfo;
      console.log(personalInfo.name, personalInfo.phoneNumber);
      return {
        name: personalInfo.name,
        phone: personalInfo.phoneNumber
      };
    } else {
      console.error(`No such rider with ID: ${riderID}`);
      return null;
    }
  } catch (error) {
    console.error(`Error fetching rider info for ID ${riderID}:`, error);
    return null;
  }
};

const getPendingRiders = async () => {
  try {
    const ridersQuery = query(
      collection(db, RIDERS_COLLECTION),
      where('reviewStatus.status', '==', 'pending')
    );

    const querySnapshot = await getDocs(ridersQuery);
    return querySnapshot.docs.map(doc => doc.id);
    
  } catch (e) {
    console.error('Error fetching riders:', e);
    return [];
  }
};

const approveRider = async (riderID) => {
    try {
        const riderRef = doc(db, RIDERS_COLLECTION, riderID);
    
        await updateDoc(riderRef, {
          'reviewStatus.status': 'approved'
        });
    
        console.log(`Rider ${riderID} approved successfully`);
      } catch (error) {
        console.error(`Error approving rider ${riderID}:`, error);
      }
};

const getRiderDocument = async (riderID) => {
    try {
      const docRef = doc(db, DOCUMENT_COLLECTION, riderID);
      const docSnap = await getDoc(docRef);
      return docSnap.data();
    } catch (e) {
      console.error("Error getting document: ", e);
    }
  };

const getQuariedRiderDocument = async (riderIDs) => {
  // try {
  //   const documents = [];
  //   for (const riderID of riderIDs) {
  //     const docRef = doc(db, DOCUMENT_COLLECTION, riderID);
  //     const docSnap = await getDoc(docRef);
  //     documents.push(docSnap.data());
  //   }
  //   return documents;
  // } catch (e) {
  //   console.error("Error getting document: ", e);
  // }
  try {
    const documentPromises = riderIDs.map(riderID => {
      const docRef = doc(db, DOCUMENT_COLLECTION, riderID);
      return getDoc(docRef);
    });
    
    const snapshots = await Promise.all(documentPromises);
    return snapshots.map(snap => snap.data());
    
  } catch (e) {
    console.error("Error getting document: ", e);
    return []; 
  }
}

export {
    getRiders, getPendingRiders, getRiderInfo, approveRider, getRiderDocument, getQuariedRiderDocument
};

