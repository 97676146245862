// Insured.tsx
import React from "react";
import { Shield, CheckCircle2, TrendingUp } from "lucide-react";

const Insured: React.FC = () => {
  return (
    <div className="relative overflow-hidden">
      <div className="relative p-4 rounded-xl bg-gradient-to-br from-gray-800/50 to-gray-900/50 border border-gray-800">
        <div className="flex items-start gap-4">
          <div className="relative">
            <div className="absolute inset-0 bg-emerald-400/20 rounded-lg blur-xl animate-pulse" />
            <div className="relative p-2 rounded-lg bg-emerald-500/10 border border-emerald-500/20">
              <Shield size={20} className="text-emerald-400 animate-pulse" />
            </div>
          </div>

          <div className="space-y-3">
            <div className="space-y-1">
              <div className="flex items-center gap-2">
                <h3 className="font-medium text-white">Package Protection</h3>
                <span
                  className="px-2 py-0.5 text-xs font-medium text-emerald-400 bg-emerald-500/10 
                  rounded-full border border-emerald-500/20"
                >
                  Free
                </span>
              </div>
              <div className="text-xs space-y-1">
                <p>All packages are insured up to £200, subject to T&Cs.</p>
                <p>
                  <a
                    href="https://blitzo.co.uk/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-400 hover:text-blue-300 underline"
                  >
                    View T&Cs
                  </a>
                </p>
              </div>
            </div>

            <div className="flex items-center gap-4">
              <div className="flex items-center gap-1.5 text-sm">
                <div className="relative">
                  <div className="absolute inset-0 bg-emerald-400/20 rounded-full blur-md animate-pulse" />
                  <CheckCircle2
                    size={14}
                    className="relative text-emerald-400"
                  />
                </div>
                <span className="text-gray-300">
                  100% Delivery Success Rate
                </span>
              </div>
              <div className="w-1 h-1 rounded-full bg-gray-700" />
              <div className="flex items-center gap-1.5 text-sm">
                <div className="relative">
                  <div className="absolute inset-0 bg-emerald-400/20 rounded-full blur-md animate-pulse" />
                  <TrendingUp size={14} className="relative text-emerald-400" />
                </div>
                <span className="text-gray-300">Zero Damage/Theft to date</span>
              </div>
            </div>
          </div>
        </div>

        {/* Animated background elements */}
        <div className="absolute inset-0 -z-10">
          <div
            className="absolute top-0 left-1/4 w-32 h-32 bg-emerald-500/10 rounded-full 
            blur-3xl animate-pulse"
          />
          <div
            className="absolute bottom-0 right-1/4 w-40 h-40 bg-emerald-500/5 rounded-full 
            blur-3xl animate-pulse"
            style={{ animationDelay: "500ms" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Insured;
