import { PackageType } from "./PackageType";
import { Location } from "./Location";

export type BlitzoOrder = {
  id: string;
  pickupLocation: Location;
  dropoffLocation: Location;
  packageType: PackageType;
  specialInstructions: string;
  isInsured: boolean;
  fee: FeeType;
  orderedBy: string;
  orderedAt: Date;
  status: OrderStatus;
  assignment: OrderAssigned | null;
  deliveryProof: DeliveryProof | null;
  schedule: Schedule;
  senderNumber?: string;
  receiverNumber?: string;
  customerFeedback: OrderFeedback | null;
  riderFeedback: RiderFeedback | null;
  paymentIntentId: string | null;
  ridersAndPickupDistances?: { riderId: string; distance: number }[];
  unassignedRiders?: string[];
};

export type OrderAssigned = {
  blitzerId: string;
  startedAt: Date;
  completedAt: Date;
  tip: number | null;
  blitzerLocation: Location | null;
};

export enum OrderStatus {
  pending = "pending",
  accepted = "accepted",
  rejected = "rejected",
  pickedUp = "pickedUp",
  delivered = "delivered",
  cancelled = "cancelled",
  paymnentDeclined = "paymentDeclined",
}

export interface RiderFeedback {
  orderId: string;
  easeOfAppUse: 1 | 2 | 3 | 4 | 5;
  riderFeedback: string;
  wouldRecommend: boolean;
  anyImprovements: string;
}

export interface RiderFee {
  baseFee: number;
  distanceFee: number;
  total: number;
}

export type FeeType = {
  baseFee: number;
  distanceFee: number;
  total: number;
  serviceFee: number;
  discountInPercentage: number;
  sizeFee: number;
  riderFee: RiderFee;
};

export type DeliveryProof = {
  deliveryProof: any | Document | null;
  deliveryProofCreatedAt: Date | null;
  deliveryProofLocation: Location | null;
};

interface Document {
  name: string;
  uri: string;
  type?: string | undefined;
  size?: number | undefined;
}

interface DocumentsState {
  license: any | Document | null;
  vehicleRegistration: any | Document | null;
  insurance: any | Document | null;
}

export interface CancelledOrder extends BlitzoOrder {
  cancellationReason: string;
  cancellationTime: Date;
  refundId: string | null;
  refundAmount: number;
}

export const DEFAULT_CANCELLATION_REASON = "Timeout Cancellation";

export type OrderFeedback = {
  orderId: string;
  experience: 1 | 2 | 3 | 4 | 5;
  feedback: string;
};

export const isDefaultOrderFeedback = (
  feedback: OrderFeedback,
  orderId: string
): boolean => {
  return (
    feedback.experience === 1 &&
    feedback.feedback === "" &&
    feedback.orderId === orderId
  );
};

export type MultidropOrder = {
  id: string;
  activeOrderIds: string[] | null;
  pickupLocation: Location;
  dropoffLocations: Location[];
  packageType: PackageType.Garments;
  specialInstructions: string;
  isInsured: false;
  fee: MultidropFeeType;
  orderedBy: string;
  orderedAt: Date;
  status: OrderStatus;
  assignment: OrderAssigned | null;
  deliveryProofs: DeliveryProof[] | null;
  customerFeedback: OrderFeedback | null;
  riderFeedback: RiderFeedback | null;
  paymentIntentId: string | null;
};

export interface MultidropFeeType {
  baseFee: number;
  pricePerDrop: number;
  serviceFee: number;
  sizeFee: number;
  total: number;
  discountInPercentage: number;
  discountedPrice?: number;
}
