import React, { useState } from "react";
import {
  Route,
  MapPin,
  Package,
  PoundSterling,
  Computer,
  Info,
} from "lucide-react";

interface RiderFee {
  baseFee: number;
  distanceFee: number;
  total: number;
}

interface PricingBreakdownProps {
  total: number;
  baseFee: number;
  distanceFee: number;
  serviceFee: number;
  sizeFee: number;
  riderFee: RiderFee;
}

const PricingBreakdown: React.FC<PricingBreakdownProps> = ({
  total,
  baseFee,
  distanceFee,
  serviceFee,
  sizeFee,
  riderFee,
}) => {
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const fees = [
    {
      name: "Base Fee",
      amount: baseFee + sizeFee,
      description: "Standard delivery charge",
      icon: PoundSterling,
      highlight: "blue",
    },
    {
      name: "Distance Fee",
      amount: distanceFee,
      description: `Based on route distance`,
      icon: Route,
      highlight: "blue",
    },
    {
      name: "Service Fee",
      amount: serviceFee,
      description: "Platform service charge",
      icon: Computer,
      highlight: "blue",
    },
  ];

  return (
    <div className="space-y-2 mt-6">
      <div className="bg-gray-800/50 rounded-xl border border-gray-700">
        {/* Total Price Display with Toggle */}
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="w-full p-6 border-b border-gray-700 flex justify-between items-start"
        >
          <div>
            <h3 className="text-lg font-medium text-gray-400">Total Price</h3>
            <div className="mt-1 text-3xl font-bold text-white">
              £{total.toFixed(2)}
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="px-3 py-1 rounded-lg bg-blue-500/10 border border-blue-500/20">
              <span className="text-sm font-medium text-blue-400">
                Best Value
              </span>
            </div>
            <Info
              size={20}
              className={`text-gray-400 transition-transform duration-200
                ${isExpanded ? "rotate-180" : "rotate-0"}`}
            />
          </div>
        </button>

        {/* Collapsible Fee Breakdown */}
        <div
          className={`grid transition-all duration-200 ease-in-out ${
            isExpanded
              ? "grid-rows-[1fr] opacity-100"
              : "grid-rows-[0fr] opacity-0"
          }`}
        >
          <div className="overflow-hidden">
            <div className="p-4">
              <div className="space-y-3">
                {fees.map((fee) => (
                  <div
                    key={fee.name}
                    onMouseEnter={() => setHoveredItem(fee.name)}
                    onMouseLeave={() => setHoveredItem(null)}
                    className="relative"
                  >
                    <div
                      className={`flex items-center justify-between p-3 rounded-lg
                      transition-all duration-200 
                      ${hoveredItem === fee.name ? "bg-gray-800" : ""}`}
                    >
                      <div className="flex items-center gap-3">
                        <div
                          className={`p-2 rounded-lg bg-${fee.highlight}-500/10`}
                        >
                          <fee.icon
                            size={16}
                            className={`text-${fee.highlight}-400`}
                          />
                        </div>
                        <span className="text-sm text-gray-300">
                          {fee.name}
                        </span>
                      </div>
                      <span className="font-medium text-white">
                        £{fee.amount.toFixed(2)}
                      </span>
                    </div>

                    {/* Tooltip */}
                    {hoveredItem === fee.name && (
                      <div
                        className="absolute z-10 left-0 -bottom-12 px-4 py-2 rounded-lg 
                        bg-gray-700 text-sm text-gray-300 shadow-xl border border-gray-600"
                      >
                        {fee.description}
                        <div
                          className="absolute -top-1 left-6 transform rotate-45 
                          w-2 h-2 bg-gray-700 border-l border-t border-gray-600"
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>

            {/* Final Total */}
            <div className="px-6 py-4 border-t border-gray-700 bg-gray-800/50">
              <div className="flex justify-between items-center">
                <span className="text-gray-300">Final Total</span>
                <span className="text-xl font-bold text-white">
                  £{total.toFixed(2)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingBreakdown;
