import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, PaymentMethod } from "@stripe/stripe-js";
import {
  CreditCard,
  Plus,
  CheckCircle,
  Trash2,
  Star,
  StarOff,
  AlertCircle,
} from "lucide-react";
import BusinessNavbar from "../../components/BusinessNavbar";
import AddCardModal from "../../components/resuable/AddCardModal";
import {
  getPaymentMethods,
  createSetupIntent,
  removePaymentMethod,
  setDefaultPaymentMethod,
} from "../../firebase/billing";

const stripePromise =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? loadStripe(
        "pk_test_51O8qV4IJxIKoMeqTrRkJNzvVRLQdSYFQQeeE98Z0XwcxpwZi1zP15rEcTyMrrlnlyXHSKLMphYvOWerCyjkpQ6dc00ZusGyWsT"
      )
    : loadStripe(
        "pk_live_51O8qV4IJxIKoMeqTam8YI8YTiGuNgO76L84S1TxfUxLBnLHmckD06etSjOTkHhyaamYNa4IasNUejkzRXq4UE8zm007NyDnv5w"
      );

const Billing: React.FC = () => {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [isAddingCard, setIsAddingCard] = useState(false);
  const [setupIntentSecret, setSetupIntentSecret] = useState<string | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(true);
  const [processingId, setProcessingId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  const fetchPaymentMethods = async () => {
    try {
      const methods = await getPaymentMethods();
      setPaymentMethods(methods);
      setError(null);
    } catch (error) {
      setError("Failed to load payment methods");
      console.error("Error fetching payment methods:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddCard = async () => {
    try {
      setError(null);
      const { clientSecret } = await createSetupIntent();
      setSetupIntentSecret(clientSecret);
      setIsAddingCard(true);
    } catch (error) {
      setError("Failed to initialize card setup");
      console.error("Error creating setup intent:", error);
    }
  };

  const handleRemoveCard = async (paymentMethodId: string) => {
    try {
      setError(null);
      setProcessingId(paymentMethodId);
      await removePaymentMethod(paymentMethodId);
      await fetchPaymentMethods();
    } catch (error) {
      setError("Failed to remove card");
      console.error("Error removing payment method:", error);
    } finally {
      setProcessingId(null);
    }
  };

  const getCardBrandIcon = () => {
    return <CreditCard className="text-gray-400" size={20} />;
  };

  return (
    <>
      <BusinessNavbar />
      <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-900 to-gray-800 text-white pt-16">
        <div className="max-w-4xl mx-auto px-4 py-8">
          {/* Header */}
          <div className="mb-8">
            <h1 className="text-2xl font-bold bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
              Payment Methods
            </h1>
            <p className="text-gray-400 mt-1">
              Manage your saved payment methods
            </p>
          </div>

          {/* Error Message */}
          {error && (
            <div className="mb-6 p-4 bg-red-500/10 border border-red-500/20 rounded-xl flex items-center gap-3">
              <AlertCircle className="text-red-400" size={20} />
              <p className="text-red-400">{error}</p>
            </div>
          )}

          {/* Add Card Button */}
          <button
            onClick={handleAddCard}
            className="mb-6 px-4 py-3 w-full rounded-xl bg-gray-800/50 border border-gray-700 
              hover:bg-gray-800 transition-colors flex items-center justify-center gap-2"
          >
            <Plus size={20} />
            Add New Card
          </button>

          {/* Cards List */}
          <div className="space-y-4">
            {isLoading ? (
              <div className="animate-pulse space-y-4">
                {[1, 2].map((i) => (
                  <div
                    key={i}
                    className="h-24 bg-gray-800/50 border border-gray-700 rounded-xl"
                  />
                ))}
              </div>
            ) : paymentMethods.length === 0 ? (
              <div className="text-center py-12 bg-gray-800/50 border border-gray-700 rounded-xl">
                <CreditCard size={48} className="mx-auto mb-3 text-gray-400" />
                <p className="text-gray-400">No payment methods added yet</p>
              </div>
            ) : (
              paymentMethods.map((method) => (
                <div
                  key={method.id}
                  className="p-4 bg-gray-800/50 border border-gray-700 rounded-xl"
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                      {getCardBrandIcon()}
                      <div>
                        <div className="flex items-center gap-2">
                          <p className="font-medium">
                            •••• {method.card?.last4}
                          </p>
                        </div>
                        <p className="text-sm text-gray-400">
                          Expires{" "}
                          {method.card?.exp_month.toString().padStart(2, "0")}/
                          {method.card?.exp_year}
                        </p>
                      </div>
                    </div>

                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => handleRemoveCard(method.id)}
                        disabled={!!processingId}
                        className="p-2 rounded-lg hover:bg-gray-700 transition-colors
                          text-gray-400 hover:text-red-400"
                        title="Remove card"
                      >
                        <Trash2 size={20} />
                      </button>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      {/* Add Card Modal */}
      {setupIntentSecret && (
        <Elements stripe={stripePromise}>
          <AddCardModal
            isOpen={isAddingCard}
            onClose={() => {
              setIsAddingCard(false);
              setSetupIntentSecret(null);
            }}
            onSuccess={() => {
              fetchPaymentMethods();
              setIsAddingCard(false);
              setSetupIntentSecret(null);
            }}
            setupIntentSecret={setupIntentSecret}
          />
        </Elements>
      )}
    </>
  );
};

export default Billing;
