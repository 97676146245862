import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import FashionHome from "./pages/FashionHome";
import WorkWithUs from "./pages/WorkWithUs";
import WaitlistProvider from "./Contexts/WaitlistProvider";
import Terms from "./components/Terms";
import AboutUs from "./components/AboutUs";
import Tracker from "./pages/Tracker";
import Multidrop from "./components/Blogs/Multidrop";
import RiderDashboard from "./pages/admin/RiderDashboard";
import PendingRequest from "./pages/admin/PendingRequest";
import DocumentView from "./pages/admin/DocumentView";
import Notifications from "./pages/admin/Notifications";
import HowWeWork from "./pages/HowWeWork";
import Contact from "./pages/Contact";
import Blog from "./components/blog";
import Blog1 from "./components/Blogs/blog1";
import Blog2 from "./components/Blogs/blog2";
import Blog3 from "./components/Blogs/blog3";
import Blog4 from "./components/Blogs/blog4";
import Blog5 from "./components/Blogs/blog5";
import Blog6 from "./components/Blogs/blog6";
import Blog7 from "./components/Blogs/blog7";
import Blog8 from "./components/Blogs/blog8";
import TrackOrder from "./pages/trackOrder";

//Web app
import CreateSingleDrop from "./web-app/pages/booking/CreateSingleDrop";
import CreateMultidrop from "./web-app/pages/booking/CreateMultidrop";
import BookingProvider from "./web-app/contexts/providers/BookingProvider";
import ActiveOrderProvider from "./web-app/contexts/providers/ActiveOrderProvider";
import SummaryAndPayment from "./web-app/pages/booking/SummaryAndPayment";
import { AuthProvider } from "./web-app/contexts/providers/AuthProvider";
import PrivateRoute from "./web-app/pages/auth/PrivateRoute";
import BusinessRoute from "./web-app/pages/auth/BusinessRoute";
import Login from "./web-app/pages/auth/Login";
import SignUp from "./web-app/pages/auth/SignUp";
import LiveTracker from "./web-app/pages/tracking/LiveTracker";
import TrackingHome from "./web-app/pages/tracking/TrackingHome";
import PastOrders from "./web-app/pages/history/PastOrders";
import Billing from "./web-app/pages/billing/Billing";
import SupportPage from "./web-app/pages/history/SupportPage";
import BusinessOnboarding from "./web-app/pages/auth/BusinessOnboarding";
import ProtectedRoute from "./components/ProtectedRoute";
import ActiveOrders from "./pages/admin/ActiveOrders";
import AdminLogin from "./pages/admin/Login";
import Home from "./pages/Home";
import MilkRuns from "./pages/MilkRuns";
import DoorstepInteractions from "./pages/DoorstepInteractions";
import HighValueRetail from "./pages/HighValueRetail";
import LocalBusinesses from "./pages/LocalBusinesses";
import Careers from "./pages/Careers";
import BlitzoOperate from "./pages/BlitzoOperate";
import BlitzoAthena from "./pages/BlitzoAthena";
import OperatorThankYouPage from "./pages/OperatorThankYouPage";

function AppContent() {
  return (
    <WaitlistProvider>
      <ActiveOrderProvider>
        {/* {!isTrackerPage && <Navbar />} */}
        <BookingProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/fashion" element={<FashionHome />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/how-we-work" element={<HowWeWork />} />
            <Route path="/rider" element={<WorkWithUs />} />
            {/* <Route path="/faq" element={<FAQ />} /> */}
            <Route path="/terms" element={<Terms />} />
            <Route path="/milk-runs" element={<MilkRuns />} />
            <Route
              path="/doorstep-interactions"
              element={<DoorstepInteractions />}
            />
            <Route path="operator" element={<BlitzoOperate />} />
            <Route path="athena" element={<BlitzoAthena />} />
            <Route
              path="operator/thank-you"
              element={<OperatorThankYouPage />}
            />
            <Route path="retail" element={<HighValueRetail />} />
            <Route path="/local-business" element={<LocalBusinesses />} />
            <Route path="/careers" element={<Careers />} />
            {/* <Route path="/stylists" element={<Stylists />} />
        <Route path="/clothing-returns" element={<Returns />} />
        <Route path="/click-collect" element={<ClickAndCollect />} />
        <Route path="/parcel-deliveries" element={<Parcel />} /> */}
            <Route path="/about" element={<AboutUs />} />
            <Route path="/liveOrders/:orderId/:view?" element={<Tracker />} />
            <Route path="/multidrop" element={<Multidrop />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/blog1" element={<Blog1 />} />
            <Route path="/blog/blog2" element={<Blog2 />} />
            <Route path="/blog/blog3" element={<Blog3 />} />
            <Route path="/blog/blog4" element={<Blog4 />} />
            <Route path="/blog/blog5" element={<Blog5 />} />
            <Route path="/blog/blog6" element={<Blog6 />} />
            <Route path="/blog/blog7" element={<Blog7 />} />
            <Route path="/blog/blog8" element={<Blog8 />} />
            <Route path="/track-order" element={<TrackOrder />} />

            {/* Auth Route */}
            <Route path="/business" element={<BusinessRoute />} />
            <Route path="/business/login" element={<Login />} />
            <Route path="/business/signup" element={<SignUp />} />

            {/* Protected business routes */}
            <Route
              path="/business/book"
              element={<PrivateRoute element={<CreateSingleDrop />} />}
            />
            <Route
              path="/business/book/multidrop"
              element={<PrivateRoute element={<CreateMultidrop />} />}
            />
            <Route
              path="/business/book/payment"
              element={<PrivateRoute element={<SummaryAndPayment />} />}
            />
            <Route
              path="/business/track/:orderId"
              element={<PrivateRoute element={<LiveTracker />} />}
            />
            <Route
              path="/business/track"
              element={<PrivateRoute element={<TrackingHome />} />}
            />
            <Route
              path="/business/past-orders"
              element={<PrivateRoute element={<PastOrders />} />}
            />
            <Route
              path="/business/billing"
              element={<PrivateRoute element={<Billing />} />}
            />
            <Route
              path="/business/support"
              element={<PrivateRoute element={<SupportPage />} />}
            />
            <Route
              path="/business/onboarding"
              element={<PrivateRoute element={<BusinessOnboarding />} />}
            />
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route
              path="/admin/active-orders"
              element={
                <ProtectedRoute>
                  <ActiveOrders />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin"
              element={<Navigate to="/admin/login" replace />}
            />
            <Route
              path="/admin/dashboard"
              element={
                <ProtectedRoute>
                  <RiderDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/requests"
              element={
                <ProtectedRoute>
                  <PendingRequest />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/requests/view"
              element={
                <ProtectedRoute>
                  <DocumentView />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/notifications"
              element={
                <ProtectedRoute>
                  <Notifications />
                </ProtectedRoute>
              }
            />
            {/* <Route
          path="/admin"
          element={<Navigate to="/admin/dashboard" replace />}
        />
        <Route path="/admin/dashboard" element={<RiderDashboard />} />
        <Route path="/admin/requests" element={<PendingRequest />} />
        <Route path="/admin/requests/view" element={<DocumentView />} />
        <Route path="/admin/notifications" element={<Notifications />} /> */}
          </Routes>
        </BookingProvider>
      </ActiveOrderProvider>
      {/* {!isTrackerPage && <Footer />} */}
    </WaitlistProvider>
  );
}

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Router>
          <AppContent />
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
