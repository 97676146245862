import React, { useState, useRef, useEffect } from "react";
import {
  Menu,
  X,
  User,
  FileText,
  Package,
  CreditCard,
  Map,
  PhoneCall,
  Route,
  LogOut,
  ChevronDown,
} from "lucide-react";
import logoImage from "../../Images/logo.png";
import { useNavigate } from "react-router-dom";
import { logout } from "../firebase/auth";

const BusinessNavbar: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAccountDropdownOpen, setIsAccountDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleAccountDropdown = () =>
    setIsAccountDropdownOpen(!isAccountDropdownOpen);

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/business/signup");
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsAccountDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <>
      {/* TOP NAVBAR - Always Visible */}
      <nav className="fixed w-full bg-gray-900 text-white z-50 border-b border-gray-800">
        <div className="h-16 relative">
          {/* Left Menu Button and Logo - Absolute positioned */}
          <div className="absolute left-10 top-1/2 -translate-y-1/2 flex items-center space-x-4">
            <button
              onClick={toggleMenu}
              className="text-gray-300 hover:text-white"
            >
              <Menu size={28} />
            </button>
            <a href="/business/book" className="flex items-center">
              <img
                src={logoImage}
                alt="Blitzo"
                className="h-8 w-auto self-center mr-1 mb-1"
              />
              <span className="text-2xl text-white font-semibold ml-2 self-baseline">
                Blitzo
              </span>
              <span className="text-md font-normal ml-2 text-gray-300 self-baseline">
                For Business
              </span>
            </a>
          </div>

          {/* Centered Dummy Component */}
          <div className="max-w-7xl h-full mx-auto">
            <div className="h-full flex items-center justify-center">
              {/* Your centered content goes here */}
            </div>
          </div>

          {/* Right Account Button with Dropdown - Absolute positioned */}
          <div
            className="absolute right-10 top-1/2 -translate-y-1/2"
            ref={dropdownRef}
          >
            <button
              onClick={toggleAccountDropdown}
              className="flex items-center space-x-1 text-gray-200 hover:text-white"
            >
              <User size={20} />
              <span>Account</span>
              <ChevronDown
                size={16}
                className={`ml-1 transform transition-transform duration-200 ${
                  isAccountDropdownOpen ? "rotate-180" : ""
                }`}
              />
            </button>

            {/* Account Dropdown Menu */}
            {isAccountDropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-gray-800 ring-1 ring-black ring-opacity-5">
                <div className="py-1">
                  <button
                    onClick={() => {
                      handleLogout();
                      setIsAccountDropdownOpen(false);
                    }}
                    className="flex items-center w-full px-4 py-2 text-sm text-gray-100 hover:bg-gray-700"
                  >
                    <LogOut size={16} className="mr-2" />
                    Logout
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>

      {/* Sidebar Menu Overlay */}
      {isMenuOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity"
          onClick={toggleMenu}
        ></div>
      )}

      {/* Sidebar Menu */}
      <div
        className={`fixed top-0 left-0 w-72 h-screen bg-gray-900 shadow-xl z-50 transform transition-transform duration-300 ${
          isMenuOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="flex items-center justify-between p-5 border-b border-gray-800">
          <span className="text-xl font-semibold text-white">Menu</span>
          <button
            onClick={toggleMenu}
            className="text-gray-400 hover:text-white"
          >
            <X size={28} />
          </button>
        </div>

        <nav className="mt-4 flex flex-col space-y-1">
          <a
            href="/business/book"
            onClick={toggleMenu}
            className="flex items-center px-5 py-4 text-white hover:bg-gray-800 transition"
          >
            <FileText size={20} className="mr-3" /> Book
          </a>

          <a
            href="/business/track"
            onClick={toggleMenu}
            className="flex items-center px-5 py-4 text-white hover:bg-gray-800 transition"
          >
            <Map size={20} className="mr-3" /> Track
          </a>
          <a
            href="/business/past-orders"
            onClick={toggleMenu}
            className="flex items-center px-5 py-4 text-white hover:bg-gray-800 transition"
          >
            <Package size={20} className="mr-3" /> Past Orders
          </a>
          <a
            href="/business/billing"
            onClick={toggleMenu}
            className="flex items-center px-5 py-4 text-white hover:bg-gray-800 transition"
          >
            <CreditCard size={20} className="mr-3" /> Billing
          </a>
          <a
            href="/business/support"
            onClick={toggleMenu}
            className="flex items-center px-5 py-4 text-white hover:bg-gray-800 transition"
          >
            <PhoneCall size={20} className="mr-3" /> Support
          </a>
          <a
            href="/business/book/multidrop"
            onClick={toggleMenu}
            className="flex items-center px-5 py-4 text-white hover:bg-gray-800 transition"
          >
            <Route size={20} className="mr-3" /> Multi Drop
          </a>
        </nav>
      </div>
    </>
  );
};

export default BusinessNavbar;
