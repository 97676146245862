// src/web-app/firebase/order.ts
import {
  doc,
  getDoc,
  setDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  collection,
  query,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

// Import your initialized Firebase instances
import { db, auth, functions } from "../../Firebase/firebase-config";
import { Location } from "../types/Location";
import { onSnapshot } from "firebase/firestore";
import { PackageType } from "../types/PackageType";
import {
  BlitzoOrder,
  CancelledOrder,
  DEFAULT_CANCELLATION_REASON,
  FeeType,
  MultidropFeeType,
  MultidropOrder,
  OrderStatus,
  RiderFee,
} from "../types/BlitzoOrder";
import { ParcelSize } from "../types/ParcelSize";
/**
 * Create an order in Firestore. Adapt from your original `createBlitzoOrder`.
 */
export async function createBlitzoOrder(
  orderId: string,
  pickupLocation: Location,
  dropoffLocation: Location,
  packageType: PackageType,
  specialInstructions: string,
  isInsured: boolean,
  fee: FeeType,
  paymentIntentId: string | null,
  schedule: Schedule,
  senderNumber?: string,
  receiverNumber?: string
): Promise<{ orderId: string; order: BlitzoOrder }> {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("No authenticated user");
  }

  const blitzoOrder: BlitzoOrder = {
    id: orderId,
    pickupLocation,
    dropoffLocation,
    packageType,
    specialInstructions,
    isInsured,
    fee,
    orderedBy: user.uid,
    orderedAt: new Date(),
    status: OrderStatus.pending,
    assignment: null,
    deliveryProof: null,
    senderNumber,
    receiverNumber,
    customerFeedback: null,
    riderFeedback: null,
    paymentIntentId,
    schedule,
  };

  if (schedule.isNow) {
    await setDoc(doc(db, "activeOrders", orderId), blitzoOrder);
  } else {
    await setDoc(doc(db, "scheduledOrders", orderId), blitzoOrder);
  }

  return { orderId, order: blitzoOrder };
}

/**
 * Retrieve a single active order for the current user.
 */
export async function getActiveOrder(): Promise<BlitzoOrder | null> {
  const user = auth.currentUser;
  if (!user) return null;

  const q = query(
    collection(db, "activeOrders"),
    where("orderedBy", "==", user.uid)
  );
  const snap = await getDocs(q);
  if (snap.empty) return null;

  const docData = snap.docs[0].data() as any;
  const blitzoOrder: BlitzoOrder = {
    ...docData,
    orderedAt: docData.orderedAt.toDate(),
  };
  return blitzoOrder;
}

/**
 * Example: get all active orders for current user.
 */
export async function getAllActiveOrders(): Promise<BlitzoOrder[]> {
  const user = auth.currentUser;
  if (!user) {
    console.error("No authenticated user");
    return [];
  }

  const q = query(
    collection(db, "activeOrders"),
    where("orderedBy", "==", user.uid)
  );
  const snap = await getDocs(q);
  if (snap.empty) return [];

  return snap.docs.map((d) => {
    const data = d.data();
    return {
      ...data,
      orderedAt: data.orderedAt.toDate(),
    } as BlitzoOrder;
  });
}

export async function getScheduledOrders(): Promise<BlitzoOrder[]> {
  const user = auth.currentUser;
  if (!user) return [];

  const q = query(
    collection(db, "scheduledOrders"),
    where("orderedBy", "==", user.uid)
  );
  const snap = await getDocs(q);
  if (snap.empty) return [];

  return snap.docs.map((d) => {
    const data = d.data();
    return {
      ...data,
      orderedAt: data.orderedAt.toDate(),
    } as BlitzoOrder;
  });
}

/**
 * Example: create a multi-drop order
 */
export async function createMultidropOrder(
  orderId: string,
  pickupLocation: Location,
  dropoffLocations: Location[],
  specialInstructions: string,
  fee: MultidropFeeType,
  paymentIntentId: string | null
): Promise<{ orderId: string; order: MultidropOrder }> {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("No authenticated user");
  }

  const multidropOrder: MultidropOrder = {
    id: orderId,
    activeOrderIds: null,
    pickupLocation,
    dropoffLocations,
    specialInstructions,
    fee,
    packageType: PackageType.Garments, // example default
    isInsured: false, // default
    orderedBy: user.uid,
    orderedAt: new Date(),
    status: OrderStatus.pending,
    assignment: null,
    deliveryProofs: null,
    customerFeedback: null,
    riderFeedback: null,
    paymentIntentId,
  };

  await setDoc(doc(db, "activeMultidropOrders", orderId), multidropOrder);

  return { orderId, order: multidropOrder };
}

/**
 * Example: get average pickup time from a callable cloud function
 */
export async function getWaitTimeInMinutes(): Promise<{
  avgPickupTimeInMinutes: number;
}> {
  const callable = httpsCallable(functions, "getAveragePickupTimeInMinutes");
  const res = await callable({});
  return res.data as { avgPickupTimeInMinutes: number };
}

/**
 * Example: Subscription (onSnapshot) for an active order
 * For web, you use onSnapshot from 'firebase/firestore'
 */

export function subscribeToActiveOrder(
  orderId: string,
  callback: (order: BlitzoOrder | null) => void
) {
  const docRef = doc(db, "activeOrders", orderId);
  return onSnapshot(docRef, (snapshot) => {
    if (!snapshot.exists()) {
      callback(null);
      return;
    }
    const data = snapshot.data();
    const order = {
      ...data,
      orderedAt: data.orderedAt.toDate(),
    };
    callback(order as BlitzoOrder);
  });
}

/**
 * Example: cancel & refund an order
 */
export async function refundAndCancelOrder(
  orderId: string,
  collectionName: "activeOrders" | "scheduledOrders"
) {
  const orderRef = doc(db, collectionName, orderId);
  const snapshot = await getDoc(orderRef);
  if (!snapshot.exists()) {
    throw new Error("Order does not exist");
  }

  const paymentIntentId = snapshot.data()?.paymentIntentId;
  if (!paymentIntentId) {
    // If there's no payment intent, handle logic or skip.
    throw new Error("No paymentIntentId to refund");
  }

  const createRefund = httpsCallable(functions, "createStripeRefund");
  const res = await createRefund({ paymentIntentId });
  const { refundId, refundAmount } = res.data as {
    refundId: string;
    refundAmount: number;
  };

  // Mark order as cancelled + store in `cancelledOrders`
  const cancelledOrder = {
    ...snapshot.data(),
    status: OrderStatus.cancelled,
    cancellationReason: DEFAULT_CANCELLATION_REASON,
    cancellationTime: new Date(),
    refundId,
    refundAmount,
  };
  await addDoc(
    collection(db, "cancelledOrders"),
    cancelledOrder as CancelledOrder
  );

  // Delete original order doc
  await deleteDoc(orderRef);
}

export async function getAllPastOrders(): Promise<BlitzoOrder[]> {
  const user = auth.currentUser;
  if (!user) {
    console.error("No authenticated user");
    return [];
  }

  const q = query(
    collection(db, "pastOrders"),
    where("orderedBy", "==", user.uid)
  );
  const snap = await getDocs(q);
  if (snap.empty) return [];

  return snap.docs.map((d) => {
    const data = d.data();
    return {
      ...data,
      orderedAt: data.orderedAt.toDate(),
    } as BlitzoOrder;
  });
}

export async function getAllPastMultidropOrders(): Promise<MultidropOrder[]> {
  const user = auth.currentUser;
  if (!user) {
    console.error("No authenticated user");
    return [];
  }

  const q = query(
    collection(db, "pastMultidropOrders"),
    where("orderedBy", "==", user.uid)
  );
  const snap = await getDocs(q);
  if (snap.empty) return [];

  return snap.docs.map((d) => {
    const data = d.data();
    return {
      ...data,
      orderedAt: data.orderedAt.toDate(),
    } as MultidropOrder;
  });
}

export async function getStripeCustomerId(): Promise<string | null> {
  const user = auth.currentUser;
  if (!user) {
    console.error("No authenticated user");
    return null;
  }

  const userDoc = await getDoc(doc(db, "users", user.uid));
  return userDoc.data()?.stripeCustomerId ?? "cus_R5CdZPE3Wr9ry6";
}

export async function getPriceSplitForSingleDrop(
  pickupLocation: Location,
  dropoffLocation: Location,
  isInsured: boolean,
  parcelSize: ParcelSize
): Promise<{
  total: number;
  baseFee: number;
  distanceFee: number;
  serviceFee: number;
  sizeFee: number;
  riderFee: RiderFee;
  paymentIntentId: string;
  orderId: string;
  clientSecret: string;
  ephemeralKey: string;
  customerId: string;
}> {
  const distanceCallable = httpsCallable(
    functions,
    "fetchDistanceInMilesAndDurationInMinutes"
  );
  console.log(
    "pickupLocation",
    pickupLocation,
    "dropoffLocation",
    dropoffLocation
  );
  const distanceRes = (
    await distanceCallable({ start: pickupLocation, end: dropoffLocation })
  ).data as {
    distance: number;
    duration: number;
  };
  const callable = httpsCallable(functions, "getPriceSplitForBlitzoOrder");
  const distance = distanceRes.distance;
  const needsPrintout = false;
  const res = await callable({
    distance,
    isInsured,
    parcelSize,
    needsPrintout,
    isExpress: false,
  });

  console.log("res", res.data);

  const returnValue = {
    total: (res.data as any).total - (res.data as any).printingFee,
    baseFee: (res.data as any).baseFee,
    distanceFee: (res.data as any).distanceFee,
    serviceFee: (res.data as any).serviceFee,
    sizeFee: (res.data as any).sizeFee,
    riderFee: (res.data as any).riderFee,
  } as {
    total: number;
    baseFee: number;
    distanceFee: number;
    serviceFee: number;
    sizeFee: number;
    riderFee: RiderFee;
  };

  console.log("returnValue", returnValue);

  // Stripe payment intent ID
  const stripeCustomerId = await getStripeCustomerId();
  const intentCallable = httpsCallable(functions, "createStripePaymentIntent");

  //Reserve a document ID for the order in activeOrders
  const orderId = doc(collection(db, "activeOrders")).id;
  const intentRes = await intentCallable({
    amount: Math.round(returnValue.total * 100),
    customerId: stripeCustomerId,
    orderId,
  });

  const stripeReturnValue = intentRes.data as {
    clientSecret: string;
    ephemeralKey: string;
    orderId: string;
    paymentIntentId: string;
    customerId: string;
  };

  return {
    ...returnValue,
    orderId,
    paymentIntentId: stripeReturnValue.paymentIntentId,
    clientSecret: stripeReturnValue.clientSecret,
    ephemeralKey: stripeReturnValue.ephemeralKey,
    customerId: stripeReturnValue.customerId,
  };
}
