import React, { useContext, useEffect, useState } from "react";
import {
  getAllPastOrders,
  getAllPastMultidropOrders,
} from "../../firebase/order";
import {
  BlitzoOrder,
  MultidropOrder,
  OrderStatus,
} from "../../types/BlitzoOrder";
import { useNavigate } from "react-router-dom";
import {
  CircleCheck,
  Clock,
  Bike,
  XCircle,
  Package,
  Grid,
  Search,
  Filter,
  ArrowRight,
} from "lucide-react";
import BusinessNavbar from "../../components/BusinessNavbar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PastOrderModal from "../../components/resuable/PastOrderModal";
import BookingContext from "../../contexts/BookingContext";
const PastOrders: React.FC = () => {
  const [showSingleDrop, setShowSingleDrop] = useState(true);
  const [singleDropOrders, setSingleDropOrders] = useState<BlitzoOrder[]>([]);
  const [multiDropOrders, setMultiDropOrders] = useState<MultidropOrder[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dateRangePreset, setDateRangePreset] = useState("");
  const [priceFilter, setPriceFilter] = useState("");
  const navigate = useNavigate();

  const [selectedOrder, setSelectedOrder] = useState<BlitzoOrder | null>(null);

  function handleDateRangePresetChange(preset: string) {
    setDateRangePreset(preset);

    const now = new Date();
    let from = new Date();
    switch (preset) {
      case "24h":
        from = new Date(now.getTime() - 24 * 60 * 60 * 1000);
        break;
      case "7d":
        from = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
        break;
      case "1m":
        from.setMonth(from.getMonth() - 1);
        break;
      case "1q":
        from.setMonth(from.getMonth() - 3);
        break;
      case "6m":
        from.setMonth(from.getMonth() - 6);
        break;
      case "ytd":
        from = new Date(now.getFullYear(), 0, 1);
        break;
      default:
        // No preset
        setStartDate("");
        setEndDate("");
        return;
    }
    setStartDate(from.toISOString().split("T")[0]);
    setEndDate(now.toISOString().split("T")[0]);
  }

  useEffect(() => {
    async function fetchOrders() {
      const singleOrders = await getAllPastOrders();
      const multiOrders = await getAllPastMultidropOrders();
      setSingleDropOrders(singleOrders);
      setMultiDropOrders(multiOrders);
    }

    fetchOrders();
  }, []);

  /* Filtering logic */
  const filterOrders = (orders: BlitzoOrder[] | MultidropOrder[]) => {
    return orders.filter((order) => {
      const matchesSearch =
        searchQuery === "" ||
        order.id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        order.pickupLocation?.locationName
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        ("dropoffLocation" in order &&
          (order as BlitzoOrder).dropoffLocation?.locationName
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase())) ||
        ("dropoffLocations" in order &&
          (order as MultidropOrder).dropoffLocations.some((loc) =>
            loc?.locationName?.toLowerCase().includes(searchQuery.toLowerCase())
          ));

      let matchesDateRange = true;
      if (order.orderedAt && startDate && endDate) {
        const orderDate = new Date(order.orderedAt);
        const from = new Date(startDate);
        const to = new Date(endDate);
        matchesDateRange = orderDate >= from && orderDate <= to;
      }

      const matchesPrice =
        priceFilter === "" || order.fee?.total <= parseFloat(priceFilter);

      return matchesSearch && matchesDateRange && matchesPrice;
    });
  };

  return (
    <>
      <BusinessNavbar />
      <div className="min-h-screen bg-gray-900 text-white py-20 px-6">
        <h1 className="text-3xl font-semibold mb-6">Past Orders</h1>
        {/* Render the Past Order Modal if an order is selected */}
        {selectedOrder && (
          <PastOrderModal
            order={selectedOrder}
            onClose={() => setSelectedOrder(null)}
          />
        )}
        {/* Toggle Between Single Drop & Multidrop Orders */}
        <div className="flex justify-center mb-6">
          <button
            className={`px-6 py-2 rounded-l-lg ${
              showSingleDrop ? "bg-red-500" : "bg-gray-800"
            } transition duration-300`}
            onClick={() => setShowSingleDrop(true)}
          >
            Single Drop Orders
          </button>
          <button
            className={`px-6 py-2 rounded-r-lg ${
              !showSingleDrop ? "bg-red-500" : "bg-gray-800"
            } transition duration-300`}
            onClick={() => setShowSingleDrop(false)}
          >
            Multidrop Orders
          </button>
        </div>

        {/* Search & Filter Bar */}
        <div className="flex space-x-4 mb-6">
          {/* Search Input */}
          <div className="flex items-center bg-gray-800 rounded-lg px-3 py-2 w-1/3">
            <Search size={20} className="text-gray-400" />
            <input
              type="text"
              placeholder="Search by Order ID, Pickup, Dropoff..."
              className="bg-transparent border-none outline-none text-white px-2 w-full"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>

          {/* Preset Range Selector */}
          <div className="flex items-center bg-gray-800 rounded-lg px-3 py-2">
            <Filter size={20} className="text-gray-400" />
            <select
              value={dateRangePreset}
              onChange={(e) => handleDateRangePresetChange(e.target.value)}
              className="bg-transparent border-none outline-none text-white px-2"
            >
              <option value="">No Preset</option>
              <option value="24h">Last 24 Hours</option>
              <option value="7d">Last 7 Days</option>
              <option value="1m">Last Month</option>
              <option value="1q">Last Quarter</option>
              <option value="6m">Last 6 Months</option>
              <option value="ytd">Year to Date</option>
            </select>
          </div>

          {/* From Date */}
          <div className="flex items-center bg-gray-800 rounded-lg px-3 py-2">
            <Filter size={20} className="text-gray-400" />
            <DatePicker
              className="bg-transparent border-none outline-none text-white px-2"
              value={startDate}
              placeholderText="From Date"
              onChange={(e) => setStartDate(e?.toDateString() ?? "")}
            />
          </div>

          {/* To Date */}
          <div className="flex items-center bg-gray-800 rounded-lg px-3 py-2">
            <Filter size={20} className="text-gray-400" />
            <DatePicker
              className="bg-transparent border-none outline-none text-white px-2"
              value={endDate}
              placeholderText="To Date"
              onChange={(e) => setEndDate(e?.toDateString() ?? "")}
            />
          </div>

          {/* Price Filter */}
          <div className="flex items-center bg-gray-800 rounded-lg px-3 py-2">
            <Filter size={20} className="text-gray-400" />
            <input
              type="number"
              placeholder="Max Price (£)"
              className="bg-transparent border-none outline-none text-white px-2"
              value={priceFilter}
              onChange={(e) => setPriceFilter(e.target.value)}
            />
          </div>
          <button
            className="px-4 py-2 bg-gray-700 hover:bg-gray-600 text-white rounded-lg transition duration-300"
            onClick={() => {
              setSearchQuery("");
              setStartDate("");
              setEndDate("");
              setDateRangePreset("");

              setPriceFilter("");
            }}
          >
            Clear Filters
          </button>
        </div>

        {/* Order List */}
        <div className="space-y-4">
          {showSingleDrop
            ? filterOrders(singleDropOrders).length
              ? filterOrders(singleDropOrders).map((order) => (
                  <OrderCard
                    key={order.id}
                    order={order as BlitzoOrder}
                    onSelect={setSelectedOrder}
                  />
                ))
              : NoOrders("No past single-drop orders match the filters")
            : filterOrders(multiDropOrders).length
            ? filterOrders(multiDropOrders).map((order) => (
                <MultidropOrderCard
                  key={order.id}
                  order={order as MultidropOrder}
                  navigate={navigate}
                />
              ))
            : NoOrders("No past multidrop orders match the filters")}
        </div>
      </div>
    </>
  );
};

export default PastOrders;

/* Order Card Component */
const OrderCard: React.FC<{ order: BlitzoOrder; onSelect: any }> = ({
  order,
  onSelect,
}) => {
  const statusDetails = getStatusDetails(order.status);
  const formattedDate = new Date(order.orderedAt).toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    hour: "2-digit",
    minute: "2-digit",
  });

  const {
    setPickupLocation,
    setDropoffLocation,
    setPackageType,
    setSpecialInstructions,
    setSchedule,
  } = useContext(BookingContext);

  const navigate = useNavigate();
  const handleRepeatOrder = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent opening the modal

    // Pre-fill the booking context with order details
    setPickupLocation({
      latitude: order.pickupLocation.latitude,
      longitude: order.pickupLocation.longitude,
      locationName: order.pickupLocation.locationName,
      detailedLocation: order.pickupLocation.detailedLocation,
      contact: {
        name: order.pickupLocation?.contact?.name ?? "",
        number:
          order.pickupLocation?.contact?.number ?? order?.senderNumber ?? "",
      },
    });
    setDropoffLocation({
      latitude: order.dropoffLocation.latitude,
      longitude: order.dropoffLocation.longitude,
      locationName: order.dropoffLocation.locationName,
      detailedLocation: order.dropoffLocation.detailedLocation,
      contact: {
        name: order.dropoffLocation?.contact?.name ?? "",
        number:
          order.dropoffLocation?.contact?.number ?? order?.receiverNumber ?? "",
      },
    });
    setPackageType(order.packageType);
    setSpecialInstructions(order.specialInstructions || "");
    setSchedule({ isNow: true }); // Always set to ASAP for repeated orders

    // Navigate to booking page
    navigate("/business/book");
  };

  return (
    <div
      onClick={() => onSelect(order)}
      className="group bg-gray-900/50 hover:bg-gray-800/80 rounded-2xl border border-gray-800 
          hover:border-gray-700 transition-all duration-300 cursor-pointer overflow-hidden"
    >
      <button
        onClick={handleRepeatOrder}
        className="absolute top-5 right-24 px-4 py-2 bg-blue-500 hover:bg-blue-600 
          rounded-lg text-sm font-medium transition-all duration-200 
          opacity-0 group-hover:opacity-100 flex items-center gap-2"
      >
        <ArrowRight className="rotate-45" size={16} />
        Repeat Order
      </button>
      <div className="px-5 pt-5 pb-3 flex items-center justify-between relative">
        <div className="flex items-center gap-3">
          <div
            className={`p-2 rounded-xl bg-${statusDetails.color}/10 border border-${statusDetails.color}/20`}
          >
            {statusDetails.icon}
          </div>
          <div>
            <div className="flex items-center gap-2">
              <span
                className={`text-sm font-medium text-${statusDetails.color}`}
              >
                {statusDetails.label}
              </span>
              <span className="text-gray-600">•</span>
              <span className="text-sm text-gray-500 font-medium">
                #{order.id.slice(0, 8)}
              </span>
            </div>
            <div className="text-sm text-gray-500 mt-1">{formattedDate}</div>
          </div>
        </div>

        {/* Price and Actions */}
        <div className="flex items-center gap-4">
          {/* Repeat Order Button */}
          <button
            onClick={handleRepeatOrder}
            className="px-4 py-2 bg-blue-500 hover:bg-blue-600 
      rounded-lg text-sm font-medium transition-colors duration-200 
      flex items-center gap-2"
          >
            <ArrowRight className="rotate-45" size={16} />
            Repeat Order
          </button>

          <div className="text-right">
            <span className="text-sm text-gray-400">Total</span>
            <p className="text-base font-medium text-white">
              £{order.fee.total.toFixed(2)}
            </p>
          </div>
          <ArrowRight
            size={20}
            className="text-gray-500 group-hover:text-gray-400 
        transform group-hover:translate-x-1 transition-all"
          />
        </div>
      </div>

      {/* Location Details */}
      <div className="px-5 py-4 bg-gray-800/50 border-t border-gray-800/80">
        <div className="grid grid-cols-[1fr,auto,1fr] gap-4 items-start">
          {/* Pickup */}
          <div>
            <div className="flex items-center gap-2 mb-1.5">
              <div className="w-2 h-2 rounded-full bg-emerald-500" />
              <span className="text-sm font-medium text-gray-400">Pickup</span>
            </div>
            <h3 className="font-medium text-white mb-1">
              {order.pickupLocation.locationName}
            </h3>
            <p className="text-sm text-gray-500 line-clamp-2">
              {order.pickupLocation.detailedLocation}
            </p>
          </div>

          {/* Connector */}
          <div className="flex flex-col items-center gap-1 pt-6">
            <div className="w-[1px] h-12 bg-gradient-to-b from-emerald-500/50 to-blue-500/50" />
          </div>

          {/* Dropoff */}
          <div>
            <div className="flex items-center gap-2 mb-1.5">
              <div className="w-2 h-2 rounded-full bg-blue-500" />
              <span className="text-sm font-medium text-gray-400">Dropoff</span>
            </div>
            <h3 className="font-medium text-white mb-1">
              {order.dropoffLocation.locationName}
            </h3>
            <p className="text-sm text-gray-500 line-clamp-2">
              {order.dropoffLocation.detailedLocation}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

/* Multidrop Order Card */
const MultidropOrderCard: React.FC<{
  order: MultidropOrder;
  navigate: any;
}> = ({ order, navigate }) => {
  const formattedDate = new Date(order.orderedAt).toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <div
      onClick={() => navigate(`/business/track/${order.id}`)}
      className="group bg-gray-900/50 hover:bg-gray-800/80 rounded-2xl border border-gray-800 
          hover:border-gray-700 transition-all duration-300 cursor-pointer overflow-hidden"
    >
      {/* Header */}
      <div className="px-5 pt-5 pb-3 flex items-center justify-between">
        <div className="flex items-center gap-3">
          <div className="p-2 rounded-xl bg-purple-500/10 border border-purple-500/20">
            <Grid size={20} className="text-purple-400" />
          </div>
          <div>
            <div className="flex items-center gap-2">
              <span className="text-sm font-medium text-purple-400">
                Multi-Drop
              </span>
              <span className="text-gray-600">•</span>
              <span className="text-sm text-gray-500 font-medium">
                #{order.id.slice(0, 8)}
              </span>
            </div>
            <div className="text-sm text-gray-500 mt-1">{formattedDate}</div>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <div className="text-right">
            <span className="text-sm text-gray-400">Total</span>
            <p className="text-base font-medium text-white">
              £{order.fee.total.toFixed(2)}
            </p>
          </div>
          <ArrowRight
            size={20}
            className="text-gray-500 group-hover:text-gray-400 
                transform group-hover:translate-x-1 transition-all"
          />
        </div>
      </div>

      {/* Locations */}
      <div className="px-5 py-4 bg-gray-800/50 border-t border-gray-800/80">
        <div className="space-y-4">
          {/* Pickup */}
          <div>
            <div className="flex items-center gap-2 mb-1.5">
              <div className="w-2 h-2 rounded-full bg-emerald-500" />
              <span className="text-sm font-medium text-gray-400">
                Pickup Point
              </span>
            </div>
            <h3 className="font-medium text-white">
              {order.pickupLocation.locationName}
            </h3>
          </div>

          {/* Dropoffs Summary */}
          <div className="pt-2 border-t border-gray-700">
            <div className="flex items-center gap-2 mb-2">
              <div className="w-2 h-2 rounded-full bg-blue-500" />
              <span className="text-sm font-medium text-gray-400">
                {order.dropoffLocations.length} Delivery Points
              </span>
            </div>
            <div className="flex flex-wrap gap-2">
              {order.dropoffLocations.slice(0, 3).map((location, index) => (
                <span
                  key={index}
                  className="px-2 py-1 text-sm bg-gray-800 rounded-lg text-gray-300"
                >
                  {location.locationName}
                </span>
              ))}
              {order.dropoffLocations.length > 3 && (
                <span className="px-2 py-1 text-sm bg-gray-800 rounded-lg text-gray-400">
                  +{order.dropoffLocations.length - 3} more
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const getStatusDetails = (status: OrderStatus) => {
  switch (status) {
    case OrderStatus.delivered:
      return {
        label: "Delivered",
        color: "emerald",
        icon: <CircleCheck className="text-emerald-500" size={20} />,
      };
    case OrderStatus.pending:
      return {
        label: "Pending",
        color: "yellow",
        icon: <Clock className="text-yellow-500" size={20} />,
      };
    case OrderStatus.accepted:
      return {
        label: "Accepted",
        color: "blue",
        icon: <Bike className="text-blue-500" size={20} />,
      };
    case OrderStatus.pickedUp:
      return {
        label: "Picked Up",
        color: "purple",
        icon: <Package className="text-purple-500" size={20} />,
      };
    case OrderStatus.rejected:
      return {
        label: "Rejected",
        color: "red",
        icon: <XCircle className="text-red-500" size={20} />,
      };
    default:
      return {
        label: "Unknown",
        color: "gray",
        icon: <Search className="text-gray-500" size={20} />,
      };
  }
};

/* No Orders Message */
const NoOrders = (message: string) => (
  <div className="text-center text-gray-400 text-lg mt-10">{message}</div>
);
