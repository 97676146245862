import React, { useState } from "react";
import { X } from "lucide-react";

interface CancelModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (reason: string) => void;
}

const CancelModal: React.FC<CancelModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  const [reason, setReason] = useState("");
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 300);
  };

  const handleConfirm = () => {
    onConfirm(reason);
    handleClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className={`absolute inset-0 bg-black transition-opacity duration-300
          ${isClosing ? "bg-opacity-0" : "bg-opacity-60"}`}
        onClick={handleClose}
      />

      <div
        className={`relative w-full max-w-md bg-gradient-to-br from-gray-900 to-gray-800 
          text-white rounded-2xl shadow-2xl transition-all duration-300 transform
          ${isClosing ? "scale-95 opacity-0" : "scale-100 opacity-100"}`}
      >
        <div className="p-6">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-xl font-semibold text-white">Cancel Order</h2>
            <button
              onClick={handleClose}
              className="p-2 hover:bg-gray-700 rounded-full transition-colors"
            >
              <X size={20} className="text-gray-400 hover:text-white" />
            </button>
          </div>

          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Reason for cancellation
              </label>
              <textarea
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                placeholder="Please provide a reason..."
                className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-lg
                  focus:outline-none focus:ring-2 focus:ring-red-500 focus:border-transparent
                  placeholder-gray-400 transition-all min-h-[100px]"
              />
            </div>

            <div className="flex justify-end gap-3 mt-6">
              <button
                onClick={handleClose}
                className="px-4 py-2 rounded-lg border border-gray-600 hover:bg-gray-700 
                  transition-all text-sm font-medium"
              >
                Keep Order
              </button>
              <button
                onClick={handleConfirm}
                disabled={!reason.trim()}
                className={`px-4 py-2 rounded-lg transition-all text-sm font-medium
                  ${
                    !reason.trim()
                      ? "bg-gray-600 cursor-not-allowed"
                      : "bg-red-500 hover:bg-red-600"
                  }`}
              >
                Cancel Order
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelModal;
