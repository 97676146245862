import { useEffect, useState } from "react";
import { CollectionSpeed } from "../../types/CollectionSpeed";
import { PackageType } from "../../types/PackageType";
import { ParcelSize } from "../../types/ParcelSize";
import { Location } from "../../types/Location";
import BookingContext from "../BookingContext";

interface Props {
  children: React.ReactNode;
}

const BookingProvider = ({ children }: Props) => {
  const [packageType, setPackageType] = useState<PackageType | null>(null);
  const [specialInstructions, setSpecialInstructions] = useState("");
  const [isInsured, setIsInsured] = useState(false);
  const [senderNumber, setSenderNumber] = useState("");
  const [receiverNumber, setReceiverNumber] = useState("");
  const [selectedSizes, setSelectedSizes] = useState<
    | {
        [key in ParcelSize]: number;
      }
    | null
  >(null);
  const [schedule, setSchedule] = useState<Schedule | null>(null);
  const [collectionSpeed, setCollectionSpeed] =
    useState<CollectionSpeed | null>(null);
  const [pickupLocation, setPickupLocation] = useState<Location | null>(null);
  const [dropoffLocation, setDropoffLocation] = useState<Location | null>(null);

  const clearPDC = () => {
    setPackageType(null);
    setSpecialInstructions("");
    setIsInsured(false);
    setSenderNumber("");
    setReceiverNumber("");
    setSelectedSizes(null);
    setSchedule(null);
    setCollectionSpeed(null);
    setPickupLocation(null);
    setDropoffLocation(null);
  };

  useEffect(() => {
    setSchedule({ isNow: true });
  }, []);

  return (
    <BookingContext.Provider
      value={{
        packageType,
        setPackageType,
        specialInstructions,
        setSpecialInstructions,
        isInsured,
        setIsInsured,
        clearPDC,
        senderNumber,
        setSenderNumber,
        receiverNumber,
        setReceiverNumber,
        selectedSizes,
        setSelectedSizes,
        schedule,
        setSchedule,
        collectionSpeed,
        setCollectionSpeed,
        pickupLocation,
        setPickupLocation,
        dropoffLocation,
        setDropoffLocation,
      }}
    >
      {children}
    </BookingContext.Provider>
  );
};

export default BookingProvider;
