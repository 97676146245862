import React from "react";
import logoImage from "../Images/logo.png";
import { ArrowRight, Phone, Mail, Linkedin, Calendar } from "lucide-react";

// Gradient text component for "Operator"
const GradientText = ({ children }) => (
  <span className="bg-gradient-to-r from-[#E54C4C] to-[#FF6B6B] inline bg-clip-text text-transparent">
    {children}
  </span>
);

const OperatorFooter = () => {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <footer className="bg-[#0E1420] text-white pt-16 pb-8 border-t border-[#2A3143]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Top section with logo and call to action */}
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-12">
          <div className="mb-8 md:mb-0">
            <div className="flex items-center">
              <a href="/operator" className="flex items-center">
                <img src={logoImage} alt="Blitzo" className="h-10 w-auto" />
                <div className="flex items-center ml-3">
                  <span className="text-white text-3xl font-semibold">
                    Blitzo
                  </span>
                  <span className="ml-2 px-2 py-0.5 text-xs font-medium text-white bg-gradient-to-r from-[#E54C4C] to-[#FF6B6B] rounded-md shadow-sm">
                    OPERATOR
                  </span>
                </div>
              </a>
            </div>
            <p className="text-gray-300 max-w-md mb-6 mt-5">
              AI powered workers for logistics. Handling calls, emails, and
              negotiations 24/7 so you can focus on growing your business.
            </p>
          </div>
          <button
            onClick={() => scrollToSection("cta-section")}
            className="bg-[#E54C4C] text-white px-8 py-3 rounded-lg hover:bg-[#E54C4C]/90 transition-all shadow-lg shadow-[#E54C4C]/10 flex items-center font-medium"
          >
            Speak to Us <ArrowRight className="ml-2" size={18} />
          </button>
        </div>

        {/* Divider */}
        <div className="border-t border-[#2A3143] mb-12"></div>

        {/* Navigation Links - Synced with navbar structure */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 mb-12">
          <div>
            <h4 className="text-lg font-semibold mb-6 text-white">Features</h4>
            <ul className="space-y-3">
              <li>
                <button
                  onClick={() => scrollToSection("live-examples")}
                  className="text-gray-300 hover:text-[#E54C4C] transition-colors"
                >
                  Outbound Calls
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection("live-examples")}
                  className="text-gray-300 hover:text-[#E54C4C] transition-colors"
                >
                  Track & Trace Calls
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection("live-examples")}
                  className="text-gray-300 hover:text-[#E54C4C] transition-colors"
                >
                  Delivery Slot Booking
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection("live-examples")}
                  className="text-gray-300 hover:text-[#E54C4C] transition-colors"
                >
                  Carrier Verification
                </button>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="text-lg font-semibold mb-6 text-white">Platform</h4>
            <ul className="space-y-3">
              <li>
                <button
                  onClick={() => scrollToSection("how-we-work")}
                  className="text-gray-300 hover:text-[#E54C4C] transition-colors"
                >
                  How We Work
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection("integrations")}
                  className="text-gray-300 hover:text-[#E54C4C] transition-colors"
                >
                  Integrations
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection("live-examples")}
                  className="text-gray-300 hover:text-[#E54C4C] transition-colors"
                >
                  Live Examples
                </button>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="text-lg font-semibold mb-6 text-white">
              Contact Us
            </h4>
            <ul className="space-y-3">
              <li>
                <a
                  href="mailto:operator@blitzo.co.uk"
                  className="text-gray-300 hover:text-[#E54C4C] transition-colors flex items-center"
                >
                  <Mail className="h-5 w-5 mr-2" />
                  sales@blitzo.co.uk
                </a>
              </li>
              <li>
                <a
                  href="tel:+442071128120"
                  className="text-gray-300 hover:text-[#E54C4C] transition-colors flex items-center"
                >
                  <Phone className="h-5 w-5 mr-2" />
                  +44 7553848371
                </a>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection("cta-section")}
                  className="text-gray-300 hover:text-[#E54C4C] transition-colors flex items-center"
                >
                  <Calendar className="h-5 w-5 mr-2" />
                  Schedule a Demo
                </button>
              </li>
            </ul>

            {/* Social Links */}
            <div className="flex space-x-4 mt-6">
              <a
                href="https://www.linkedin.com/company/blitzouk/"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-[#192032] hover:bg-[#E54C4C]/10 p-2 rounded-full text-gray-300 hover:text-[#E54C4C] transition-all"
              >
                <Linkedin className="h-5 w-5" />
              </a>
            </div>
          </div>
        </div>

        {/* Bottom section with legal links */}
        <div className="border-t border-[#2A3143] pt-8 flex flex-col md:flex-row justify-between items-center">
          <p className="text-gray-400 text-sm mb-4 md:mb-0">
            &copy; {new Date().getFullYear()} Blitzo. All rights reserved.
          </p>
          <div className="flex space-x-8">
            <a
              href="/terms"
              className="text-gray-400 hover:text-[#E54C4C] text-sm transition-colors"
            >
              Terms of Service
            </a>
            <a
              href="https://www.privacypolicies.com/live/0c0b870e-c126-4cbe-b8ab-9a25b9108610"
              className="text-gray-400 hover:text-[#E54C4C] text-sm transition-colors"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default OperatorFooter;
