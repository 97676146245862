import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Mail, Lock, ArrowRight, TrendingUp, Clock, Users } from "lucide-react";
import { motion } from "framer-motion";
import { loginEmailPassword, loginWithGoogle } from "../../firebase/auth";
import logoImage from "../../../Images/logo.png";
import { hasOnboardingInfo } from "../../firebase/user";

const LoginPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await loginEmailPassword(email, password);
      navigate("/business/book");
    } catch (err: any) {
      setError(err.message || "Login failed");
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await loginWithGoogle();

      // Check if user has completed onboarding
      const hasOnboarding = await hasOnboardingInfo();

      if (hasOnboarding) {
        navigate("/business/book");
      } else {
        navigate("/business/onboarding");
      }
    } catch (err: any) {
      setError(err.message || "Google login failed");
    }
  };
  const benefits = [
    {
      icon: <Users className="w-6 h-6 text-blue-400" />,
      title: "62% Increase in Customer Retention",
      description:
        "Average increase in customer retention with instant delivery",
      gradient: "from-blue-500 to-blue-600",
    },
    {
      icon: <TrendingUp className="w-6 h-6 text-purple-400" />,
      title: "2.8x Higher Customer Spend",
      description: "Avg. Customer spend with instant delivery options",
      gradient: "from-purple-500 to-purple-600",
    },
    {
      icon: <Clock className="w-6 h-6 text-emerald-400" />,
      title: "Instant Pickup",
      description: "Rapid Collection, Rapid Gratification for your customers",
      gradient: "from-emerald-500 to-emerald-600",
    },
  ];

  return (
    <div className="min-h-screen w-full flex bg-gray-900">
      {/* Left Side - Marketing Content */}
      <div className="hidden lg:flex lg:w-1/2 p-12 flex-col justify-between relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900" />

        {/* Content */}
        <div className="relative z-10 space-y-8">
          {/* Logo - Fixed baseline alignment */}
          <a href="/business/book" className="flex items-center">
            <img
              src={logoImage}
              alt="Blitzo"
              className="h-14 w-auto self-center mr-1 mb-1 pb-3"
            />
            <span className="text-4xl text-white font-semibold ml-2 self-baseline">
              Blitzo
            </span>
            <span className="text-lg font-normal ml-2 text-gray-300 self-baseline">
              For Business
            </span>
          </a>

          {/* Main Copy */}
          <div className="space-y-4 max-w-lg">
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="text-4xl font-bold bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent"
            >
              Instant On-Demand Delivery for Your Business
            </motion.h1>

            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="text-lg text-gray-400"
            >
              Deliver your products to customers in minutes with one click
            </motion.p>
          </div>
        </div>

        {/* New Benefits Design */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
          className="relative z-10 space-y-4"
        >
          {benefits.map((benefit, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.4, delay: 0.6 + index * 0.1 }}
              className="flex items-center space-x-4 p-6 rounded-2xl 
                bg-gray-800/50 border border-gray-700/50 backdrop-blur-lg
                hover:bg-gray-800/70 transition-all duration-300"
            >
              {/* Icon Circle */}
              <div
                className={`p-3 rounded-xl bg-gradient-to-br ${
                  benefit.gradient
                }/10 
                border border-${benefit.gradient.split(" ")[1]}/20`}
              >
                {benefit.icon}
              </div>

              {/* Text Content */}
              <div>
                <h3 className="text-xl font-bold text-white mb-1">
                  {benefit.title}
                </h3>
                <p className="text-sm text-gray-400">{benefit.description}</p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>

      {/* Right Side - Login Form */}
      <div className="w-full lg:w-1/2 flex items-center justify-center p-8">
        <div className="w-full max-w-md space-y-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-white">Welcome back</h2>
            <p className="mt-2 text-gray-400">
              Log in to your account to continue
            </p>
          </div>

          <form onSubmit={handleSubmit} className="mt-8 space-y-6">
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-400 mb-2">
                  Email address
                </label>
                <div className="relative">
                  <Mail
                    className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400"
                    size={20}
                  />
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full pl-12 pr-4 py-3 bg-gray-800/50 border border-gray-700 rounded-xl
                      text-white placeholder-gray-400 focus:outline-none focus:border-red-500
                      transition-colors"
                    placeholder="Enter your email"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-400 mb-2">
                  Password
                </label>
                <div className="relative">
                  <Lock
                    className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400"
                    size={20}
                  />
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full pl-12 pr-4 py-3 bg-gray-800/50 border border-gray-700 rounded-xl
                      text-white placeholder-gray-400 focus:outline-none focus:border-red-500
                      transition-colors"
                    placeholder="Enter your password"
                  />
                </div>
              </div>
            </div>

            {error && <p className="text-red-500 text-sm">{error}</p>}

            <div>
              <button
                type="submit"
                className="w-full flex items-center justify-center py-3 px-4 border border-transparent
                  rounded-xl bg-red-500 hover:bg-red-600 text-white font-medium transition-colors
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                <span>Sign in to dashboard</span>
                <ArrowRight className="ml-2" size={20} />
              </button>
            </div>
          </form>

          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-700"></div>
            </div>
            <div className="relative flex justify-center text-sm">
              <span className="px-2 bg-gray-900 text-gray-400">
                Or continue with
              </span>
            </div>
          </div>

          <button
            onClick={handleGoogleLogin}
            className="w-full flex items-center justify-center py-3 px-4 rounded-xl
              bg-white hover:bg-gray-50 transition-colors"
          >
            <img
              src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
              alt="Google"
              className="w-5 h-5 mr-2"
            />
            <span className="text-gray-900 font-medium">
              Sign in with Google
            </span>
          </button>

          <p className="text-center text-gray-400">
            Don't have an account?{" "}
            <a
              href="/business/signup"
              className="text-red-500 hover:text-red-400 font-medium"
            >
              Create an account
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
