import React from "react";
import {
  Calendar,
  MapPin,
  Clock,
  Package,
  Phone,
  X,
  Route,
  Calendar as CalendarIcon,
  Info,
} from "lucide-react";
import { BlitzoOrder } from "../../types/BlitzoOrder";

interface ScheduledOrderModalProps {
  order: BlitzoOrder;
  onClose: () => void;
}

const ScheduledOrderModal: React.FC<ScheduledOrderModalProps> = ({
  order,
  onClose,
}) => {
  const formatScheduleTime = (date: any) => {
    return date.toDate().toLocaleString("en-GB", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };

  return (
    <div className="fixed inset-0 z-50" onClick={(e) => e.stopPropagation()}>
      {/* Backdrop */}
      <div
        className="absolute inset-0 bg-black/60 backdrop-blur-sm"
        onClick={onClose}
      />

      {/* Modal */}
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full max-w-2xl">
        <div className="bg-gradient-to-b from-gray-900 to-gray-800 rounded-2xl border border-gray-700 shadow-xl">
          {/* Header */}
          <div className="px-6 py-4 border-b border-gray-700 flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div className="p-2 rounded-xl bg-blue-500/10 border border-blue-500/20">
                <CalendarIcon className="text-blue-400" size={20} />
              </div>
              <div>
                <h2 className="text-lg font-semibold text-white">
                  Scheduled Delivery
                </h2>
                <p className="text-sm text-gray-400">#{order.id.slice(0, 8)}</p>
              </div>
            </div>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-800 rounded-lg transition-colors"
            >
              <X size={20} className="text-gray-400" />
            </button>
          </div>

          {/* Content */}
          <div className="p-6 space-y-6">
            {/* Scheduled Time */}
            <div className="p-4 rounded-xl bg-blue-500/5 border border-blue-500/10">
              <div className="flex items-start gap-3">
                <div className="p-2 rounded-lg bg-blue-500/10 mt-1">
                  <Clock size={18} className="text-blue-400" />
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-400 mb-1">
                    Scheduled For
                  </p>
                  <h3 className="text-lg font-medium text-white">
                    {formatScheduleTime(order.schedule.scheduledFor)}
                  </h3>
                </div>
              </div>
            </div>

            {/* Package Details */}
            <div className="grid grid-cols-2 gap-4">
              <div className="p-4 rounded-xl bg-gray-800/50 border border-gray-700">
                <div className="flex items-start gap-3">
                  <div className="p-2 rounded-lg bg-purple-500/10">
                    <Package size={18} className="text-purple-400" />
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-400">
                      Package Type
                    </p>
                    <p className="text-white mt-1">{order.packageType}</p>
                  </div>
                </div>
              </div>
              <div className="p-4 rounded-xl bg-gray-800/50 border border-gray-700">
                <div className="flex items-start gap-3">
                  <div className="p-2 rounded-lg bg-emerald-500/10">
                    <Route size={18} className="text-emerald-400" />
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-400">
                      Distance
                    </p>
                    <p className="text-white mt-1">~2.5 miles</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Locations */}
            <div className="space-y-4">
              {/* Pickup */}
              <div className="p-4 rounded-xl bg-gray-800/50 border border-gray-700">
                <div className="flex items-start gap-3">
                  <div className="p-2 rounded-lg bg-emerald-500/10 mt-1">
                    <MapPin size={18} className="text-emerald-400" />
                  </div>
                  <div className="flex-1">
                    <p className="text-sm font-medium text-gray-400 mb-1">
                      Pickup Location
                    </p>
                    <h3 className="text-white font-medium mb-1">
                      {order.pickupLocation.locationName}
                    </h3>
                    <p className="text-sm text-gray-500">
                      {order.pickupLocation.detailedLocation}
                    </p>
                    {order.pickupLocation.contact?.number && (
                      <div className="flex items-center gap-2 mt-3 text-sm text-gray-400">
                        <Phone size={14} />
                        <span>{order.pickupLocation.contact.number}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Dropoff */}
              <div className="p-4 rounded-xl bg-gray-800/50 border border-gray-700">
                <div className="flex items-start gap-3">
                  <div className="p-2 rounded-lg bg-blue-500/10 mt-1">
                    <MapPin size={18} className="text-blue-400" />
                  </div>
                  <div className="flex-1">
                    <p className="text-sm font-medium text-gray-400 mb-1">
                      Dropoff Location
                    </p>
                    <h3 className="text-white font-medium mb-1">
                      {order.dropoffLocation.locationName}
                    </h3>
                    <p className="text-sm text-gray-500">
                      {order.dropoffLocation.detailedLocation}
                    </p>
                    {order.dropoffLocation.contact?.number && (
                      <div className="flex items-center gap-2 mt-3 text-sm text-gray-400">
                        <Phone size={14} />
                        <span>{order.dropoffLocation.contact.number}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Footer */}
          <div className="px-6 py-4 border-t border-gray-700 flex items-center justify-between">
            <div className="flex items-center gap-2 text-gray-400">
              <Info size={16} />
              <span className="text-sm">
                We'll notify you when delivery starts
              </span>
            </div>
            <button
              onClick={onClose}
              className="px-4 py-2 bg-gray-800 hover:bg-gray-700 rounded-lg 
                transition-colors text-white text-sm font-medium"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduledOrderModal;
