// AddressModal.tsx
import React, { useState, useEffect } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import {
  Building,
  MapPin,
  X,
  Search,
  MapPinOff,
  Phone,
  User,
  Tag,
  ChevronRight,
  History,
  Star,
} from "lucide-react";
import { Location } from "../../types/Location";
import { mapOptions } from "../../styles/MapStyles";
import { mapStyle } from "../../pages/tracking/LiveTracker";
import { getSavedAddresses, storeAddress } from "../../firebase/user";
import {
  addRecentLocationToCache,
  getRecentLocationsFromCache,
  clearRecentLocationsCache,
  removeRecentLocationFromCache,
} from "../../utils/cache/location";

interface AddressModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (location: Location) => void;
  initialLocation: Location | null;
  disableContinue?: boolean;
}

interface AddressFields {
  line1: string;
  line2: string;
  city: string;
  postcode: string;
  label: string;
}

const AddressModal: React.FC<AddressModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  initialLocation,
  disableContinue = false,
}) => {
  const [location, setLocation] = useState<Location>({
    latitude: initialLocation?.latitude || 51.5072,
    longitude: initialLocation?.longitude || -0.1276,
    locationName: initialLocation?.locationName || "",
    detailedLocation: initialLocation?.detailedLocation || "",
    contact: initialLocation?.contact || { name: "", number: "" },
  });

  const [addressFields, setAddressFields] = useState<AddressFields>({
    line1: "",
    line2: "",
    city: "",
    postcode: "",
    label: "",
  });

  const [hasSelectedPlace, setHasSelectedPlace] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const [recentLocations, setRecentLocations] = useState<Location[]>([]);
  const [savedAddresses, setSavedAddresses] = useState<Location[]>([]);

  useEffect(() => {
    if (initialLocation) {
      setLocation(initialLocation);
      setHasSelectedPlace(!!initialLocation.locationName);
    }
  }, [initialLocation]);

  useEffect(() => {
    const fetchAddresses = async () => {
      const recent = getRecentLocationsFromCache();
      const saved = await getSavedAddresses();
      setRecentLocations(recent);
      setSavedAddresses(saved);
      console.log("Saved Addresses", saved);
      console.log("Recent Locations", recent);
    };

    fetchAddresses();
  }, []);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 300);
  };

  const handleSelectAddress = async (selected: any) => {
    const placeId = selected?.value?.place_id;
    const description = selected?.label || "";

    if (!placeId) return;

    try {
      const results = await geocodeByPlaceId(placeId);
      if (results[0]) {
        const { geometry, address_components } = results[0];
        const lat = geometry.location.lat();
        const lng = geometry.location.lng();

        setLocation((prev) => ({
          latitude: lat,
          longitude: lng,
          locationName: description,
          contact: { name: "", number: "" },
        }));

        parseAddressComponents(address_components, description);
        setHasSelectedPlace(true);
      }
    } catch (error) {
      console.error("Error geocoding place:", error);
    }
  };

  const parseAddressComponents = (
    components: google.maps.GeocoderAddressComponent[],
    description: string
  ) => {
    let localCity = "";
    let postalCode = "";
    let route = "";

    console.log(components);

    components.forEach((component) => {
      const types = component.types;
      if (types.includes("locality") || types.includes("postal_town")) {
        localCity = component.long_name;
      }
      if (types.includes("postal_code")) {
        postalCode = component.long_name;
      }

      // Include possible building/premise types along with route.
      if (types.includes("route") || types.includes("street_number")) {
        route = route ? route + " " + component.long_name : component.long_name;
      }
    });

    setAddressFields((prev) => ({
      ...prev,
      city: localCity,
      postcode: postalCode,
      line2: description.split(",")[0] + ", " + route,
    }));
  };

  const handleMarkerDragEnd = (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      setLocation((prev) => ({
        ...prev,
        latitude: e.latLng!.lat(),
        longitude: e.latLng!.lng(),
      }));
    }
  };

  const isPhoneNumberValid = (number: string) => {
    // Only allow numbers, spaces, +, (, ), and -
    return number.length > 0 && /^[0-9+() -]+$/.test(number);
  };

  const PresetOptions = () => {
    const handleSelectPreset = (location: Location) => {
      setLocation(location);
      setHasSelectedPlace(true);
      setAddressFields({
        line1: location.detailedLocation?.split(",")[0] || "",
        line2: location.detailedLocation?.split(",")[1] || "",
        city: location.detailedLocation?.split(",")[2] || "",
        postcode: location.detailedLocation?.split(",")[3] || "",
        label: location.label || "",
      });
    };

    return (
      <div className="mt-4 space-y-4">
        {/* Recent Locations */}
        {/* Update the Recent Locations section in PresetOptions */}
        {recentLocations.length > 0 && (
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <h3 className="text-sm font-medium text-gray-400">
                Recent Locations
              </h3>
              <button
                onClick={() => {
                  clearRecentLocationsCache();
                  setRecentLocations([]);
                }}
                className="text-xs text-gray-400 hover:text-red-400 transition-colors px-2 py-1 rounded-md hover:bg-gray-800"
              >
                Clear All
              </button>
            </div>
            <div className="space-y-2">
              {recentLocations.map((location, index) => (
                <div
                  key={index}
                  className="group relative flex items-center bg-gray-800/50 
            hover:bg-gray-800 border border-gray-700 rounded-lg transition-colors"
                >
                  <button
                    onClick={() => handleSelectPreset(location)}
                    className="flex-1 px-4 py-3 flex items-center gap-3 text-left"
                  >
                    <History size={16} className="text-gray-400" />
                    <div>
                      <p className="text-sm text-white">
                        {location.locationName}
                      </p>
                      <p className="text-xs text-gray-400">
                        {location.detailedLocation}
                      </p>
                    </div>
                  </button>
                  <button
                    onClick={() => {
                      removeRecentLocationFromCache(location);
                      setRecentLocations(
                        recentLocations.filter(
                          (loc) =>
                            loc.detailedLocation !== location.detailedLocation
                        )
                      );
                    }}
                    className="opacity-0 group-hover:opacity-100 p-2 mr-2 hover:bg-gray-700 
              rounded-md transition-all duration-200"
                    aria-label="Remove location"
                  >
                    <X size={16} className="text-gray-400 hover:text-red-400" />
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Saved Addresses */}
        {savedAddresses.length > 0 && (
          <div className="space-y-2">
            <h3 className="text-sm font-medium text-gray-400">
              Saved Addresses
            </h3>
            <div className="space-y-2">
              {savedAddresses.map((location, index) => (
                <button
                  key={index}
                  onClick={() => handleSelectPreset(location)}
                  className="w-full px-4 py-3 flex items-center gap-3 bg-gray-800/50 
                    hover:bg-gray-800 border border-gray-700 rounded-lg transition-colors"
                >
                  <Star size={16} className="text-yellow-400" />
                  <div className="text-left">
                    <p className="text-sm text-white">{location.label}</p>
                    <p className="text-xs text-gray-400">
                      {location.detailedLocation}
                    </p>
                  </div>
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const shouldStoreAddress = () => {
    return (
      !savedAddresses.some(
        (address) => address.detailedLocation === location.detailedLocation
      ) && addressFields.label
    );
  };

  const shouldCacheAddress = () => !addressFields.label;

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className={`absolute inset-0 bg-black transition-opacity duration-300
          ${isClosing ? "bg-opacity-0" : "bg-opacity-60"}`}
        onClick={handleClose}
      />

      <div
        className={`relative flex w-full max-w-6xl h-[85vh] bg-gradient-to-br 
          from-gray-900 to-gray-800 text-white rounded-2xl shadow-2xl overflow-hidden
          transition-all duration-300 transform ${
            isClosing ? "scale-95 opacity-0" : "scale-100 opacity-100"
          }`}
      >
        {/* Left Column */}
        <div className="w-1/2 p-8 overflow-auto">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-2xl font-bold bg-gradient-to-r from-blue-400 to-cyan-300 bg-clip-text text-transparent">
              Address Details
            </h2>
            <button
              onClick={handleClose}
              className="p-2 hover:bg-gray-700 rounded-full transition-colors"
            >
              <X
                size={20}
                className="text-gray-400 hover:text-white transition-colors"
              />
            </button>
          </div>

          {!hasSelectedPlace ? (
            <div className="space-y-4">
              <div className="relative">
                <Search
                  size={20}
                  className="absolute left-3 top-3 text-gray-400"
                />
                <GooglePlacesAutocomplete
                  selectProps={{
                    onChange: handleSelectAddress,
                    placeholder: "Search for an address...",
                    styles: {
                      control: (base) => ({
                        ...base,
                        backgroundColor: "#1f2937",
                        border: "1px solid #374151",
                        borderRadius: "0.75rem",
                        paddingLeft: "2.5rem",
                        boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1)",
                      }),
                      input: (provided) => ({
                        ...provided,
                        color: "#ffffff",
                        padding: "0.5rem",
                      }),
                      menu: (provided) => ({
                        ...provided,
                        backgroundColor: "#1f2937",
                        border: "1px solid #374151",
                        borderRadius: "0.75rem",
                        marginTop: "0.5rem",
                        overflow: "hidden",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isFocused
                          ? "#374151"
                          : "#1f2937",
                        color: "#ffffff",
                        padding: "1rem",
                        cursor: "pointer",
                      }),
                    },
                  }}
                  apiOptions={{ region: "GB" }}
                  autocompletionRequest={{
                    componentRestrictions: { country: ["uk"] },
                  }}
                />
              </div>
              <PresetOptions />
            </div>
          ) : (
            <div className="space-y-6">
              <button
                onClick={() => setHasSelectedPlace(false)}
                className="flex items-center gap-2 px-4 py-2 rounded-lg bg-gray-700 
                  hover:bg-gray-600 transition-all text-sm font-medium"
              >
                <MapPinOff size={16} />
                Search Different Address
              </button>

              <div className="space-y-4">
                {[
                  {
                    icon: Building,
                    label: "Address Line 1",
                    value: addressFields.line1,
                    field: "line1",
                    placeholder: "Flat/Apt number...",
                  },
                  {
                    icon: Building,
                    label: "Address Line 2",
                    value: addressFields.line2,
                    field: "line2",
                    placeholder: "Street address...",
                  },
                  {
                    icon: MapPin,
                    label: "City",
                    value: addressFields.city,
                    field: "city",
                    placeholder: "City name...",
                  },
                  {
                    icon: MapPin,
                    label: "Postcode",
                    value: addressFields.postcode,
                    field: "postcode",
                    placeholder: "Post code...",
                  },
                  {
                    icon: Tag,
                    label: "Label",
                    value: addressFields.label,
                    field: "label",
                    placeholder: "e.g. Home, Work...",
                  },
                ].map(({ icon: Icon, label, value, field, placeholder }) => (
                  <div key={field} className="space-y-2">
                    <label className="flex items-center gap-2 text-sm font-medium text-gray-300">
                      <Icon size={16} />
                      {label}
                    </label>
                    <input
                      value={value}
                      onChange={(e) =>
                        setAddressFields((prev) => ({
                          ...prev,
                          [field]: e.target.value,
                        }))
                      }
                      placeholder={placeholder}
                      className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-lg
                        focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent
                        placeholder-gray-400 transition-all"
                    />
                  </div>
                ))}

                {/* Contact Information */}
                <div className="space-y-4">
                  <div className="space-y-2">
                    <label className="flex items-center gap-2 text-sm font-medium text-gray-300">
                      <User size={16} />
                      Contact Name
                    </label>
                    <input
                      value={location.contact.name}
                      onChange={(e) =>
                        setLocation((prev) => ({
                          ...prev,
                          contact: { ...prev.contact, name: e.target.value },
                        }))
                      }
                      placeholder="Contact person..."
                      className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-lg
                        focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent
                        placeholder-gray-400 transition-all"
                    />
                  </div>
                  <div className="space-y-2">
                    <label className="flex items-center gap-2 text-sm font-medium text-gray-300">
                      <Phone size={16} />
                      Contact Number
                    </label>
                    <input
                      value={location.contact.number}
                      onChange={(e) =>
                        setLocation((prev) => ({
                          ...prev,
                          contact: { ...prev.contact, number: e.target.value },
                        }))
                      }
                      placeholder="Phone number..."
                      className="w-full px-4 py-3 bg-gray-700 border border-gray-600 rounded-lg
                        focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent
                        placeholder-gray-400 transition-all"
                    />
                  </div>
                </div>

                <div className="flex justify-end gap-3 pt-4">
                  <button
                    onClick={handleClose}
                    className="px-6 py-2 rounded-lg border border-gray-600 hover:bg-gray-700 
                      transition-all text-sm font-medium"
                  >
                    Cancel
                  </button>
                  <button
                    disabled={
                      !addressFields.line1 ||
                      !addressFields.city ||
                      !addressFields.postcode ||
                      !isPhoneNumberValid(location.contact.number)
                    }
                    onClick={async () => {
                      const finalLocation: Location = {
                        ...location,
                        detailedLocation: [
                          addressFields.line1,
                          addressFields.line2,
                          addressFields.city,
                          addressFields.postcode,
                        ]
                          .filter(Boolean)
                          .join(", "),
                        label: addressFields.label,
                      };
                      if (shouldStoreAddress()) {
                        await storeAddress(finalLocation);
                      }
                      if (shouldCacheAddress()) {
                        addRecentLocationToCache(finalLocation);
                      }

                      onConfirm(finalLocation);
                      handleClose();
                    }}
                    className={`px-6 py-2 rounded-lg transition-all text-sm font-medium flex items-center gap-2 ${
                      !addressFields.line1 ||
                      !addressFields.city ||
                      !addressFields.postcode ||
                      !isPhoneNumberValid(location.contact.number)
                        ? "bg-gray-500 cursor-not-allowed"
                        : "bg-gradient-to-r from-blue-500 to-cyan-500 hover:from-blue-600 hover:to-cyan-600"
                    }`}
                  >
                    Confirm Location
                    <ChevronRight size={16} />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Right Column: Map */}
        <div className="w-1/2 h-full relative">
          <GoogleMap
            mapContainerStyle={{ width: "100%", height: "100%" }}
            center={{ lat: location.latitude, lng: location.longitude }}
            zoom={13}
            options={{
              ...mapOptions,
              styles: mapStyle,
            }}
          >
            {location.locationName && (
              <Marker
                position={{ lat: location.latitude, lng: location.longitude }}
                draggable
                onDragEnd={handleMarkerDragEnd}
                animation={google.maps.Animation.DROP}
              />
            )}
          </GoogleMap>
        </div>
      </div>
    </div>
  );
};

export default AddressModal;
