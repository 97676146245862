import { db, functions } from "./firebase-config";
import {
  collection,
  doc,
  addDoc,
  onSnapshot,
  getDoc,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { Location } from "../web-app/types/Location";
import { BlitzoOrder } from "../web-app/types/BlitzoOrder";

const ACTIVE_ORDER_COLLECTION = "activeOrders";

const getActiveOrder = async (orderId: string) => {
  try {
    const docRef = doc(db, ACTIVE_ORDER_COLLECTION, orderId);
    let docSnap = await getDoc(docRef);
    console.log(docSnap.exists());
    //If it doesnt exist, its in scheduledOrders
    return {
      ...(docSnap.data() as BlitzoOrder),
      orderedAt: docSnap.data()?.toDate(),
    };
  } catch (e) {
    console.error("Error getting document: ", e);
  }
};

const getScheduledOrder = async (orderId: string) => {
  try {
    const docRef = doc(db, "scheduledOrders", orderId);
    let docSnap = await getDoc(docRef);
    console.log(docSnap.exists());
    //If it doesnt exist, its in scheduledOrders
    return {
      ...(docSnap.data() as BlitzoOrder),
      orderedAt: docSnap.data()?.toDate(),
      schedule: {
        ...docSnap.data()?.schedule,
        scheduledFor: docSnap.data()?.schedule?.scheduledFor.toDate(),
      },
    };
  } catch (e) {
    console.error("Error getting document: ", e);
  }
};

const subscribeToActiveOrder = (orderId: string, callback: any) => {
  const docRef = doc(db, ACTIVE_ORDER_COLLECTION, orderId);
  const unsubscribe = onSnapshot(docRef, (docSnap) => {
    callback(docSnap.data());
  });
  return unsubscribe;
};

const fetchDistanceInMilesAndDurationInMinutes = async (
  start: Location,
  end: Location
) => {
  try {
    const fetchDistanceFunction = httpsCallable(
      functions,
      "fetchDistanceInMilesAndDurationInMinutes"
    );
    const response = await fetchDistanceFunction({ start, end });
    return response.data;
  } catch (error) {
    console.error(error);
  }
  return { distance: 0, duration: 0 };
};

const getPolyline = async (start: Location, end: Location) => {
  try {
    const getPolyLineFunction = httpsCallable(functions, "getPolyLine");
    const response = await getPolyLineFunction({ start, end });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const getRider = async (riderId: string) => {
  try {
    const docRef = doc(db, "riders", riderId);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
  } catch (e) {
    console.error("Error getting document: ", e);
  }
};

export {
  getActiveOrder,
  subscribeToActiveOrder,
  fetchDistanceInMilesAndDurationInMinutes,
  getPolyline,
  getRider,
  getScheduledOrder,
};
