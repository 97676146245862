import React from "react";
import { getStoreLink } from "../utils/deviceDetection";

const CTA = () => {
  return (
    <div className="bg-white py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
          Deliver with Confidence. Every Time.
        </h2>
        <p className="text-gray-900/90 mb-8 max-w-2xl mx-auto">
          Trusted by London's top stylists and Fashion PR, we ensure your
          high-value items are delivered safely, on time, and stress-free.
          Exciting? You bet it is.
        </p>
        <a
          href="mailto:support@blitzo.co.uk"
          className="inline-block bg-primary text-white font-bold font-poppins px-8 py-3 rounded-lg hover:bg-gray-50 hover:text-primary transition-colors shadow-xl"
        >
          Talk to us
        </a>
      </div>
    </div>
  );
};

export default CTA;
