import React, { useState } from "react";
import { MapPin, CheckCircle, Phone, User } from "lucide-react";
import AddressModal from "./AddressModal";
import { Location } from "../../types/Location";

interface LocationPickerProps {
  label: "Pickup" | "Dropoff";
  location: Location | null;
  setLocation: (location: Location) => void;
  disableContinue?: boolean;
}

const LocationPicker: React.FC<LocationPickerProps> = ({
  label,
  location,
  setLocation,
  disableContinue,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="p-4 bg-gradient-to-br from-gray-900/50 to-gray-800/50 rounded-2xl border border-gray-800">
      {!location ? (
        <button
          onClick={() => setIsModalOpen(true)}
          className="w-full group relative px-5 py-4 flex items-center gap-3 rounded-xl 
            bg-gray-900/50 hover:bg-gray-800 transition-all duration-300 text-white text-left
            border border-gray-700/50 hover:border-gray-600"
        >
          <div
            className={`p-2 rounded-lg ${
              label === "Pickup" ? "bg-emerald-500/10" : "bg-red-500/10"
            }`}
          >
            <MapPin
              size={22}
              className={`${
                label === "Pickup" ? "text-emerald-400" : "text-red-400"
              } transition-all group-hover:scale-110`}
            />
          </div>
          <span className="text-gray-300 font-medium">
            Select {label} Location
          </span>
        </button>
      ) : (
        <div
          onClick={() => setIsModalOpen(true)}
          className="cursor-pointer group"
        >
          <div className="flex items-center gap-3 mb-2">
            <div
              className={`p-1.5 rounded-lg ${
                label === "Pickup" ? "bg-emerald-500/10" : "bg-red-500/10"
              }`}
            >
              <MapPin
                size={18}
                className={`${
                  label === "Pickup" ? "text-emerald-400" : "text-red-400"
                }`}
              />
            </div>
            <span className="text-sm font-medium text-gray-400">{label}</span>
          </div>

          <div className="p-4 rounded-xl bg-gray-900/50 border border-gray-800/50 group-hover:bg-gray-800/50 group-hover:border-gray-700/50 transition-all">
            <div className="space-y-2">
              <p className="text-lg font-medium text-white leading-snug">
                {location.detailedLocation}
              </p>
              <div className="flex items-center gap-3 text-gray-400">
                <div className="flex items-center gap-1.5">
                  <User size={14} />
                  <span className="text-sm font-medium">
                    {location.contact.name}
                  </span>
                </div>
                <div className="w-1 h-1 rounded-full bg-gray-700" />
                <div className="flex items-center gap-1.5">
                  <Phone size={14} />
                  <span className="text-sm font-medium">
                    {location.contact.number}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <AddressModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={(selectedLocation) => {
          setLocation(selectedLocation);
          setIsModalOpen(false);
        }}
        initialLocation={location}
        disableContinue={disableContinue}
      />
    </div>
  );
};

export default LocationPicker;
