import React, { useContext } from "react";
import { useEffect, useState } from "react";
import {
  getAllActiveOrders,
  getScheduledOrders,
  refundAndCancelOrder,
} from "../../firebase/order";
import { BlitzoOrder } from "../../types/BlitzoOrder";
import ActiveOrderContext from "../ActiveOrderContext";
import { AuthContext, useAuth } from "../AuthContext";

interface Props {
  children: React.ReactNode;
}

const ActiveOrderProvider = ({ children }: Props) => {
  const [activeOrders, setActiveOrders] = useState<BlitzoOrder[] | null>(null);
  const [scheduledOrders, setScheduledOrders] = useState<BlitzoOrder[] | null>(
    null
  );

  const fetchScheduledOrders = async () => {
    console.log("Calling provider");
    const scheduledOrder = await getScheduledOrders();
    if (scheduledOrder) {
      setScheduledOrders(scheduledOrder);
    }
  };

  const fetchActiveOrders = async () => {
    console.log("Calling provider");
    const activeOrders = await getAllActiveOrders();
    console.log("Active orders", activeOrders);
    if (activeOrders.length > 0) {
      setActiveOrders(activeOrders);
    }
  };

  useEffect(() => {
    fetchActiveOrders();
    fetchScheduledOrders();
  }, [useAuth().user]);

  const cancelOrder = async (
    orderId: string,
    collectionName: "activeOrders" | "scheduledOrders"
  ) => {
    console.log("Cancelling order", collectionName);
    await refundAndCancelOrder(orderId, collectionName);
    if (collectionName === "activeOrders") {
      const updatedOrders = activeOrders?.filter(
        (order) => order.id !== orderId
      );
      if (updatedOrders) {
        setActiveOrders(updatedOrders);
      }
    } else {
      const updatedOrders = scheduledOrders?.filter(
        (order) => order.id !== orderId
      );
      if (updatedOrders) {
        setScheduledOrders(updatedOrders);
      }
    }
  };

  return (
    <ActiveOrderContext.Provider
      value={{
        activeOrders,
        setActiveOrders,
        fetchAndSetActiveOrders: fetchActiveOrders,
        fetchAndSetScheduledOrders: fetchScheduledOrders,
        cancelOrder,
        scheduledOrders,
        setScheduledOrders,
      }}
    >
      {children}
    </ActiveOrderContext.Provider>
  );
};

export default ActiveOrderProvider;
