import React from "react";
import { PackageSearch, Smartphone, Globe, ChevronRight } from "lucide-react";
import BusinessNavbar from "../../components/BusinessNavbar";

const CreateMultiDrop: React.FC = () => {
  return (
    <>
      <BusinessNavbar />
      <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-900 to-gray-800 text-white pt-16 pb-10">
        <div className="max-w-4xl mx-auto px-4">
          {/* Main Content */}
          <div className="text-center py-16 space-y-6">
            {/* Animated Icon */}
            <div className="relative mx-auto w-20 h-20">
              <div className="absolute inset-0 bg-blue-500/20 rounded-xl animate-ping" />
              <div className="relative flex items-center justify-center w-full h-full bg-blue-500/10 border border-blue-500/20 rounded-xl">
                <PackageSearch size={32} className="text-blue-400" />
              </div>
            </div>

            <h1 className="text-4xl font-bold bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
              Multi-Drop Delivery
            </h1>
            <p className="text-xl text-gray-400 max-w-2xl mx-auto">
              Coming soon to the web app! In the meantime, you have some great
              options:
            </p>

            {/* Alternative Options Cards */}
            <div className="grid md:grid-cols-2 gap-6 max-w-3xl mx-auto mt-12">
              {/* Web App Option */}
              <a
                href="/business/book"
                target="_blank"
                rel="noopener noreferrer"
                className="group bg-gray-800/50 border border-gray-700 rounded-2xl p-8 hover:bg-gray-800/70 
                  transition-all hover:border-blue-500/20"
              >
                <div className="flex items-center gap-4 mb-4">
                  <div className="p-4 rounded-xl bg-purple-500/10 border border-purple-500/20">
                    <Globe className="text-purple-400" size={24} />
                  </div>
                  <div className="flex-1">
                    <h2 className="text-xl font-semibold group-hover:text-purple-400 transition-colors">
                      Use Web App
                    </h2>
                    <p className="text-gray-400">
                      Book multiple deliveries sequentially
                    </p>
                  </div>
                  <ChevronRight className="text-gray-400 group-hover:text-purple-400 transition-colors" />
                </div>
              </a>

              {/* Mobile App Option */}
              <a
                href="https://apps.apple.com/gb/app/blitzo/id6502848319"
                target="_blank"
                rel="noopener noreferrer"
                className="group bg-gray-800/50 border border-gray-700 rounded-2xl p-8 hover:bg-gray-800/70 
                  transition-all hover:border-blue-500/20"
              >
                <div className="flex items-center gap-4 mb-4">
                  <div className="p-4 rounded-xl bg-blue-500/10 border border-blue-500/20">
                    <Smartphone className="text-blue-400" size={24} />
                  </div>
                  <div className="flex-1">
                    <h2 className="text-xl font-semibold group-hover:text-blue-400 transition-colors">
                      Try Mobile App
                    </h2>
                    <p className="text-gray-400">
                      Full multi-drop functionality available
                    </p>
                  </div>
                  <ChevronRight className="text-gray-400 group-hover:text-blue-400 transition-colors" />
                </div>
              </a>
            </div>

            {/* Additional Info */}
            <div className="mt-12 text-center text-gray-400">
              <p>
                We're working hard to bring multi-drop functionality to the web
                app. Check back soon!
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateMultiDrop;
