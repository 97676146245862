import React, {
  useContext,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import "react-datepicker/dist/react-datepicker.css";
import { ParcelSize } from "../../types/ParcelSize";
import BusinessNavbar from "../../components/BusinessNavbar";
import BookingContext from "../../contexts/BookingContext";
import { Location } from "../../types/Location";
import AddressModal from "../../components/location/AddressModal"; // <— Our AddressModal
import { GoogleMap, Marker, Polyline } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";
import LocationPicker from "../../components/location/LocationPicker";
import PackageContents from "../../components/package/PackageContents";
import Insured from "../../components/package/Insured";
import SpecialInstructions from "../../components/package/SpecialInstructions";
import SchedulePicker from "../../components/package/SchedulePicker";
import ParcelSizePicker from "../../components/package/ParcelSizePicker";
import { mapOptions, markerOptions } from "../../styles/MapStyles";
import {
  createBlitzoOrder,
  getPriceSplitForSingleDrop,
} from "../../firebase/order";
import { Clock, MapPin, Package } from "lucide-react";
import { mapStyle } from "../tracking/LiveTracker";
import PaymentSection from "../../components/pricing/PaymentSection";

const MAPS_KEY = "AIzaSyDc8ZE2eaxXND5WTbOeZ5BZc008_ykqv2M";

// London Central
const DEFAULT_CENTER = {
  lat: 51.509865,
  lng: -0.118092,
};

const mapContainerStyle: React.CSSProperties = {
  width: "100%",
  height: "100%",
};

const CreateSingleDrop: React.FC = () => {
  // Access context
  const {
    packageType,
    setPackageType,
    specialInstructions,
    setSpecialInstructions,
    selectedSizes,
    setSelectedSizes,
    schedule,
    setSchedule,
    pickupLocation,
    setPickupLocation,
    dropoffLocation,
    setDropoffLocation,
  } = useContext(BookingContext);

  // Local state to toggle modals
  const [isPickupModalOpen, setIsPickupModalOpen] = useState(false);
  const [isDropoffModalOpen, setIsDropoffModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoadingPrice, setIsLoadingPrice] = useState(false);

  const mapRef = useRef<any | undefined>(undefined);

  //Pricing
  const [priceDetails, setPriceDetails] = useState<PriceDetails | null>(null);
  const [isPaymentComplete, setIsPaymentComplete] = useState(false);

  const getCurvedPath = useCallback((start: Location, end: Location) => {
    if (!start || !end) return [];

    // Convert to LatLng objects
    const startPoint = { lat: start.latitude, lng: start.longitude };
    const endPoint = { lat: end.latitude, lng: end.longitude };

    // Calculate the distance between points to adjust curve height dynamically
    const distance = Math.sqrt(
      Math.pow(endPoint.lat - startPoint.lat, 2) +
        Math.pow(endPoint.lng - startPoint.lng, 2)
    );

    // Make curve height proportional to distance
    const offsetDistance = distance * 0.2; // 20% of the total distance

    // Calculate midpoint
    const midPoint = {
      lat: (startPoint.lat + endPoint.lat) / 2,
      lng: (startPoint.lng + endPoint.lng) / 2,
    };

    // Calculate angle between points
    const angle = Math.atan2(
      endPoint.lat - startPoint.lat,
      endPoint.lng - startPoint.lng
    );

    // Create control point with increased offset
    const controlPoint = {
      lat: midPoint.lat + Math.cos(angle + Math.PI / 2) * offsetDistance,
      lng: midPoint.lng + Math.sin(angle + Math.PI / 2) * offsetDistance,
    };

    // Generate curve points using quadratic Bezier
    const curvePoints = [];
    const steps = 50; // Increased steps for smoother curve

    for (let i = 0; i <= steps; i++) {
      const t = i / steps;

      // Quadratic Bezier curve formula
      const lat =
        Math.pow(1 - t, 2) * startPoint.lat +
        2 * (1 - t) * t * controlPoint.lat +
        Math.pow(t, 2) * endPoint.lat;

      const lng =
        Math.pow(1 - t, 2) * startPoint.lng +
        2 * (1 - t) * t * controlPoint.lng +
        Math.pow(t, 2) * endPoint.lng;

      curvePoints.push({ lat, lng });
    }

    return curvePoints;
  }, []);

  const getPathOptions = useCallback(
    () => ({
      geodesic: true,
      strokeColor: "#E54C4C", // Updated to Blitzo primary color
      strokeOpacity: 0,
      strokeWeight: 2,
      icons: [
        {
          icon: {
            path: "M 0,-1 0,1",
            strokeOpacity: 1,
            strokeWeight: 2.5,
            scale: 3,
          },
          offset: "0",
          repeat: "10px",
        },
      ],
    }),
    []
  );

  // Function to update map bounds
  const updateMapBounds = () => {
    if (mapRef.current) {
      const bounds = new google.maps.LatLngBounds();
      if (pickupLocation) {
        bounds.extend(
          new google.maps.LatLng(
            pickupLocation.latitude,
            pickupLocation.longitude
          )
        );
      }
      if (dropoffLocation) {
        bounds.extend(
          new google.maps.LatLng(
            dropoffLocation.latitude,
            dropoffLocation.longitude
          )
        );
      }
      if (!bounds.isEmpty()) {
        mapRef.current.fitBounds(bounds);
      }
    }
  };

  // Update map bounds whenever pickup or dropoff location changes
  useEffect(() => {
    updateMapBounds();
  }, [pickupLocation, dropoffLocation]);

  // Confirming from address modal for pickup
  const handlePickupConfirm = (location: Location) => {
    setPickupLocation(location);
    setIsPickupModalOpen(false);
  };

  // Confirming from address modal for dropoff
  const handleDropoffConfirm = (location: Location) => {
    setDropoffLocation(location);
    setIsDropoffModalOpen(false);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPriceDetails = async () => {
      if (areAllMandatoryFieldsFilled()) {
        setIsLoadingPrice(true);
        try {
          const res = await getPriceSplitForSingleDrop(
            pickupLocation!,
            dropoffLocation!,
            true,
            getLargestSelectedSize()
          );
          setPriceDetails(res);
        } catch (error) {
          console.error("Error calculating price:", error);
          // You might want to show an error message to the user here
        } finally {
          setIsLoadingPrice(false);
        }
      }
    };

    fetchPriceDetails();
  }, [pickupLocation, dropoffLocation, packageType, selectedSizes, schedule]);

  const getLargestSelectedSize = () => {
    if (!selectedSizes) return ParcelSize.Medium;

    // If medium is selected, return medium
    if (selectedSizes[ParcelSize.Medium] > 0) return ParcelSize.Medium;
    return ParcelSize.Small;
  };

  const areZeroBoxes =
    !selectedSizes || !Object.values(selectedSizes).some((count) => count > 0);

  const areAllMandatoryFieldsFilled = () => {
    return !(
      !pickupLocation?.contact.number ||
      !dropoffLocation?.contact.number ||
      !pickupLocation?.detailedLocation ||
      !dropoffLocation?.detailedLocation ||
      !packageType ||
      !schedule
    );
  };

  const handleBookDelivery = async () => {
    if (!priceDetails) return;
    try {
      // Implement your order creation logic here
      const order = await createBlitzoOrder(
        priceDetails.orderId,
        pickupLocation!,
        dropoffLocation!,
        packageType!,
        specialInstructions || "",
        true,
        {
          total: priceDetails.total,
          baseFee: priceDetails.baseFee,
          distanceFee: priceDetails.distanceFee,
          serviceFee: priceDetails.serviceFee,
          sizeFee: priceDetails.sizeFee,
          riderFee: priceDetails.riderFee,
          discountInPercentage: 0,
        },
        priceDetails.paymentIntentId,
        schedule ?? { isNow: true },
        pickupLocation?.contact.number,
        dropoffLocation?.contact.number
      );
      navigate(`/business/track/${priceDetails.orderId}`, { replace: true });
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };

  const [isMapExpanded, setIsMapExpanded] = useState(false);

  return (
    <>
      <BusinessNavbar />

      <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-900 to-gray-800 text-white pt-16 pb-10">
        <div className="max-w-7xl mx-auto px-4">
          {/* Header */}
          <div className="py-6">
            <h1 className="text-2xl font-bold bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
              New Delivery
            </h1>
            <p className="text-gray-400 mt-1">Book a new delivery in minutes</p>
          </div>

          <div className="grid grid-cols-8 gap-6">
            {/* Left Column: Main Form */}
            <div className="col-span-5 space-y-6">
              {/* Locations Card */}
              <div className="bg-gray-800/50 border border-gray-700 rounded-2xl p-6">
                <h2 className="text-lg font-semibold text-white flex items-center gap-2 mb-4">
                  <MapPin size={20} className="text-blue-400" />
                  Locations
                </h2>
                <div className="space-y-4">
                  <LocationPicker
                    label="Pickup"
                    location={pickupLocation}
                    setLocation={setPickupLocation}
                    disableContinue={!pickupLocation?.contact.number}
                  />
                  <LocationPicker
                    label="Dropoff"
                    location={dropoffLocation}
                    setLocation={setDropoffLocation}
                    disableContinue={!dropoffLocation?.contact.number}
                  />
                </div>
                <button
                  onClick={() => setIsMapExpanded(!isMapExpanded)}
                  className="bg-red-500 text-white px-4 py-2 rounded transition-colors duration-300 my-4"
                >
                  {isMapExpanded ? "Hide Map" : "Show Map"}
                </button>
                {isMapExpanded && (
                  <div className="mt-4 transition-all duration-300 ease-in-out">
                    <div className="bg-gray-800/50 border border-gray-700 rounded-2xl overflow-hidden">
                      <div className="h-[320px]">
                        <GoogleMap
                          mapContainerStyle={{ width: "100%", height: "100%" }}
                          zoom={13}
                          center={DEFAULT_CENTER}
                          options={{
                            ...mapOptions,
                            styles: mapStyle,
                          }}
                          onLoad={(map) => {
                            mapRef.current = map;
                            updateMapBounds();
                          }}
                        >
                          {pickupLocation && (
                            <Marker
                              options={{
                                icon: {
                                  fillColor: "#10B981",
                                  path: google.maps.SymbolPath.CIRCLE,
                                  scale: 8,
                                  strokeColor: "#fff",
                                  strokeWeight: 2,
                                  fillOpacity: 1,
                                },
                              }}
                              position={{
                                lat: pickupLocation.latitude,
                                lng: pickupLocation.longitude,
                              }}
                            />
                          )}
                          {dropoffLocation && (
                            <Marker
                              position={{
                                lat: dropoffLocation.latitude,
                                lng: dropoffLocation.longitude,
                              }}
                            />
                          )}
                          {pickupLocation && dropoffLocation && (
                            <Polyline
                              path={getCurvedPath(
                                pickupLocation,
                                dropoffLocation
                              )}
                              options={getPathOptions()}
                            />
                          )}
                        </GoogleMap>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* Package Details Card */}
              <div className="bg-gray-800/50 border border-gray-700 rounded-2xl p-6">
                {/* <h2 className="text-lg font-semibold text-white flex items-center gap-2 mb-4">
                  <Package size={20} className="text-blue-400" />
                  Package Details
                </h2> */}
                <div className="space-y-6">
                  <PackageContents
                    selectedType={packageType}
                    onChange={setPackageType}
                  />
                  {/* <ParcelSizePicker
                    selectedSizes={selectedSizes ?? { Small: 0, Medium: 0 }}
                    onChange={setSelectedSizes}
                  /> */}
                  <Insured />
                </div>
              </div>
            </div>

            {/* Right Column: Map & Options */}
            <div className="col-span-3 space-y-6">
              {/* Map Card */}

              {/* Delivery Options Card */}
              <div className="bg-gray-800/50 border border-gray-700 rounded-2xl p-6">
                {/* <h2 className="text-lg font-semibold text-white flex items-center gap-2 mb-4">
                  <Clock size={20} className="text-blue-400" />
                  Delivery Options
                </h2> */}
                <div className="space-y-6">
                  <SchedulePicker schedule={schedule} onChange={setSchedule} />
                  <SpecialInstructions
                    value={specialInstructions ?? ""}
                    onChange={setSpecialInstructions}
                  />
                </div>
              </div>

              {isLoadingPrice ? (
                <div className="bg-gray-800/50 border border-gray-700 rounded-2xl p-6">
                  <div className="flex items-center justify-center space-x-2">
                    <div className="w-4 h-4 rounded-full bg-blue-500 animate-pulse" />
                    <span className="text-gray-400">Calculating price...</span>
                  </div>
                </div>
              ) : areAllMandatoryFieldsFilled() && priceDetails ? (
                <PaymentSection
                  priceDetails={priceDetails}
                  onPaymentSuccess={async () => {
                    setIsPaymentComplete(true);
                    await handleBookDelivery();
                  }}
                  isPaymentComplete={isPaymentComplete}
                  setIsPaymentComplete={setIsPaymentComplete}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateSingleDrop;
