import React from "react";
import {
  ArrowUpRight,
  Phone,
  Mail,
  Linkedin,
  Calendar,
  MessageSquare,
  RefreshCw,
  Zap,
  Clock,
  Users,
  AlertTriangle,
  ArrowRight,
  BarChart2,
} from "react-feather";

// Import the logo using require to avoid TypeScript errors
const logoImage = require("../Images/logo.png");

// Gradient text component for "Athena"
const GradientText = ({ children }) => (
  <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
    {children}
  </span>
);

const AthenaFooter = () => {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <footer className="bg-[#080A12] text-white pt-16 pb-8 border-t border-[#262D40]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Top section with logo and call to action */}
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-12">
          <div className="mb-8 md:mb-0">
            <div className="flex items-center">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className="flex items-center cursor-pointer"
              >
                <img src={logoImage} alt="Blitzo" className="h-10 w-auto" />
                <div className="flex items-center ml-3">
                  <span className="text-white text-3xl font-semibold">
                    Blitzo
                  </span>
                  <span className="ml-2 text-3xl bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600 font-semibold">
                    ATHENA
                  </span>
                </div>
              </a>
            </div>
            <p className="text-gray-300 max-w-md mb-6 mt-5">
              AI-powered logistics assistant that handles rider queries,
              optimizes routes in real-time, and prevents missed deliveries to
              transform your last-mile operations.
            </p>
          </div>
          <button
            onClick={() => scrollToSection("contact-section")}
            className="bg-gradient-to-r from-blue-600 to-purple-600 text-white px-8 py-3 rounded-lg hover:from-blue-700 hover:to-purple-700 transition-all shadow-lg shadow-blue-600/20 flex items-center font-medium"
          >
            Speak To Us <ArrowUpRight className="ml-2" size={18} />
          </button>
        </div>

        {/* Divider */}
        <div className="border-t border-[#262D40] mb-12"></div>

        {/* Navigation Links - Synced with navbar structure */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 mb-12">
          <div>
            <h4 className="text-lg font-semibold mb-6 text-white">Features</h4>
            <ul className="space-y-3">
              <li>
                <button
                  onClick={() => scrollToSection("free-up-your-time")}
                  className="text-gray-300 hover:text-blue-400 transition-colors"
                >
                  Voice Communication
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection("free-up-your-time")}
                  className="text-gray-300 hover:text-blue-400 transition-colors"
                >
                  Text Communication
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection("free-up-your-time")}
                  className="text-gray-300 hover:text-blue-400 transition-colors"
                >
                  Smart Routing
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection("free-up-your-time")}
                  className="text-gray-300 hover:text-blue-400 transition-colors"
                >
                  Dynamic Fleet Management
                </button>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="text-lg font-semibold mb-6 text-white">Use Cases</h4>
            <ul className="space-y-3">
              <li>
                <button
                  onClick={() => scrollToSection("use-cases-section")}
                  className="text-gray-300 hover:text-blue-400 transition-colors"
                >
                  Intelligent Delivery Scheduling
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection("use-cases-section")}
                  className="text-gray-300 hover:text-blue-400 transition-colors"
                >
                  Access & Delivery Handling
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection("use-cases-section")}
                  className="text-gray-300 hover:text-blue-400 transition-colors"
                >
                  Delivery Challenge Resolution
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection("use-cases-section")}
                  className="text-gray-300 hover:text-blue-400 transition-colors"
                >
                  Seamless System Integration
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection("use-cases-section")}
                  className="text-gray-300 hover:text-blue-400 transition-colors"
                >
                  Customer Query Management
                </button>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection("use-cases-section")}
                  className="text-gray-300 hover:text-blue-400 transition-colors"
                >
                  Rider Query Handling
                </button>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="text-lg font-semibold mb-6 text-white">
              Contact Us
            </h4>
            <ul className="space-y-3">
              <li>
                <a
                  href="mailto:sales@blitzo.co.uk"
                  className="text-gray-300 hover:text-blue-400 transition-colors flex items-center"
                >
                  <Mail className="h-5 w-5 mr-2" />
                  sales@blitzo.co.uk
                </a>
              </li>
              <li>
                <a
                  href="tel:+447810778980"
                  className="text-gray-300 hover:text-blue-400 transition-colors flex items-center"
                >
                  <Phone className="h-5 w-5 mr-2" />
                  +44 7810 778980
                </a>
              </li>
              <li>
                <button
                  onClick={() => scrollToSection("contact-section")}
                  className="text-gray-300 hover:text-blue-400 transition-colors flex items-center"
                >
                  <Calendar className="h-5 w-5 mr-2" />
                  Schedule a Demo
                </button>
              </li>
            </ul>

            {/* Social Links */}
            <div className="flex space-x-4 mt-6">
              <a
                href="https://www.linkedin.com/company/blitzouk/"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-[#0F121B] hover:bg-blue-600/10 p-2 rounded-full text-gray-300 hover:text-blue-400 transition-all"
              >
                <Linkedin className="h-5 w-5" />
              </a>
            </div>
          </div>
        </div>

        {/* Bottom section with legal links */}
        <div className="border-t border-[#262D40] pt-8 flex flex-col md:flex-row justify-between items-center">
          <p className="text-gray-400 text-sm mb-4 md:mb-0">
            &copy; {new Date().getFullYear()} Blitzo. All rights reserved.
          </p>
          <div className="flex space-x-8">
            <a
              href="/terms"
              className="text-gray-400 hover:text-blue-400 text-sm transition-colors"
            >
              Terms of Service
            </a>
            <a
              href="https://www.privacypolicies.com/live/0c0b870e-c126-4cbe-b8ab-9a25b9108610"
              className="text-gray-400 hover:text-blue-400 text-sm transition-colors"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default AthenaFooter;
