import React from "react";
import { Zap, ArrowRight } from "lucide-react";

const OperatorButton = ({ onNavigate }) => {
  const handleClick = () => {
    if (onNavigate) {
      onNavigate("/operator");
    }
  };

  return (
    <button
      onClick={handleClick}
      className="group flex items-center space-x-2 bg-gradient-to-r from-[#E54C4C]/10 to-[#4ECAFC]/10 px-4 py-2 rounded-lg border border-[#2A3143] hover:border-[#E54C4C]/50 transition-all duration-300"
    >
      <div className="flex items-center space-x-1.5">
        <div className="rounded-full p-1 bg-[#E54C4C]/20 text-[#E54C4C]">
          <Zap size={14} />
        </div>
        <span className="text-sm font-medium text-white">Freight AI</span>
      </div>
      <ArrowRight
        size={14}
        className="text-gray-400 group-hover:text-[#E54C4C] group-hover:translate-x-0.5 transition-all duration-300"
      />
    </button>
  );
};

export default OperatorButton;
