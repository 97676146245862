export const darkMapStyle = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#1f2937", // Dark background
      },
    ],
  },
  {
    elementType: "labels",
    stylers: [
      {
        visibility: "off", // Hide all labels by default
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#374151", // Subtle road color
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#4B5563", // Slightly lighter for major roads
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#6B7280", // Even lighter for highways
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9CA3AF",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#111827", // Very dark water
      },
    ],
  },
  {
    featureType: "poi",
    stylers: [
      {
        visibility: "off", // Hide points of interest
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off", // Hide transit
      },
    ],
  },
];

// Map options for both maps
export const mapOptions = {
  styles: darkMapStyle,
  disableDefaultUI: true,
  zoomControl: true,
  streetViewControl: false,
  fullscreenControl: false,
  mapTypeControl: false,
  clickableIcons: false,
  tilt: 0, // Flat view
  backgroundColor: "#1f2937", // Dark background
};

// Updated Marker configuration (can be used for both maps)
export const markerOptions = {
  icon: {
    path: google.maps.SymbolPath.CIRCLE,
    scale: 12,
    fillColor: "#FFFFFF",
    fillOpacity: 1,
    strokeColor: "#E54c4c",
    strokeWeight: 2,
  },
};
