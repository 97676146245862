import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { Clock, Calendar, Zap } from "lucide-react";
import "react-datepicker/dist/react-datepicker.css";

interface SchedulePickerProps {
  schedule: Schedule | null;
  onChange: (schedule: Schedule) => void;
}

const SchedulePicker: React.FC<SchedulePickerProps> = ({
  schedule,
  onChange,
}) => {
  const [showPicker, setShowPicker] = useState(false);

  const filterTime = (time: Date) => {
    const hours = time.getHours();
    return hours >= 8 && hours < 22;
  };

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-center gap-3">
        <Clock size={24} className="text-gray-400" />
        <h2 className="text-xl text-white font-medium">Delivery Time</h2>
      </div>

      {/* Options */}
      <div className="grid grid-cols-2 gap-4">
        {/* Immediate Option */}
        <button
          onClick={() => {
            onChange({ isNow: true });
            setShowPicker(false);
          }}
          className={`group flex items-center gap-4 p-6 rounded-xl border transition-all duration-200
            ${
              schedule?.isNow
                ? "bg-gray-800/80 border-gray-700"
                : "bg-gray-800/40 border-gray-800 hover:bg-gray-800/60"
            }`}
        >
          <div
            className={`p-2 rounded-lg ${
              schedule?.isNow ? "bg-emerald-500/10" : "bg-gray-700/50"
            }`}
          >
            <Zap
              size={20}
              className={schedule?.isNow ? "text-emerald-400" : "text-gray-400"}
            />
          </div>
          <div className="text-left">
            <div
              className={`font-medium mb-1 ${
                schedule?.isNow ? "text-emerald-400" : "text-white"
              }`}
            >
              Immediate
            </div>
            <div className="text-sm text-gray-400">Same-Hour Delivery</div>
          </div>
        </button>

        {/* Schedule Option */}
        <button
          onClick={() => {
            onChange({ isNow: false, scheduledFor: schedule?.scheduledFor });
            setShowPicker(true);
          }}
          className={`group flex items-center gap-4 p-6 rounded-xl border transition-all duration-200
            ${
              !schedule?.isNow
                ? "bg-gray-800/80 border-gray-700"
                : "bg-gray-800/40 border-gray-800 hover:bg-gray-800/60"
            }`}
        >
          <div
            className={`p-2 rounded-lg ${
              !schedule?.isNow ? "bg-blue-500/10" : "bg-gray-700/50"
            }`}
          >
            <Calendar
              size={20}
              className={!schedule?.isNow ? "text-blue-400" : "text-gray-400"}
            />
          </div>
          <div className="text-left">
            <div
              className={`font-medium mb-1 ${
                !schedule?.isNow ? "text-blue-400" : "text-white"
              }`}
            >
              Schedule
            </div>
            <div className="text-sm text-gray-400">Pick a delivery time</div>
          </div>
        </button>
      </div>

      {/* Date Picker Section */}
      {!schedule?.isNow && showPicker && (
        <div className="space-y-4 pt-2">
          <div className="relative">
            <Calendar
              size={18}
              className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-400 pointer-events-none"
            />
            <DatePicker
              selected={schedule?.scheduledFor}
              onChange={(date) => {
                if (!date) return;
                onChange({ isNow: false, scheduledFor: date });
              }}
              showTimeSelect
              timeIntervals={30}
              filterTime={filterTime}
              minDate={new Date()}
              maxDate={(() => {
                const date = new Date();
                date.setMonth(date.getMonth() + 1);
                return date;
              })()}
              dateFormat="MMM d, yyyy h:mm aa"
              placeholderText="Select date and time"
              className="w-full pl-12 pr-4 py-3.5 bg-gray-800/40 border border-gray-700 
                rounded-xl text-gray-300 placeholder-gray-500 focus:outline-none 
                focus:ring-2 focus:ring-blue-500/40 focus:border-blue-500/40"
              wrapperClassName="w-full"
              popperClassName="react-datepicker-popper"
              customInput={
                <input
                  style={{
                    width: "100%",
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    color: "inherit",
                  }}
                />
              }
            />
          </div>

          <div className="text-sm text-gray-500">
            Delivery available from 8:00 AM to 10:00 PM, up to one month in
            advance
          </div>
        </div>
      )}
    </div>
  );
};

export default SchedulePicker;
