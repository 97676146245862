import React, { useEffect, useState } from "react";
import DarkNavbar from "../components/DarkNavbar";
import Footer from "../components/footer";
import BottomCTA from "../components/BottomCta";
import SpeakToSalesModal from "../components/SpeakToSalesModal";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  Check,
  ShoppingBag,
  Clock,
  Sparkles,
  Zap,
  UserCheck,
  ChevronRight,
  ArrowRight,
  Shield,
  Package,
  Briefcase,
  Diamond,
  Star,
  Gift,
  MapPin,
  CircleCheck,
  Settings,
  Store,
  Truck,
  HeadphonesIcon,
  TrendingUp,
  BarChart4,
} from "lucide-react";
import FleetSolutions from "../components/FleetSolutions";

// Gradient text component
const GradientText = styled.span`
  background: linear-gradient(90deg, #e54c4c 0%, #ff6b6b 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
`;

// Blue gradient text component
const BlueGradient = styled.span`
  background: linear-gradient(90deg, #4ecafc 0%, #6a8cff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
`;

// Animated Gradient Border
const AnimatedBorder = styled.div`
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #0f121b;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-image: conic-gradient(
      transparent,
      rgba(229, 76, 76, 0.3),
      transparent 30%
    );
    animation: rotate 7s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 2px;
    top: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background: #0f121b;
    border-radius: 14px;
  }

  @keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }
`;

// Blue Animated Gradient Border
const BlueAnimatedBorder = styled(AnimatedBorder)`
  &::before {
    background-image: conic-gradient(
      transparent,
      rgba(78, 202, 252, 0.3),
      transparent 30%
    );
  }
`;

// Network Animation
const NetworkAnimation = () => {
  return (
    <div className="absolute inset-0 z-0 overflow-hidden opacity-40">
      <div className="absolute inset-0 opacity-10">
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `
              linear-gradient(to right, rgba(255,255,255,0.05) 1px, transparent 1px),
              linear-gradient(to bottom, rgba(255,255,255,0.05) 1px, transparent 1px)
            `,
            backgroundSize: "20px 20px",
          }}
        ></div>
      </div>

      {/* Animated dots */}
      <div className="absolute inset-0">
        {[...Array(25)].map((_, i) => (
          <div
            key={`red-${i}`}
            className="absolute w-1 h-1 rounded-full bg-[#E54C4C]/40"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animation: `float ${
                3 + Math.random() * 4
              }s infinite ease-in-out ${Math.random() * 2}s`,
            }}
          ></div>
        ))}
        {[...Array(20)].map((_, i) => (
          <div
            key={`blue-${i}`}
            className="absolute w-1 h-1 rounded-full bg-[#4ECAFC]/40"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animation: `float ${
                2 + Math.random() * 3
              }s infinite ease-in-out ${Math.random() * 2}s`,
            }}
          ></div>
        ))}
      </div>

      {/* Radial gradients */}
      <div
        className="absolute inset-0 opacity-40"
        style={{
          background:
            "radial-gradient(50% 50% at 50% 50%, rgba(229,76,76,0.08) 0%, rgba(10,13,20,0) 100%)",
        }}
      ></div>
      <div
        className="absolute inset-0 opacity-20"
        style={{
          background:
            "radial-gradient(70% 70% at 30% 30%, rgba(78,202,252,0.1) 0%, rgba(10,13,20,0) 100%)",
        }}
      ></div>

      {/* Glowing orbs */}
      <div
        className="absolute w-96 h-96 rounded-full"
        style={{
          background:
            "radial-gradient(circle, rgba(229,76,76,0.1) 0%, rgba(10,13,20,0) 70%)",
          left: "30%",
          top: "40%",
          filter: "blur(50px)",
          animation: "pulseGlow 8s infinite ease-in-out",
        }}
      ></div>
      <div
        className="absolute w-96 h-96 rounded-full"
        style={{
          background:
            "radial-gradient(circle, rgba(78,202,252,0.08) 0%, rgba(10,13,20,0) 70%)",
          right: "25%",
          top: "30%",
          filter: "blur(50px)",
          animation: "pulseGlow 10s infinite ease-in-out 2s",
        }}
      ></div>

      <style jsx>{`
        @keyframes float {
          0%,
          100% {
            transform: translateY(0) translateX(0);
          }
          50% {
            transform: translateY(-15px) translateX(8px);
          }
        }
        @keyframes pulseGlow {
          0%,
          100% {
            opacity: 0.3;
            transform: scale(1);
          }
          50% {
            opacity: 0.8;
            transform: scale(1.3);
          }
        }
      `}</style>
    </div>
  );
};

// Feature Card Component
const FeatureCard = ({ icon: Icon, title, description, color = "#E54C4C" }) => (
  <div className="bg-[#0F121B]/80 border border-[#262D40] hover:border-[#414D75] p-6 rounded-xl transition-all duration-300 h-full group">
    <div
      className={`w-14 h-14 bg-[${color}]/10 rounded-xl flex items-center justify-center mb-4 transform group-hover:scale-110 transition-transform duration-500`}
    >
      <Icon className={`text-[${color}]`} size={28} />
    </div>
    <h3 className="text-lg font-semibold text-white mb-2 group-hover:text-[#E54C4C] transition-colors">
      {title}
    </h3>
    <p className="text-gray-400 text-sm">{description}</p>
  </div>
);

// Benefit Item Component
const BenefitItem = ({ title, description, icon: Icon, delay = 0 }) => (
  <div
    className="flex items-start gap-4"
    data-aos="fade-up"
    data-aos-delay={delay}
  >
    <div className="w-10 h-10 rounded-full bg-[#E54C4C]/10 flex items-center justify-center flex-shrink-0 mt-1">
      <Icon className="text-[#E54C4C]" size={20} />
    </div>
    <div>
      <h3 className="text-lg font-semibold text-white mb-1">{title}</h3>
      <p className="text-gray-300">{description}</p>
    </div>
  </div>
);

// Testimonial Component
const Testimonial = ({ quote, company, role, stars = 5 }) => (
  <div className="bg-[#0F121B]/60 border border-[#262D40] p-6 rounded-xl">
    <div className="flex mb-4">
      {[...Array(stars)].map((_, i) => (
        <Star key={i} className="text-[#E54C4C]" size={18} />
      ))}
    </div>
    <p className="text-gray-300 italic mb-4">"{quote}"</p>
    <div>
      <div className="text-white font-medium">{company}</div>
      <div className="text-gray-400 text-sm">{role}</div>
    </div>
  </div>
);

// Stats Card Component
const StatsCard = ({ value, label, color = "#E54C4C" }) => (
  <div className="bg-[#0F121B]/80 rounded-xl p-6 border border-[#262D40] hover:border-[#414D75] transition-all duration-300 text-center group">
    <div
      className={`text-3xl lg:text-4xl font-bold mb-2 text-[${color}] group-hover:scale-110 transition-transform duration-300`}
    >
      {value}
    </div>
    <div className="text-gray-300 text-sm">{label}</div>
  </div>
);

const HighValueRetail = () => {
  const [showSalesModal, setShowSalesModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({
      duration: 1000,
      once: true,
      mirror: false,
      anchorPlacement: "top-bottom",
    });
  }, []);

  return (
    <div className="min-h-screen bg-[#080A12] text-white">
      <SpeakToSalesModal
        isOpen={showSalesModal}
        onClose={() => setShowSalesModal(false)}
      />
      <DarkNavbar />

      {/* Hero Section */}
      <div className="relative pt-32 pb-24 overflow-hidden">
        <NetworkAnimation />

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="text-center" data-aos="fade-up">
            <div className="mb-4 inline-block px-4 py-1.5 rounded-full bg-[#E54C4C]/10 border border-[#E54C4C]/20 text-[#E54C4C] text-sm">
              Premium Retail Solutions
            </div>
            <h1 className="text-4xl md:text-6xl font-bold text-white mb-6">
              High-Value <GradientText>Same-Hour</GradientText> Delivery
            </h1>
            <p className="mt-6 max-w-2xl mx-auto text-xl text-gray-300">
              Elevate your luxury retail experience with white-glove delivery
              services performed by our premium fleet of professionally trained
              personnel.
            </p>

            <div className="mt-12 flex flex-wrap justify-center gap-4">
              <button
                onClick={() => setShowSalesModal(true)}
                className="px-8 py-3.5 bg-gradient-to-r from-[#E54C4C] to-[#FF6B6B] text-white rounded-lg font-medium flex items-center shadow-lg shadow-[#E54C4C]/20 hover:shadow-xl hover:shadow-[#E54C4C]/30 transition-all duration-300"
              >
                Speak to Sales
                <ChevronRight
                  className="ml-2 group-hover:translate-x-1 transition-transform"
                  size={18}
                />
              </button>
            </div>
          </div>
        </div>

        {/* Glowing orbs */}
        <div className="absolute top-0 left-0 w-64 h-64 rounded-full bg-[#4ECAFC]/10 blur-3xl opacity-30 animate-pulse-slow"></div>
        <div className="absolute bottom-0 right-0 w-64 h-64 rounded-full bg-[#E54C4C]/10 blur-3xl opacity-30 animate-pulse-slow-delay"></div>
      </div>

      {/* Moving Dark Stores Section */}
      <div className="py-24 bg-[#0A0D16] relative">
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top,_#E54C4C08,transparent_50%)]"></div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
            <div className="order-2 lg:order-1" data-aos="fade-right">
              <div className="mb-4 inline-block text-[#4ECAFC] text-sm font-medium tracking-wider uppercase">
                Introducing the Future of Retail
              </div>
              <h2 className="text-3xl md:text-4xl font-bold text-white mb-6">
                You've heard of dark stores.
                <br />
                Meet our <BlueGradient>moving dark stores</BlueGradient>.
              </h2>

              <p className="text-gray-300 mb-6">
                While traditional retail struggles with static fulfillment
                centers, we've revolutionized the concept. Our secret? A dynamic
                network of mobile inventory units powered by AI logistics that
                puts luxury products in motion toward your customers.
              </p>

              <p className="text-gray-300 mb-8">
                This proprietary operational approach enables us to fulfill
                premium orders in hours rather than days, without compromising
                on the white-glove experience your high-value customers expect.
              </p>

              <div className="space-y-4 mb-8">
                <div className="flex items-center text-gray-300">
                  <div className="w-6 h-6 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mr-3">
                    <Check className="text-[#E54C4C]" size={14} />
                  </div>
                  <span>Premium-trained, uniformed delivery specialists</span>
                </div>
                <div className="flex items-center text-gray-300">
                  <div className="w-6 h-6 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mr-3">
                    <Check className="text-[#E54C4C]" size={14} />
                  </div>
                  <span>Same-hour delivery for high-value items</span>
                </div>
                <div className="flex items-center text-gray-300">
                  <div className="w-6 h-6 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mr-3">
                    <Check className="text-[#E54C4C]" size={14} />
                  </div>
                  <span>Zero operational overhead for your business</span>
                </div>
                <div className="flex items-center text-gray-300">
                  <div className="w-6 h-6 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mr-3">
                    <Check className="text-[#E54C4C]" size={14} />
                  </div>
                  <span>Automated distribution optimization</span>
                </div>
              </div>
            </div>

            <div className="order-1 lg:order-2" data-aos="fade-left">
              <AnimatedBorder>
                <div className="bg-[#0F121B]/80 rounded-xl p-8 h-full">
                  <div className="relative" style={{ paddingBottom: "56.25%" }}>
                    <div className="absolute inset-0 bg-gradient-to-br from-[#E54C4C]/10 to-[#4ECAFC]/10 rounded-lg flex items-center justify-center">
                      <div className="text-center">
                        <div className="w-20 h-20 bg-[#0F121B]/80 rounded-full flex items-center justify-center mb-4 mx-auto border border-[#E54C4C]">
                          <Store className="text-[#E54C4C]" size={32} />
                        </div>
                        <h3 className="text-xl font-bold text-white mb-1">
                          Mobile Inventory Network
                        </h3>
                        <p className="text-gray-400 text-sm px-6">
                          Our AI-powered logistics system keeps premium products
                          in motion, creating a dynamic inventory network that's
                          always closing the gap to your customers
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-4 mt-8">
                    <div className="text-center p-4 bg-[#080A12] rounded-lg">
                      <div className="text-2xl font-bold text-[#E54C4C] mb-1">
                        47 Min
                      </div>
                      <div className="text-xs text-gray-400">
                        Average Delivery
                      </div>
                    </div>
                    <div className="text-center p-4 bg-[#080A12] rounded-lg">
                      <div className="text-2xl font-bold text-[#4ECAFC] mb-1">
                        99.8%
                      </div>
                      <div className="text-xs text-gray-400">Success Rate</div>
                    </div>
                    <div className="text-center p-4 bg-[#080A12] rounded-lg">
                      <div className="text-2xl font-bold text-[#E54C4C] mb-1">
                        4.9/5
                      </div>
                      <div className="text-xs text-gray-400">
                        Customer Rating
                      </div>
                    </div>
                  </div>
                </div>
              </AnimatedBorder>
            </div>
          </div>
        </div>
      </div>

      {/* Premium Experience Section */}
      <div className="py-24 bg-[#080A12] relative overflow-hidden">
        <NetworkAnimation />

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="text-center mb-16" data-aos="fade-up">
            <div className="mb-4 inline-block px-4 py-1.5 rounded-full bg-[#E54C4C]/10 border border-[#E54C4C]/20 text-[#E54C4C] text-sm">
              White-Glove Service
            </div>
            <h2 className="text-3xl md:text-5xl font-bold text-white mb-6">
              The <GradientText>Premium</GradientText> Experience
            </h2>
            <p className="text-gray-300 max-w-2xl mx-auto">
              Every touchpoint is designed to reflect the premium quality of
              your brand, from our professionally trained couriers to our
              elegant packaging solutions.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <FeatureCard
              icon={UserCheck}
              title="Elite Delivery Specialists"
              description="Our couriers are selected for their professionalism and trained specifically for high-value deliveries. Well-groomed, articulate, and courteous at every interaction."
              color="#E54C4C"
            />
            <FeatureCard
              icon={Clock}
              title="Same-Hour Fulfillment"
              description="Our moving dark store approach enables us to deliver luxury items within 60 minutes, providing an experience that exceeds even the highest customer expectations."
              color="#4ECAFC"
            />
            <FeatureCard
              icon={Package}
              title="Premium Packaging Protection"
              description="Special handling protocols and premium packaging ensure your high-value items arrive in perfect condition, maintaining the luxury unboxing experience."
              color="#E54C4C"
            />
            <FeatureCard
              icon={Shield}
              title="Secure Chain of Custody"
              description="End-to-end tracking with verification protocols for every high-value item, providing complete security and accountability throughout the delivery process."
              color="#4ECAFC"
            />
            <FeatureCard
              icon={Gift}
              title="Personalized Handover"
              description="Special instructions, gift messaging, and personalized delivery experiences tailored to each recipient's preferences and your brand standards."
              color="#E54C4C"
            />
            <FeatureCard
              icon={TrendingUp}
              title="Sales Conversion Uplift"
              description="Offering same-hour delivery for premium products has been proven to increase cart conversion by up to 28% and average order value by 32%."
              color="#4ECAFC"
            />
          </div>
        </div>
      </div>

      {/* Business Impact Section */}
      <div className="py-24 bg-[#0A0D16] relative">
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top,_#4ECAFC08,transparent_50%)]"></div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="text-center mb-16" data-aos="fade-up">
            <div className="mb-4 inline-block text-[#4ECAFC] text-sm font-medium tracking-wider uppercase">
              Business Benefits
            </div>
            <h2 className="text-3xl md:text-5xl font-bold text-white mb-6">
              Driving <BlueGradient>Exceptional Results</BlueGradient>
            </h2>
            <p className="text-gray-300 max-w-2xl mx-auto">
              Our premium delivery solution is designed to enhance your
              high-value retail operations, boost customer satisfaction, and
              increase revenue.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-16 gap-y-10">
            <BenefitItem
              title="Increased Basket Value"
              description="Premium same-hour delivery has been shown to increase average order values by 32%, with customers more willing to add luxury items when fast delivery is available."
              icon={ShoppingBag}
              delay={100}
            />

            <BenefitItem
              title="Higher Conversion Rates"
              description="Our retail partners report up to 28% higher conversion rates when offering white-glove same-hour delivery at checkout, especially for high-value purchases."
              icon={BarChart4}
              delay={200}
            />

            <BenefitItem
              title="Enhanced Brand Perception"
              description="Premium delivery specialists become an extension of your brand, enhancing customer perception and loyalty through exceptional service standards."
              icon={Sparkles}
              delay={300}
            />

            <BenefitItem
              title="Customer Retention"
              description="Luxury customers who experience our white-glove delivery service show 42% higher retention rates and 35% higher lifetime value."
              icon={Diamond}
              delay={400}
            />

            <BenefitItem
              title="Zero Operational Overhead"
              description="No need to build complex fulfillment operations—we handle everything from rider training to delivery execution with no operational burden on your team."
              icon={Settings}
              delay={500}
            />

            <BenefitItem
              title="Competitive Advantage"
              description="Differentiate your luxury retail experience from competitors with a service level that matches the premium quality of your products."
              icon={Briefcase}
              delay={600}
            />
          </div>
        </div>
      </div>

      {/* Stats Section */}
      <div className="py-24 bg-[#080A12] relative overflow-hidden">
        <NetworkAnimation />

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="text-center mb-20" data-aos="fade-up">
            <div className="mb-4 inline-block px-4 py-1.5 rounded-full bg-[#E54C4C]/10 border border-[#E54C4C]/20 text-[#E54C4C] text-sm">
              Proven Results
            </div>
            <h2 className="text-3xl md:text-5xl font-bold text-white mb-6">
              Performance that <GradientText>Speaks Volumes</GradientText>
            </h2>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <StatsCard
              value="47 min"
              label="Average Delivery Time"
              color="#E54C4C"
            />
            <StatsCard
              value="99.8%"
              label="On-Time Success Rate"
              color="#4ECAFC"
            />
            <StatsCard
              value="32%"
              label="Increased Basket Value"
              color="#E54C4C"
            />
            <StatsCard
              value="4.9/5"
              label="Customer Satisfaction"
              color="#4ECAFC"
            />
          </div>
        </div>
      </div>

      {/* Testimonials Section */}
      {/* <div className="py-24 bg-[#0A0D16] relative">
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top,_#E54C4C08,transparent_50%)]"></div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="text-center mb-16" data-aos="fade-up">
            <div className="mb-4 inline-block text-[#4ECAFC] text-sm font-medium tracking-wider uppercase">
              Client Success Stories
            </div>
            <h2 className="text-3xl md:text-5xl font-bold text-white mb-6">
              What Our <BlueGradient>Partners</BlueGradient> Say
            </h2>
            <p className="text-gray-300 max-w-2xl mx-auto">
              Hear how our premium delivery solution has transformed the
              operations of luxury retail brands across the UK.
            </p>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <Testimonial
              quote="Blitzo's premium delivery specialists represent our luxury brand perfectly. The white-glove service has significantly enhanced our customer experience, leading to higher repeat purchases and brand loyalty."
              company="Luxury Fashion Retailer"
              role="Head of Customer Experience"
            />
            <Testimonial
              quote="The moving dark store concept completely revolutionized our approach to luxury fulfillment. Being able to offer same-hour delivery without any operational complexity on our side has been a game-changer."
              company="High-End Jewelry Brand"
              role="Operations Director"
            />
          </div>
        </div>
      </div> */}

      {/* Integration Section */}
      {/* <div className="py-24 bg-[#080A12] relative overflow-hidden">
        <NetworkAnimation />

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
            <div data-aos="fade-right">
              <BlueAnimatedBorder>
                <div className="bg-[#0F121B]/80 rounded-xl p-8 h-full">
                  <div className="space-y-6">
                    <div className="flex items-start gap-4">
                      <div className="w-10 h-10 rounded-full bg-[#4ECAFC]/10 flex items-center justify-center flex-shrink-0 mt-1">
                        <CircleCheck className="text-[#4ECAFC]" size={20} />
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold text-white mb-1">
                          Seamless E-commerce Integration
                        </h3>
                        <p className="text-gray-300">
                          Connect with all major e-commerce platforms including
                          Shopify, WooCommerce, and custom solutions.
                        </p>
                      </div>
                    </div>

                    <div className="flex items-start gap-4">
                      <div className="w-10 h-10 rounded-full bg-[#4ECAFC]/10 flex items-center justify-center flex-shrink-0 mt-1">
                        <CircleCheck className="text-[#4ECAFC]" size={20} />
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold text-white mb-1">
                          In-Store POS Integration
                        </h3>
                        <p className="text-gray-300">
                          Simple connection with in-store point-of-sale systems
                          for unified order management.
                        </p>
                      </div>
                    </div>

                    <div className="flex items-start gap-4">
                      <div className="w-10 h-10 rounded-full bg-[#4ECAFC]/10 flex items-center justify-center flex-shrink-0 mt-1">
                        <CircleCheck className="text-[#4ECAFC]" size={20} />
                      </div>
                      <div>
                        <h3 className="text-lg font-semibold text-white mb-1">
                          Custom API Solutions
                        </h3>
                        <p className="text-gray-300">
                          Tailored API integrations for bespoke retail systems
                          and enterprise solutions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </BlueAnimatedBorder>
            </div>

            <div data-aos="fade-left">
              <div className="mb-4 inline-block text-[#4ECAFC] text-sm font-medium tracking-wider uppercase">
                Seamless Integration
              </div>
              <h2 className="text-3xl md:text-4xl font-bold text-white mb-6">
                Connect with <BlueGradient>Confidence</BlueGradient>
              </h2>
              <p className="text-gray-300 mb-8">
                Our premium delivery solution is designed to seamlessly
                integrate with your existing retail systems, providing a
                frictionless experience for your team and customers.
              </p>

              <div className="flex items-center gap-4">
                <button
                  onClick={() => setShowSalesModal(true)}
                  className="px-8 py-3.5 bg-gradient-to-r from-[#4ECAFC] to-[#6A8CFF] text-white rounded-lg font-medium flex items-center shadow-lg shadow-[#4ECAFC]/20 hover:shadow-xl hover:shadow-[#4ECAFC]/30 transition-all duration-300"
                >
                  Speak to Sales
                  <ChevronRight
                    className="ml-2 group-hover:translate-x-1 transition-transform"
                    size={18}
                  />
                </button>
                <button
                  onClick={() => setShowSalesModal(true)}
                  className="px-8 py-3.5 bg-[#0F121B] border border-[#4ECAFC] text-[#4ECAFC] rounded-lg font-medium flex items-center shadow-lg shadow-[#4ECAFC]/20 hover:shadow-xl hover:shadow-[#4ECAFC]/30 transition-all duration-300"
                >
                  Request Demo
                  <ArrowRight
                    className="ml-2 group-hover:translate-x-1 transition-transform"
                    size={18}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <FleetSolutions />
      <BottomCTA
        title="Ready to Elevate Your Retail Experience?"
        businessOption={{
          title: "Try Blitzo for Business",
          description:
            "Discover how our premium delivery solution can transform your luxury retail operations and enhance customer satisfaction.",
          buttonText: "Speak to Sales",
        }}
        enterpriseOption={{
          title: "Enterprise Solutions",
          description:
            "Explore our custom API integrations and bespoke delivery solutions for large-scale retail operations.",
          buttonText: "Learn More",
        }}
        handleBusinessClick={() => setShowSalesModal(true)}
        handleEnterpriseClick={() => setShowSalesModal(true)}
      />
      <Footer />
    </div>
  );
};

export default HighValueRetail;
