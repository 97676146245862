import React, { useState, useEffect } from "react";
import { Menu, X, ArrowRight } from "lucide-react";
import logoImage from "../Images/logo.png";

// Gradient text component for "Operator"
const GradientText = ({ children }) => (
  <span className="bg-gradient-to-r from-[#E54C4C] to-[#FF6B6B] inline bg-clip-text text-transparent">
    {children}
  </span>
);

const OperatorNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setIsMenuOpen(false);
    }
  };

  return (
    <nav
      className={`fixed w-full backdrop-blur-lg z-50 transition-all bg-[#0E1420]/95 border-b border-[#2A3143]`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-20">
          <div className="flex items-center">
            <a href="/operator" className="flex items-center">
              <img src={logoImage} alt="Blitzo" className="h-10 w-auto" />
              <div className="flex items-center ml-3">
                <span className="text-white text-3xl font-semibold">
                  Blitzo
                </span>
                <span className="ml-2 px-2 py-0.5 text-xs font-medium text-white bg-gradient-to-r from-[#E54C4C] to-[#FF6B6B] rounded-md shadow-sm">
                  OPERATOR
                </span>
              </div>
            </a>
          </div>

          {/* Desktop navigation */}
          <div className="hidden md:flex items-center space-x-8">
            <button
              onClick={() => scrollToSection("how-we-work")}
              className="text-gray-300 hover:text-[#E54C4C] transition-colors text-sm font-medium"
            >
              How We Work
            </button>
            <button
              onClick={() => scrollToSection("integrations")}
              className="text-gray-300 hover:text-[#E54C4C] transition-colors text-sm font-medium"
            >
              Integrations
            </button>
            <button
              onClick={() => scrollToSection("live-examples")}
              className="text-gray-300 hover:text-[#E54C4C] transition-colors text-sm font-medium"
            >
              Live Examples
            </button>

            <button
              onClick={() => scrollToSection("cta-section")}
              className="bg-[#E54C4C] text-white px-6 py-2 rounded-lg hover:bg-[#E54C4C]/90 transition-all shadow-lg shadow-[#E54C4C]/10 flex items-center text-sm font-medium"
            >
              Speak to Us <ArrowRight className="ml-2" size={16} />
            </button>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-gray-300 hover:text-white"
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div
        className={`md:hidden transition-all duration-300 overflow-hidden ${
          isMenuOpen ? "max-h-screen py-4" : "max-h-0"
        }`}
      >
        <div className="px-4 space-y-4 bg-[#0E1420]/95 border-t border-[#2A3143]">
          <button
            onClick={() => scrollToSection("how-we-work")}
            className="block w-full text-left py-3 text-lg text-gray-300 hover:text-[#E54C4C]"
          >
            How We Work
          </button>
          <button
            onClick={() => scrollToSection("integrations")}
            className="block w-full text-left py-3 text-lg text-gray-300 hover:text-[#E54C4C]"
          >
            Integrations
          </button>
          <button
            onClick={() => scrollToSection("live-examples")}
            className="block w-full text-left py-3 text-lg text-gray-300 hover:text-[#E54C4C]"
          >
            Live Examples
          </button>
          <button
            onClick={() => scrollToSection("cta-section")}
            className="block w-full py-3 text-center bg-[#E54C4C] text-white rounded-lg"
          >
            Speak to Us
          </button>
        </div>
      </div>
    </nav>
  );
};

export default OperatorNavbar;
