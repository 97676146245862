import React from "react";
import {
  Flower2,
  Cake,
  ShoppingBag,
  UtensilsCrossed,
  PackageOpen,
  Smartphone,
  Shirt,
  AlertCircle,
} from "lucide-react";
import { PackageType } from "../../types/PackageType";
import Tooltip from "../resuable/Tooltip";

interface PackageContentsProps {
  selectedType: PackageType | null;
  onChange: (type: PackageType) => void;
}

const packageOptions = [
  {
    type: PackageType.Flowers,
    icon: Flower2,
    iconColor: "#ec4899",
    bgColor: "rgba(236, 72, 153, 0.1)",
    borderColor: "rgba(236, 72, 153, 0.2)",
    note: "Temperature controlled delivery",
    dimensions: "Max Size: 50cm × 50cm × 50cm",
  },
  {
    type: PackageType.Cakes,
    icon: Cake,
    iconColor: "#f59e0b",
    bgColor: "rgba(245, 158, 11, 0.1)",
    borderColor: "rgba(245, 158, 11, 0.2)",
    note: "Flat placement guaranteed",
    dimensions: "Max Size: 40cm × 40cm × 30cm",
  },
  {
    type: PackageType.Grocery,
    icon: ShoppingBag,
    iconColor: "#10b981",
    bgColor: "rgba(16, 185, 129, 0.1)",
    borderColor: "rgba(16, 185, 129, 0.2)",
    note: "Careful sorting & handling",
    dimensions: "Max Size: 35cm × 35cm × 35cm",
  },
  {
    type: PackageType.Food,
    icon: UtensilsCrossed,
    iconColor: "#fb923c",
    bgColor: "rgba(251, 146, 60, 0.1)",
    borderColor: "rgba(251, 146, 60, 0.2)",
    note: "Hot/Cold insulation",
    dimensions: "Max Size: 35cm × 35cm × 35cm",
  },
  {
    type: PackageType.Fragile,
    icon: PackageOpen,
    iconColor: "#3b82f6",
    bgColor: "rgba(59, 130, 246, 0.1)",
    borderColor: "rgba(59, 130, 246, 0.2)",
    note: "Extra padding protection",
    dimensions: "Max Size: 50cm × 40cm × 40cm",
  },
  {
    type: PackageType.Electronics,
    icon: Smartphone,
    iconColor: "#8b5cf6",
    bgColor: "rgba(139, 92, 246, 0.1)",
    borderColor: "rgba(139, 92, 246, 0.2)",
    note: "Anti-static protection",
    dimensions: "Max Size: 35cm × 35cm × 35cm",
  },
  {
    type: PackageType.Garments,
    icon: Shirt,
    iconColor: "#06b6d4",
    bgColor: "rgba(6, 182, 212, 0.1)",
    borderColor: "rgba(6, 182, 212, 0.2)",
    note: "Wrinkle-free transport",
    dimensions: "Max Size: 60cm × 45cm × 30cm",
  },
];

const PackageContents: React.FC<PackageContentsProps> = ({
  selectedType,
  onChange,
}) => {
  return (
    <div className="space-y-4 py-0">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <PackageOpen size={18} className="text-gray-400" />
          <h3 className="font-medium text-white text-xl">Package Type</h3>
        </div>
        <Tooltip
          text={
            <div className="text-xs space-y-1">
              <p>35cm × 35cm × 35cm is the maximum size for other packages.</p>
              <p>
                <a
                  href="https://blitzo.co.uk/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-400 hover:text-blue-300 underline"
                >
                  View T&Cs
                </a>
              </p>
            </div>
          }
        >
          <div className="flex items-center gap-1 text-xs text-gray-400 cursor-help">
            <AlertCircle size={14} />
            <span>Max Dimensions</span>
          </div>
        </Tooltip>
      </div>

      <div className="flex flex-wrap gap-3">
        {packageOptions.map(
          ({
            type,
            icon: Icon,
            iconColor,
            bgColor,
            borderColor,
            note,
            dimensions,
          }) => (
            <Tooltip key={type} text={dimensions}>
              <div
                onClick={() => onChange(type)}
                className="group cursor-pointer"
              >
                <div
                  className="flex flex-col rounded-lg transition-all duration-300"
                  style={{
                    background:
                      selectedType === type ? bgColor : "rgba(31, 41, 55, 0.4)",
                    borderWidth: "1px",
                    borderColor:
                      selectedType === type
                        ? borderColor
                        : "rgba(75, 85, 99, 0.5)",
                  }}
                >
                  <div className="flex items-center gap-2 px-3 py-2">
                    <Icon
                      size={18}
                      style={{
                        color: selectedType === type ? iconColor : "#9ca3af",
                        transition: "color 0.3s ease",
                      }}
                    />
                    <span
                      className="text-sm font-medium"
                      style={{
                        color: selectedType === type ? iconColor : "#d1d5db",
                        transition: "color 0.3s ease",
                      }}
                    >
                      {type}
                    </span>
                  </div>
                  <div className="px-3 pb-2 text-xs text-gray-400">{note}</div>
                </div>
              </div>
            </Tooltip>
          )
        )}
      </div>
    </div>
  );
};

export default PackageContents;
