import React, { useEffect, useState } from "react";
import DarkNavbar from "../components/DarkNavbar";
import Footer from "../components/footer";
import AOS from "aos";
import "aos/dist/aos.css";
import styled from "styled-components";
import {
  ArrowRight,
  CheckCircle,
  ChevronRight,
  Users,
  Target,
  Zap,
  RefreshCw,
  GanttChart,
  Leaf,
} from "lucide-react";
import SpeakToSalesModal from "../components/SpeakToSalesModal";

// Styled components for gradient text effects
const GradientText = styled.span`
  background: linear-gradient(90deg, #e54c4c 0%, #ff6b6b 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
`;

const BlueGradient = styled.span`
  background: linear-gradient(90deg, #4ecafc 0%, #6a8cff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
`;

// Network Animation Component
const NetworkAnimation = () => {
  return (
    <div className="absolute inset-0 z-0 overflow-hidden opacity-40">
      <div className="absolute inset-0 opacity-10">
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `
              linear-gradient(to right, rgba(255,255,255,0.05) 1px, transparent 1px),
              linear-gradient(to bottom, rgba(255,255,255,0.05) 1px, transparent 1px)
            `,
            backgroundSize: "20px 20px",
          }}
        ></div>
      </div>

      {/* Animated dots */}
      <div className="absolute inset-0">
        {[...Array(25)].map((_, i) => (
          <div
            key={`red-${i}`}
            className="absolute w-1 h-1 rounded-full bg-[#E54C4C]/40"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animation: `float ${
                3 + Math.random() * 4
              }s infinite ease-in-out ${Math.random() * 2}s`,
            }}
          ></div>
        ))}
        {[...Array(20)].map((_, i) => (
          <div
            key={`blue-${i}`}
            className="absolute w-1 h-1 rounded-full bg-[#4ECAFC]/40"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animation: `float ${
                2 + Math.random() * 3
              }s infinite ease-in-out ${Math.random() * 2}s`,
            }}
          ></div>
        ))}
      </div>

      {/* Radial gradients */}
      <div
        className="absolute inset-0 opacity-40"
        style={{
          background:
            "radial-gradient(50% 50% at 50% 50%, rgba(229,76,76,0.08) 0%, rgba(10,13,20,0) 100%)",
        }}
      ></div>
      <div
        className="absolute inset-0 opacity-20"
        style={{
          background:
            "radial-gradient(70% 70% at 30% 30%, rgba(78,202,252,0.1) 0%, rgba(10,13,20,0) 100%)",
        }}
      ></div>

      {/* Glowing orbs */}
      <div
        className="absolute w-96 h-96 rounded-full"
        style={{
          background:
            "radial-gradient(circle, rgba(229,76,76,0.1) 0%, rgba(10,13,20,0) 70%)",
          left: "30%",
          top: "40%",
          filter: "blur(50px)",
          animation: "pulseGlow 8s infinite ease-in-out",
        }}
      ></div>
      <div
        className="absolute w-96 h-96 rounded-full"
        style={{
          background:
            "radial-gradient(circle, rgba(78,202,252,0.08) 0%, rgba(10,13,20,0) 70%)",
          right: "25%",
          top: "30%",
          filter: "blur(50px)",
          animation: "pulseGlow 10s infinite ease-in-out 2s",
        }}
      ></div>

      <style jsx>{`
        @keyframes float {
          0%,
          100% {
            transform: translateY(0) translateX(0);
          }
          50% {
            transform: translateY(-15px) translateX(8px);
          }
        }
        @keyframes pulseGlow {
          0%,
          100% {
            opacity: 0.3;
            transform: scale(1);
          }
          50% {
            opacity: 0.8;
            transform: scale(1.3);
          }
        }
      `}</style>
    </div>
  );
};

// Value Card Component
const ValueCard = ({ icon: Icon, title, description, delay = 0 }) => (
  <div
    className="bg-[#0F121B]/80 p-8 rounded-xl border border-[#262D40] hover:border-[#414D75] transition-all duration-300 h-full"
    data-aos="fade-up"
    data-aos-delay={delay}
  >
    <div className="w-12 h-12 bg-[#E54C4C]/10 rounded-xl flex items-center justify-center mb-6 text-[#E54C4C]">
      <Icon size={24} />
    </div>
    <h3 className="text-xl font-bold text-white mb-3">{title}</h3>
    <p className="text-gray-300">{description}</p>
  </div>
);

const Careers = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [showSalesModal, setShowSalesModal] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease-out",
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would normally handle the submission to your server
    // For now, we'll just simulate success
    setTimeout(() => {
      setSubmitted(true);
      setEmail("");
    }, 500);
  };

  return (
    <div className="min-h-screen bg-[#080A12] text-white">
      <SpeakToSalesModal
        isOpen={showSalesModal}
        onClose={() => setShowSalesModal(false)}
      />
      <DarkNavbar />

      {/* Hero Section */}
      <div className="relative pt-40 pb-24 overflow-hidden">
        <NetworkAnimation />

        <div className="max-w-7xl mx-auto px-6 lg:px-8 relative z-10">
          <div className="text-center" data-aos="fade-up">
            <div className="mb-4 inline-block px-4 py-1.5 rounded-full bg-[#E54C4C]/10 border border-[#E54C4C]/20 text-[#E54C4C] text-sm">
              Join Our Team
            </div>
            <h1 className="text-4xl md:text-6xl font-bold text-white mb-8">
              Build the <GradientText>Future</GradientText> of Delivery
            </h1>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto leading-relaxed">
              We're revolutionizing the same-hour delivery experience in London
              and beyond, one innovative solution at a time.
            </p>
          </div>
        </div>

        {/* Glowing orbs */}
        <div className="absolute top-0 left-0 w-64 h-64 rounded-full bg-[#4ECAFC]/10 blur-3xl opacity-30 animate-pulse-slow"></div>
        <div className="absolute bottom-0 right-0 w-64 h-64 rounded-full bg-[#E54C4C]/10 blur-3xl opacity-30 animate-pulse-slow-delay"></div>
      </div>

      {/* Current Openings Section */}
      <div className="max-w-7xl mx-auto px-6 lg:px-8 py-20 relative z-10">
        <div className="text-center mb-12" data-aos="fade-up">
          <h2 className="text-3xl md:text-5xl font-bold text-white mb-6">
            Current <BlueGradient>Openings</BlueGradient>
          </h2>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto">
            While we don't currently have open positions, we're always looking
            for exceptional talent to join our team.
          </p>
        </div>

        <div
          className="max-w-3xl mx-auto bg-[#0F121B]/80 p-10 rounded-xl border border-[#262D40] text-center"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="mb-6">
            <div className="w-16 h-16 bg-[#4ECAFC]/10 rounded-full flex items-center justify-center mx-auto mb-4 text-[#4ECAFC]">
              <Users size={32} />
            </div>
            <h3 className="text-2xl font-bold text-white mb-2">
              We've Recently Filled Our Open Roles
            </h3>
            <p className="text-gray-300 mb-8">
              We've just completed our hiring for current positions, but we're
              growing rapidly and new opportunities arise frequently. Join our
              talent community to be the first to know when we have new openings
              that match your skills and interests.
            </p>
          </div>

          {!submitted ? (
            <form onSubmit={handleSubmit} className="max-w-md mx-auto">
              <div className="flex flex-col md:flex-row gap-4 mb-4">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  required
                  className="flex-grow px-4 py-3 bg-[#080A12] border border-[#262D40] rounded-lg focus:outline-none focus:border-[#4ECAFC] text-white"
                />
                <button
                  type="submit"
                  className="px-6 py-3 bg-gradient-to-r from-[#4ECAFC] to-[#6A8CFF] text-white rounded-lg font-medium flex items-center justify-center shadow-lg shadow-[#4ECAFC]/20 hover:shadow-xl hover:shadow-[#4ECAFC]/30 transition-all duration-300"
                >
                  Stay Updated
                  <ArrowRight
                    className="ml-2 group-hover:translate-x-1 transition-transform"
                    size={18}
                  />
                </button>
              </div>
              <p className="text-gray-400 text-sm mt-2">
                We'll notify you when new positions open up that match your
                profile.
              </p>
            </form>
          ) : (
            <div className="bg-[#0A0D16] rounded-lg p-6 border border-[#4ECAFC]/30 max-w-md mx-auto">
              <CheckCircle className="text-[#4ECAFC] mx-auto mb-3" size={32} />
              <h4 className="text-xl font-bold text-white mb-2">
                Thank You for Your Interest!
              </h4>
              <p className="text-gray-300">
                We've added you to our talent community. We'll reach out when
                roles that match your skills become available.
              </p>
            </div>
          )}
        </div>
      </div>

      {/* Our Values Section */}
      <div className="bg-[#0A0D16] py-24 relative">
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top,_#E54C4C08,transparent_50%)]"></div>

        <div className="max-w-7xl mx-auto px-6 lg:px-8 relative z-10">
          <div className="text-center mb-16" data-aos="fade-up">
            <div className="mb-4 inline-block text-[#E54C4C] text-sm font-medium tracking-wider uppercase">
              What We Believe
            </div>
            <h2 className="text-3xl md:text-5xl font-bold text-white mb-6">
              Our <GradientText>Vision & Values</GradientText>
            </h2>
            <p className="text-gray-300 max-w-2xl mx-auto">
              At Blitzo, our values guide everything we do. They shape our
              culture, define our priorities, and drive our decision-making
              process.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <ValueCard
              icon={Zap}
              title="Solve Fast, Solve Right"
              description="We resolve issues with speed and precision, supporting our riders and customers personally and directly. For every delivery, we bring urgency and care."
              delay={100}
            />

            <ValueCard
              icon={RefreshCw}
              title="The Blitzo Bet"
              description="Ironically, we don't believe in taking blind bets. Every decision we make is grounded in constant feedback from our customers and riders, who are at the core of our growth. By learning from every interaction, we ensure our path is driven by insight and purpose, not guesswork."
              delay={200}
            />

            <ValueCard
              icon={Target}
              title="Innovative Pragmatism"
              description="We think big but build practically, advancing in steps that make an immediate impact."
              delay={300}
            />

            <ValueCard
              icon={GanttChart}
              title="Purposeful Growth"
              description="Our drive for growth is intentional and relentless. We prioritize what truly adds value to our customers and riders, balancing quality and quantity to build a scalable, lasting service that meets their evolving needs."
              delay={400}
            />

            <ValueCard
              icon={Leaf}
              title="Sustainability at Heart"
              description="We integrate sustainability into every decision we make, from our eco-friendly fleet to route-optimizations. For Blitzo, each step forward is also a step toward a greener, more responsible future."
              delay={500}
            />

            <div
              className="bg-[#0F121B]/80 p-8 rounded-xl border border-[#262D40] hover:border-[#414D75] transition-all duration-300 flex flex-col justify-center items-center"
              data-aos="fade-up"
              data-aos-delay="600"
            >
              <p className="text-xl text-white font-medium mb-6 text-center">
                Want to learn more about our culture and values?
              </p>
              <button
                onClick={() => setShowSalesModal(true)}
                className="px-6 py-3 bg-[#E54C4C]/10 border border-[#E54C4C]/20 text-[#E54C4C] rounded-lg font-medium hover:bg-[#E54C4C]/20 transition-all duration-300 flex items-center"
              >
                Get in Touch
                <ChevronRight
                  className="ml-2 group-hover:translate-x-1 transition-transform"
                  size={18}
                />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Team Life Section */}
      <div className="py-24 bg-[#080A12] relative overflow-hidden">
        <NetworkAnimation />

        <div className="max-w-7xl mx-auto px-6 lg:px-8 relative z-10">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
            <div data-aos="fade-right">
              <div className="mb-4 inline-block text-[#4ECAFC] text-sm font-medium tracking-wider uppercase">
                Life at Blitzo
              </div>
              <h2 className="text-3xl md:text-4xl font-bold text-white mb-6">
                Work With the <BlueGradient>Best</BlueGradient>
              </h2>

              <p className="text-gray-300 mb-8">
                Joining Blitzo means becoming part of a diverse, dynamic team
                dedicated to revolutionizing delivery services. We're a group of
                problem-solvers and innovators who are passionate about creating
                exceptional experiences for our customers and riders.
              </p>

              <div className="space-y-4 mb-8">
                <div className="flex items-center text-gray-300">
                  <div className="w-6 h-6 rounded-full bg-[#4ECAFC]/10 flex items-center justify-center mr-3">
                    <CheckCircle className="text-[#4ECAFC]" size={14} />
                  </div>
                  <span>Collaborative, inclusive work environment</span>
                </div>
                <div className="flex items-center text-gray-300">
                  <div className="w-6 h-6 rounded-full bg-[#4ECAFC]/10 flex items-center justify-center mr-3">
                    <CheckCircle className="text-[#4ECAFC]" size={14} />
                  </div>
                  <span>Competitive compensation and benefits</span>
                </div>
                <div className="flex items-center text-gray-300">
                  <div className="w-6 h-6 rounded-full bg-[#4ECAFC]/10 flex items-center justify-center mr-3">
                    <CheckCircle className="text-[#4ECAFC]" size={14} />
                  </div>
                  <span>Professional growth and development opportunities</span>
                </div>
                <div className="flex items-center text-gray-300">
                  <div className="w-6 h-6 rounded-full bg-[#4ECAFC]/10 flex items-center justify-center mr-3">
                    <CheckCircle className="text-[#4ECAFC]" size={14} />
                  </div>
                  <span>
                    Chance to make a real impact on a growing industry
                  </span>
                </div>
              </div>
            </div>

            <div data-aos="fade-left">
              <div className="aspect-video bg-gradient-to-br from-[#0F121B] to-[#171E2F] rounded-xl overflow-hidden border border-[#262D40] relative">
                <div className="absolute inset-0 bg-[#0F121B]/80 flex items-center justify-center">
                  <div className="text-center px-8 py-12">
                    <div className="w-20 h-20 mx-auto mb-6 bg-[#4ECAFC]/10 rounded-full flex items-center justify-center">
                      <Users className="text-[#4ECAFC]" size={32} />
                    </div>

                    <h3 className="text-2xl font-bold text-white mb-4">
                      Be Part of Our Journey
                    </h3>

                    <p className="text-gray-300 mb-6">
                      We're on a mission to redefine what's possible in delivery
                      services. If you're passionate about innovation, customer
                      experience, and making a difference, we want to hear from
                      you – even if we don't have openings right now.
                    </p>

                    <button
                      onClick={() => setShowSalesModal(true)}
                      className="px-6 py-3 bg-gradient-to-r from-[#4ECAFC] to-[#6A8CFF] text-white rounded-lg font-medium flex items-center mx-auto shadow-lg shadow-[#4ECAFC]/20 hover:shadow-xl hover:shadow-[#4ECAFC]/30 transition-all duration-300"
                    >
                      Connect With Us
                      <ChevronRight
                        className="ml-2 group-hover:translate-x-1 transition-transform"
                        size={18}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Final CTA Section */}
      <div className="relative bg-[#0A0D16] py-24 overflow-hidden">
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top,_#E54C4C08,transparent_50%)]"></div>

        <div className="max-w-7xl mx-auto px-6 lg:px-8 relative z-10">
          <div className="text-center" data-aos="fade-up">
            <div className="mb-4 inline-block text-[#E54C4C] text-sm font-medium tracking-wider uppercase">
              Stay Connected
            </div>
            <h2 className="text-3xl md:text-5xl font-bold text-white mb-6">
              The Future of Delivery is <GradientText>Here</GradientText>
            </h2>
            <p className="text-gray-300 max-w-2xl mx-auto mb-10">
              While we don't have open positions at the moment, we're growing
              fast. Follow us on social media or join our talent community to be
              the first to know when new opportunities arise.
            </p>

            <div className="flex flex-wrap justify-center gap-6">
              <a
                href="https://www.linkedin.com/company/blitzouk/"
                target="_blank"
                rel="noopener noreferrer"
                className="px-8 py-3.5 bg-gradient-to-r from-[#E54C4C] to-[#FF6B6B] text-white rounded-lg font-medium flex items-center shadow-lg shadow-[#E54C4C]/20 hover:shadow-xl hover:shadow-[#E54C4C]/30 transition-all duration-300"
              >
                Follow on LinkedIn
                <ChevronRight
                  className="ml-2 group-hover:translate-x-1 transition-transform"
                  size={18}
                />
              </a>

              <a
                href="mailto:careers@blitzo.co.uk"
                className="px-8 py-3.5 bg-[#0F121B] border border-gray-700 text-white rounded-lg font-medium flex items-center hover:border-gray-500 transition-all duration-300"
              >
                Email Talent Team
                <ArrowRight
                  className="ml-2 group-hover:translate-x-1 transition-transform"
                  size={18}
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Animations for fade and pulse effects */}
      <style jsx>{`
        @keyframes pulse-slow {
          0%,
          100% {
            transform: scale(1);
            opacity: 0.3;
          }
          50% {
            transform: scale(1.1);
            opacity: 0.5;
          }
        }

        @keyframes pulse-slow-delay {
          0%,
          100% {
            transform: scale(1);
            opacity: 0.3;
          }
          50% {
            transform: scale(1.15);
            opacity: 0.6;
          }
        }
      `}</style>

      <Footer />
    </div>
  );
};

export default Careers;
