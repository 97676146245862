import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logoImage from "../Images/logo.png";
import "./SideBar.css";
import bikeIcon from "../Icons/bike.svg";

function SideBar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const navItems = [
    { path: "/admin/dashboard", label: "Dashboard", icon: "" },
    { path: "#", label: "Riders", icon: "" },
    { path: "/admin/requests", label: "Requests", icon: "" },
    { path: "/admin/notifications", label: "Notifications", icon: "" },
  ];

  const isActive = (path) => {
    return location.pathname === path ? "active" : "";
  };

  const handleLogout = () => {
    sessionStorage.removeItem("isAdminAuthenticated");
    navigate("/admin/login");
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <button
        className="mobile-menu-toggle"
        onClick={toggleSidebar}
        aria-label="Toggle navigation menu"
      >
        <div className="hamburger-icon">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>

      <div className={`sidebar ${isOpen ? "open" : ""}`}>
        <div className="logo-container">
          <img className="logoo" src={logoImage} alt="" />
          <h1 className="sidebar-title">Blitzo</h1>
          <button className="close-sidebar" onClick={toggleSidebar}>
            ×
          </button>
        </div>

        <div className="nav-items">
          {navItems.map((item) => (
            <Link
              key={item.path}
              to={item.path}
              className={`nav-item ${isActive(item.path)}`}
              onClick={() => setIsOpen(false)}
            >
              <span className="nav-icon">{item.icon}</span>
              <span>{item.label}</span>
            </Link>
          ))}
        </div>

        <button onClick={handleLogout} className="logout-button">
          <span className="nav-icon"></span>
          <span>Log out</span>
        </button>
      </div>

      {isOpen && (
        <div className="sidebar-overlay" onClick={toggleSidebar}></div>
      )}
    </>
  );
}

export default SideBar;
