import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

const BusinessRoute = () => {
  const { user } = useAuth();

  if (user) {
    return <Navigate to="/business/book" replace />;
  }

  return <Navigate to="/business/signup" replace />;
};

export default BusinessRoute;

// In your routes:
