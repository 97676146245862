import React, { useEffect, useState } from "react";
import { collection, onSnapshot, doc, updateDoc } from "firebase/firestore";
import { db } from "../../Firebase/firebase-config";
import { getRiderInfo } from "../../Firebase/rider";
import { getUserInfo } from "../../Firebase/user";
import { FaDirections, FaChevronDown, FaChevronUp } from "react-icons/fa";
import SideBar from "../../components/SideBar";
import "./RiderDashboard.css";

const ActiveOrders = () => {
  const [activeOrders, setActiveOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [riderInfo, setRiderInfo] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [editingFees, setEditingFees] = useState({});
  const [editedFees, setEditedFees] = useState({});
  const [showProfit, setShowProfit] = useState({});
  const [editingLocation, setEditingLocation] = useState({});
  const [editedLocation, setEditedLocation] = useState({});
  const [expandedOrders, setExpandedOrders] = useState({});
  const [showNearbyRiders, setShowNearbyRiders] = useState({});

  useEffect(() => {
    // Set up real-time listener for active orders
    const unsubscribe = onSnapshot(
      collection(db, "activeOrders"),
      async (snapshot) => {
        try {
          const orders = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            orders.push({
              id: doc.id,
              ...data,
            });
          });
          setActiveOrders(orders);

          // Fetch user info for all orders
          const userInfoMap = {};
          for (const order of orders) {
            if (order.orderedBy) {
              const info = await getUserInfo(order.orderedBy);
              if (info) {
                userInfoMap[order.orderedBy] = info;
              }
            }
          }
          setUserInfo(userInfoMap);

          // Fetch rider info for all assigned riders and potential riders
          const riderInfoMap = {};
          for (const order of orders) {
            // Get info for assigned rider
            if (order.assignment?.blitzerId) {
              const info = await getRiderInfo(order.assignment.blitzerId);
              if (info) {
                riderInfoMap[order.assignment.blitzerId] = info;
              }
            }

            // Get info for potential riders
            if (order.ridersAndPickupDistances) {
              for (const riderData of order.ridersAndPickupDistances) {
                if (
                  riderData &&
                  riderData.riderId &&
                  !riderInfoMap[riderData.riderId]
                ) {
                  const info = await getRiderInfo(riderData.riderId);
                  if (info) {
                    riderInfoMap[riderData.riderId] = info;
                  }
                }
              }
            }

            // Get info for unassigned riders
            if (order.unassignedRiders) {
              for (const riderId of order.unassignedRiders) {
                if (!riderInfoMap[riderId]) {
                  const info = await getRiderInfo(riderId);
                  if (info) {
                    riderInfoMap[riderId] = info;
                  }
                }
              }
            }
          }
          setRiderInfo(riderInfoMap);
        } catch (error) {
          console.error("Error in real-time update:", error);
        } finally {
          setLoading(false);
        }
      },
      (error) => {
        console.error("Error setting up real-time listener:", error);
        setLoading(false);
      }
    );

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const handleEditFees = (orderId) => {
    setEditingFees((prev) => ({
      ...prev,
      [orderId]: !prev[orderId],
    }));

    // Initialize edited fees with current values
    if (!editedFees[orderId]) {
      const order = activeOrders.find((o) => o.id === orderId);
      setEditedFees((prev) => ({
        ...prev,
        [orderId]: { ...order.fee },
      }));
    }
  };

  const handleFeeChange = (orderId, field, value) => {
    setEditedFees((prev) => ({
      ...prev,
      [orderId]: {
        ...prev[orderId],
        [field]: field === "riderFee" ? value : parseFloat(value) || 0,
      },
    }));
  };

  const handleSaveFees = async (orderId) => {
    try {
      const orderRef = doc(db, "activeOrders", orderId);
      await updateDoc(orderRef, {
        fee: editedFees[orderId],
      });

      // Update local state
      setActiveOrders((prev) =>
        prev.map((order) =>
          order.id === orderId ? { ...order, fee: editedFees[orderId] } : order
        )
      );

      // Exit edit mode
      setEditingFees((prev) => ({
        ...prev,
        [orderId]: false,
      }));
    } catch (error) {
      console.error("Error updating fees:", error);
      alert("Failed to update fees. Please try again.");
    }
  };

  const handleUnassignRider = async (orderId) => {
    try {
      const order = activeOrders.find((o) => o.id === orderId);
      const riderId = order.assignment.blitzerId;

      // Update the order document
      const orderRef = doc(db, "activeOrders", orderId);
      await updateDoc(orderRef, {
        assignment: null,
        status: "pending",
        unassignedRiders: [...(order.unassignedRiders || []), riderId],
      });

      // Update the rider's document
      const riderRef = doc(db, "riders", riderId);
      await updateDoc(riderRef, {
        currentOrder: null,
        status: "available",
      });

      // Update local state
      setActiveOrders((prev) =>
        prev.map((o) =>
          o.id === orderId
            ? {
                ...o,
                assignment: null,
                status: "pending",
                unassignedRiders: [...(o.unassignedRiders || []), riderId],
              }
            : o
        )
      );
    } catch (error) {
      console.error("Error unassigning rider:", error);
      alert("Failed to unassign rider. Please try again.");
    }
  };

  const handleLocationChange = (orderId, field, value) => {
    setEditedLocation((prev) => ({
      ...prev,
      [orderId]: {
        ...prev[orderId],
        [field]: parseFloat(value) || 0,
      },
    }));
  };

  const handleEditLocation = (orderId) => {
    setEditingLocation((prev) => ({
      ...prev,
      [orderId]: !prev[orderId],
    }));

    // Initialize edited location with current values
    if (!editedLocation[orderId]) {
      const order = activeOrders.find((o) => o.id === orderId);
      setEditedLocation((prev) => ({
        ...prev,
        [orderId]: { ...order.assignment.blitzerLocation },
      }));
    }
  };

  const handleSaveLocation = async (orderId) => {
    try {
      const orderRef = doc(db, "activeOrders", orderId);
      await updateDoc(orderRef, {
        "assignment.blitzerLocation": editedLocation[orderId],
      });

      // Exit edit mode
      setEditingLocation((prev) => ({
        ...prev,
        [orderId]: false,
      }));
    } catch (error) {
      console.error("Error updating location:", error);
      alert("Failed to update location. Please try again.");
    }
  };

  const toggleOrderExpansion = (orderId) => {
    setExpandedOrders((prev) => ({
      ...prev,
      [orderId]: !prev[orderId],
    }));
  };

  const renderFeeInput = (orderId, field, label, value) => (
    <p>
      <strong>{label}:</strong>{" "}
      {editingFees[orderId] ? (
        <input
          type="number"
          step="0.01"
          value={editedFees[orderId]?.[field] || 0}
          onChange={(e) => handleFeeChange(orderId, field, e.target.value)}
          className="fee-input"
        />
      ) : (
        `£${value?.toFixed(2) || "0.00"}`
      )}
    </p>
  );

  return (
    <div className="adminWrapper">
      <div>
        <SideBar />
      </div>
      <div className="mainContent">
        <h1>Active Orders</h1>
        {loading ? (
          <p>Loading orders...</p>
        ) : activeOrders.length === 0 ? (
          <p>No active orders at the moment</p>
        ) : (
          <div className="orders-grid">
            {activeOrders.map((order) => (
              <div key={order.id} className="order-card">
                <div
                  className="order-header"
                  onClick={() => toggleOrderExpansion(order.id)}
                >
                  <h3>
                    <a
                      href={`https://blitzo.co.uk/liveOrders/${order.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="order-link"
                      onClick={(e) => e.stopPropagation()}
                    >
                      Order ID: {order.id}
                    </a>
                  </h3>
                  <div className="mobile-order-summary">
                    {order.orderedBy && userInfo[order.orderedBy] && (
                      <p>
                        <strong>Customer:</strong>{" "}
                        {userInfo[order.orderedBy].name || "N/A"}
                      </p>
                    )}
                    <p>
                      <strong>Pickup:</strong>{" "}
                      {order.pickupLocation?.locationName || "N/A"}
                    </p>
                    <p className="text-sm text-gray-400 ml-4">
                      {order.pickupLocation?.detailedLocation || "N/A"}
                    </p>
                    <p>
                      <strong>Dropoff:</strong>{" "}
                      {order.dropoffLocation?.locationName || "N/A"}
                    </p>
                    <p className="text-sm text-gray-400 ml-4">
                      {order.dropoffLocation?.detailedLocation || "N/A"}
                    </p>
                    {order.fee && (
                      <>
                        <p>
                          <strong>Order Price:</strong> £
                          {order.fee.total?.toFixed(2) || "N/A"}
                        </p>
                        {order.fee.riderFee && (
                          <p>
                            <strong>Rider Fee:</strong> £
                            {order.fee.riderFee.total?.toFixed(2) || "N/A"}
                          </p>
                        )}
                      </>
                    )}
                  </div>
                  <button className="expand-toggle">
                    {expandedOrders[order.id] ? (
                      <FaChevronUp />
                    ) : (
                      <FaChevronDown />
                    )}
                  </button>
                </div>
                {expandedOrders[order.id] && (
                  <div className="order-details">
                    {/* User Information Section */}
                    {order.orderedBy && userInfo[order.orderedBy] && (
                      <div className="user-details">
                        <h4>Customer Information</h4>
                        <p>
                          <strong>Name:</strong>{" "}
                          {userInfo[order.orderedBy].name || "N/A"}
                        </p>
                        <p>
                          <strong>Phone:</strong>{" "}
                          <a
                            href={`tel:${
                              userInfo[order.orderedBy].phoneNumber
                            }`}
                            className="phone-link"
                          >
                            {userInfo[order.orderedBy].phoneNumber || "N/A"}
                          </a>
                        </p>
                      </div>
                    )}
                    <p>
                      <strong>Status:</strong> {order.status || "N/A"}
                    </p>
                    <p>
                      <strong>Started At:</strong>{" "}
                      {order.assignment?.startedAt
                        ? new Date(
                            order.assignment.startedAt.seconds * 1000
                          ).toLocaleString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                            hour12: false,
                          })
                        : "N/A"}
                    </p>
                    {console.log("Full order object:", order)}
                    {console.log("Timestamp fields:", {
                      startedAt: order.startedAt,
                      started_at: order.started_at,
                      createdAt: order.createdAt,
                      created_at: order.created_at,
                      timestamp: order.timestamp,
                      orderTime: order.orderTime,
                      created: order.created,
                    })}
                    <p>
                      <strong>Pickup:</strong>{" "}
                      {order.pickupLocation?.locationName || "N/A"}
                    </p>
                    <p className="text-sm text-gray-400 ml-4">
                      {order.pickupLocation?.detailedLocation || "N/A"}
                    </p>
                    <p>
                      <strong>Dropoff:</strong>{" "}
                      {order.dropoffLocation?.locationName || "N/A"}
                    </p>
                    <p className="text-sm text-gray-400 ml-4">
                      {order.dropoffLocation?.detailedLocation || "N/A"}
                    </p>
                    {order.pickupLocation?.latitude &&
                      order.pickupLocation?.longitude &&
                      order.dropoffLocation?.latitude &&
                      order.dropoffLocation?.longitude && (
                        <a
                          href={`https://www.google.com/maps/dir/${order.pickupLocation.latitude},${order.pickupLocation.longitude}/${order.dropoffLocation.latitude},${order.dropoffLocation.longitude}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="map-link"
                        >
                          <FaDirections className="map-icon" />
                        </a>
                      )}
                    <p>
                      <strong>Package Type:</strong>{" "}
                      {order.packageType || "N/A"}
                    </p>
                    <p>
                      <strong>Sender Number:</strong>{" "}
                      <a
                        href={`tel:${order.senderNumber}`}
                        className="phone-link"
                      >
                        {order.senderNumber || "N/A"}
                      </a>
                    </p>
                    <p>
                      <strong>Receiver Number:</strong>{" "}
                      <a
                        href={`tel:${order.receiverNumber}`}
                        className="phone-link"
                      >
                        {order.receiverNumber || "N/A"}
                      </a>
                    </p>
                    {order.specialInstructions && (
                      <div className="special-instructions">
                        <p>
                          <strong>Special Instructions:</strong>{" "}
                          {order.specialInstructions}
                        </p>
                      </div>
                    )}

                    {/* Fee Breakdown Section */}
                    {order.fee && (
                      <div className="fee-details">
                        <h4>Fee Breakdown</h4>
                        {editingFees[order.id] ? (
                          <>
                            <div className="fee-breakdown">
                              <p>
                                <strong>Total Fee:</strong>{" "}
                                <input
                                  type="number"
                                  step="0.01"
                                  value={editedFees[order.id]?.total || 0}
                                  onChange={(e) =>
                                    handleFeeChange(
                                      order.id,
                                      "total",
                                      e.target.value
                                    )
                                  }
                                  className="fee-input"
                                />
                              </p>
                              <p>
                                <strong>Base Fee:</strong>{" "}
                                <input
                                  type="number"
                                  step="0.01"
                                  value={editedFees[order.id]?.baseFee || 0}
                                  onChange={(e) =>
                                    handleFeeChange(
                                      order.id,
                                      "baseFee",
                                      e.target.value
                                    )
                                  }
                                  className="fee-input"
                                />
                              </p>
                              <p>
                                <strong>Distance Fee:</strong>{" "}
                                <input
                                  type="number"
                                  step="0.01"
                                  value={editedFees[order.id]?.distanceFee || 0}
                                  onChange={(e) =>
                                    handleFeeChange(
                                      order.id,
                                      "distanceFee",
                                      e.target.value
                                    )
                                  }
                                  className="fee-input"
                                />
                              </p>
                              <p>
                                <strong>Service Fee:</strong>{" "}
                                <input
                                  type="number"
                                  step="0.01"
                                  value={editedFees[order.id]?.serviceFee || 0}
                                  onChange={(e) =>
                                    handleFeeChange(
                                      order.id,
                                      "serviceFee",
                                      e.target.value
                                    )
                                  }
                                  className="fee-input"
                                />
                              </p>
                              <p>
                                <strong>Size Fee:</strong>{" "}
                                <input
                                  type="number"
                                  step="0.01"
                                  value={editedFees[order.id]?.sizeFee || 0}
                                  onChange={(e) =>
                                    handleFeeChange(
                                      order.id,
                                      "sizeFee",
                                      e.target.value
                                    )
                                  }
                                  className="fee-input"
                                />
                              </p>
                              <p>
                                <strong>Printing Fee:</strong>{" "}
                                <input
                                  type="number"
                                  step="0.01"
                                  value={editedFees[order.id]?.printingFee || 0}
                                  onChange={(e) =>
                                    handleFeeChange(
                                      order.id,
                                      "printingFee",
                                      e.target.value
                                    )
                                  }
                                  className="fee-input"
                                />
                              </p>
                              <p>
                                <strong>Express Fee:</strong>{" "}
                                <input
                                  type="number"
                                  step="0.01"
                                  value={editedFees[order.id]?.expressFee || 0}
                                  onChange={(e) =>
                                    handleFeeChange(
                                      order.id,
                                      "expressFee",
                                      e.target.value
                                    )
                                  }
                                  className="fee-input"
                                />
                              </p>
                              <p>
                                <strong>Discount:</strong>{" "}
                                <input
                                  type="number"
                                  step="1"
                                  value={
                                    editedFees[order.id]
                                      ?.discountInPercentage || 0
                                  }
                                  onChange={(e) =>
                                    handleFeeChange(
                                      order.id,
                                      "discountInPercentage",
                                      e.target.value
                                    )
                                  }
                                  className="fee-input"
                                />
                                %
                              </p>
                            </div>
                          </>
                        ) : (
                          <>
                            <p>
                              <strong>Total Fee:</strong> £
                              {order.fee.total?.toFixed(2) || "N/A"}
                              {order.fee.discountInPercentage > 0 && (
                                <span className="discount-tag">
                                  ({order.fee.discountInPercentage}% OFF)
                                </span>
                              )}
                            </p>
                            {order.fee.discountedPrice && (
                              <p className="discounted-price">
                                <strong>Discounted Price:</strong> £
                                {order.fee.discountedPrice.toFixed(2)}
                              </p>
                            )}
                            <div className="fee-breakdown">
                              <p>
                                <strong>Base Fee:</strong> £
                                {order.fee.baseFee?.toFixed(2) || "N/A"}
                              </p>
                              <p>
                                <strong>Distance Fee:</strong> £
                                {order.fee.distanceFee?.toFixed(2) || "N/A"}
                              </p>
                              <p>
                                <strong>Service Fee:</strong> £
                                {order.fee.serviceFee?.toFixed(2) || "N/A"}
                              </p>
                              <p>
                                <strong>Size Fee:</strong> £
                                {order.fee.sizeFee?.toFixed(2) || "0.00"}
                              </p>
                              <p>
                                <strong>Printing Fee:</strong> £
                                {order.fee.printingFee?.toFixed(2) || "0.00"}
                              </p>
                              <p>
                                <strong>Express Fee:</strong> £
                                {order.fee.expressFee?.toFixed(2) || "0.00"}
                              </p>
                              <p>
                                <strong>Discount:</strong>{" "}
                                {order.fee.discountInPercentage || 0}%
                              </p>
                            </div>
                          </>
                        )}

                        {/* Rider Fee Section */}
                        {order.fee.riderFee && (
                          <div className="rider-fee">
                            <h4>Rider Fee Breakdown</h4>
                            {editingFees[order.id] ? (
                              <div className="fee-breakdown">
                                <p className="total-rider-fee">
                                  <strong>Total Rider Fee:</strong>{" "}
                                  <input
                                    type="number"
                                    step="0.01"
                                    value={
                                      editedFees[order.id]?.riderFee?.total || 0
                                    }
                                    onChange={(e) =>
                                      handleFeeChange(order.id, "riderFee", {
                                        ...editedFees[order.id]?.riderFee,
                                        total: parseFloat(e.target.value) || 0,
                                      })
                                    }
                                    className="fee-input"
                                  />
                                </p>
                                <div className="rider-fee-breakdown">
                                  <p>
                                    <strong>Base Fee:</strong>{" "}
                                    <input
                                      type="number"
                                      step="0.01"
                                      value={
                                        editedFees[order.id]?.riderFee
                                          ?.baseFee || 0
                                      }
                                      onChange={(e) =>
                                        handleFeeChange(order.id, "riderFee", {
                                          ...editedFees[order.id]?.riderFee,
                                          baseFee:
                                            parseFloat(e.target.value) || 0,
                                        })
                                      }
                                      className="fee-input"
                                    />
                                  </p>
                                  <p>
                                    <strong>Distance Fee:</strong>{" "}
                                    <input
                                      type="number"
                                      step="0.01"
                                      value={
                                        editedFees[order.id]?.riderFee
                                          ?.distanceFee || 0
                                      }
                                      onChange={(e) =>
                                        handleFeeChange(order.id, "riderFee", {
                                          ...editedFees[order.id]?.riderFee,
                                          distanceFee:
                                            parseFloat(e.target.value) || 0,
                                        })
                                      }
                                      className="fee-input"
                                    />
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <div className="fee-breakdown">
                                <p className="total-rider-fee">
                                  <strong>Total Rider Fee:</strong> £
                                  {order.fee.riderFee.total?.toFixed(2) ||
                                    "N/A"}
                                </p>
                                <div className="rider-fee-breakdown">
                                  <p>
                                    <strong>Base Fee:</strong> £
                                    {order.fee.riderFee.baseFee?.toFixed(2) ||
                                      "N/A"}
                                  </p>
                                  <p>
                                    <strong>Distance Fee:</strong> £
                                    {order.fee.riderFee.distanceFee?.toFixed(
                                      2
                                    ) || "N/A"}
                                  </p>
                                </div>
                              </div>
                            )}

                            {/* Profit Calculation Section */}
                            <div className="profit-section">
                              <button
                                className="profit-toggle"
                                onClick={() =>
                                  setShowProfit((prev) => ({
                                    ...prev,
                                    [order.id]: !prev[order.id],
                                  }))
                                }
                              >
                                <h4>
                                  Profit Analysis
                                  <span className="toggle-icon">
                                    {showProfit[order.id] ? "▼" : "▶"}
                                  </span>
                                </h4>
                              </button>
                              {showProfit[order.id] && (
                                <div className="profit-breakdown">
                                  {(() => {
                                    const customerFee = order.fee.total || 0;
                                    const riderFee =
                                      order.fee.riderFee.total || 0;
                                    const profit = customerFee - riderFee;
                                    const profitPercentage =
                                      (profit / customerFee) * 100;
                                    const isProfit = profit > 0;

                                    return (
                                      <>
                                        <p
                                          className={`profit-amount ${
                                            isProfit
                                              ? "profit-positive"
                                              : "profit-negative"
                                          }`}
                                        >
                                          <strong>Profit:</strong> £
                                          {profit.toFixed(2)}
                                        </p>
                                        <p
                                          className={`profit-percentage ${
                                            isProfit
                                              ? "profit-positive"
                                              : "profit-negative"
                                          }`}
                                        >
                                          <strong>Profit Margin:</strong>{" "}
                                          {profitPercentage.toFixed(1)}%
                                        </p>
                                      </>
                                    );
                                  })()}
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        {/* Moved fee actions to the bottom */}
                        <div className="fee-actions">
                          {editingFees[order.id] ? (
                            <>
                              <button
                                className="save-button"
                                onClick={() => handleSaveFees(order.id)}
                              >
                                Save Changes
                              </button>
                              <button
                                className="cancel-button"
                                onClick={() => {
                                  setEditingFees((prev) => ({
                                    ...prev,
                                    [order.id]: false,
                                  }));
                                  setEditedFees((prev) => ({
                                    ...prev,
                                    [order.id]: { ...order.fee },
                                  }));
                                }}
                              >
                                Cancel
                              </button>
                            </>
                          ) : (
                            <button
                              className="edit-button"
                              onClick={() => handleEditFees(order.id)}
                            >
                              Edit Fees
                            </button>
                          )}
                        </div>
                      </div>
                    )}

                    {/* Assigned Rider Section */}
                    {order.assignment?.blitzerId ? (
                      <div className="rider-details">
                        <h4>Assigned Rider</h4>
                        <p>
                          <strong>Rider ID:</strong>{" "}
                          {order.assignment.blitzerId}
                        </p>
                        <p>
                          <strong>Name:</strong>{" "}
                          {riderInfo[order.assignment.blitzerId]?.name ||
                            "Loading..."}
                        </p>
                        <p>
                          <strong>Phone:</strong>{" "}
                          <a
                            href={`tel:${
                              riderInfo[order.assignment.blitzerId]?.phone
                            }`}
                            className="phone-link"
                          >
                            {riderInfo[order.assignment.blitzerId]?.phone ||
                              "Loading..."}
                          </a>
                        </p>
                        {order.assignment?.blitzerLocation && (
                          <div className="location-details">
                            <div className="location-header">
                              <p>
                                <strong>Current Location:</strong>
                              </p>
                              {editingLocation[order.id] ? (
                                <button
                                  className="save-location-button"
                                  onClick={() => handleSaveLocation(order.id)}
                                >
                                  Save Location
                                </button>
                              ) : (
                                <button
                                  className="edit-location-button"
                                  onClick={() => handleEditLocation(order.id)}
                                >
                                  Edit Location
                                </button>
                              )}
                            </div>
                            <p>
                              <strong>Latitude:</strong>{" "}
                              {editingLocation[order.id] ? (
                                <input
                                  type="number"
                                  step="0.000001"
                                  value={
                                    editedLocation[order.id]?.latitude || 0
                                  }
                                  onChange={(e) =>
                                    handleLocationChange(
                                      order.id,
                                      "latitude",
                                      e.target.value
                                    )
                                  }
                                  className="location-input"
                                />
                              ) : (
                                order.assignment.blitzerLocation.latitude.toFixed(
                                  6
                                )
                              )}
                            </p>
                            <p>
                              <strong>Longitude:</strong>{" "}
                              {editingLocation[order.id] ? (
                                <input
                                  type="number"
                                  step="0.000001"
                                  value={
                                    editedLocation[order.id]?.longitude || 0
                                  }
                                  onChange={(e) =>
                                    handleLocationChange(
                                      order.id,
                                      "longitude",
                                      e.target.value
                                    )
                                  }
                                  className="location-input"
                                />
                              ) : (
                                order.assignment.blitzerLocation.longitude.toFixed(
                                  6
                                )
                              )}
                            </p>
                            <a
                              href={`https://www.google.com/maps?q=${order.assignment.blitzerLocation.latitude},${order.assignment.blitzerLocation.longitude}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="view-map-link"
                            >
                              View on Map
                            </a>
                            {editingLocation[order.id] && (
                              <button
                                className="cancel-location-button"
                                onClick={() => {
                                  setEditingLocation((prev) => ({
                                    ...prev,
                                    [order.id]: false,
                                  }));
                                  setEditedLocation((prev) => ({
                                    ...prev,
                                    [order.id]: {
                                      ...order.assignment.blitzerLocation,
                                    },
                                  }));
                                }}
                              >
                                Cancel
                              </button>
                            )}
                          </div>
                        )}
                        <button
                          className="unassign-button"
                          onClick={() => {
                            if (
                              window.confirm(
                                "Are you sure you want to unassign this rider?"
                              )
                            ) {
                              handleUnassignRider(order.id);
                            }
                          }}
                        >
                          Unassign Rider
                        </button>
                      </div>
                    ) : (
                      <div className="rider-details no-rider">
                        <h4>Assigned Rider</h4>
                        <p className="no-rider-message">No rider assigned</p>
                      </div>
                    )}

                    {/* Riders and Pickup Distances Section */}
                    {order.ridersAndPickupDistances && (
                      <div className="potential-riders">
                        <button
                          className="profit-toggle w-full"
                          onClick={() =>
                            setShowNearbyRiders((prev) => ({
                              ...prev,
                              [order.id]: !prev[order.id],
                            }))
                          }
                        >
                          <h4>
                            Riders Near Pickup
                            <span className="toggle-icon">
                              {showNearbyRiders[order.id] ? "▼" : "▶"}
                            </span>
                          </h4>
                        </button>
                        {showNearbyRiders[order.id] && (
                          <div className="mt-2">
                            {order.ridersAndPickupDistances.map(
                              (riderData, index) => (
                                <div key={index} className="potential-rider">
                                  <p>
                                    <strong>Rider ID:</strong>{" "}
                                    {riderData.riderId}
                                  </p>
                                  <p>
                                    <strong>Name:</strong>{" "}
                                    {riderInfo[riderData.riderId]?.name ||
                                      "Loading..."}
                                  </p>
                                  <p>
                                    <strong>Phone:</strong>{" "}
                                    <a
                                      href={`tel:${
                                        riderInfo[riderData.riderId]?.phone
                                      }`}
                                      className="phone-link"
                                    >
                                      {riderInfo[riderData.riderId]?.phone ||
                                        "Loading..."}
                                    </a>
                                  </p>
                                  <p>
                                    <strong>Distance:</strong>{" "}
                                    {riderData.distance
                                      ? `${riderData.distance.toFixed(2)} miles`
                                      : "N/A"}
                                  </p>
                                </div>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    )}

                    {/* Unassigned Riders Section */}
                    {order.unassignedRiders &&
                      order.unassignedRiders.length > 0 && (
                        <div className="unassigned-riders">
                          <h4>Unassigned Riders</h4>
                          {order.unassignedRiders.map((riderId) => (
                            <div key={riderId} className="unassigned-rider">
                              <p>
                                <strong>Rider ID:</strong> {riderId}
                              </p>
                              <p>
                                <strong>Name:</strong>{" "}
                                {riderInfo[riderId]?.name || "Loading..."}
                              </p>
                              <p>
                                <strong>Phone:</strong>{" "}
                                <a
                                  href={`tel:${riderInfo[riderId]?.phone}`}
                                  className="phone-link"
                                >
                                  {riderInfo[riderId]?.phone || "Loading..."}
                                </a>
                              </p>
                            </div>
                          ))}
                        </div>
                      )}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ActiveOrders;
