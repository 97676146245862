import { createContext } from "react";
import { CollectionSpeed } from "../types/CollectionSpeed";
import { PackageType } from "../types/PackageType";
import { ParcelSize } from "../types/ParcelSize";
import { Location } from "../types/Location";

interface BookingContextType {
  packageType: PackageType | null;
  setPackageType: (packageType: PackageType) => void;
  specialInstructions?: string;
  setSpecialInstructions: (specialInstructions: string) => void;
  isInsured: boolean;
  setIsInsured: (isInsured: boolean) => void;
  clearPDC: () => void;
  senderNumber?: string;
  setSenderNumber: (senderNumber: string) => void;
  receiverNumber?: string;
  setReceiverNumber: (receiverNumber: string) => void;
  selectedSizes:
    | {
        [key in ParcelSize]: number;
      }
    | null;
  setSelectedSizes: (selectedSizes: {
    [key in ParcelSize]: number;
  }) => void;
  schedule: Schedule | null;
  setSchedule: (schedule: Schedule) => void;
  collectionSpeed: CollectionSpeed | null;
  setCollectionSpeed: (collectionSpeed: CollectionSpeed) => void;
  pickupLocation: Location | null;
  setPickupLocation: React.Dispatch<Location | null>;
  dropoffLocation: Location | null;
  setDropoffLocation: React.Dispatch<Location | null>;
}

const BookingContext = createContext<BookingContextType>({
  packageType: null,
  setPackageType: () => {},
  specialInstructions: "",
  setSpecialInstructions: () => {},
  isInsured: false,
  setIsInsured: () => {},
  clearPDC: () => {},
  senderNumber: "",
  setSenderNumber: () => {},
  receiverNumber: "",
  setReceiverNumber: () => {},
  selectedSizes: null,
  setSelectedSizes: () => {},
  schedule: null,
  setSchedule: () => {},
  collectionSpeed: null,
  setCollectionSpeed: () => {},
  pickupLocation: null,
  dropoffLocation: null,
  setPickupLocation: () => {},
  setDropoffLocation: () => {},
});

export default BookingContext;
