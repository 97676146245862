import React, { useEffect } from "react";
import JoinBlitzo from "../components/JoinBlitzo";
import DarkNavbar from "../components/DarkNavbar";
import Footer from "../components/footer";

const WorkWithUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-[#080A12]">
      <DarkNavbar />
      <JoinBlitzo />
      <Footer />
    </div>
  );
};

export default WorkWithUs;
