import React, { useState, useEffect } from "react";
import { Menu, X, ArrowUpRight, ChevronDown, Users, User } from "lucide-react";
import { getStoreLink } from "../utils/deviceDetection";
import logoImage from "../Images/logo.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import OperatorButton from "./OperatorButton";
import AthenaButton from "./AthenaButton";

const DarkNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentRoute, setCurrentRoute] = useState(window.location.pathname);
  const [isSolutionsOpen, setIsSolutionsOpen] = useState(false);
  const [isCompanyOpen, setIsCompanyOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [viewMode, setViewMode] = useState("business"); // "business" or "individual"
  const navigate = useNavigate();

  const handleBookNow = () => {
    navigate("/business/book");
  };

  const toggleViewMode = () => {
    setViewMode(viewMode === "business" ? "individual" : "business");
  };

  useEffect(() => {
    const handleRouteChange = () => {
      setCurrentRoute(window.location.pathname);
    };

    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener("popstate", handleRouteChange);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("popstate", handleRouteChange);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const linkClasses = (path) =>
    `text-gray-300 hover:text-[#E54C4C] transition-colors text-sm font-medium ${
      currentRoute === path ? "text-[#E54C4C]" : ""
    }`;

  // Fixed mobile menu classes - removed top-20 that was causing positioning issues
  const mobileMenuClasses = `
    md:hidden fixed w-full left-0 right-0 top-20 z-50 
    bg-[#192032] border-t border-[#2A3143] shadow-lg
    transform transition-all duration-300 ease-in-out
    ${
      isMenuOpen
        ? "translate-y-0 opacity-100"
        : "-translate-y-2 opacity-0 pointer-events-none"
    }
  `;

  return (
    <nav
      className={`fixed w-full backdrop-blur-lg z-50 transition-all ${
        isScrolled
          ? "bg-[#0E1420]/95 border-b border-[#2A3143]"
          : "bg-transparent"
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-20">
          <div className="flex items-center">
            <a href="/" className="flex items-center">
              <img src={logoImage} alt="Blitzo" className="h-10 w-auto" />
              <span className="text-3xl font-semibold text-white ml-3">
                Blitzo
              </span>
            </a>
          </div>
          <div className="hidden md:flex items-center space-x-8">
            {/* Solutions Dropdown */}
            <div className="relative group">
              <button className="flex items-center text-gray-300 hover:text-[#E54C4C] transition-colors text-sm font-medium">
                Solutions <ChevronDown className="ml-1" size={16} />
              </button>
              <div className="absolute invisible group-hover:visible hover:visible top-[calc(100%-10px)] left-0 pt-[10px] w-48">
                <div className="bg-[#192032] rounded-lg shadow-lg py-2 border border-[#2A3143]">
                  <Link
                    to="/doorstep-interactions"
                    className="block px-4 py-2 text-gray-300 hover:text-[#E54C4C] hover:bg-[#1C2438]"
                  >
                    Doorstep Interactions
                  </Link>
                  <Link
                    to="/retail"
                    className="block px-4 py-2 text-gray-300 hover:text-[#E54C4C] hover:bg-[#1C2438]"
                  >
                    High Value Retail
                  </Link>
                  <Link
                    to="/local-business"
                    className="block px-4 py-2 text-gray-300 hover:text-[#E54C4C] hover:bg-[#1C2438]"
                  >
                    Local Business
                  </Link>
                  <Link
                    to="/milk-runs"
                    className="block px-4 py-2 text-gray-300 hover:text-[#E54C4C] hover:bg-[#1C2438]"
                  >
                    Milk Rounds
                  </Link>
                  <Link
                    to="/fashion"
                    className="block px-4 py-2 text-gray-300 hover:text-[#E54C4C] hover:bg-[#1C2438]"
                  >
                    For Fashion
                  </Link>
                </div>
              </div>
            </div>
            <a href="/how-we-work" className={linkClasses("/how-we-work")}>
              How we work
            </a>
            <a href="/blog" className={linkClasses("/blog")}>
              Blog
            </a>

            {/* Ride for Blitzo */}
            <a href="/rider" className={linkClasses("/rider")}>
              Ride for Blitzo
            </a>

            {/* Company Dropdown */}
            <div className="relative group">
              <button className="flex items-center text-gray-300 hover:text-[#E54C4C] transition-colors text-sm font-medium">
                Company <ChevronDown className="ml-1" size={16} />
              </button>
              <div className="absolute invisible group-hover:visible hover:visible top-[calc(100%-10px)] left-0 pt-[10px] w-48">
                <div className="bg-[#192032] rounded-lg shadow-lg py-2 border border-[#2A3143]">
                  <Link
                    to="/about"
                    className="block px-4 py-2 text-gray-300 hover:text-[#E54C4C] hover:bg-[#1C2438]"
                  >
                    About
                  </Link>
                  <Link
                    to="/contact"
                    className="block px-4 py-2 text-gray-300 hover:text-[#E54C4C] hover:bg-[#1C2438]"
                  >
                    Contact
                  </Link>
                  <Link
                    to="/careers"
                    className="block px-4 py-2 text-gray-300 hover:text-[#E54C4C] hover:bg-[#1C2438]"
                  >
                    Careers
                  </Link>
                </div>
              </div>
            </div>

            <div className="flex items-center space-x-6">
              {/* AI Assistant Buttons with better spacing */}
              <div className="flex items-center space-x-4">
                <OperatorButton onNavigate={navigate} />
                <AthenaButton onNavigate={navigate} />
              </div>

              <button
                onClick={handleBookNow}
                className="bg-[#E54C4C] text-white px-6 py-2 rounded-lg hover:bg-[#E54C4C]/90 transition-all shadow-lg shadow-[#E54C4C]/10 flex items-center text-sm font-medium"
              >
                Book Now <ArrowUpRight className="ml-2" size={16} />
              </button>
            </div>
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-gray-300"
            >
              {isMenuOpen ? <X size={28} /> : <Menu size={28} />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu - Fixed positioning */}
      <div className={mobileMenuClasses}>
        <div className="px-4 py-6 space-y-6 max-h-[calc(100vh-5rem)] overflow-y-auto">
          <div className="block">
            <button
              onClick={() => setIsSolutionsOpen(!isSolutionsOpen)}
              className="w-full text-left py-3 text-lg text-gray-300 hover:text-[#E54C4C] flex items-center justify-between"
            >
              <span>Solutions</span>{" "}
              <ChevronDown
                className={`ml-1 transition-transform ${
                  isSolutionsOpen ? "rotate-180" : ""
                }`}
                size={16}
              />
            </button>
            {isSolutionsOpen && (
              <div className="pl-4 mt-2 border-l border-[#2A3143] space-y-3">
                <Link
                  to="/doorstep-interactions"
                  className="block py-2 text-base text-gray-300 hover:text-[#E54C4C]"
                >
                  Doorstep Interactions
                </Link>
                <Link
                  to="/retail"
                  className="block py-2 text-base text-gray-300 hover:text-[#E54C4C]"
                >
                  High Value Retail
                </Link>
                <Link
                  to="/local-business"
                  className="block py-2 text-base text-gray-300 hover:text-[#E54C4C]"
                >
                  Local Business
                </Link>
                <Link
                  to="/milk-runs"
                  className="block py-2 text-base text-gray-300 hover:text-[#E54C4C]"
                >
                  Milk Rounds
                </Link>
                <Link
                  to="/fashion"
                  className="block py-2 text-base text-gray-300 hover:text-[#E54C4C]"
                >
                  For Fashion
                </Link>
              </div>
            )}
          </div>

          <a
            href="/how-we-work"
            className="block py-3 text-lg text-gray-300 hover:text-[#E54C4C]"
          >
            How we work
          </a>

          <a
            href="/blog"
            className="block py-3 text-lg text-gray-300 hover:text-[#E54C4C]"
          >
            Blog
          </a>

          {/* Ride for Blitzo in Mobile */}
          <a
            href="/rider"
            className="block py-3 text-lg text-gray-300 hover:text-[#E54C4C]"
          >
            Ride for Blitzo
          </a>

          {/* Company Dropdown in Mobile */}
          <div className="block">
            <button
              onClick={() => setIsCompanyOpen(!isCompanyOpen)}
              className="w-full text-left py-3 text-lg text-gray-300 hover:text-[#E54C4C] flex items-center justify-between"
            >
              <span>Company</span>{" "}
              <ChevronDown
                className={`ml-1 transition-transform ${
                  isCompanyOpen ? "rotate-180" : ""
                }`}
                size={16}
              />
            </button>
            {isCompanyOpen && (
              <div className="pl-4 mt-2 border-l border-[#2A3143] space-y-3">
                <Link
                  to="/about"
                  className="block py-2 text-base text-gray-300 hover:text-[#E54C4C]"
                >
                  About
                </Link>
                <Link
                  to="/contact"
                  className="block py-2 text-base text-gray-300 hover:text-[#E54C4C]"
                >
                  Contact
                </Link>
                <Link
                  to="/careers"
                  className="block py-2 text-base text-gray-300 hover:text-[#E54C4C]"
                >
                  Careers
                </Link>
              </div>
            )}
          </div>

          {/* View Mode Toggle for Mobile */}
          <div className="space-y-4 py-3">
            <div>
              <OperatorButton onNavigate={navigate} />
            </div>

            <div>
              <AthenaButton onNavigate={navigate} />
            </div>
          </div>

          <button
            onClick={handleBookNow}
            className="w-full text-center px-4 py-3 bg-[#E54C4C] text-white rounded-lg text-lg font-medium flex items-center justify-center"
          >
            Book Now <ArrowUpRight className="ml-2" size={18} />
          </button>
        </div>
      </div>
    </nav>
  );
};

export default DarkNavbar;
