import React from "react";
import { Phone, Mail, Clock, MessageCircle } from "lucide-react";
import BusinessNavbar from "../../components/BusinessNavbar";

const SupportPage: React.FC = () => {
  const handleCall = () => {
    window.location.href = "tel:+447553848371";
  };

  const handleEmail = () => {
    window.location.href = "mailto:support@blitzo.co.uk";
  };

  return (
    <>
      <BusinessNavbar />
      <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-900 to-gray-800 text-white pt-16 pb-10">
        <div className="max-w-4xl mx-auto px-4">
          {/* Header Section */}
          <div className="text-center py-16">
            <h1 className="text-4xl font-bold bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent mb-4">
              Need Help? We're Here!
            </h1>
            <p className="text-xl text-gray-400">
              Have a question or need assistance? We're just a message away.
            </p>
          </div>

          {/* Contact Options */}
          <div className="grid md:grid-cols-2 gap-6">
            {/* Call Option */}
            <div className="bg-gray-800/50 border border-gray-700 rounded-2xl p-8 hover:bg-gray-800/70 transition-all">
              <div className="flex items-center gap-4 mb-6">
                <div className="p-4 rounded-xl bg-blue-500/10 border border-blue-500/20">
                  <Phone className="text-blue-400" size={24} />
                </div>
                <div>
                  <h2 className="text-xl font-semibold">Give Us a Call</h2>
                  <p className="text-gray-400">We're a friendly bunch!</p>
                </div>
              </div>
              <button
                onClick={handleCall}
                className="w-full py-4 px-6 bg-blue-500 hover:bg-blue-600 rounded-xl font-medium 
                  transition-colors flex items-center justify-center gap-2"
              >
                <Phone size={20} />
                Call the Blitzo Team
              </button>
              <div className="mt-4 flex items-center gap-2 text-sm text-gray-400">
                <Clock size={16} />
                <span>Available 24/7</span>
              </div>
            </div>

            {/* Email Option */}
            <div className="bg-gray-800/50 border border-gray-700 rounded-2xl p-8 hover:bg-gray-800/70 transition-all">
              <div className="flex items-center gap-4 mb-6">
                <div className="p-4 rounded-xl bg-purple-500/10 border border-purple-500/20">
                  <Mail className="text-purple-400" size={24} />
                </div>
                <div>
                  <h2 className="text-xl font-semibold">Send Us an Email</h2>
                  <p className="text-gray-400">We'll respond quickly!</p>
                </div>
              </div>
              <button
                onClick={handleEmail}
                className="w-full py-4 px-6 bg-purple-500 hover:bg-purple-600 rounded-xl font-medium 
                  transition-colors flex items-center justify-center gap-2"
              >
                <Mail size={20} />
                Email Support Team
              </button>
              <div className="mt-4 flex items-center gap-2 text-sm text-gray-400">
                <MessageCircle size={16} />
                <span>Average response time: 15 minutes</span>
              </div>
            </div>
          </div>

          {/* Additional Info */}
          <div className="mt-12 text-center">
            <p className="text-gray-400">
              No matter how big or small your question is, we're here to help
              you get the most out of Blitzo.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportPage;
