import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Building2, Package, Briefcase, ArrowRight } from "lucide-react";
import { storeOnboardingInfo } from "../../firebase/user";

const BusinessOnboarding = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    businessName: "",
    businessSector: "",
    deliveryVolume: "",
  });

  const businessSectors = [
    "E-commerce",
    "Retail",
    "Restaurant",
    "Grocery",
    "Beauty & Cosmetics",
    "Health & Wellness",
    "Electronics",
    "Fashion & Apparel",
    "Flowers & Gifts",
    "Other",
  ];

  const deliveryVolumes = [
    { label: "Less than 10 deliveries", value: "<10" },
    { label: "10-50 deliveries", value: "10-50" },
    { label: "50-100 deliveries", value: "50-100" },
    { label: "100-500 deliveries", value: "100-500" },
    { label: "500+ deliveries", value: "500+" },
  ];

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const validateForm = () => {
    if (
      !formData.businessName ||
      !formData.deliveryVolume ||
      !formData.businessSector
    ) {
      setError("All fields are required");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      await storeOnboardingInfo(
        formData.businessName,
        formData.businessSector,
        formData.deliveryVolume
      );
      navigate("/business/book");
    } catch (err: any) {
      setError(err.message || "Failed to save business information");
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 flex items-center justify-center p-8">
      <div className="w-full max-w-md space-y-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-white">
            Tell us about your business
          </h2>
          <p className="mt-2 text-gray-400">
            Help us customize your experience
          </p>
        </div>

        <form onSubmit={handleSubmit} className="mt-8 space-y-6">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-400 mb-2">
                Business Name
              </label>
              <div className="relative">
                <Building2
                  className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400"
                  size={20}
                />
                <input
                  type="text"
                  name="businessName"
                  value={formData.businessName}
                  onChange={handleChange}
                  className="w-full pl-12 pr-4 py-3 bg-gray-800/50 border border-gray-700 rounded-xl
                    text-white placeholder-gray-400 focus:outline-none focus:border-red-500
                    transition-colors"
                  placeholder="Enter your business name"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-2">
                Business Sector
              </label>
              <div className="relative">
                <Briefcase
                  className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400"
                  size={20}
                />
                <select
                  name="businessSector"
                  value={formData.businessSector}
                  onChange={handleChange}
                  className="w-full pl-12 pr-4 py-3 bg-gray-800/50 border border-gray-700 rounded-xl
                    text-white placeholder-gray-400 focus:outline-none focus:border-red-500
                    transition-colors appearance-none"
                >
                  <option value="">Select your business sector</option>
                  {businessSectors.map((sector) => (
                    <option key={sector} value={sector}>
                      {sector}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-400 mb-2">
                Monthly Delivery Volume
              </label>
              <div className="relative">
                <Package
                  className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400"
                  size={20}
                />
                <select
                  name="deliveryVolume"
                  value={formData.deliveryVolume}
                  onChange={handleChange}
                  className="w-full pl-12 pr-4 py-3 bg-gray-800/50 border border-gray-700 rounded-xl
                    text-white placeholder-gray-400 focus:outline-none focus:border-red-500
                    transition-colors appearance-none"
                >
                  <option value="">Select your delivery volume</option>
                  {deliveryVolumes.map((volume) => (
                    <option key={volume.value} value={volume.value}>
                      {volume.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {error && <p className="text-red-500 text-sm">{error}</p>}

          <button
            type="submit"
            className="w-full flex items-center justify-center py-3 px-4 border border-transparent 
              rounded-xl bg-red-500 hover:bg-red-600 text-white font-medium transition-colors"
          >
            <span>Complete Setup</span>
            <ArrowRight className="ml-2" size={20} />
          </button>
        </form>
      </div>
    </div>
  );
};

export default BusinessOnboarding;
