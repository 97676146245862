import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { ChevronRight } from "lucide-react";
import AOS from "aos";
import "aos/dist/aos.css";
import styled from "styled-components";
import SpeakToSalesModal from "./SpeakToSalesModal";
import SalesChannelsImg from "../Images/SalesChannels.png";
import Orchestration from "../Images/Orchestration.png";
import Dispatch from "../Images/Dispatch.png";
import ZeroFailure from "../Images/ZeroFailure.png";

// Reusable gradient text for main headings
const GradientText = styled.span`
  background: linear-gradient(90deg, #e54c4c 0%, #ff6b6b 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
`;

// Reusable gradient text for subheadings (we'll alternate colors below)
const GradientSubheading = styled.span<{ gradientClass: string }>`
  display: inline-block;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
`;

// Step data with more succinct copy
const stepsData = [
  {
    title: "Auto-Fill Bookings",
    subheading: "No Re-Typing, No Delays",
    description: [
      "Blitzo syncs orders from any channel—Shopify, Instagram, WhatsApp, calls—no manual re-entry.",
      "We confirm addresses and special instructions (e.g., 'Leave with Concierge') before sending them to riders.",
    ],
    img: SalesChannelsImg,
  },
  {
    title: "Real-Time AI Orchestration",
    subheading: "No Mistakes, No Guesswork",
    description: [
      "Blitzo Intelligence verifies addresses, load requirements, and urgency instantly.",
      "We fix mismatches (like missing apartment numbers) before dispatch—fewer delays, zero confusion.",
    ],
    img: Orchestration,
  },
  {
    title: "Rider Dispatch & Tracking",
    subheading: "1000+ Trained Riders, Always On Time",
    description: [
      "We auto-assign the best rider, factoring in traffic, weather, and more.",
      "Customers get a co-branded tracking link—no anxious waiting, full transparency.",
    ],
    img: Dispatch,
  },
  {
    title: "Zero-Failure Delivery",
    subheading: "Same-Hour Promise, AI Agent's Managing Every Detail",
    description: [
      "If delays arise, we re-route on the fly—maintaining a 99%+ on-time rate. Blitzo Intelligence keeps customers informed via text/call.",
      "Proof-of-delivery photos confirm items arrived exactly where they should.",
    ],
    img: ZeroFailure,
  },
];

// Define some alternating gradients for subheadings
const subheadingGradients = [
  "bg-gradient-to-r from-[#E54C4C] to-[#FF6B6B]",
  "bg-gradient-to-r from-[#4ECAFC] to-[#6A8CFF]",
  "bg-gradient-to-r from-[#E54C4C] to-[#FF6B6B]",
  "bg-gradient-to-r from-[#4ECAFC] to-[#6A8CFF]",
];

const HowItWorks = () => {
  const { ref, inView } = useInView({ threshold: 0.1, triggerOnce: true });
  const [showSalesModal, setShowSalesModal] = React.useState(false);

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <section
      ref={ref}
      className="relative w-full py-24 bg-[#080A12] overflow-hidden"
      id="how-it-works"
    >
      <SpeakToSalesModal
        isOpen={showSalesModal}
        onClose={() => setShowSalesModal(false)}
      />
      {/* Subtle background grid & orbs */}
      <div className="absolute inset-0 z-0 opacity-5">
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `
              linear-gradient(to right, rgba(255,255,255,0.05) 1px, transparent 1px),
              linear-gradient(to bottom, rgba(255,255,255,0.05) 1px, transparent 1px)
            `,
            backgroundSize: "40px 40px",
          }}
        ></div>
      </div>
      {/* Glowing orbs */}
      <div className="absolute top-0 left-0 w-64 h-64 rounded-full bg-[#4ECAFC]/10 blur-3xl opacity-30 animate-pulse-slow"></div>
      <div className="absolute bottom-0 right-0 w-64 h-64 rounded-full bg-[#E54C4C]/10 blur-3xl opacity-30 animate-pulse-slow-delay"></div>

      {/* Floating particles */}
      <div className="absolute inset-0 pointer-events-none z-0">
        {[...Array(12)].map((_, i) => (
          <div
            key={i}
            className="absolute w-1 h-1 bg-white rounded-full opacity-70"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animation: `float ${
                2 + Math.random() * 4
              }s infinite ease-in-out ${Math.random() * 2}s`,
            }}
          />
        ))}
      </div>

      <div className="relative max-w-7xl mx-auto px-6 sm:px-8 z-10">
        {/* Section Heading */}
        <div className="text-center mb-16" data-aos="fade-up">
          <h2 className="text-3xl md:text-5xl font-bold text-white mb-4 leading-tight">
            From Your Store to Their Door:
            <br />
            How <GradientText>Blitzo</GradientText> Works
          </h2>
          <p className="text-gray-300 text-base md:text-lg max-w-2xl mx-auto">
            Each delivery has many steps. Blitzo Intelligence automates them
            all—so you can focus on what truly matters:{" "}
            <GradientText>serving your customers</GradientText>.
          </p>
        </div>

        {/* Steps */}
        <div className="flex flex-col space-y-0">
          {stepsData.map((step, index) => {
            // Alternate the layout: image left on even, right on odd
            const isEven = index % 2 === 0;
            return (
              <div
                key={index}
                className={`flex flex-col-reverse md:flex-row items-center md:justify-between gap-8 ${
                  !isEven ? "md:flex-row-reverse" : ""
                }`}
                data-aos="fade-up"
                data-aos-delay={100 * index}
              >
                {/* Text content */}
                <div className="w-full md:w-1/2">
                  <h3 className="text-2xl md:text-3xl font-bold text-white mb-2">
                    {step.title}
                  </h3>
                  <h4 className="mb-4 text-xl font-semibold">
                    <GradientSubheading
                      gradientClass={subheadingGradients[index]}
                      className={`${subheadingGradients[index]} bg-clip-text`}
                    >
                      {step.subheading}
                    </GradientSubheading>
                  </h4>
                  <ul className="list-outside space-y-2">
                    {step.description.map((desc, i) => (
                      <li
                        key={i}
                        className="text-gray-300 leading-relaxed text-lg"
                      >
                        {desc}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="w-full md:w-1/2 flex justify-center items-center">
                  {step.img ? (
                    <img
                      src={step.img}
                      alt={step.title}
                      className="w-full max-w-[400px] h-auto object-contain transform hover:scale-105 transition-transform duration-700"
                    />
                  ) : (
                    <div className="text-gray-500 text-center">
                      <div className="text-4xl mb-2 bg-gradient-to-r from-[#E54C4C] to-[#FF6B6B] bg-clip-text text-transparent">
                        Step {index + 1}
                      </div>
                      <div className="text-sm text-gray-400">{step.title}</div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>

        {/* CTA Section */}
        <div
          className="mt-20 bg-[#0F121B]/60 border border-[#262D40] rounded-xl shadow-xl p-8 flex flex-col md:flex-row items-center md:justify-between"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          <div className="mb-6 md:mb-0 md:mr-8">
            <h3 className="text-2xl font-bold text-white mb-2">
              Ready to Offer Same-Hour Delivery?
            </h3>
            <p className="text-gray-400 max-w-md">
              Let Blitzo handle every detail—from auto-filling bookings to final
              proof of delivery—so you can focus on growth and customer
              satisfaction.
            </p>
          </div>
          <button
            className="flex items-center bg-[#E54C4C] text-white px-6 py-3 rounded-full font-medium hover:bg-[#E54C4C]/90 transition-all shadow-lg shadow-[#E54C4C]/20"
            onClick={() => setShowSalesModal(true)}
          >
            Get Started
            <ChevronRight className="ml-2" size={18} />
          </button>
        </div>
      </div>

      {/* Extra Animations */}
      <style jsx>{`
        @keyframes float {
          0%,
          100% {
            transform: translateY(0) translateX(0);
          }
          50% {
            transform: translateY(-10px) translateX(5px);
          }
        }

        @keyframes pulse-slow {
          0%,
          100% {
            transform: scale(1);
            opacity: 0.3;
          }
          50% {
            transform: scale(1.1);
            opacity: 0.5;
          }
        }
        @keyframes pulse-slow-delay {
          0%,
          100% {
            transform: scale(1);
            opacity: 0.3;
          }
          50% {
            transform: scale(1.15);
            opacity: 0.6;
          }
        }
        .animate-pulse-slow {
          animation: pulse-slow 15s infinite ease-in-out;
        }
        .animate-pulse-slow-delay {
          animation: pulse-slow-delay 20s infinite ease-in-out 2s;
        }
      `}</style>
    </section>
  );
};

export default HowItWorks;
