import React from "react";
import { Zap, ArrowRight } from "lucide-react";

const AthenaButton = ({ onNavigate }) => {
  const handleClick = () => {
    if (onNavigate) {
      onNavigate("/athena");
    }
  };

  return (
    <button
      onClick={handleClick}
      className="group flex items-center space-x-2 bg-gradient-to-r from-[#4ECAFC]/10 to-[#6C63FF]/10 px-4 py-2 rounded-lg border border-[#2A3143] hover:border-[#4ECAFC]/50 transition-all duration-300"
    >
      <div className="flex items-center space-x-1.5">
        <div className="rounded-full p-1 bg-[#4ECAFC]/20 text-[#4ECAFC]">
          <Zap size={14} />
        </div>
        <span className="text-sm font-medium text-white">Last Mile AI</span>
      </div>
      <ArrowRight
        size={14}
        className="text-gray-400 group-hover:text-[#4ECAFC] group-hover:translate-x-0.5 transition-all duration-300"
      />
    </button>
  );
};

export default AthenaButton;
