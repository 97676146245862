import React, { useState, useEffect } from "react";
import { X, ArrowRight, CheckCircle } from "lucide-react";
import Illustration from "../Images/3D-Enterprise.png";
import { db } from "../Firebase/firebase-config";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

const SpeakToSalesModal = ({ isOpen, onClose }) => {
  // Simple form data
  const [formData, setFormData] = useState({
    email: "",
    company: "",
    contactNumber: "",
    industry: "",
    deliveriesPerMonth: "",
    message: "",
  });

  // Form submission state
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);

  // Animation states
  const [isAnimatingIn, setIsAnimatingIn] = useState(false);
  const [isAnimatingOut, setIsAnimatingOut] = useState(false);

  // Handle animation on open/close
  useEffect(() => {
    if (isOpen) {
      setIsAnimatingIn(true);
      setIsAnimatingOut(false);
      // Reset form and status on open
      setFormData({
        email: "",
        company: "",
        contactNumber: "",
        industry: "",
        deliveriesPerMonth: "",
        message: "",
      });
      setSubmitSuccess(false);
      setSubmitError(null);
    }
  }, [isOpen]);

  // Handle modal close with animation
  const handleClose = () => {
    setIsAnimatingIn(false);
    setIsAnimatingOut(true);

    // Wait for animation to complete before actually closing
    setTimeout(() => {
      onClose?.();
    }, 300); // Match with CSS transition duration
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitError(null);

    try {
      // Add the form data to the salesEnquiries collection in Firestore
      const salesEnquiriesRef = collection(db, "salesEnquiries");

      await addDoc(salesEnquiriesRef, {
        ...formData,
        timestamp: serverTimestamp(),
        status: "new", // Optional: to track status of the enquiry
      });

      // Set success and reset form
      setSubmitSuccess(true);
      console.log("Sales enquiry submitted successfully");

      // Close modal after delay
      setTimeout(() => {
        handleClose();
      }, 2000);
    } catch (error) {
      console.error("Error submitting sales enquiry:", error);
      setSubmitError(
        "There was an error submitting your enquiry. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle backdrop click
  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div
      className={`fixed inset-0 z-50 bg-black/70 flex items-center justify-center p-4 transition-opacity duration-300 ${
        isAnimatingIn ? "opacity-100" : "opacity-0"
      } ${isAnimatingOut ? "opacity-0" : ""}`}
      onClick={handleBackdropClick}
    >
      {/* Modal Container */}
      <div
        className={`relative bg-[#0F121B] text-white rounded-xl shadow-2xl w-full max-w-4xl mx-auto flex flex-col md:flex-row overflow-hidden max-h-[90vh] transition-all duration-300 ${
          isAnimatingIn ? "scale-100 translate-y-0" : "scale-95 translate-y-4"
        } ${isAnimatingOut ? "scale-95 translate-y-4" : ""}`}
        onClick={(e) => e.stopPropagation()} // Prevent clicks inside modal from closing it
      >
        {/* Left Column: Form */}
        <div className="w-full md:w-3/5 p-5 sm:p-6 relative overflow-y-auto">
          <h2 className="text-xl font-bold mb-4">Speak to Us</h2>
          <p className="text-sm text-gray-400 mb-6">
            Discover how our same-hour delivery and zero operational overhead
            can transform your business.
          </p>

          {submitSuccess ? (
            <div className="flex flex-col items-center justify-center py-10">
              <div className="w-14 h-14 bg-[#E54C4C]/10 rounded-full flex items-center justify-center mb-4">
                <CheckCircle className="text-[#E54C4C]" size={28} />
              </div>
              <h3 className="text-xl font-semibold text-white mb-2">
                Thank You!
              </h3>
              <p className="text-gray-300 text-center max-w-sm">
                Your enquiry has been received. Our team will get back to you
                shortly.
              </p>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mb-3">
                {/* Email */}
                <div>
                  <label
                    className="block text-xs font-medium mb-1"
                    htmlFor="email"
                  >
                    Email Address*
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full bg-[#080A12] border border-[#262D40] rounded-md px-2.5 py-1.5 text-white text-sm placeholder-gray-500 focus:outline-none focus:border-[#4ECAFC]"
                    placeholder="name@company.com"
                    required
                    disabled={isSubmitting}
                  />
                </div>

                {/* Company */}
                <div>
                  <label
                    className="block text-xs font-medium mb-1"
                    htmlFor="company"
                  >
                    Company
                  </label>
                  <input
                    id="company"
                    name="company"
                    type="text"
                    value={formData.company}
                    onChange={handleChange}
                    className="w-full bg-[#080A12] border border-[#262D40] rounded-md px-2.5 py-1.5 text-white text-sm placeholder-gray-500 focus:outline-none focus:border-[#4ECAFC]"
                    placeholder="e.g. ACME Corp"
                    disabled={isSubmitting}
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mb-3">
                {/* Contact Number */}
                <div>
                  <label
                    className="block text-xs font-medium mb-1"
                    htmlFor="contactNumber"
                  >
                    Contact Number
                  </label>
                  <input
                    id="contactNumber"
                    name="contactNumber"
                    type="text"
                    value={formData.contactNumber}
                    onChange={handleChange}
                    className="w-full bg-[#080A12] border border-[#262D40] rounded-md px-2.5 py-1.5 text-white text-sm placeholder-gray-500 focus:outline-none focus:border-[#4ECAFC]"
                    placeholder="e.g. +44 7123 456789"
                    disabled={isSubmitting}
                  />
                </div>

                {/* Industry */}
                <div>
                  <label
                    className="block text-xs font-medium mb-1"
                    htmlFor="industry"
                  >
                    Industry
                  </label>
                  <input
                    id="industry"
                    name="industry"
                    type="text"
                    value={formData.industry}
                    onChange={handleChange}
                    className="w-full bg-[#080A12] border border-[#262D40] rounded-md px-2.5 py-1.5 text-white text-sm placeholder-gray-500 focus:outline-none focus:border-[#4ECAFC]"
                    placeholder="e.g. E-Commerce"
                    disabled={isSubmitting}
                  />
                </div>
              </div>

              {/* Deliveries Per Month */}
              <div className="mb-3">
                <label
                  className="block text-xs font-medium mb-1"
                  htmlFor="deliveriesPerMonth"
                >
                  Est. Deliveries Per Month
                </label>
                <input
                  id="deliveriesPerMonth"
                  name="deliveriesPerMonth"
                  type="text"
                  value={formData.deliveriesPerMonth}
                  onChange={handleChange}
                  className="w-full bg-[#080A12] border border-[#262D40] rounded-md px-2.5 py-1.5 text-white text-sm placeholder-gray-500 focus:outline-none focus:border-[#4ECAFC]"
                  placeholder="e.g. 500"
                  disabled={isSubmitting}
                />
              </div>

              {/* Message */}
              <div className="mb-3">
                <label
                  className="block text-xs font-medium mb-1"
                  htmlFor="message"
                >
                  Message (Optional)
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows={2}
                  value={formData.message}
                  onChange={handleChange}
                  className="w-full bg-[#080A12] border border-[#262D40] rounded-md px-2.5 py-1.5 text-white text-sm placeholder-gray-500 focus:outline-none focus:border-[#4ECAFC]"
                  placeholder="Anything else you'd like us to know?"
                  disabled={isSubmitting}
                />
              </div>

              {/* Error message */}
              {submitError && (
                <div className="text-red-500 text-sm mb-4">{submitError}</div>
              )}

              <button
                type="submit"
                className={`mt-4 w-full py-2 bg-gradient-to-r from-[#E54C4C] to-[#FF6B6B] text-white rounded-md font-medium transition-all duration-300 shadow-lg shadow-[#E54C4C]/20 flex items-center justify-center
                ${
                  isSubmitting
                    ? "opacity-70 cursor-not-allowed"
                    : "hover:from-[#FF6B6B] hover:to-[#E54C4C]"
                }`}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Submitting...
                  </>
                ) : (
                  <>
                    Submit
                    <ArrowRight size={16} className="ml-2" />
                  </>
                )}
              </button>
            </form>
          )}
        </div>

        {/* Right Column: 3D Enterprise Illustration */}
        <div className="hidden md:flex md:w-2/5 bg-gradient-to-br from-[#0A0D16] to-[#111520] flex-col justify-center items-center p-6">
          {/* Close Button */}
          <button
            onClick={handleClose}
            className="absolute top-3 right-3 text-gray-400 hover:text-white transition-colors"
          >
            <X size={18} />
          </button>
          <div className="max-w-xs mx-auto">
            <img
              src={Illustration}
              alt="Enterprise-Level 3D Graphic"
              className="w-full h-auto mb-4 max-h-48 object-contain"
            />
            <h3 className="text-xl font-bold mb-3 text-left">
              Unlock Same-Hour Delivery
            </h3>
            <p className="text-gray-400 text-sm text-left leading-relaxed">
              Harness zero operational overhead while delivering in under 60
              minutes. Our AI Agents manage the manual tasks so you can focus on
              building your business.
            </p>
            {/* Quick bullet points */}
            <div className="mt-4 pt-3 border-t border-[#262D40] space-y-2">
              <div className="flex items-center justify-left">
                <div className="w-1.5 h-1.5 rounded-full bg-[#E54C4C] mr-2"></div>
                <span className="text-s text-gray-300">
                  Instant Fulfillment
                </span>
              </div>
              <div className="flex items-center justify-left">
                <div className="w-1.5 h-1.5 rounded-full bg-[#E54C4C] mr-2"></div>
                <span className="text-s text-gray-300">Real-Time Tracking</span>
              </div>
              <div className="flex items-center justify-left">
                <div className="w-1.5 h-1.5 rounded-full bg-[#E54C4C] mr-2"></div>
                <span className="text-s text-gray-300">
                  Full-Service Automation
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpeakToSalesModal;
