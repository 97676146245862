// In utils/cache/location.ts

import { Location } from "../../types/Location";

const RECENT_LOCATIONS_KEY = "recent_locations";
const MAX_RECENT_LOCATIONS = 4;

export const addRecentLocationToCache = (location: Location) => {
  try {
    const existingLocations = getRecentLocationsFromCache();

    // Add new location to the beginning
    const newLocations = [location, ...existingLocations].slice(
      0,
      MAX_RECENT_LOCATIONS
    );

    localStorage.setItem(RECENT_LOCATIONS_KEY, JSON.stringify(newLocations));
  } catch (error) {
    console.error("Error saving recent location:", error);
  }
};

export const getRecentLocationsFromCache = (): Location[] => {
  try {
    const locations = localStorage.getItem(RECENT_LOCATIONS_KEY);
    return locations ? JSON.parse(locations) : [];
  } catch (error) {
    console.error("Error getting recent locations:", error);
    return [];
  }
};

export const clearRecentLocationsCache = () => {
  localStorage.removeItem(RECENT_LOCATIONS_KEY);
};

export const removeRecentLocationFromCache = (location: Location) => {
  try {
    const existingLocations = getRecentLocationsFromCache();
    const newLocations = existingLocations.filter(
      (loc) => loc.latitude !== location.latitude
    );

    localStorage.setItem(RECENT_LOCATIONS_KEY, JSON.stringify(newLocations));
  } catch (error) {
    console.error("Error removing recent location:", error);
  }
};
