import React, { useEffect } from "react";
import {
  ArrowUpRight,
  ArrowRight,
  Check,
  Truck,
  Phone,
  MessageSquare,
  Calendar,
  FileCheck,
  Clock,
  Shield,
  Star,
  Globe,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import OperatorNavbar from "../components/OperatorNavbar";
import OperatorFooter from "../components/OperatorFooter";

// Styled gradient text components
const GradientText = ({ children }) => (
  <span className="bg-gradient-to-r from-[#DC2626] to-[#F87171] inline bg-clip-text text-transparent">
    {children}
  </span>
);

// Background pattern component
const NetworkAnimation = () => (
  <div className="absolute inset-0 z-0 overflow-hidden">
    {/* Grid lines */}
    <div className="absolute inset-0 opacity-10">
      <div
        className="absolute top-0 left-0 right-0 bottom-0"
        style={{
          backgroundImage: `
             linear-gradient(to right, rgba(255,255,255,0.05) 1px, transparent 1px),
             linear-gradient(to bottom, rgba(255,255,255,0.05) 1px, transparent 1px)
           `,
          backgroundSize: "20px 20px",
        }}
      ></div>
    </div>

    {/* Animated dots and connections */}
    <div className="absolute inset-0">
      {[...Array(15)].map((_, i) => (
        <div
          key={i}
          className="absolute w-1 h-1 rounded-full bg-[#E54C4C]/40"
          style={{
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
            animation: `float ${3 + Math.random() * 4}s infinite ease-in-out ${
              Math.random() * 2
            }s`,
          }}
        ></div>
      ))}

      {/* Blue dots */}
      {[...Array(10)].map((_, i) => (
        <div
          key={i}
          className="absolute w-1 h-1 rounded-full bg-[#2563EB]/40"
          style={{
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
            animation: `float ${2 + Math.random() * 3}s infinite ease-in-out ${
              Math.random() * 2
            }s`,
          }}
        ></div>
      ))}
    </div>

    {/* Radial gradient layers */}
    <div
      className="absolute inset-0 opacity-40"
      style={{
        background:
          "radial-gradient(50% 50% at 50% 50%, rgba(229, 76, 76, 0.08) 0%, rgba(10, 13, 20, 0) 100%)",
      }}
    ></div>
    <div
      className="absolute inset-0 opacity-20"
      style={{
        background:
          "radial-gradient(70% 70% at 30% 30%, rgba(78, 202, 252, 0.1) 0%, rgba(10, 13, 20, 0) 100%)",
      }}
    ></div>

    {/* Styles for animations */}
    <style jsx>{`
      @keyframes float {
        0%,
        100% {
          transform: translateY(0) translateX(0);
        }
        50% {
          transform: translateY(-10px) translateX(5px);
        }
      }
    `}</style>
  </div>
);

// Use case card component
const UseCaseCard = ({ icon: Icon, title, description }) => (
  <div className="bg-[#F9FAFB] rounded-xl border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 hover:border-[#94A3B8] transition-all duration-300">
    <div className="p-5">
      <div className="flex items-center mb-3">
        <div className="w-10 h-10 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mr-3">
          <Icon className="text-[#E54C4C]" size={20} />
        </div>
        <div>
          <h4 className="text-[#111827] font-medium">{title}</h4>
        </div>
      </div>
      <p className="text-[#374151] text-sm mb-3">{description}</p>
    </div>
  </div>
);

const OperatorThankYouPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Initialize animation library
    AOS.init({
      duration: 1000,
      easing: "ease-out",
      once: true,
    });

    // Scroll to top on page load
    window.scrollTo(0, 0);

    // Add Google Ads conversion tracking code here if needed
    // This is where you would place the Google Ads conversion snippet
    if (window.gtag) {
      window.gtag("event", "conversion", {
        send_to: "AW-16934726786/5f_lCNSgwqwaEILZjYs_",
      });
      console.log("Conversion event fired");
    } else {
      console.warn("gtag not found - conversion tracking may not work");
    }
  }, []);

  // Handle return to homepage
  const goToHome = () => {
    navigate("/operator");
  };

  // Handle booking demo
  const scheduleDemo = () => {
    window.open("https://calendly.com/sundar-arvind-blitzo/30min", "_blank");
  };

  return (
    <>
      <Helmet>
        <title>Thank You | Blitzo Operator | AI Workers for Logistics</title>
        <meta
          name="description"
          content="Thank you for your interest in Blitzo Operator. Our team will contact you shortly to discuss how our AI agents can transform your logistics operations."
        />
        <meta name="robots" content="noindex" />
        {/* Google Ads conversion tracking script */}
        <script>
          {`
            gtag('event', 'conversion', {'send_to': 'AW-16934726786/5f_lCNSgwqwaEILZjYs_'});
          `}
        </script>
      </Helmet>

      <div className="min-h-screen bg-[#F9FAFB]">
        <OperatorNavbar />

        {/* Hero section */}
        <div className="relative min-h-[80vh] flex flex-col justify-center overflow-hidden bg-gradient-to-br from-[#FFFFFF] to-[#F8FAFC]">
          {/* Background animation */}
          <NetworkAnimation />

          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-28 md:pt-28 pb-24 relative z-10">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
              {/* Left side - Confirmation message */}
              <div data-aos="fade-right" className="text-left">
                <div className="inline-flex items-center px-3 py-1 rounded-full bg-[#E54C4C]/10 border border-[#E54C4C]/20 text-[#E54C4C] text-xs mb-6">
                  <Star className="mr-2" size={12} />
                  <span>Form submitted successfully</span>
                </div>

                <h1 className="text-4xl sm:text-5xl font-bold text-[#111827] mb-6 leading-tight">
                  <GradientText>Thank You! </GradientText>
                  <br />
                  We'll be in touch soon.
                </h1>

                <div className="mb-8">
                  <div className="flex items-center mb-4">
                    <div className="w-12 h-12 bg-[#E54C4C]/10 rounded-full flex items-center justify-center mr-4">
                      <Check className="text-[#E54C4C]" size={24} />
                    </div>
                    <p className="text-xl text-[#374151]">
                      We've received your information
                    </p>
                  </div>

                  <p className="text-lg text-[#374151] mb-6">
                    Our team will contact you shortly to discuss how Blitzo
                    Operator's AI Workers can transform your logistics
                    operations and arrange your demonstration.
                  </p>

                  <div className="bg-[#F9FAFB] border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 rounded-lg p-4 mb-6">
                    <h3 className="text-lg font-medium text-[#111827] mb-2">
                      What happens next?
                    </h3>
                    <ul className="space-y-3">
                      <li className="flex items-start">
                        <div className="w-5 h-5 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mr-3 mt-0.5">
                          <Check className="text-[#E54C4C]" size={14} />
                        </div>
                        <span className="text-[#374151]">
                          A member of our team will email you within 1 hour.
                        </span>
                      </li>
                      <li className="flex items-start">
                        <div className="w-5 h-5 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mr-3 mt-0.5">
                          <Check className="text-[#E54C4C]" size={14} />
                        </div>
                        <span className="text-[#374151]">
                          We'll schedule a personalized demo for your team
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div className="flex flex-col sm:flex-row items-start gap-4">
                    <button
                      onClick={goToHome}
                      className="px-6 py-3 bg-[#F9FAFB] border border-[#E5E7EB] text-[#111827] rounded-lg shadow-md shadow-[#E5E7EB]/40 flex items-center justify-center hover:border-[#4ECAFC] transition-all duration-300"
                    >
                      Return to Homepage
                      <ArrowRight className="ml-2" size={18} />
                    </button>
                    <button
                      onClick={scheduleDemo}
                      className="px-6 py-3 bg-gradient-to-r from-[#DC2626] to-[#F87171] text-white rounded-lg shadow-lg shadow-[#E54C4C]/20 flex items-center justify-center hover:shadow-[#E54C4C]/40 transition-all duration-300"
                    >
                      Fast-track: Book a Call Now
                      <ArrowUpRight className="ml-2" size={18} />
                    </button>
                  </div>
                </div>
              </div>

              {/* Right side - Confirmation illustration */}
              <div data-aos="fade-left" className="lg:pl-10">
                <div className="bg-[#F9FAFB] border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 rounded-xl p-8">
                  <div className="flex items-center mb-6">
                    <div className="w-12 h-12 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mr-4">
                      <Globe className="text-[#E54C4C]" size={24} />
                    </div>
                    <div>
                      <h3 className="text-xl font-bold text-[#111827]">
                        While You Wait...
                      </h3>
                      <p className="text-[#374151]">
                        Learn more about what our AI can do
                      </p>
                    </div>
                  </div>

                  <div className="space-y-4 mb-6">
                    <div className="flex items-center p-3 bg-[#F9FAFB] border border-[#E5E7EB] rounded-lg hover:border-[#4ECAFC] transition-all duration-300">
                      <div className="w-10 h-10 rounded-full bg-[#4ECAFC]/10 flex items-center justify-center mr-3">
                        <Phone className="text-[#4ECAFC]" size={20} />
                      </div>
                      <div>
                        <p className="text-[#111827] font-medium">
                          100% of calls answered
                        </p>
                        <p className="text-[#374151] text-sm">
                          Never miss a carrier opportunity
                        </p>
                      </div>
                    </div>

                    <div className="flex items-center p-3 bg-[#F9FAFB] border border-[#E5E7EB] rounded-lg hover:border-[#4ECAFC] transition-all duration-300">
                      <div className="w-10 h-10 rounded-full bg-[#4ECAFC]/10 flex items-center justify-center mr-3">
                        <Clock className="text-[#4ECAFC]" size={20} />
                      </div>
                      <div>
                        <p className="text-[#111827] font-medium">
                          Save 4+ hours daily
                        </p>
                        <p className="text-[#374151] text-sm">
                          Automated communications 24/7
                        </p>
                      </div>
                    </div>

                    <div className="flex items-center p-3 bg-[#F9FAFB] border border-[#E5E7EB] rounded-lg hover:border-[#4ECAFC] transition-all duration-300">
                      <div className="w-10 h-10 rounded-full bg-[#4ECAFC]/10 flex items-center justify-center mr-3">
                        <Truck className="text-[#4ECAFC]" size={20} />
                      </div>
                      <div>
                        <p className="text-[#111827] font-medium">
                          Increase margins by 12%+
                        </p>
                        <p className="text-[#374151] text-sm">
                          AI-powered rate negotiation
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="bg-[#F9FAFB]/70 border border-[#E5E7EB] rounded-lg p-4">
                    <div className="flex items-start">
                      <div className="mr-3 mt-1 flex-shrink-0">
                        <div className="w-8 h-8 rounded-full bg-[#F9FAFB] flex items-center justify-center">
                          <span className="text-[#111827] font-semibold">
                            JH
                          </span>
                        </div>
                      </div>
                      <div>
                        <p className="text-[#374151] italic mb-2 text-sm">
                          "Blitzo has been magical on our load tendering
                          results. We've reduced admin time by 78% and increased
                          our margin on LTL."
                        </p>
                        <p className="text-[#111827] font-medium text-sm">
                          James
                        </p>
                        <p className="text-[#374151] text-xs">
                          Operations Director
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Featured use cases */}
        <div className="py-16 bg-[#F9FAFB]">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-12" data-aos="fade-up">
              <h2 className="text-3xl font-bold text-[#111827] mb-4">
                <GradientText>Explore More</GradientText> Blitzo Capabilities
              </h2>
              <p className="text-[#374151] max-w-3xl mx-auto">
                Here are some other ways our AI agents can help transform your
                logistics operations
              </p>
            </div>

            <div
              className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-10"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <UseCaseCard
                icon={Phone}
                title="Outbound Sales Calls"
                description="AI automatically calls priority carriers to secure capacity at the best rates when new loads are tendered."
              />
              <UseCaseCard
                icon={Truck}
                title="Track & Trace Automation"
                description="Automatically contact drivers and update your TMS with real-time location and ETA information."
              />
              <UseCaseCard
                icon={Calendar}
                title="Delivery Slot Booking"
                description="AI handles warehouse communication and secures optimal delivery slots without human intervention."
              />
            </div>

            <div
              className="text-center mt-8"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <button
                onClick={goToHome}
                className="px-6 py-3 bg-gradient-to-r from-[#DC2626] to-[#F87171] text-white rounded-lg shadow-lg shadow-[#E54C4C]/20 inline-flex items-center justify-center hover:shadow-[#E54C4C]/40 transition-all duration-300"
              >
                <span className="font-medium">
                  Learn More About Our Solutions
                </span>
                <ArrowRight className="ml-2" size={18} />
              </button>
            </div>
          </div>
        </div>

        {/* Footer */}
        <OperatorFooter />
      </div>
    </>
  );
};

export default OperatorThankYouPage;
