import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './DocumentCard.css';
import { approveRider, getRiderInfo } from '../../Firebase/rider'; // Import the approveRider method

const DocumentCard = ({ documentData }) => {
  const navigate = useNavigate();
  const [riderInfo, setRiderInfo] = useState({ name: '', phone: '' });

  useEffect(() => {
    const fetchRiderInfo = async () => {
      const info = await getRiderInfo(documentData.id);
      if (info) {
        setRiderInfo(info);
        console.log(riderInfo);
      }
    };
    fetchRiderInfo();
  }, [documentData.id]);

  const handleApprove = async () => {
    try {
      await approveRider(documentData.id);
      alert(`Rider ${documentData.id} approved successfully`);
      window.location.reload(); // Refresh the page
    } catch (error) {
      console.error(`Error approving rider ${documentData.id}:`, error);
      alert(`Error approving rider ${documentData.id}`);
    }
  };

  const renderDocumentLink = (value, label, key) => {
    return (
      <div key={key} className="document-field">
        <span className="field-label">{label}:</span>
        {value ? (
          <button onClick={() => navigate(`/admin/requests/view`, { state: { url: value, name: riderInfo.name, phone: riderInfo.phone } })} className="view-link">
            View
          </button>
        ) : (
          <span className="null-value">null</span>
        )}
      </div>
    );
  };

  const documentFields = {
    license: "License",
    residencePermit: "Residence Permit",
    vehicleInsurance: "Vehicle Insurance",
    vehiclePicture: "Vehicle Picture"
  };

  return (
    <div className="document-card">
      <div className="document-text">ID: {documentData.id}</div>
      <div className="document-fields">
        {Object.entries(documentFields).map(([key, label]) => 
          renderDocumentLink(documentData.docs?.[key], label, key)
        )}
      </div>
      <div className="button-group">
        <button onClick={handleApprove} className="approve-button">Accept</button>
      </div>
    </div>
  );
};

export default DocumentCard;