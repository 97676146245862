import React, { useEffect } from "react";
import {
  Building,
  BikeIcon,
  Handshake,
  Clock,
  MapPin,
  Shield,
  ArrowUpRight,
} from "lucide-react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import CTA from "../components/CTA";
import AOS from "aos";
import "aos/dist/aos.css";
import logoImage from "../Images/logo.png";
import FIA1 from "../Images/Founders_In_Action.jpeg";
import FIA2 from "../Images/FIA_2.jpeg";
import FIA3 from "../Images/FIA_3.jpeg";
import sundarPFP from "../Images/sundar-pfp.png";
import arjunPFP from "../Images/Arjun-pfp.jpg";

// New components from your Home redesign
import HowItWorks from "../components/HowItWorks";
import BusinessImpactNarrative from "../components/BusinessImpactNarrative";
import PremiumFleetPricing from "../components/PremiumFleetPricing";
import FleetSolutions from "../components/FleetSolutions";
import BottomCTA from "../components/BottomCta";

// Styled Components for gradient text
import styled from "styled-components";
import DarkNavbar from "./DarkNavbar";
const GradientTextStyled = styled.span`
  background: linear-gradient(90deg, #e54c4c 0%, #ff6b6b 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
`;

const AboutUs = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, easing: "ease-out", once: true });
  }, []);

  return (
    <div className="bg-[#080A12] min-h-screen">
      <DarkNavbar />

      {/* Hero Section */}
      <div className="relative bg-gradient-to-br from-[#080A12] to-[#0F121B] pt-40 pb-20">
        <div
          className="max-w-7xl mx-auto px-6 lg:px-8 text-center"
          data-aos="fade-up"
        >
          <img
            src={logoImage}
            alt="Blitzo"
            className="h-24 w-auto mx-auto mb-6"
          />
          <h1 className="text-3xl md:text-5xl font-bold text-white mb-4">
            <span className="block mb-2">Our Journey</span>
            <span className="text-[#E54C4C] relative inline-block">
              From a Simple Idea to a Last-Mile Revolution
            </span>
          </h1>
          <p className="text-xl md:text-xl text-gray-300 max-w-3xl mx-auto mt-8 leading-relaxed">
            We discovered that moving things easily wasn’t enough – the real
            cost lies in the hidden “donkey work” of last-mile logistics.
            Delays, high costs, and inefficiencies were draining resources. We
            built Blitzo to eliminate these challenges, delivering zero
            operational overhead and instant gratification for your customers.
          </p>
        </div>
      </div>

      {/* Our Story, What We Do, & Vision */}
      <div className="max-w-7xl mx-auto px-6 lg:px-8 pb-16 grid grid-cols-1 md:grid-cols-3 gap-12">
        {/* Our Story */}
        <div
          className="bg-[#0F121B] shadow-lg p-8 rounded-xl border border-[#262D40] transition-transform hover:-translate-y-1"
          data-aos="fade-up"
        >
          <div className="w-16 h-16 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mb-6">
            <Building className="text-[#E54C4C]" size={32} />
          </div>
          <h2 className="text-2xl font-bold text-white mb-4">Our Story</h2>
          <p className="text-gray-300">
            What began as a simple idea to move things swiftly turned into an
            eye‐opening journey. We uncovered the hidden, laborious work of
            last‑mile logistics – the endless delays and exorbitant costs. We’re
            here to fix that.
          </p>
        </div>

        {/* What We Do */}
        <div
          className="bg-[#0F121B] shadow-lg p-8 rounded-xl border border-[#262D40] transition-transform hover:-translate-y-1"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="w-16 h-16 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mb-6">
            <BikeIcon className="text-[#E54C4C]" size={32} />
          </div>
          <h2 className="text-2xl font-bold text-white mb-4">What We Do</h2>
          <p className="text-gray-300">
            We automate every step – from order management and dispatch to
            human‑like support and real‑time tracking. No more manual overhead;
            just seamless, automated operations.
          </p>
        </div>

        {/* Our Vision */}
        <div
          className="bg-[#0F121B] shadow-lg p-8 rounded-xl border border-[#262D40] transition-transform hover:-translate-y-1"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div className="w-16 h-16 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mb-6">
            <Handshake className="text-[#E54C4C]" size={32} />
          </div>
          <h2 className="text-2xl font-bold text-white mb-4">Our Vision</h2>
          <p className="text-gray-300">
            We provide businesses with two essential benefits: zero operational
            overhead and instant customer gratification. This dual approach is
            set to redefine last‑mile logistics across all industries.
          </p>
        </div>
      </div>

      {/* Founders in Action Section */}
      <div className="bg-[#0F121B] py-20">
        <div className="max-w-7xl mx-auto px-6 lg:px-8">
          <div
            className="text-center max-w-3xl mx-auto mb-16"
            data-aos="fade-up"
          >
            <h2 className="text-3xl md:text-4xl font-bold mb-6 text-white">
              A Glimpse of our Founders in Action
            </h2>
            <p className="text-xl text-gray-300 leading-relaxed">
              We discovered the true cost of last‑mile logistics – the endless
              delays and manual hassles. Determined to fix it, we took to the
              streets ourselves. We’re not just building tech; we’re rethinking
              how deliveries work.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-12">
            {/* Action Shot 1 */}
            <div
              className="group relative rounded-xl overflow-hidden shadow-lg h-[600px]"
              data-aos="fade-up"
            >
              <div className="w-full h-full bg-gray-200">
                <img
                  src={FIA1}
                  alt="Founders in Action 1"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/70 to-transparent p-6">
                <p className="text-white text-sm">
                  Personally handling urgent deliveries, we experienced it all.
                </p>
              </div>
            </div>
            {/* Action Shot 2 */}
            <div
              className="group relative rounded-xl overflow-hidden shadow-lg h-[600px]"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="w-full h-full bg-gray-200">
                <img
                  src={FIA2}
                  alt="Founders in Action 2"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/70 to-transparent p-6">
                <p className="text-white text-sm">
                  Crafting solutions on the ground – we live our mission.
                </p>
              </div>
            </div>
            {/* Action Shot 3 */}
            <div
              className="group relative rounded-xl overflow-hidden shadow-lg h-[600px]"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="w-full h-full bg-gray-200">
                <img
                  src={FIA3}
                  alt="Founders in Action 3"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/70 to-transparent p-6">
                <p className="text-white text-sm">
                  Tackling logistical challenges head-on – our passion drives
                  innovation.
                </p>
              </div>
            </div>
          </div>

          <div
            className="mt-12 text-center max-w-2xl mx-auto"
            data-aos="fade-up"
          >
            <p className="text-gray-400 italic">
              "What we want is to enable every business to deliver products to
              their customers in minutes, without any manual interevention.
              We're building the future of last-mile logistics, an AI agent
              driven by data and powered by human-like intuition."
            </p>
            <p className="mt-4 text-[#E54C4C] font-medium">- Sundar & Arjun</p>
          </div>
        </div>
      </div>
      {/* Team Section */}
      <div className="bg-[#0F121B] py-20">
        <div
          className="max-w-7xl mx-auto px-6 lg:px-8 text-center"
          data-aos="fade-up"
        >
          <h2 className="text-3xl md:text-4xl font-bold mb-8 text-white">
            Meet Our Founders
          </h2>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-12">
            Two friends from King's College London, united by a passion for
            solving complex problems and making life easier for others.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 max-w-4xl mx-auto">
            {/* Sundar */}
            <div
              className="bg-[#0F121B]/80 p-8 rounded-xl shadow-lg border border-[#262D40] transition-transform hover:-translate-y-1"
              data-aos="fade-up"
            >
              <div className="h-36 w-36 rounded-full bg-gray-800 mx-auto mb-6">
                <img
                  src={sundarPFP}
                  alt="Sundar Arvind"
                  className="h-full w-full object-cover rounded-full"
                />
              </div>
              <h3 className="text-xl font-semibold text-white">
                Sundar Arvind
              </h3>
              <p className="text-[#E54C4C] font-medium mb-4">Co-CEO</p>
              <p className="text-gray-300">
                A former professional tennis player ranked in India's top 3, and
                one of the youngest music producers signed by Spinnin Records,
                Sundar brings his creative drive and analytical thinking to
                every challenge.
              </p>
            </div>
            {/* Arjun */}
            <div
              className="bg-[#0F121B]/80 p-8 rounded-xl shadow-lg border border-[#262D40] transition-transform hover:-translate-y-1"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="h-36 w-36 rounded-full bg-gray-800 mx-auto mb-6">
                <img
                  src={arjunPFP}
                  alt="Arjun"
                  className="h-full w-full object-cover rounded-full"
                />
              </div>
              <h3 className="text-xl font-semibold text-white">Arjun</h3>
              <p className="text-[#E54C4C] font-medium mb-4">Co-CEO</p>
              <p className="text-gray-300">
                Coming from a family of successful entrepreneurs, Arjun has
                business in his blood. His fleet management techniques have been
                instrumental in realising Blitzo's vision of reliable, speedy
                high value deliveries.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom CTA & Footer */}
      <BottomCTA />
      <Footer />
    </div>
  );
};

export default AboutUs;
