import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import BookingContext from "../../contexts/BookingContext";
import { GoogleMap, Marker, Polyline } from "@react-google-maps/api";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Location } from "../../types/Location";
import { useLocation, useNavigate } from "react-router-dom";
import { createBlitzoOrder } from "../../firebase/order";
import { PackageType } from "../../types/PackageType";
import { FeeType } from "../../types/BlitzoOrder";
import { mapOptions } from "../../styles/MapStyles";
import PricingBreakdown from "../../components/pricing/PricingBreakdown";

// Dummy Stripe publishable key:
const stripePromise =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? loadStripe(
        "pk_test_51O8qV4IJxIKoMeqTrRkJNzvVRLQdSYFQQeeE98Z0XwcxpwZi1zP15rEcTyMrrlnlyXHSKLMphYvOWerCyjkpQ6dc00ZusGyWsT"
      )
    : loadStripe(
        "pk_live_51O8qV4IJxIKoMeqTam8YI8YTiGuNgO76L84S1TxfUxLBnLHmckD06etSjOTkHhyaamYNa4IasNUejkzRXq4UE8zm007NyDnv5w"
      );

// For map styling/curve – can reuse from your create page
const mapContainerStyle: React.CSSProperties = {
  width: "100%",
  height: "100%",
};

interface PriceDetails {
  total: number;
  baseFee: number;
  distanceFee: number;
  serviceFee: number;
  sizeFee: number;
  riderFee: {
    baseFee: number;
    distanceFee: number;
    total: number;
  };
  orderId: string;
  paymentIntentId: string;
  ephemeralKey: string;
  clientSecret: string;
  customerId: string;
}

function SummaryAndPayment() {
  const {
    pickupLocation,
    dropoffLocation,
    packageType,
    selectedSizes,
    schedule,
    collectionSpeed,
    isInsured,
    specialInstructions,
  } = useContext(BookingContext);
  const location = useLocation();
  const priceDetails = location.state as PriceDetails;
  const navigate = useNavigate();
  const mapRef = useRef<google.maps.Map | null>(null);

  // Estimate user is saving X minutes; also a dummy calculation
  const [timeSavings, setTimeSavings] = useState(15);

  // Example: approximate ETA
  const [etaMinutes, setEtaMinutes] = useState(30);

  const getCurvedPath = useCallback((start: Location, end: Location) => {
    if (!start || !end) return [];

    const startPoint = { lat: start.latitude, lng: start.longitude };
    const endPoint = { lat: end.latitude, lng: end.longitude };

    const distance = Math.sqrt(
      Math.pow(endPoint.lat - startPoint.lat, 2) +
        Math.pow(endPoint.lng - startPoint.lng, 2)
    );
    const offsetDistance = distance * 0.2;

    const midPoint = {
      lat: (startPoint.lat + endPoint.lat) / 2,
      lng: (startPoint.lng + endPoint.lng) / 2,
    };
    const angle = Math.atan2(
      endPoint.lat - startPoint.lat,
      endPoint.lng - startPoint.lng
    );
    const controlPoint = {
      lat: midPoint.lat + Math.cos(angle + Math.PI / 2) * offsetDistance,
      lng: midPoint.lng + Math.sin(angle + Math.PI / 2) * offsetDistance,
    };

    const curvePoints = [];
    const steps = 50;
    for (let i = 0; i <= steps; i++) {
      const t = i / steps;
      const lat =
        (1 - t) * (1 - t) * startPoint.lat +
        2 * (1 - t) * t * controlPoint.lat +
        t * t * endPoint.lat;
      const lng =
        (1 - t) * (1 - t) * startPoint.lng +
        2 * (1 - t) * t * controlPoint.lng +
        t * t * endPoint.lng;
      curvePoints.push({ lat, lng });
    }
    return curvePoints;
  }, []);

  const getPathOptions = useCallback(
    () => ({
      geodesic: true,
      strokeColor: "#E54C4C", // Updated to Blitzo primary color
      strokeOpacity: 0,
      strokeWeight: 2,
      icons: [
        {
          icon: {
            path: "M 0,-1 0,1",
            strokeOpacity: 1,
            strokeWeight: 2.5,
            scale: 3,
          },
          offset: "0",
          repeat: "10px",
        },
      ],
    }),
    []
  );

  // For demonstration, we can call setPrice, setTimeSavings, setEtaMinutes
  // once we have real logic or an API. Right now, they are static.

  // Fit map to markers
  useEffect(() => {
    if (!priceDetails) {
      navigate("/business/book"); // Redirect if no price details
      return;
    }
    if (mapRef.current && pickupLocation && dropoffLocation) {
      const bounds = new google.maps.LatLngBounds();
      bounds.extend(
        new google.maps.LatLng(
          pickupLocation.latitude,
          pickupLocation.longitude
        )
      );
      bounds.extend(
        new google.maps.LatLng(
          dropoffLocation.latitude,
          dropoffLocation.longitude
        )
      );
      mapRef?.current?.fitBounds(bounds);
    }
  }, [pickupLocation, dropoffLocation]);

  const handlePaymentSuccess = async () => {
    try {
      // 1) Suppose payment was successful & we have a PaymentIntent ID
      const paymentIntentId = "pi_3QfjukIJxIKoMeqT0MSALfTA"; // from server or stripe confirm step

      // 3) Create the order in Firestore
      const { order } = await createBlitzoOrder(
        priceDetails.orderId,
        pickupLocation as Location,
        dropoffLocation as Location,
        packageType as PackageType,
        "Any instructions here...",
        isInsured || false,
        {
          baseFee: 5,
          distanceFee: 4,
          total: 9,
          riderFee: {
            baseFee: 5,
            distanceFee: 4,
            total: 9,
          },
          sizeFee: 0,
          serviceFee: 0,
          discountInPercentage: 0,
        },
        paymentIntentId,
        schedule as Schedule,
        pickupLocation?.contact.number,
        dropoffLocation?.contact.number
      );

      // 4) Navigate to track page
      navigate(`/business/track/${order.id}`);
    } catch (err) {
      console.error("Error creating order:", err);
      // Show user error
    }
  };

  return (
    <div className="flex bg-gray-900 text-white min-h-screen pt-16">
      {/* LEFT: Summary Details + Payment */}
      <div className="w-1/2 p-6">
        <div className="bg-gray-800 rounded-lg shadow-md p-6">
          <h2 className="text-2xl font-semibold mb-4">Booking Summary</h2>

          {/* Time Savings Banner */}
          <div className="mb-6 bg-gradient-to-r from-red-500/10 to-red-400/5 rounded-xl p-4 border border-red-500/20">
            <div className="flex items-center gap-2 mb-1">
              <div className="w-2 h-2 rounded-full bg-red-400 animate-pulse" />
              <p className="text-sm font-medium text-red-400">
                You're saving <strong>{timeSavings} minutes</strong> with
                Blitzo!
              </p>
            </div>
            <p className="text-xs text-gray-400 ml-4">
              Estimated delivery time: ~{etaMinutes} mins
            </p>
          </div>

          {/* Order Details
          <div className="mb-6 space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="p-4 rounded-lg bg-gray-800/50 border border-gray-700">
                <h3 className="text-sm font-medium text-gray-400 mb-2">
                  Package Details
                </h3>
                <p className="text-sm text-white">{packageType || "N/A"}</p>
                <div className="mt-2 text-xs text-gray-500">
                  Small: {selectedSizes?.Small ?? 0} • Medium:{" "}
                  {selectedSizes?.Medium ?? 0}
                </div>
              </div>
              <div className="p-4 rounded-lg bg-gray-800/50 border border-gray-700">
                <h3 className="text-sm font-medium text-gray-400 mb-2">
                  Delivery Time
                </h3>
                <p className="text-sm text-white">
                  {schedule?.isNow ? "ASAP Delivery" : "Scheduled"}
                </p>
                {!schedule?.isNow && schedule?.scheduledFor && (
                  <p className="mt-2 text-xs text-gray-500">
                    {schedule.scheduledFor.toLocaleString()}
                  </p>
                )}
              </div>
            </div>
          </div> */}

          {/* Price Breakdown */}
          {priceDetails && (
            <PricingBreakdown
              total={priceDetails.total}
              baseFee={priceDetails.baseFee}
              distanceFee={priceDetails.distanceFee}
              serviceFee={priceDetails.serviceFee}
              sizeFee={priceDetails.sizeFee}
              riderFee={priceDetails.riderFee}
            />
          )}

          {/* Payment Section */}
          <Elements stripe={stripePromise}>
            <PaymentForm
              priceDetails={priceDetails}
              dropoffLocation={dropoffLocation as Location}
              price={priceDetails?.total || 0}
              onPaymentSuccess={async () => {
                try {
                  const { order } = await createBlitzoOrder(
                    priceDetails.orderId,
                    pickupLocation as Location,
                    dropoffLocation as Location,
                    packageType as PackageType,
                    specialInstructions || "",
                    isInsured || false,
                    {
                      total: priceDetails.total,
                      baseFee: priceDetails.baseFee,
                      distanceFee: priceDetails.distanceFee,
                      serviceFee: priceDetails.serviceFee,
                      sizeFee: priceDetails.sizeFee,
                      riderFee: priceDetails.riderFee,
                      discountInPercentage: 0,
                    },
                    priceDetails.paymentIntentId,
                    schedule ?? { isNow: true },
                    pickupLocation?.contact.number,
                    dropoffLocation?.contact.number
                  );
                  navigate(`/business/track/${order.id}`);
                } catch (err) {
                  console.error("Error creating order:", err);
                }
              }}
            />
          </Elements>
        </div>
      </div>

      {/* RIGHT: Map */}
      <div className="w-1/2 relative">
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={13}
          center={{
            lat: 51.5074,
            lng: -0.1278,
          }}
          options={mapOptions}
          onLoad={(map) => {
            mapRef.current = map;
          }}
          clickableIcons={false}
        >
          {pickupLocation && (
            <Marker
              position={{
                lat: pickupLocation.latitude,
                lng: pickupLocation.longitude,
              }}
            />
          )}
          {dropoffLocation && (
            <Marker
              position={{
                lat: dropoffLocation.latitude,
                lng: dropoffLocation.longitude,
              }}
            />
          )}
          {pickupLocation && dropoffLocation && (
            <Polyline
              path={getCurvedPath(pickupLocation, dropoffLocation)}
              options={{
                strokeColor: "#E54C4C",
                strokeOpacity: 0.8,
                strokeWeight: 3,
              }}
            />
          )}
        </GoogleMap>
      </div>
    </div>
  );
}

const PaymentForm: React.FC<{
  price: number;
  onPaymentSuccess: () => void;
  priceDetails: PriceDetails;
  dropoffLocation: Location;
}> = ({ price, onPaymentSuccess, priceDetails, dropoffLocation }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async () => {
    if (!stripe || !elements) return;

    setIsProcessing(true);
    setError(null);

    try {
      // Get card element
      const cardElement = elements.getElement(CardElement);
      if (!cardElement) throw new Error("Card element not found");

      // Attempt to confirm the payment
      const { error: stripeError } = await stripe.confirmCardPayment(
        // We'll get this from the server
        priceDetails.clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              // These could be collected separately if needed
              address: {
                country: "GB",
              },
            },
          },
        }
      );

      if (stripeError) {
        setError(stripeError.message || "Payment failed");
        return;
      }

      onPaymentSuccess();
    } catch (error: any) {
      setError(error.message || "Payment failed");
      console.error("Payment error:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="mt-6 space-y-4">
      <div className="p-4 bg-gray-800/50 rounded-lg border border-gray-700">
        <label className="text-sm font-medium text-gray-300 block mb-3">
          Card Details
        </label>
        <div className="p-3 bg-white/5 rounded-lg border border-gray-700">
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#ffffff",
                  "::placeholder": {
                    color: "#6b7280",
                  },
                  ":-webkit-autofill": {
                    color: "#ffffff",
                  },
                },
              },
              // UK-specific options
              classes: {
                focus: "focused-card-element",
              },
            }}
          />
        </div>
      </div>

      {error && (
        <div className="p-3 rounded-lg bg-red-500/10 border border-red-500/20">
          <p className="text-sm text-red-400">{error}</p>
        </div>
      )}

      <button
        onClick={handleSubmit}
        disabled={!stripe || isProcessing}
        className={`w-full px-4 py-3 rounded-lg text-white font-medium
            transition-all duration-200 ${
              isProcessing
                ? "bg-gray-600 cursor-not-allowed"
                : "bg-red-500 hover:bg-red-600"
            }`}
      >
        {isProcessing ? "Processing..." : `Pay £${price.toFixed(2)}`}
      </button>

      <p className="text-xs text-center text-gray-500 mt-2">
        Test card: 4242 4242 4242 4242 • Any future date • Any 3 digits
      </p>
    </div>
  );
};

export default SummaryAndPayment;
