import React from "react";
import { X, Package, MapPin, Phone, Send } from "lucide-react";
import { BlitzoOrder } from "../../types/BlitzoOrder";

interface PastOrderModalProps {
  order: BlitzoOrder;
  onClose: () => void;
}

const PastOrderModal: React.FC<PastOrderModalProps> = ({ order, onClose }) => {
  // Helper to format a date or a Firestore timestamp
  const formatDateTime = (date: Date | any) => {
    // If the date has a .toDate() method (e.g. Firestore Timestamp), call it first
    const jsDate = date?.toDate ? date.toDate() : new Date(date);
    return jsDate.toLocaleString("en-GB", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };

  // Dummy function for now
  const handleSendReceipt = () => {
    alert("Receipt sent!");
  };

  return (
    <div className="fixed inset-0 z-50" onClick={(e) => e.stopPropagation()}>
      {/* Backdrop */}
      <div
        className="absolute inset-0 bg-black/70 backdrop-blur-sm"
        onClick={onClose}
      />

      {/* Modal Container */}
      <div className="absolute top-1/2 left-1/2 w-full max-w-3xl -translate-x-1/2 -translate-y-1/2">
        <div className="bg-gray-900 rounded-2xl border border-gray-700 shadow-xl overflow-hidden">
          {/* Header */}
          <div className="flex items-center justify-between px-6 py-4 border-b border-gray-700">
            <div className="flex items-center gap-3">
              <Package size={24} className="text-indigo-400" />
              <div>
                <h2 className="text-xl font-semibold text-white">
                  Past Order Details
                </h2>
                <p className="text-sm text-gray-400">
                  Order #{order.id.slice(0, 8)}
                </p>
              </div>
            </div>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-800 rounded-md transition"
            >
              <X size={24} className="text-gray-400" />
            </button>
          </div>

          {/* Content */}
          <div className="p-6 space-y-6">
            {/* Order Summary & Locations */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Order Information */}
              <div className="bg-gray-800 p-4 rounded-xl border border-gray-700">
                <h3 className="text-lg font-medium text-white mb-2">
                  Order Information
                </h3>
                <p className="text-sm text-gray-400">
                  <span className="font-medium">Ordered At:</span>{" "}
                  {formatDateTime(order.orderedAt)}
                </p>
                <p className="text-sm text-gray-400 mt-1">
                  <span className="font-medium">Package Type:</span>{" "}
                  {order.packageType}
                </p>
                {order.specialInstructions && (
                  <p className="text-sm text-gray-400 mt-1">
                    <span className="font-medium">Special Instructions:</span>{" "}
                    {order.specialInstructions}
                  </p>
                )}
              </div>

              {/* Locations */}
              <div className="bg-gray-800 p-4 rounded-xl border border-gray-700">
                <h3 className="text-lg font-medium text-white mb-2">
                  Locations
                </h3>
                {/* Pickup */}
                <div className="flex items-start gap-3">
                  <MapPin size={20} className="text-emerald-400" />
                  <div>
                    <p className="text-sm text-gray-400">
                      <span className="font-medium">Pickup:</span>{" "}
                      {order.pickupLocation.locationName}
                    </p>
                    <p className="text-sm text-gray-500">
                      {order.pickupLocation.detailedLocation}
                    </p>
                    {order.pickupLocation.contact?.number && (
                      <p className="text-sm text-gray-400 mt-1">
                        <Phone size={14} className="inline mr-1" />
                        {order.pickupLocation.contact.number}
                      </p>
                    )}
                  </div>
                </div>
                {/* Dropoff */}
                <div className="flex items-start gap-3 mt-4">
                  <MapPin size={20} className="text-blue-400" />
                  <div>
                    <p className="text-sm text-gray-400">
                      <span className="font-medium">Dropoff:</span>{" "}
                      {order.dropoffLocation.locationName}
                    </p>
                    <p className="text-sm text-gray-500">
                      {order.dropoffLocation.detailedLocation}
                    </p>
                    {order.dropoffLocation.contact?.number && (
                      <p className="text-sm text-gray-400 mt-1">
                        <Phone size={14} className="inline mr-1" />
                        {order.dropoffLocation.contact.number}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Delivery Proof Section */}
            <div className="bg-gray-800 p-4 rounded-xl border border-gray-700">
              <h3 className="text-lg font-medium text-white mb-2">
                Delivery Proof
              </h3>
              {order.deliveryProof && order.deliveryProof.deliveryProof ? (
                <div className="flex flex-col items-start">
                  <p className="text-sm text-gray-400 mb-2">
                    Delivered on:{" "}
                    {order.deliveryProof.deliveryProofCreatedAt
                      ? formatDateTime(
                          order.deliveryProof.deliveryProofCreatedAt
                        )
                      : "N/A"}
                  </p>
                  {typeof order.deliveryProof.deliveryProof === "string" ? (
                    <img
                      src={order.deliveryProof.deliveryProof}
                      alt="Delivery Proof"
                      className="w-full max-h-64 object-cover rounded-md border border-gray-700"
                    />
                  ) : (order.deliveryProof.deliveryProof as any)?.uri ? (
                    <img
                      src={(order.deliveryProof.deliveryProof as any).uri}
                      alt="Delivery Proof"
                      className="w-full max-h-64 object-cover rounded-md border border-gray-700"
                    />
                  ) : (
                    <p className="text-sm text-gray-400">
                      Proof available but cannot be displayed.
                    </p>
                  )}
                </div>
              ) : (
                <p className="text-sm text-gray-400">
                  No delivery proof available.
                </p>
              )}
            </div>
          </div>

          {/* Footer */}
          <div className="flex items-center justify-end px-6 py-4 border-t border-gray-700">
            <button
              onClick={handleSendReceipt}
              className="flex items-center gap-2 px-4 py-2 bg-indigo-600 hover:bg-indigo-500 rounded-md text-white text-sm font-medium transition"
            >
              <Send size={18} />
              Send Receipt
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PastOrderModal;
