import React from 'react';
import PropTypes from 'prop-types';
import './NotificationInspiration.css';

const NotificationInspiration = ({ title, body, onAdapt }) => {
  return (
    <div className="notification-box">
      <h2 className="notification-title">{title}</h2>
      <p className="notification-body">{body}</p>
      <button 
        className="adapt-button" 
        onClick={() => onAdapt(title, body)}
      >
        Adapt
      </button>
    </div>
  );
};

NotificationInspiration.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  onAdapt: PropTypes.func.isRequired,
};

export default NotificationInspiration;