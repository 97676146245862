import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Mail,
  Lock,
  ArrowRight,
  Building2,
  Package,
  Briefcase,
  Users,
  TrendingUp,
  Clock,
} from "lucide-react";
import { motion } from "framer-motion";
import { signUpEmailPassword } from "../../firebase/auth";
import { storeOnboardingInfo } from "../../firebase/user";
import logoImage from "../../../Images/logo.png";

const SignUp = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    businessName: "",
    deliveryVolume: "",
    businessSector: "",
  });
  const [error, setError] = useState("");

  const businessSectors = [
    "E-commerce",
    "Retail",
    "Restaurant",
    "Grocery",
    "Beauty & Cosmetics",
    "Health & Wellness",
    "Electronics",
    "Fashion & Apparel",
    "Flowers & Gifts",
    "Other",
  ];

  const deliveryVolumes = [
    { label: "Less than 10 deliveries", value: "<10" },
    { label: "10-50 deliveries", value: "10-50" },
    { label: "50-100 deliveries", value: "50-100" },
    { label: "100-500 deliveries", value: "100-500" },
    { label: "500+ deliveries", value: "500+" },
  ];

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const validateStep1 = () => {
    if (!formData.email || !formData.password || !formData.confirmPassword) {
      setError("All fields are required");
      return false;
    }
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match");
      return false;
    }
    if (formData.password.length < 6) {
      setError("Password must be at least 6 characters");
      return false;
    }
    return true;
  };

  const validateStep2 = () => {
    if (
      !formData.businessName ||
      !formData.deliveryVolume ||
      !formData.businessSector
    ) {
      setError("All fields are required");
      return false;
    }
    return true;
  };

  const handleNext = () => {
    if (currentStep === 1 && validateStep1()) {
      setError("");
      setCurrentStep(2);
    }
  };

  const handleBack = () => {
    setError("");
    setCurrentStep(1);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateStep2()) return;

    try {
      await signUpEmailPassword(formData.email, formData.password);
      // Here you would also save the additional business info to your database
      await storeOnboardingInfo(
        formData.businessName,
        formData.businessSector,
        formData.deliveryVolume
      );
      navigate("/business/book");
    } catch (err: any) {
      setError(err.message || "Sign up failed");
    }
  };

  // Benefits array remains the same as in LoginPage
  const benefits = [
    {
      icon: <Users className="w-6 h-6 text-blue-400" />,
      title: "62% Increase in Customer Retention",
      description:
        "Average increase in customer retention with instant delivery",
      gradient: "from-blue-500 to-blue-600",
    },
    {
      icon: <TrendingUp className="w-6 h-6 text-purple-400" />,
      title: "2.8x Higher Customer Spend",
      description: "Avg. Customer spend with instant delivery options",
      gradient: "from-purple-500 to-purple-600",
    },
    {
      icon: <Clock className="w-6 h-6 text-emerald-400" />,
      title: "Instant Pickup",
      description: "Rapid Collection, Rapid Gratification for your customers",
      gradient: "from-emerald-500 to-emerald-600",
    },
  ];

  return (
    <div className="min-h-screen w-full flex bg-gray-900">
      {/* Left Side - Marketing Content */}
      <div className="hidden lg:flex lg:w-1/2 p-12 flex-col justify-between relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900" />

        {/* Content */}
        <div className="relative z-10 space-y-8">
          {/* Logo - Fixed baseline alignment */}
          <a href="/business/book" className="flex items-center">
            <img
              src={logoImage}
              alt="Blitzo"
              className="h-14 w-auto self-center mr-1 mb-1 pb-3"
            />
            <span className="text-4xl text-white font-semibold ml-2 self-baseline">
              Blitzo
            </span>
            <span className="text-lg font-normal ml-2 text-gray-300 self-baseline">
              For Business
            </span>
          </a>

          {/* Main Copy */}
          <div className="space-y-4 max-w-lg">
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="text-4xl font-bold bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent"
            >
              Instant On-Demand Delivery for Your Business
            </motion.h1>

            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="text-lg text-gray-400"
            >
              Deliver your products to customers in minutes with one click
            </motion.p>
          </div>
        </div>

        {/* New Benefits Design */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
          className="relative z-10 space-y-4"
        >
          {benefits.map((benefit, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.4, delay: 0.6 + index * 0.1 }}
              className="flex items-center space-x-4 p-6 rounded-2xl 
                bg-gray-800/50 border border-gray-700/50 backdrop-blur-lg
                hover:bg-gray-800/70 transition-all duration-300"
            >
              {/* Icon Circle */}
              <div
                className={`p-3 rounded-xl bg-gradient-to-br ${
                  benefit.gradient
                }/10 
                border border-${benefit.gradient.split(" ")[1]}/20`}
              >
                {benefit.icon}
              </div>

              {/* Text Content */}
              <div>
                <h3 className="text-xl font-bold text-white mb-1">
                  {benefit.title}
                </h3>
                <p className="text-sm text-gray-400">{benefit.description}</p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>

      {/* Right Side - Sign Up Form */}
      <div className="w-full lg:w-1/2 flex items-center justify-center p-8">
        <div className="w-full max-w-md space-y-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-white">
              Create your account
            </h2>
            <p className="mt-2 text-gray-400">
              {currentStep === 1
                ? "First, let's set up your login details"
                : "Tell us about your business"}
            </p>
          </div>

          <form
            onSubmit={currentStep === 1 ? handleNext : handleSubmit}
            className="mt-8 space-y-6"
          >
            {currentStep === 1 ? (
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-2">
                    Email address
                  </label>
                  <div className="relative">
                    <Mail
                      className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400"
                      size={20}
                    />
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="w-full pl-12 pr-4 py-3 bg-gray-800/50 border border-gray-700 rounded-xl
                        text-white placeholder-gray-400 focus:outline-none focus:border-red-500
                        transition-colors"
                      placeholder="Enter your business email"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-2">
                    Password
                  </label>
                  <div className="relative">
                    <Lock
                      className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400"
                      size={20}
                    />
                    <input
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      className="w-full pl-12 pr-4 py-3 bg-gray-800/50 border border-gray-700 rounded-xl
                        text-white placeholder-gray-400 focus:outline-none focus:border-red-500
                        transition-colors"
                      placeholder="Create a password"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-2">
                    Confirm Password
                  </label>
                  <div className="relative">
                    <Lock
                      className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400"
                      size={20}
                    />
                    <input
                      type="password"
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      className="w-full pl-12 pr-4 py-3 bg-gray-800/50 border border-gray-700 rounded-xl
                        text-white placeholder-gray-400 focus:outline-none focus:border-red-500
                        transition-colors"
                      placeholder="Confirm your password"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-2">
                    Business Name
                  </label>
                  <div className="relative">
                    <Building2
                      className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400"
                      size={20}
                    />
                    <input
                      type="text"
                      name="businessName"
                      value={formData.businessName}
                      onChange={handleChange}
                      className="w-full pl-12 pr-4 py-3 bg-gray-800/50 border border-gray-700 rounded-xl
                        text-white placeholder-gray-400 focus:outline-none focus:border-red-500
                        transition-colors"
                      placeholder="Enter your business name"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-2">
                    Business Sector
                  </label>
                  <div className="relative">
                    <Briefcase
                      className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400"
                      size={20}
                    />
                    <select
                      name="businessSector"
                      value={formData.businessSector}
                      onChange={handleChange}
                      className="w-full pl-12 pr-4 py-3 bg-gray-800/50 border border-gray-700 rounded-xl
                        text-white placeholder-gray-400 focus:outline-none focus:border-red-500
                        transition-colors appearance-none"
                    >
                      <option value="">Select your business sector</option>
                      {businessSectors.map((sector) => (
                        <option key={sector} value={sector}>
                          {sector}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-400 mb-2">
                    Daily Delivery Volume
                  </label>
                  <div className="relative">
                    <Package
                      className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400"
                      size={20}
                    />
                    <select
                      name="deliveryVolume"
                      value={formData.deliveryVolume}
                      onChange={handleChange}
                      className="w-full pl-12 pr-4 py-3 bg-gray-800/50 border border-gray-700 rounded-xl
                        text-white placeholder-gray-400 focus:outline-none focus:border-red-500
                        transition-colors appearance-none"
                    >
                      <option value="">Select your delivery volume</option>
                      {deliveryVolumes.map((volume) => (
                        <option key={volume.value} value={volume.value}>
                          {volume.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            )}

            {error && <p className="text-red-500 text-sm">{error}</p>}

            <div className="flex space-x-4">
              {currentStep === 2 && (
                <button
                  type="button"
                  onClick={handleBack}
                  className="w-1/2 py-3 px-4 border border-gray-700 rounded-xl
                    text-white font-medium transition-colors hover:bg-gray-800"
                >
                  Back
                </button>
              )}
              <button
                type={currentStep === 1 ? "button" : "submit"}
                onClick={currentStep === 1 ? handleNext : undefined}
                className={`${
                  currentStep === 1 ? "w-full" : "w-1/2"
                } flex items-center justify-center
                  py-3 px-4 border border-transparent rounded-xl bg-red-500 hover:bg-red-600 
                  text-white font-medium transition-colors`}
              >
                <span>{currentStep === 1 ? "Continue" : "Create Account"}</span>
                <ArrowRight className="ml-2" size={20} />
              </button>
            </div>
          </form>

          <p className="text-center text-gray-400">
            Already have an account?{" "}
            <a
              href="/business/login"
              className="text-red-500 hover:text-red-400 font-medium"
            >
              Sign in
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
