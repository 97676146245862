import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Clock, MapPin, BikeIcon, Shield, ArrowUpRight } from "lucide-react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

// New Components from Home's redesign
import DarkNavbar from "../components/DarkNavbar";
import HowItWorks from "../components/HowItWorks";
import BusinessImpactNarrative from "../components/BusinessImpactNarrative";
import PremiumFleetPricing from "../components/PremiumFleetPricing";
import FleetSolutions from "../components/FleetSolutions";
import BottomCTA from "../components/BottomCta";
import Footer from "../components/footer";

// Styled Components for gradient text
const GradientText = styled.span`
  background: linear-gradient(90deg, #e54c4c 0%, #ff6b6b 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
`;

// ------------------------------
// Operating Hours Component
// ------------------------------
const OperatingHours = () => (
  <section className="bg-[#0A0C16] py-20">
    <div className="max-w-7xl mx-auto px-6 lg:px-8">
      <div className="flex flex-col md:flex-row items-start gap-12">
        <div className="md:w-1/3" data-aos="fade-up">
          <Clock className="text-[#E54C4C] mb-4" size={32} />
          <h2 className="text-2xl font-bold mb-4 text-white">
            Operating Hours
          </h2>
        </div>
        <div className="md:w-2/3" data-aos="fade-up" data-aos-delay="100">
          <p className="text-3xl font-bold bg-gradient-to-r from-gray-100 to-gray-300 bg-clip-text text-transparent mb-4">
            We're working pretty much always.
          </p>
          <div className="bg-[#0F121B]/80 rounded-xl p-8 shadow-sm hover:shadow-xl transition-all duration-300 border border-[#262D40]">
            <p className="text-xl text-gray-300 font-medium mb-4">
              Blitzo operates between 7am and 11pm, 7 days a week.
            </p>
            <p className="text-gray-400">
              Need a delivery outside these hours? Get in touch – we’ll do our
              best to make it happen!
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

// ------------------------------
// Operating Area (Coverage) Component
// ------------------------------
const OperatingArea = ({ coverageImage }) => (
  <section className="bg-[#0A0C16] py-20">
    <div className="max-w-7xl mx-auto px-6 lg:px-8">
      <div className="flex flex-col md:flex-row items-start gap-12">
        <div className="md:w-1/3" data-aos="fade-up">
          <MapPin className="text-[#E54C4C] mb-4" size={32} />
          <h2 className="text-2xl font-bold mb-4 text-white">Operating Area</h2>
        </div>
        <div className="md:w-2/3" data-aos="fade-up" data-aos-delay="100">
          <p className="text-3xl font-bold text-white mb-4 leading-tight">
            Delivering to <span className="text-[#E54C4C]">every area</span> in
            London.
          </p>
          <div className="h-80 bg-[#0F121B] rounded-lg mb-6 overflow-hidden border border-[#262D40]">
            <div className="w-full h-full flex items-center justify-center">
              <img
                src={coverageImage}
                alt="London Coverage Map"
                className="h-full object-cover"
              />
            </div>
          </div>
          <p className="text-sm text-gray-500">
            Full postcode list available on request.
          </p>
        </div>
      </div>
    </div>
  </section>
);

// ------------------------------
// Protection (Insurance) Component
// ------------------------------
const ProtectionSection = () => (
  <section className="bg-[#0A0C16] py-20">
    <div className="max-w-7xl mx-auto px-6 lg:px-8">
      <div className="flex flex-col md:flex-row items-start gap-12">
        <div className="md:w-1/3" data-aos="fade-up">
          <Shield className="text-[#E54C4C] mb-4" size={32} />
          <h2 className="text-2xl font-bold mb-4 text-white">
            Buyer Protection
          </h2>
        </div>
        <div className="md:w-2/3" data-aos="fade-up" data-aos-delay="100">
          <p className="text-3xl font-bold text-white mb-4">
            Every delivery is <span className="text-[#E54C4C]">protected</span>{" "}
            — at no extra cost.
          </p>
          <div className="bg-[#0F121B]/80 rounded-xl p-8 shadow-sm hover:shadow-xl transition-all duration-300 border border-[#262D40]">
            <p className="text-xl text-gray-300 font-medium mb-4">
              We take the safety of your items seriously. All deliveries are
              automatically covered by our comprehensive protection policy.
            </p>
            <p className="text-gray-400 italic">
              Subject to our standard terms and conditions.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

// ------------------------------
// HowWeWork Component (Updated)
// ------------------------------
const HowWeWork = () => {
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-out",
      once: true,
    });
  }, []);

  // Example: replace with your coverage image path
  const coverageImage = require("../Images/coverage-london.jpg");

  return (
    <div className="bg-white min-h-screen">
      {/* New Dark Navbar */}
      <DarkNavbar />

      {/* Hero Section */}
      <div className="relative bg-gradient-to-br from-[#080A12] to-[#0F121B] pt-32 pb-16">
        <div
          className="max-w-7xl mx-auto px-6 lg:px-8 text-center"
          data-aos="fade-up"
        >
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-white mb-6">
            <span className="block mb-2">Same Day Delivery?</span>
            <span className="relative inline-block">
              <GradientText>Too Slow, We Do Same Hour.</GradientText>
            </span>
          </h1>
          <p className="text-xl md:text-2xl text-gray-300 max-w-3xl mx-auto mt-8 leading-relaxed">
            Choose how you want to work with us – via our mobile app, web
            platform, or direct integration. Regardless, we promise a flawless,
            fully automated experience powered by{" "}
            <span className="text-[#E54C4C] font-semibold">
              Blitzo Intelligence
            </span>
            .
          </p>
        </div>
      </div>

      {/* How It Works Section */}
      <HowItWorks />

      {/* Operating Hours */}
      <OperatingHours />

      {/* Operating Area / Coverage */}
      <OperatingArea coverageImage={coverageImage} />

      {/* Protection / Insurance */}
      <ProtectionSection />

      {/* Premium Fleet Section */}
      <PremiumFleetPricing />

      {/* Fleet Solutions Section */}
      <FleetSolutions />

      {/* Bottom CTA */}
      <BottomCTA />

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default HowWeWork;
