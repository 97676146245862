import React, { useEffect, useState } from "react";
import { getPendingRiders, getQuariedRiderDocument } from "../../Firebase/rider";
import DocumentCard from "./DocumentCard";
import "./RiderDashboard.css";
import SideBar from "../../components/SideBar";

const PendingRequest = () => {
  const [pendingDocs, setPendingDocs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPendingDocs = async () => {
      try {
        const pendingRiderIds = await getPendingRiders();
        const documents = await getQuariedRiderDocument(pendingRiderIds);
        const docsWithIds = documents.map((doc, index) => ({
          ...doc,
          id: pendingRiderIds[index]
        }));
        setPendingDocs(docsWithIds);
      } catch (error) {
        console.error("Error fetching documents:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPendingDocs();
  }, []);

  return (
    <div className="adminWrapper">
      <div><SideBar /></div>
      <div className="mainContent">
        <h1>Pending Requests</h1>
        {loading ? (
            <p>Loading pending requests...</p>
        ) : (
            <div className="riders-grid">
            {pendingDocs.map((doc) => (
                <DocumentCard key={doc.id} documentData={doc} />
            ))}
            </div>
        )}
      </div>
    </div>
  );
};

export default PendingRequest;