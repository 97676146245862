import React from "react";
import { ChevronRight, Check, Zap, Brain, ArrowRight } from "lucide-react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import SpeakToSalesModal from "./SpeakToSalesModal";

// Gradient text component
const GradientText = styled.span`
  background: linear-gradient(90deg, #e54c4c 0%, #ff6b6b 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
`;

// Blue gradient text component
const BlueGradient = styled.span`
  background: linear-gradient(90deg, #4ecafc 0%, #6a8cff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
`;

/**
 * BottomCTA - A reusable call-to-action component for the bottom of pages
 *
 * @param {Object} props
 * @param {string} [props.title] - Title text (default: "Ready to Transform Your Delivery Experience?")
 * @param {string} [props.description] - Description text
 * @param {Object} [props.businessOption] - Configuration for business option
 * @param {Object} [props.enterpriseOption] - Configuration for enterprise option
 * @param {string} [props.businessRoute] - Route for business option button
 * @param {string} [props.enterpriseRoute] - Route for enterprise option button
 * @param {Function} [props.onBusinessClick] - Optional callback for business button click
 * @param {Function} [props.onEnterpriseClick] - Optional callback for enterprise button click
 */
const BottomCTA = ({
  title = "Ready to Unlock <GradientText>Same Hour</GradientText> Delivery?",
  description = "Choose the solution that fits your business needs – start delivering in under 60 minutes or explore tailored enterprise options for high-volume operations.",
  businessOption = {
    title: "Blitzo for Business",
    description:
      "Perfect for local businesses and growing retailers. Connect your store to our 1000+ Rider network and deliver in under 60 minutes with zero operational overhead.",
    features: [
      "Sub-60 minute delivery",
      "Multiple vehicle types",
      "AI-powered operations",
    ],
    buttonText: "Try Blitzo for Business",
  },
  enterpriseOption = {
    title: "Enterprise Solutions",
    description:
      "Designed for high-volume operations. Our AI Rider Recruitment Agents will curate, vet, and train a fleet infrastructure aligned with your specific business needs. Zero operational overhead.",
    features: [
      "Custom vehicle mix",
      "Precise geographic coverage",
      "Specialized handling requirements",
    ],
    buttonText: "Speak to Sales",
  },
  businessRoute = "/business",
  enterpriseRoute = "https://blitzo.co.uk/business/enterprise",
  onBusinessClick,
  onEnterpriseClick,
  backgroundProps = {
    gradient: true, // Enable gradient background
    useDarkShade: false, // For an even darker shade
    gridOpacity: 0.08, // Grid opacity (0-1)
    glowStrength: 0.2, // Strength of glow effects (0-1)
    style: "modern", // Options: "modern", "minimal", "tech"
  },
}) => {
  const navigate = useNavigate();
  const [showSalesModal, setShowSalesModal] = React.useState(false);

  const handleBusinessClick = () => {
    if (onBusinessClick) {
      onBusinessClick();
    } else {
      navigate(businessRoute);
    }
  };

  const handleEnterpriseClick = () => {
    if (onEnterpriseClick) {
      onEnterpriseClick();
    } else {
      setShowSalesModal(true);
    }
  };

  // Process the title to render gradient text
  const processTitle = (text) => {
    if (!text.includes("<GradientText>")) return <>{text}</>;

    const parts = text.split(/<GradientText>(.*?)<\/GradientText>/g);
    return (
      <>
        {parts.map((part, i) => {
          if (i % 2 === 0) return part;
          return <GradientText key={i}>{part}</GradientText>;
        })}
      </>
    );
  };

  return (
    <div className="py-24 relative overflow-hidden">
      <SpeakToSalesModal
        isOpen={showSalesModal}
        onClose={() => setShowSalesModal(false)}
      />
      {/* Enhanced Background Elements */}
      <div className="absolute inset-0">
        {/* Base gradient background */}
        <div
          className={`absolute inset-0 ${
            backgroundProps.useDarkShade
              ? "bg-gradient-to-b from-[#070A14] via-[#060810] to-[#040608]"
              : "bg-gradient-to-b from-[#0A0D18] via-[#080A12] to-[#070A10]"
          }`}
        ></div>
      </div>
      {/* Content Container */}
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        {/* Main Content */}
        <div
          className="relative bg-[#0A0D16]/70 backdrop-blur-md rounded-xl shadow-2xl overflow-hidden p-8 md:p-10"
          data-aos="fade-up"
        >
          <div className="text-center mb-10">
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
              {processTitle(title)}
            </h2>
          </div>

          {/* Two-Column Option Layout */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8 max-w-4xl mx-auto">
            {/* Option 1: Try Blitzo for Business */}
            <div
              className="bg-[#0F121B] rounded-xl border border-[#262D40] p-6 hover:border-[#414D75] transition-all duration-300 hover:shadow-lg"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="mb-4 flex items-center">
                <div className="w-10 h-10 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mr-4">
                  <Zap className="text-[#E54C4C]" size={22} />
                </div>
                <h3 className="text-xl text-white font-semibold">
                  {businessOption.title}
                </h3>
              </div>

              <p className="text-gray-300 mb-12 h-20">
                {businessOption.description}
              </p>

              <div className="relative group">
                <div className="absolute -inset-0.5 bg-gradient-to-r from-[#E54C4C] to-[#FF6B6B] rounded-lg blur opacity-30 group-hover:opacity-100 transition duration-500"></div>
                <button
                  onClick={handleBusinessClick}
                  className="relative w-full px-6 py-3 bg-gradient-to-r from-[#E54C4C] to-[#FF6B6B] text-white rounded-lg shadow-lg shadow-[#E54C4C]/20 flex items-center justify-center group-hover:shadow-[#E54C4C]/40 transition-all duration-300 font-medium"
                >
                  {businessOption.buttonText}
                  <ChevronRight
                    className="ml-2 group-hover:translate-x-1 transition-transform"
                    size={18}
                  />
                </button>
              </div>
            </div>

            {/* Option 2: Enterprise Solutions */}
            <div
              className="bg-[#0F121B] rounded-xl border border-[#262D40] p-6 hover:border-[#414D75] transition-all duration-300 hover:shadow-lg"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="mb-4 flex items-center">
                <div className="w-10 h-10 rounded-full bg-[#4ECAFC]/10 flex items-center justify-center mr-4">
                  <Brain className="text-[#4ECAFC]" size={22} />
                </div>
                <h3 className="text-xl text-white font-semibold">
                  {enterpriseOption.title}
                </h3>
              </div>

              <p className="text-gray-300 mb-12 h-20">
                {enterpriseOption.description}
              </p>

              <div className="relative group">
                <div className="absolute -inset-0.5 bg-gradient-to-r from-[#4ECAFC]/30 to-[#6A8CFF]/30 rounded-lg blur opacity-0 group-hover:opacity-100 transition duration-500"></div>
                <button
                  onClick={handleEnterpriseClick}
                  className="relative w-full px-6 py-3 bg-[#0A0D16] border border-[#4ECAFC]/30 text-white rounded-lg hover:border-[#4ECAFC] flex items-center justify-center group-hover:bg-[#0A0D16]/80 transition-all duration-300"
                >
                  {enterpriseOption.buttonText}
                  <ArrowRight
                    className="ml-2 group-hover:translate-x-1 transition-transform text-[#4ECAFC]"
                    size={18}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Animation Styles */}
      <style jsx>{`
        @keyframes pulse-slow {
          0%,
          100% {
            transform: scale(1);
            opacity: ${backgroundProps.glowStrength * 0.5};
          }
          50% {
            transform: scale(1.12);
            opacity: ${backgroundProps.glowStrength * 0.9};
          }
        }

        @keyframes pulse-slow-delay {
          0%,
          100% {
            transform: scale(1);
            opacity: ${backgroundProps.glowStrength * 0.5};
          }
          50% {
            transform: scale(1.18);
            opacity: ${backgroundProps.glowStrength * 0.8};
          }
        }

        @keyframes float {
          0%,
          100% {
            transform: translateY(0) translateX(0);
          }
          50% {
            transform: translateY(-10px) translateX(5px);
          }
        }
      `}</style>
    </div>
  );
};

export default BottomCTA;
