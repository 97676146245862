import React, { useState, useEffect, useRef } from "react";
import {
  ArrowUpRight,
  ChevronRight,
  Zap,
  Shield,
  Users,
  Package,
  BarChart,
  Brain,
  Clock,
  Check,
  Layers,
  Bot,
  ArrowRight,
  Settings,
  Smartphone,
  Sparkles,
  Globe,
  Code,
  Truck,
  Phone,
  MessageSquare,
  Calendar,
  FileCheck,
  Mail,
  Cpu,
  UserCheck,
  Building,
  Database,
  Headphones,
  PlayCircle,
  Star,
  Loader,
  PauseCircle,
} from "lucide-react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import Footer from "../components/footer";
import DarkNavbar from "../components/DarkNavbar";
import EightXEight from "../Images/Integrations/Freight/8x8.png";
import Dat from "../Images/Integrations/Freight/Dat.png";
import Descartes from "../Images/Integrations/Freight/Descartes.png";
import Excel from "../Images/Integrations/Freight/Excel.svg";
import Gmail from "../Images/Integrations/Freight/Gmail.png";
import Hubspot from "../Images/Integrations/Freight/Hubspot.png";
import MCP from "../Images/Integrations/Freight/MCP.png";
import Outlook from "../Images/Integrations/Freight/Outlook.png";
import Revenova from "../Images/Integrations/Freight/Revenova.png";
import Ringcentral from "../Images/Integrations/Freight/Ringcentral.png";
import Samsara from "../Images/Integrations/Freight/Samsara.png";
import Slack from "../Images/Integrations/Freight/Slack.png";
import Tai from "../Images/Integrations/Freight/Tai.png";
import Teams from "../Images/Integrations/Freight/Teams.png";
import TransportPro from "../Images/Integrations/Freight/TransportPro.png";
import Turvo from "../Images/Integrations/Freight/Turvo.png";
import Twilio from "../Images/Integrations/Freight/Twilio.png";
import Whatsapp from "../Images/Integrations/Freight/Whatsapp.png";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../Firebase/firebase-config";
import OperatorNavbar from "../components/OperatorNavbar";
import OperatorFooter from "../components/OperatorFooter";
import ToughNegotiation from "../Audio/Freight_Tough_Clean.mp3";
import { AudioVisualizer } from "react-audio-visualize";
import { Helmet } from "react-helmet";

// Animated count up component
const CountUp = ({ end, duration = 2000, prefix = "", suffix = "" }) => {
  const [count, setCount] = useState(0);
  const countRef = useRef(null);

  useEffect(() => {
    let startTime;
    let animationFrame;

    const animate = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;
      const percentage = Math.min(progress / duration, 1);
      setCount(Math.floor(percentage * end));

      if (percentage < 1) {
        animationFrame = requestAnimationFrame(animate);
      }
    };

    animationFrame = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrame);
  }, [end, duration]);

  return (
    <span>
      {prefix}
      {count}
      {suffix}
    </span>
  );
};

// Feature card component
const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="p-6 bg-[#F9FAFB]/80 border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 rounded-xl hover:border-[#94A3B8] transition-all duration-300">
    <div className="w-12 h-12 bg-[#E54C4C]/10 rounded-xl flex items-center justify-center mb-4">
      <Icon className="text-[#E54C4C]" size={24} />
    </div>
    <h3 className="text-xl font-semibold text-[#111827] mb-2">{title}</h3>
    <p className="text-[#374151]">{description}</p>
  </div>
);

// Process Node component for the workflow diagram
const NetworkAnimation = () => (
  <div className="absolute inset-0 z-0 overflow-hidden">
    {/* Grid lines */}
    <div className="absolute inset-0 opacity-10">
      <div
        className="absolute top-0 left-0 right-0 bottom-0"
        style={{
          backgroundImage: `
             linear-gradient(to right, rgba(255,255,255,0.05) 1px, transparent 1px),
             linear-gradient(to bottom, rgba(255,255,255,0.05) 1px, transparent 1px)
           `,
          backgroundSize: "20px 20px",
        }}
      ></div>
    </div>

    {/* Animated dots and connections */}
    <div className="absolute inset-0">
      {[...Array(15)].map((_, i) => (
        <div
          key={i}
          className="absolute w-1 h-1 rounded-full bg-[#E54C4C]/40"
          style={{
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
            animation: `float ${3 + Math.random() * 4}s infinite ease-in-out ${
              Math.random() * 2
            }s`,
          }}
        ></div>
      ))}

      {/* Blue dots */}
      {[...Array(10)].map((_, i) => (
        <div
          key={i}
          className="absolute w-1 h-1 rounded-full bg-[#2563EB]/40"
          style={{
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
            animation: `float ${2 + Math.random() * 3}s infinite ease-in-out ${
              Math.random() * 2
            }s`,
          }}
        ></div>
      ))}
    </div>

    {/* Radial gradient layers */}
    <div
      className="absolute inset-0 opacity-40"
      style={{
        background:
          "radial-gradient(50% 50% at 50% 50%, rgba(229, 76, 76, 0.08) 0%, rgba(10, 13, 20, 0) 100%)",
      }}
    ></div>
    <div
      className="absolute inset-0 opacity-20"
      style={{
        background:
          "radial-gradient(70% 70% at 30% 30%, rgba(78, 202, 252, 0.1) 0%, rgba(10, 13, 20, 0) 100%)",
      }}
    ></div>

    {/* Styles for animations */}
    <style jsx>{`
      @keyframes float {
        0%,
        100% {
          transform: translateY(0) translateX(0);
        }
        50% {
          transform: translateY(-10px) translateX(5px);
        }
      }
    `}</style>
  </div>
);

// Styled gradient text components
const GradientText = ({ children }) => (
  <span className="bg-gradient-to-r from-[#DC2626] to-[#F87171] inline bg-clip-text text-transparent">
    {children}
  </span>
);

// Freight workflow diagram styled like HappyRobot but with dark theme
const FreightWorkflowDiagram = () => {
  return (
    <div className="relative min-h-[550px] bg-gradient-to-br from-[#FFFFFF] to-[#F8FAFC] shadow-lg shadow-[#E5E7EB]/30/50 rounded-xl overflow-hidden border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40">
      {/* Subtle background pattern */}
      <div className="absolute inset-0 opacity-5">
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `linear-gradient(to right, rgba(255,255,255,0.07) 1px, transparent 1px), 
                                linear-gradient(to bottom, rgba(255,255,255,0.07) 1px, transparent 1px)`,
            backgroundSize: "40px 40px",
          }}
        ></div>
      </div>

      {/* Load tendered badge and node */}
      <div className="absolute top-10 left-7">
        <div className="bg-[#DCFCE7] text-[#166534] font-semibold text-s px-4 py-2 rounded-md inline-flex items-center">
          Load Tendered
        </div>
        <div className="relative mt-2">
          <div className="w-12 h-12 rounded-full bg-[#F9FAFB] border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 flex items-center justify-center shadow-lg absolute top-0 left-12">
            <Package className="text-[#7C3AED]" size={20} />
          </div>
          <div className="w-20 h-0.5 bg-gradient-to-l from-[#E54C4C] to-[#262D40] absolute top-6 left-20"></div>
        </div>
      </div>

      {/* Outbound call card */}
      <div className="absolute top-6 left-[180px] w-[280px]">
        <div className="bg-[#F9FAFB] rounded-xl overflow-hidden border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40">
          <div className="p-6">
            {/* Phone header */}
            <div className="flex items-start mb-3">
              <Phone className="text-[#E54C4C] mr-2 mt-1" size={16} />
              <div>
                <div className="text-xs text-[#52525B]">Phone call</div>
                <div className="text-sm font-medium text-[#111827]">
                  Make outbound sales call
                </div>
              </div>
            </div>

            {/* AI worker card */}
            <div className="bg-[#F9FAFB] rounded-lg p-4 mb-3 border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40">
              <div className="flex items-center">
                <div className="w-8 h-8 rounded-full bg-gradient-to-br from-[#FFFFFF] to-[#F8FAFC] shadow-lg shadow-[#E5E7EB]/30 border border-[#E54C4C] flex items-center justify-center mr-2">
                  <span className="text-[#E54C4C] font-bold text-xs">BO</span>
                </div>
                <div>
                  <div className="text-xs text-[#52525B]">Agent</div>
                  <div className="text-sm text-[#111827]">Blitzo AI Agent</div>
                </div>
              </div>
            </div>

            {/* Dynamic call handling */}
            <div className="bg-[#F9FAFB] rounded-lg p-2 mb-3">
              <div className="flex items-center">
                <div className="w-5 h-5 rounded-full bg-[#F9FAFB] flex items-center justify-center mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="text-[#374151]"
                  >
                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="9" cy="7" r="4"></circle>
                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                  </svg>
                </div>
                <div className="text-sm text-[#374151]">
                  Dynamic call handling
                </div>
              </div>
            </div>

            {/* Action buttons */}
            <div className="grid grid-cols-2 gap-2">
              <div className="bg-[#F9FAFB] rounded-md p-2 flex items-center justify-center">
                <div className="w-4 h-4 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mr-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="text-[#E54C4C]"
                  >
                    <path d="M13 2L3 14h9l-1 8 10-12h-9l1-8z"></path>
                  </svg>
                </div>
                <span className="text-xs text-[#374151]">Negotiate rate</span>
              </div>
              <div className="bg-[#F9FAFB] rounded-md p-4 flex items-center justify-center">
                <div className="w-4 h-4 rounded-full bg-[#EDE9FE] text-[#7C3AED] font-semibold flex items-center justify-center mr-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="text-[#4ECAFC]"
                  >
                    <path d="M9 11l3 3L22 4"></path>
                    <path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path>
                  </svg>
                </div>
                <span className="text-xs text-[#374151] ml-1">
                  Confirm Pickup
                </span>
              </div>
            </div>
          </div>

          {/* Connecting line */}
          <div className="absolute left-1/4 h-16 w-0.5 bg-gradient-to-b from-[#E54C4C] to-[#262D40] top-full"></div>
        </div>
      </div>

      {/* Load booked */}
      <div className="absolute bottom-[120px] left-5">
        <div className="relative">
          <div className="w-12 h-12 rounded-full bg-[#F9FAFB] border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 flex items-center justify-center shadow-lg">
            <FileCheck className="text-[#7C3AED]" size={20} />
          </div>
          <div className="absolute top-6 left-12 w-10 h-0.5 bg-[#262D40]"></div>

          {/* New connecting line to Clock */}
          <div className="absolute top-20 right-[-100px] w-0.5 h-[27px] bg-gradient-to-b from-[#4ECAFC] to-[#262D40]"></div>
          <div className="absolute bottom-[-60px] right-[-200px] w-[100px] h-0.5 bg-gradient-to-r from-[#262D40] to-[#4ECAFC]"></div>
        </div>
        <div className="absolute left-[60px] top-0 ml-2 bg-[#F9FAFB] rounded-lg p-3 border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40">
          <div className="flex items-center">
            <div className="flex-1">
              <div className="text-sm font-medium text-[#111827]">
                Load booked
              </div>
              <div className="flex items-center mt-1">
                <span className="text-xs text-[#52525B] mr-2">
                  Send rate confirmation
                </span>
                <span className="ml-2 text-xs text-[#4ECAFC] bg-[#EDE9FE] text-[#7C3AED] font-semibold px-2 py-0.5 rounded">
                  £630
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Clock icon - Updated position to align with the connecting line */}
      <div className="absolute bottom-[40px] left-[262px]">
        <div className="w-10 h-10 rounded-full bg-[#F9FAFB] border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 flex items-center justify-center">
          <Clock className="text-[#7C3AED]" size={18} />
        </div>
        <div className="absolute top-5 left-10 w-16 h-0.5 bg-gradient-to-r from-[#262D40] to-[#4ECAFC]"></div>
      </div>

      {/* Call driver */}
      <div className="absolute bottom-[40px] left-[330px]">
        <div className="bg-[#F9FAFB] rounded-lg p-3 border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 w-56">
          <div className="flex items-center">
            <Phone className="text-[#E54C4C] mr-2" size={16} />
            <div className="flex-1">
              <div className="text-xs text-[#52525B]">Call driver</div>
              <div className="flex items-center justify-between">
                <span className="text-sm text-[#111827]">Check arrival</span>
                <span className="text-xs text-[#4ECAFC] bg-[#EDE9FE] text-[#7C3AED] font-semibold px-2 py-0.5 rounded">
                  9:30am
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const HeroSection = () => {
  const scrollToCta = () => {
    const ctaSection = document.getElementById("cta-section");
    if (ctaSection) {
      ctaSection.scrollIntoView({ behavior: "smooth" });
      // Update URL hash without jumping
      window.history.pushState(null, "#cta-section");
    }
  };
  return (
    <div className="relative min-h-screen flex flex-col justify-center overflow-hidden bg-gradient-to-br from-[#FFFFFF] to-[#F8FAFC]">
      {/* Background animation */}
      <NetworkAnimation />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-28 md:pt-28 pb-24 relative z-10">
        {/* Hero Badge */}
        <div className="text-center mb-8">
          <div className="inline-flex items-center px-3 py-1 rounded-full bg-[#E54C4C]/10 border border-[#E54C4C]/20 text-[#E54C4C] text-xs mb-4">
            <Sparkles className="mr-2" size={12} />
            <span>AI Agent based Communication for Logistics</span>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
          {/* Left side - Copy and CTA */}
          <div className="text-left">
            <h1 className="text-4xl sm:text-5xl font-bold text-[#111827] mb-6 leading-tight">
              <GradientText>AI Workers. </GradientText>
              <br />
              Built for Logistics.
            </h1>
            <p className="text-xl text-[#374151] mb-10">
              Our AI Agents handle calls, emails, rate negotiations, and
              consignment updates 24/7, saving your team over 4 hours daily.
            </p>

            <div className="flex flex-col sm:flex-row items-start gap-4">
              <button
                onClick={() => {
                  const ctaSection = document.getElementById("cta-section");
                  if (ctaSection) {
                    ctaSection.scrollIntoView({ behavior: "smooth" });
                  }
                }}
                className="px-6 py-3 bg-gradient-to-r from-[#DC2626] to-[#F87171] text-white rounded-lg shadow-lg shadow-[#E54C4C]/20 flex items-center justify-center hover:shadow-[#E54C4C]/40 transition-all duration-300"
              >
                Speak to Us
                <span className="ml-2 text-xs bg-white/20 px-2 py-0.5 rounded">
                  2 spots left for Pilot
                </span>
                <ArrowRight className="ml-2" size={18} />
              </button>
            </div>

            <div className="mt-8 text-[#374151] text-sm">
              <p>Trusted by leading freight forwarders in the UK and Europe.</p>
            </div>
          </div>

          {/* Right side - Workflow Diagram */}
          <div>
            <FreightWorkflowDiagram />
          </div>
        </div>
      </div>
    </div>
  );
};

// AudioWaveform component using react-audio-visualize correctly
const AudioWaveform = ({ isPlaying, audioFile, currentTime, duration }) => {
  const [blob, setBlob] = useState(null);
  const [containerWidth, setContainerWidth] = useState(500);
  const visualizerRef = useRef(null);
  const containerRef = useRef(null);

  // Calculate container width and update when window resizes
  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    // Initial width calculation
    updateWidth();

    // Add resize listener
    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  // Load audio file into a blob when component mounts
  useEffect(() => {
    const loadAudioBlob = async () => {
      try {
        const response = await fetch(audioFile);
        const arrayBuffer = await response.arrayBuffer();
        const audioBlob = new Blob([arrayBuffer], { type: "audio/wav" });
        setBlob(audioBlob);
        console.log("Audio blob created successfully:", audioBlob);
      } catch (error) {
        console.error("Error loading audio:", error);
      }
    };

    loadAudioBlob();
  }, [audioFile]);

  if (!blob) {
    // Show placeholder while loading
    return (
      <div
        ref={containerRef}
        className="h-8 w-full flex items-center bg-[#F9FAFB]"
      >
        <div className="h-4 w-full bg-[#E54C4C]/20 animate-pulse rounded"></div>
      </div>
    );
  }

  return (
    <div
      ref={containerRef}
      className="h-8 w-full"
      style={{ minHeight: "32px" }}
    >
      <AudioVisualizer
        ref={visualizerRef}
        blob={blob}
        width={containerWidth || 500} // Use measured width or fallback to 500
        height={32}
        barWidth={1}
        gap={1}
        barColor={"rgba(229, 76, 76, 0.4)"} // Unplayed bars are transparent red
        barPlayedColor={"#E54C4C"} // Played bars are solid red
        currentTime={currentTime}
        backgroundColor={"transparent"}
        style={{ display: "block" }} // Force display block
      />
    </div>
  );
};

// Format time in MM:SS format
const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
};

const VoiceCommunicationSamples = ({ scrollToCta }) => {
  // Audio player state
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentAudio, setCurrentAudio] = useState(null);
  const audioRef = useRef(null);

  // Update current time during playback more frequently for smoother updates
  useEffect(() => {
    let interval;
    if (isPlaying) {
      interval = setInterval(() => {
        if (audioRef.current) {
          setCurrentTime(audioRef.current.currentTime);
        }
      }, 50); // More frequent updates for smoother progress
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isPlaying]);

  const togglePlay = (audioFile) => {
    if (isPlaying && currentAudio === audioFile) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      if (audioRef.current) {
        // Only set src if it's a different audio file
        if (currentAudio !== audioFile) {
          audioRef.current.src = audioFile;
          audioRef.current.load();
        }

        // Use promise-based play with error handling
        const playPromise = audioRef.current.play();
        if (playPromise !== undefined) {
          playPromise
            .then((_) => {
              setCurrentAudio(audioFile);
              setIsPlaying(true);
            })
            .catch((err) => {
              console.error("Error playing audio:", err);
            });
        }
      }
    }
  };

  // Handle audio playback end
  const handleAudioEnd = () => {
    setIsPlaying(false);
    setCurrentTime(0);
  };

  // Handle audio metadata loaded
  const handleMetadataLoaded = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  // Handle clicking on the waveform to seek
  const handleWaveformClick = (e) => {
    if (!audioRef.current) return;

    // Calculate click position relative to the waveform container
    const rect = e.currentTarget.getBoundingClientRect();
    const clickPosition = (e.clientX - rect.left) / rect.width;

    // Set current time based on click position
    const newTime = clickPosition * duration;
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);

    // Start playing if paused
    if (!isPlaying) {
      audioRef.current
        .play()
        .then(() => {
          setIsPlaying(true);
        })
        .catch((err) => {
          console.error("Error playing audio:", err);
        });
    }
  };

  // Calculate waveform width based on screen size
  const calculateWaveformWidth = () => {
    if (typeof window !== "undefined") {
      return Math.min(window.innerWidth - 100, 500);
    }
    return 500;
  };

  const sampleItems = [
    {
      title: "Rate Negotiation",
      description:
        "AI secures best rates with natural conversation. We remember every lane and every rate with past conversations.",
      icon: Truck,
      language: "English",
      audioFile: ToughNegotiation,
      featured: true,
    },
    {
      title: "Check Calls",
      description:
        "Track & trace automation with real-time POD updates. Automatically contact drivers and update your TMS with current location and ETA.",
      icon: Phone,
      language: "English",
      featured: false,
    },
    {
      title: "Delivery Slot Booking",
      description:
        "Intelligent scheduling & slot coordination. AI handles warehouse communication and secures optimal delivery slots.",
      icon: Calendar,
      language: "English",
      featured: false,
    },
    {
      title: "Goods-in-Transit Documentation",
      description:
        "CMR & insurance verification. Automated checks ensure all documentation is valid and compliant with regulations.",
      icon: Shield,
      language: "French",
      featured: false,
    },
  ];

  return (
    <div id="live-examples" className="py-16 bg-[#F9FAFB]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-14">
          <h2 className="text-3xl md:text-4xl font-bold text-[#111827] mb-4">
            Your <GradientText>24/7, Top Performing</GradientText> Freight
            Partner
          </h2>
          <p className="text-[#374151] max-w-3xl mx-auto">
            Handling critical communications across all channels, any time of
            day
          </p>
        </div>

        <div className="bg-gradient-to-br from-[#FFFFFF] to-[#F8FAFC] shadow-lg shadow-[#E5E7EB]/30 rounded-xl p-8 border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 mb-12">
          <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-8">
            <div>
              <h3 className="text-2xl font-bold text-[#111827] mb-2">
                Experience Blitzo AI in Action
              </h3>
              <p className="text-[#374151]">
                Listen to how Blitzo handles real-world freight communications
              </p>
            </div>
            <div className="flex items-center mt-4 md:mt-0 space-x-2">
              <div className="bg-[#F9FAFB] px-3 py-1.5 rounded-lg flex items-center">
                <Globe className="text-[#4ECAFC] mr-2" size={16} />
                <span className="text-[#111827] text-sm">
                  4 languages supported{" (10+  in development)"}
                </span>
              </div>
              <div className="bg-[#F9FAFB] px-3 py-1.5 rounded-lg flex items-center">
                <Clock className="text-[#4ECAFC] mr-2" size={16} />
                <span className="text-[#111827] text-sm">
                  24/7 availability
                </span>
              </div>
            </div>
          </div>

          {/* Featured audio sample */}
          <div className="bg-[#F9FAFB] rounded-xl border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 hover:border-[#94A3B8] transition-all duration-300 mb-8">
            <div className="p-5">
              <div className="flex items-center justify-between mb-3">
                <div className="flex items-center">
                  <div className="w-12 h-12 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mr-3">
                    <Truck className="text-[#E54C4C]" size={24} />
                  </div>
                  <div>
                    <div className="flex items-center">
                      <h4 className="text-[#111827] font-medium text-lg">
                        Rate Negotiation
                      </h4>
                      <span className="ml-3 px-2 py-1 bg-[#E54C4C]/10 text-[#E54C4C] text-xs rounded-full">
                        Listen Now
                      </span>
                    </div>
                    <p className="text-[#374151] mt-1">
                      Listen to our AI agent negotiate with a carrier to secure
                      the best rate.
                    </p>
                  </div>
                </div>
                <div className="bg-[#F9FAFB] px-2 py-1 rounded text-xs text-[#4ECAFC]">
                  English
                </div>
              </div>

              <div className="flex items-center bg-[#F9FAFB] rounded-lg p-4 cursor-pointer border border-[#161B29]">
                <button
                  onClick={() => togglePlay(ToughNegotiation)}
                  className="w-10 h-10 rounded-full bg-gradient-to-br from-[#FFFFFF] to-[#F8FAFC] shadow-lg shadow-[#E5E7EB]/30 flex items-center justify-center mr-4 border border-[#E54C4C] hover:bg-[#E54C4C]/10 transition-all duration-300 flex-shrink-0"
                >
                  {isPlaying && currentAudio === ToughNegotiation ? (
                    <PauseCircle className="text-[#E54C4C]" size={24} />
                  ) : (
                    <PlayCircle className="text-[#E54C4C]" size={24} />
                  )}
                </button>
                <div className="flex-grow w-full" onClick={handleWaveformClick}>
                  <AudioWaveform
                    isPlaying={isPlaying && currentAudio === ToughNegotiation}
                    audioFile={ToughNegotiation}
                    currentTime={currentTime}
                    duration={duration}
                    width={calculateWaveformWidth()}
                  />
                </div>
                <div className="text-[#374151] ml-4 w-16 text-right text-sm flex-shrink-0">
                  {isPlaying && currentAudio === ToughNegotiation
                    ? formatTime(currentTime)
                    : duration
                    ? formatTime(duration)
                    : "0:00"}
                </div>
              </div>

              <div className="mt-4 text-[#374151] text-sm">
                <p>
                  *Customisable to your business needs - from negotiation
                  tactics, down to how you say hello, we can customise Blitzo
                  Operator to sound like your team.
                </p>
              </div>
            </div>
          </div>

          {/* Other use cases in a grid */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-10">
            {sampleItems
              .filter((item) => !item.featured)
              .map((item, index) => (
                <div
                  key={index}
                  className="bg-[#F9FAFB] rounded-xl border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 hover:border-[#94A3B8] transition-all duration-300"
                >
                  <div className="p-5">
                    <div className="flex items-center justify-between mb-3">
                      <div className="flex items-center">
                        <div className="w-10 h-10 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mr-3">
                          <item.icon className="text-[#E54C4C]" size={20} />
                        </div>
                        <div>
                          <h4 className="text-[#111827] font-medium">
                            {item.title}
                          </h4>
                        </div>
                      </div>
                      <div className="bg-[#F9FAFB] px-2 py-1 rounded text-xs text-[#4ECAFC]">
                        {item.language}
                      </div>
                    </div>
                    <p className="text-[#374151] text-sm mb-3">
                      {item.description}
                    </p>
                  </div>
                </div>
              ))}
          </div>

          {/* Integrated CTA */}
          <div className="bg-[#F9FAFB]/70 rounded-xl p-6 border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 mt-8">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="mb-4 md:mb-0 md:mr-6">
                <h3 className="text-xl font-bold text-[#111827] mb-2">
                  Want to hear Blitzo Operator in action for these use cases?
                </h3>
                <p className="text-[#374151]">
                  Our AI handles seamless conversations in multiple languages
                  across all your communication channels.
                </p>
              </div>
              <button
                onClick={scrollToCta}
                className="px-6 py-3 bg-gradient-to-r from-[#DC2626] to-[#F87171] text-white rounded-lg shadow-lg shadow-[#E54C4C]/20 flex items-center justify-center hover:shadow-[#E54C4C]/40 transition-all duration-300 whitespace-nowrap flex-shrink-0"
              >
                <span className="font-medium">Speak to Us</span>
                <ArrowRight className="ml-2" size={18} />
              </button>
            </div>
          </div>
        </div>

        {/* Hidden audio element for playing samples */}
        <audio
          ref={audioRef}
          onEnded={handleAudioEnd}
          onLoadedMetadata={handleMetadataLoaded}
          className="hidden"
        />
      </div>
    </div>
  );
};

const UseCaseTabs = () => {
  const [activeTab, setActiveTab] = useState("outbound");

  const tabs = [
    { id: "outbound", label: "Outbound Calls", icon: Phone },
    { id: "check", label: "Track & Trace Calls", icon: Truck },
    { id: "appointment", label: "Delivery Slot Booking", icon: Calendar },
    { id: "verification", label: "Carrier Verification", icon: Shield },
  ];

  const tabContent = {
    outbound: {
      title: "Outbound Calls",
      description:
        "When a new load is tendered, Blitzo AI automatically calls priority carriers to secure capacity at the best rates.",
      icon: Phone,
      bulletPoints: [
        "24/7 automated coverage",
        "Natural sounding conversations",
        "Automatic TMS updates",
        "Rate confirmation generation",
      ],
    },
    check: {
      title: "Track & Trace Calls",
      description:
        "Automatically track consignments with AI-powered check calls to drivers, providing real-time POD updates to your TMS.",
      icon: Truck,
      bulletPoints: [
        "Scheduled and automated tracking",
        "Real-time delivery status updates",
        "Immediate exception alerts",
        "Multi-language driver support",
      ],
    },
    appointment: {
      title: "Delivery Slot Booking",
      description:
        "Coordinate delivery slots without human intervention, eliminating scheduling headaches and warehouse delays.",
      icon: Calendar,
      bulletPoints: [
        "Automated booking confirmations",
        "Warehouse coordination",
        "Loading bay allocation",
        "Integrated with your TMS",
      ],
    },
    verification: {
      title: "Carrier Verification",
      description:
        "Verify carrier credentials and prevent fraud with automated insurance and operator licence checks on every load.",
      icon: Shield,
      bulletPoints: [
        "O-licence verification",
        "Insurance certificate checking",
        "Fraud prevention systems",
        "Cabotage rule compliance",
      ],
    },
  };

  // Improved diagram components that match the reference images exactly
  const OutboundCallDiagram = () => (
    <div className="relative h-[420px] bg-transparent rounded-lg border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 overflow-hidden">
      <div className="absolute top-[70px] left-[60px] w-[48%]">
        <div className="flex flex-col space-y-1">
          <div className="bg-[#1a202e] text-[#4ECAFC] p-2 pl-4 rounded-md">
            <div className="flex items-center">
              <Package className="text-[#4ECAFC] mr-2" size={18} />
              <span className="font-medium">Load tendered</span>
            </div>
            <div className="text-white text-sm mt-1">
              New consignment from customer
            </div>
          </div>

          {/* Vertical connector */}
          <div className="h-10 w-px bg-[#26293a] mx-auto"></div>

          <div className="relative z-10">
            <div className="flex items-start">
              <div className="w-50 mr-2 relative">
                <div className="bg-[#1a202e] text-white p-2 pl-4 pr-4 rounded-md">
                  <div className="flex items-center">
                    <Phone className="text-[#E54C4C] mr-2" size={18} />
                    <span className="font-medium">Carrier calls</span>
                  </div>
                  <div className="text-xs mt-1 whitespace-nowrap w-auto text-white">
                    Priority carriers contacted
                  </div>
                </div>
              </div>

              {/* Horizontal connector */}
              <div className="h-px w-4 bg-[#26293a] absolute left-1/2 top-6 transform -translate-x-2"></div>

              <div className="w-1/2 ml-2">
                <div className="bg-[#1a202e] text-white p-2 pl-4 rounded-md">
                  <div className="flex items-center">
                    <FileCheck className="text-[#4ECAFC] mr-2" size={18} />
                    <span className="font-medium">Load booked</span>
                  </div>
                  <div className="text-xs mt-1 whitespace-nowrap w-auto text-white">
                    Best rate secured
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="absolute bottom-[30px] right-[60px] w-[40%]">
        <div className="bg-[#1a202e] text-[#111827] p-4 rounded-md">
          <div className="text-[#E54C4C] font-medium mb-2">
            Sample conversation
          </div>
          <div className="text-sm text-white">
            "I'm calling about a load from Manchester to Birmingham tomorrow.
            We're offering £420 for a standard box van."
          </div>
        </div>
      </div>
    </div>
  );

  const TrackTraceDiagram = () => (
    <div className="relative h-[420px] bg-transparent rounded-lg border border-[#E5E7EB] shadow-md shadow-[#E5E7EB] overflow-hidden">
      <div className="absolute top-[70px] left-[60px] w-[40%]">
        <div className="flex flex-col space-y-6">
          <div className="bg-[#1a202e] p-2 pl-4 rounded-md text-[#4ECAFC]">
            <div className="flex items-center">
              <Truck className="text-[#4ECAFC] mr-2" size={18} />
              <span className="font-medium">Active delivery</span>
            </div>
            <div className="text-white text-sm mt-1">
              Consignment in transit
            </div>
          </div>

          {/* Vertical connector */}
          <div className="h-6 w-px bg-[#26293a] mx-auto"></div>

          <div className="bg-[#1a202e] p-2 pl-4 rounded-md text-[#E54C4C]">
            <div className="flex items-center">
              <Phone className="text-[#E54C4C] mr-2" size={18} />
              <span className="font-medium">Track & trace</span>
            </div>
            <div className="text-white text-sm mt-1">Automated check call</div>
          </div>

          {/* Vertical connector */}
          <div className="h-6 w-px bg-[#26293a] mx-auto"></div>

          <div className="bg-[#1a202e] p-2 pl-4 rounded-md text-[#2DD4BF]">
            <div className="flex items-center">
              <Clock className="text-[#2DD4BF] mr-2" size={18} />
              <span className="font-medium">ETA update</span>
            </div>
            <div className="text-white text-sm mt-1">Real-time tracking</div>
          </div>
        </div>
      </div>

      <div className="absolute top-[70px] right-[60px] w-[45%]">
        <div className="bg-[#1a202e] text-[#111827] p-4 rounded-md">
          <div className="flex items-center justify-between mb-2">
            <div className="flex items-center">
              <Truck className="text-[#E54C4C] mr-2" size={18} />
              <span className="font-medium">Driver updates</span>
            </div>
            <div className="bg-gradient-to-br from-[#FFFFFF] to-[#F8FAFC] shadow-lg shadow-[#E5E7EB]/30 px-2 py-0.5 rounded text-xs text-[#4ECAFC]">
              Auto-logged
            </div>
          </div>

          <div className="space-y-2 mt-3">
            <div className="bg-gradient-to-br from-[#FFFFFF] to-[#F8FAFC] shadow-lg shadow-[#E5E7EB]/30 p-2 rounded border border-[#26293a]">
              <div className="text-xs text-[#374151]">Location</div>
              <div className="text-sm text-[#111827]">M6 Northbound</div>
            </div>

            <div className="bg-gradient-to-br from-[#FFFFFF] to-[#F8FAFC] shadow-lg shadow-[#E5E7EB]/30 p-2 rounded border border-[#26293a]">
              <div className="text-xs text-[#374151]">Estimated arrival</div>
              <div className="text-sm text-[#111827]">14:30 (on time)</div>
            </div>

            <div className="bg-gradient-to-br from-[#FFFFFF] to-[#F8FAFC] shadow-lg shadow-[#E5E7EB]/30 p-2 rounded border border-[#26293a]">
              <div className="text-xs text-[#374151]">Status</div>
              <div className="text-sm text-[#4ECAFC]">In transit</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const DeliverySlotDiagram = () => (
    <div className="relative h-[420px] bg-transparent rounded-lg border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 overflow-hidden">
      <div className="absolute top-[70px] left-[60px] w-[40%]">
        <div className="flex flex-col space-y-6">
          <div className="bg-[#1a202e] text-[#E54c4c] p-2 pl-4 rounded-md">
            <div className="flex items-center">
              <Calendar className="text-[#E54C4C] mr-2" size={18} />
              <span className="font-medium">Delivery required</span>
            </div>
            <div className="text-white text-sm mt-1">
              Customer requests delivery
            </div>
          </div>

          {/* Vertical connector */}
          <div className="h-8 w-px bg-[#26293a] mx-auto"></div>

          <div className="bg-[#1a202e] text-[#2DD4BF] p-2 pl-4 rounded-md">
            <div className="flex items-center">
              <Phone className="text-[#2DD4BF] mr-2" size={18} />
              <span className="font-medium">Contact warehouse</span>
            </div>
            <div className="text-white text-sm mt-1">
              AI books delivery slot
            </div>
          </div>
        </div>
      </div>

      <div className="absolute top-[70px] right-[60px] w-[45%]">
        <div className="bg-[#1a202e] text-[#111827] p-4 rounded-md">
          <div className="text-[#111827] font-medium mb-3">Available slots</div>

          <div className="space-y-2">
            <div className="bg-gradient-to-br from-[#FFFFFF] to-[#F8FAFC] shadow-lg shadow-[#E5E7EB]/30 p-2 rounded border border-[#26293a] flex items-center">
              <Calendar className="text-[#4ECAFC] mr-2" size={16} />
              <div>
                <div className="text-sm text-[#111827]">Tomorrow</div>
                <div className="text-xs text-[#374151]">11:00 - 12:00</div>
              </div>
            </div>

            <div className="bg-gradient-to-br from-[#FFFFFF] to-[#F8FAFC] shadow-lg shadow-[#E5E7EB]/30 p-2 rounded border border-[#26293a] flex items-center">
              <Calendar className="text-[#4ECAFC] mr-2" size={16} />
              <div className="flex-grow">
                <div className="text-sm text-[#111827]">Tomorrow</div>
                <div className="text-xs text-[#374151]">12:00 - 13:00</div>
              </div>
              <div className="ml-2 w-5 h-5 rounded-full bg-[#E54C4C] flex items-center justify-center">
                <Check className="text-[#111827]" size={12} />
              </div>
            </div>

            <div className="bg-gradient-to-br from-[#FFFFFF] to-[#F8FAFC] shadow-lg shadow-[#E5E7EB]/30 p-2 rounded border border-[#26293a] flex items-center">
              <Calendar className="text-[#4ECAFC] mr-2" size={16} />
              <div>
                <div className="text-sm text-[#111827]">Tomorrow</div>
                <div className="text-xs text-[#374151]">13:00 - 14:00</div>
              </div>
            </div>
          </div>

          <div className="mt-4 bg-gradient-to-br from-[#FFFFFF] to-[#F8FAFC] shadow-lg shadow-[#E5E7EB]/30 p-2 rounded border border-[#26293a] text-center">
            <div className="text-[#4ECAFC] text-sm">Slot confirmed</div>
            <div className="text-[#111827] text-xs">
              Tomorrow, 12:00 - 13:00
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const HaulierVerificationDiagram = () => (
    <div className="relative h-[420px] bg-transparent rounded-lg border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 overflow-hidden">
      <div className="absolute top-[70px] left-[60px] w-[40%]">
        <div className="flex flex-col space-y-6">
          <div className="bg-[#1a202e] text-[#E54c4c] p-2 pl-4 rounded-md">
            <div className="flex items-center">
              <Phone className="text-[#E54C4C] mr-2" size={18} />
              <span className="font-medium">Inbound call</span>
            </div>
            <div className="text-white text-sm mt-1">
              Carrier offers capacity
            </div>
          </div>

          {/* Vertical connector */}
          <div className="h-8 w-px bg-[#26293a] mx-auto"></div>

          <div className="bg-[#1a202e] text-[#4ECAFC] p-2 pl-4 rounded-md">
            <div className="flex items-center">
              <Shield className="text-[#4ECAFC] mr-2" size={18} />
              <span className="font-medium">Verification</span>
            </div>
            <div className="text-white text-sm mt-1">Automated checks</div>
          </div>
        </div>
      </div>

      <div className="absolute top-[70px] right-[60px] w-[45%]">
        <div className="bg-[#1a202e] text-[#111827] p-4 rounded-md">
          <div className="text-[#111827] font-medium mb-3">
            Verification checks
          </div>

          <div className="space-y-2">
            <div className="bg-gradient-to-br from-[#FFFFFF] to-[#F8FAFC] shadow-lg shadow-[#E5E7EB]/30 p-2 rounded border border-[#26293a] flex items-center">
              <div className="w-5 h-5 rounded-full bg-[#0c0f19] flex items-center justify-center mr-2">
                <Check className="text-green-500" size={14} />
              </div>
              <div className="text-sm text-[#111827]">O-licence valid</div>
            </div>

            <div className="bg-gradient-to-br from-[#FFFFFF] to-[#F8FAFC] shadow-lg shadow-[#E5E7EB]/30 p-2 rounded border border-[#26293a] flex items-center">
              <div className="w-5 h-5 rounded-full bg-[#0c0f19] flex items-center justify-center mr-2">
                <Check className="text-green-500" size={14} />
              </div>
              <div className="text-sm text-[#111827]">Insurance verified</div>
            </div>

            <div className="bg-gradient-to-br from-[#FFFFFF] to-[#F8FAFC] shadow-lg shadow-[#E5E7EB]/30 p-2 rounded border border-[#26293a] flex items-center">
              <div className="w-5 h-5 rounded-full bg-[#0c0f19] flex items-center justify-center mr-2">
                <Check className="text-green-500" size={14} />
              </div>
              <div className="text-sm text-[#111827]">Cabotage compliant</div>
            </div>

            <div className="bg-gradient-to-br from-[#FFFFFF] to-[#F8FAFC] shadow-lg shadow-[#E5E7EB]/30 p-2 rounded border border-[#26293a] flex items-center">
              <div className="w-5 h-5 rounded-full bg-[#0c0f19] flex items-center justify-center mr-2">
                <Check className="text-green-500" size={14} />
              </div>
              <div className="text-sm text-[#111827]">Payment terms agreed</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="bg-[#F9FAFB] py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold text-[#111827] mb-5">
            Ready-to-use <GradientText>workflows</GradientText>, fine-tuned to
            freight
          </h2>
          <p className="text-[#374151] max-w-3xl mx-auto">
            Get started in seconds with templates and customise the flow down to
            how you say hello
          </p>
        </div>

        <div className="bg-[#F9FAFB]/50 rounded-xl border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 overflow-hidden">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 border-b border-[#E5E7EB]">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`py-4 flex items-center justify-center transition-all ${
                  activeTab === tab.id
                    ? "border-b-2 border-[#E54C4C]"
                    : "text-[#374151] hover:text-[#111827]"
                }`}
              >
                <tab.icon
                  className={`mr-2 ${
                    activeTab === tab.id ? "text-[#E54C4C]" : "text-[#374151]"
                  }`}
                  size={16}
                />
                <span
                  className={`text-sm font-medium ${
                    activeTab === tab.id ? "text-[#111827]" : ""
                  }`}
                >
                  {tab.label}
                </span>
              </button>
            ))}
          </div>

          <div className="p-8">
            <div className="flex flex-col md:flex-row items-start gap-8">
              <div className="md:w-1/3">
                <div className="flex items-center mb-6">
                  <div className="w-16 h-16 rounded-full flex items-center justify-center mr-4 bg-[#F9FAFB] border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40">
                    {activeTab === "outbound" && (
                      <Phone className="text-[#E54C4C]" size={24} />
                    )}
                    {activeTab === "check" && (
                      <Truck className="text-[#E54C4C]" size={24} />
                    )}
                    {activeTab === "appointment" && (
                      <Calendar className="text-[#E54C4C]" size={24} />
                    )}
                    {activeTab === "verification" && (
                      <Shield className="text-[#E54C4C]" size={24} />
                    )}
                  </div>
                  <h3 className="text-2xl font-bold text-[#111827]">
                    {tabContent[activeTab].title}
                  </h3>
                </div>
                <p className="text-[#374151] text-lg leading-relaxed mb-8">
                  {tabContent[activeTab].description}
                </p>

                <div className="space-y-4">
                  {tabContent[activeTab].bulletPoints.map((point, index) => (
                    <div
                      key={index}
                      className="flex items-center text-[#374151] text-base"
                    >
                      <div className="w-5 h-5 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mr-3">
                        <Check className="text-[#E54C4C]" size={14} />
                      </div>
                      <span>{point}</span>
                    </div>
                  ))}
                </div>
              </div>

              <div className="md:w-2/3">
                {activeTab === "outbound" && <OutboundCallDiagram />}
                {activeTab === "check" && <TrackTraceDiagram />}
                {activeTab === "appointment" && <DeliverySlotDiagram />}
                {activeTab === "verification" && <HaulierVerificationDiagram />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Integration Logos Section
const IntegrationSection = () => {
  return (
    <div className="py-16 bg-[#F9FAFB]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-12">
          <div className="max-w-xl mb-8 md:mb-0">
            <h2 className="text-3xl md:text-4xl font-bold text-[#111827] mb-4">
              <GradientText>Easy integration</GradientText> with <br />
              your existing platforms
            </h2>
            <p className="text-lg text-[#374151] mb-2">
              Syncs with your TMS, load board, and telephony for real-time data
              access.
            </p>
            <p className="text-[#374151]">
              Integrate with existing solutions to amplify your current
              operations.
            </p>
          </div>
          <button
            onClick={() => {
              const ctaSection = document.getElementById("cta-section");
              if (ctaSection) {
                ctaSection.scrollIntoView({ behavior: "smooth" });
              }
            }}
            className="px-5 py-3 bg-transparent border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 hover:border-[#4ECAFC] text-[#111827] rounded-lg transition-all duration-300 flex items-center justify-center"
          >
            <span>Missing any tools? Let's talk</span>
            <ArrowUpRight className="ml-2" size={16} />
          </button>
        </div>

        {/* Enhanced brand showcase */}
        <div className="mb-10">
          <div className="relative">
            {/* Logos container */}
            <div className="relative bg-[#F9FAFB]/90 backdrop-blur-lg rounded-xl border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 p-8 md:p-10">
              <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 gap-6 md:gap-10 items-center">
                {[
                  { logo: Tai, name: "Tai", scale: "scale-150" },
                  { logo: Turvo, name: "Turvo", scale: "scale-90" },
                  { logo: Excel, name: "Excel", scale: "scale-150" },
                  { logo: Twilio, name: "Twilio", scale: "scale-90" },
                  { logo: Descartes, name: "Descartes", scale: "scale-90" },
                  {
                    logo: Ringcentral,
                    name: "Ringcentral",
                    scale: "scale-150",
                  },
                  { logo: Teams, name: "Teams", scale: "scale-120" },
                  { logo: Dat, name: "DAT", scale: "scale-90" },
                  { logo: Gmail, name: "Gmail", scale: "scale-125" },
                  { logo: MCP, name: "MyCarrierPortal", scale: "scale-90" },
                  { logo: Slack, name: "Slack", scale: "scale-90" },
                  { logo: Revenova, name: "Revenova", scale: "scale-150" },
                  { logo: Whatsapp, name: "Whatsapp", scale: "scale-125" },
                  { logo: Samsara, name: "Samsara", scale: "scale-150" },
                  { logo: EightXEight, name: "8x8", scale: "scale-90" },
                  {
                    logo: TransportPro,
                    name: "TransportPro",
                    scale: "scale-150",
                  },
                  { logo: Hubspot, name: "Hubspot", scale: "scale-90" },
                ].map((brand, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-center group relative py-4"
                    data-aos="fade-up"
                    data-aos-delay={100 + index * 50}
                  >
                    <div className="absolute inset-0 bg-gradient-to-br from-[#E54C4C]/0 to-[#4ECAFC]/0 group-hover:from-[#E54C4C]/10 group-hover:to-[#4ECAFC]/10 rounded-xl -m-3 transition-all duration-300 opacity-0 group-hover:opacity-100 shadow-xl"></div>
                    <div
                      className={`transform transition-all duration-700 ease-in-out hover:translate-y-[-8px] ${brand.scale}`}
                    >
                      <div className="w-full h-16 flex items-center justify-center group-hover:opacity-100 transition-all duration-500">
                        <img
                          src={brand.logo}
                          alt={brand.name}
                          className="max-h-10 max-w-full object-contain"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* VISUAL CONNECTOR: Animated data flow between logo section and metrics */}
        <div className="relative py-6 my-4">
          <div className="absolute inset-0 flex items-center justify-center">
            {/* Animated data flow visualization */}
            <div className="w-1/2 h-0.5 bg-gradient-to-r from-[#E54C4C]/20 via-[#FFF]/10 to-[#4ECAFC]/20"></div>
          </div>

          {/* Connector text with icon */}
          <div className="relative bg-gradient-to-br from-[#FFFFFF] to-[#F8FAFC] shadow-lg shadow-[#E5E7EB]/30 px-6 mx-auto w-max rounded-full border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-lg">
            <div className="flex items-center space-x-2 py-2">
              <BarChart className="text-[#E54C4C] w-6 h-6" />
              <p className="text-[#111827] text-xl font-medium">
                Get going in <GradientText>minutes</GradientText>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Performance Stats Section
const PerformanceStats = () => (
  <div className="py-16 bg-[#F3F4F6]">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="text-center mb-10">
        <h2 className="text-3xl md:text-4xl font-bold text-[#111827] mb-3">
          Real results from our pilot with{" "}
          <span className="bg-gradient-to-r from-[#DC2626] to-[#F87171] inline bg-clip-text text-transparent">
            Top UK Freight Forwarders
          </span>
        </h2>
        <p className="text-[#374151] max-w-3xl mx-auto">
          Proven metrics from freight forwarders using our AI workers.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="bg-[#F9FAFB] rounded-xl p-8 border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 text-center">
          <div className="text-5xl font-bold text-[#111827] mb-4">
            <CountUp end={100} suffix="%" />
          </div>
          <p className="text-lg text-[#4ECAFC] font-medium mb-2">
            Calls answered
          </p>
          <p className="text-[#374151] text-sm">
            Record every data point, option & never miss an opportunity
          </p>
        </div>

        <div className="bg-[#F9FAFB] rounded-xl p-8 border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 text-center">
          <div className="text-5xl font-bold text-[#111827] mb-4">
            <span>3.8</span>
            <span className="text-2xl">x</span>
          </div>
          <p className="text-lg text-[#4ECAFC] font-medium mb-2">
            More cost effective
          </p>
          <p className="text-[#374151] text-sm">
            Compared to outsourced labour for communications
          </p>
        </div>

        <div className="bg-[#F9FAFB] rounded-xl p-8 border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 text-center">
          <div className="text-5xl font-bold text-[#111827] mb-4">
            <CountUp prefix="+" end={12} suffix="%" />
          </div>
          <p className="text-lg text-[#4ECAFC] font-medium mb-2">
            Higher margin
          </p>
          <p className="text-[#374151] text-sm">
            With strict negotiations & other AI-powered tactics
          </p>
        </div>
      </div>

      <div className="mt-12 text-center">
        <p className="text-[#374151] text-m italic">
          "We're redefining the margins of industry. Blitzo AI workers are
          highly effective - always available and logging every data point."
        </p>
      </div>
    </div>
  </div>
);

// CTA Section
const PilotCTASection = () => {
  // Form state
  const [email, setEmail] = useState("");
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [number, setNumber] = useState("");
  const [company, setCompany] = useState("");
  const [volume, setVolume] = useState("");
  const [showThankYou, setShowThankYou] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [animateThankYou, setAnimateThankYou] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) return;

    setIsSubmitting(true);

    try {
      // Simulate API call delay
      await new Promise((resolve) => setTimeout(resolve, 1500));

      // Firebase code would go here in production

      const docRef = await addDoc(collection(db, "OperatorWaitlist"), {
        email,
        timestamp: serverTimestamp(),
        firstName: first,
        lastName: last,
        phoneNumber: number,
        companyName: company,
        monthlyVolume: volume,
      });
      console.log("Document written with ID: ", docRef.id);

      // Show thank you with animation
      // setAnimateThankYou(true);
      // setTimeout(() => {
      //   setShowThankYou(true);
      //   setAnimateThankYou(false);
      // }, 500);
      navigate("/operator/thank-you");
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("There was an error submitting your form. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetForm = () => {
    setShowThankYou(false);
    setEmail("");
    setFirst("");
    setLast("");
    setNumber("");
    setCompany("");
    setVolume("");
  };

  return (
    <div className="py-16 bg-[#F9FAFB] relative overflow-hidden">
      {/* Background glow effects */}
      <div className="absolute top-0 right-0 w-1/3 h-2/3 bg-[#FF6B6B]/20 blur-[160px] opacity-50 rounded-full"></div>
      <div className="absolute bottom-0 left-0 w-1/3 h-1/2 bg-[#4ECAFC]/20 blur-[160px] opacity-50 rounded-full"></div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <div className="flex flex-col lg:flex-row gap-10 items-center">
          {/* Left side - CTA content */}
          <div className="w-full lg:w-1/2">
            <div className="max-w-lg">
              {/* Pilot badge */}
              <div className="inline-flex items-center px-3 py-1.5 mb-6 rounded-full bg-[#E54C4C]/10 border border-[#E54C4C]/20 text-[#E54C4C] text-sm">
                <Star className="mr-2" size={16} />
                <span>Already working with top UK freight forwarders</span>
              </div>

              <h2 className="text-3xl md:text-4xl font-bold text-[#111827] mb-6">
                Leverage <GradientText>AI-powered freight</GradientText>{" "}
                operations today.
              </h2>

              <p className="text-xl text-[#374151] mb-8">
                We've partnered with 3 of the UK's leading freight forwarders
                and are excited to expand our pilot offering to 2 more. Join
                them in realising transformative results.
              </p>

              {/* Benefits list */}
              <div className="space-y-4 mb-8">
                <div className="flex items-start">
                  <div className="mt-1 mr-3 flex-shrink-0 flex items-center justify-center w-5 h-5 rounded-full bg-[#E54C4C]/10">
                    <Check className="text-[#E54C4C]" size={14} />
                  </div>
                  <p className="text-[#374151]">
                    <span className="text-[#111827] font-medium">
                      Automated communication
                    </span>{" "}
                    — focus on relationships while we handle negotiations
                  </p>
                </div>
                <div className="flex items-start">
                  <div className="mt-1 mr-3 flex-shrink-0 flex items-center justify-center w-5 h-5 rounded-full bg-[#E54C4C]/10">
                    <Check className="text-[#E54C4C]" size={14} />
                  </div>
                  <p className="text-[#374151]">
                    <span className="text-[#111827] font-medium">
                      Answer 100% of calls, 24/7
                    </span>{" "}
                    — Inbound carrier verification, outbound load tendering,
                    automate POD chasing, delivery slot booking, check calls and
                    more
                  </p>
                </div>
                <div className="flex items-start">
                  <div className="mt-1 mr-3 flex-shrink-0 flex items-center justify-center w-5 h-5 rounded-full bg-[#E54C4C]/10">
                    <Check className="text-[#E54C4C]" size={14} />
                  </div>
                  <p className="text-[#374151]">
                    <span className="text-[#111827] font-medium">
                      Integration with your TMS
                    </span>{" "}
                    — easy setup with your choice of TMS and Load Board
                  </p>
                </div>
              </div>

              {/* Testimonial */}
              <div className="mt-10 bg-[#F9FAFB]/80 border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 rounded-lg p-5">
                <div className="flex items-start">
                  <div className="mr-4 w-10 h-10 rounded-full bg-[#F9FAFB] flex items-center justify-center">
                    <span className="text-[#111827] font-semibold">JH</span>
                  </div>
                  <div>
                    <p className="text-[#374151] italic mb-2">
                      "Blitzo has been magical on our load tendering results.
                      We've reduced admin time by 78% and increased our margin
                      on LTL."
                    </p>
                    <p className="text-[#111827] font-medium">James</p>
                    <p className="text-[#374151] text-sm">
                      Operations Director
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right side - Form */}
          <div className="w-full lg:w-1/2">
            <div className="bg-[#F9FAFB] border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 rounded-xl p-8 md:p-10 relative overflow-hidden">
              {/* Availability indicator */}
              <div className="absolute top-4 right-4 flex items-center">
                <div className="relative mr-2">
                  <div className="w-3 h-3 bg-[#E54C4C] rounded-full"></div>
                  <div className="absolute top-0 left-0 w-3 h-3 bg-[#E54C4C] rounded-full animate-ping opacity-75"></div>
                </div>
                <span className="text-sm text-[#374151]">
                  2 of 5 spots left for Q2 2025
                </span>
              </div>

              {/* Form header */}
              <div className="mb-8">
                <h3 className="text-2xl font-bold text-[#111827] mb-2">
                  Speak to Us - Blitzo Operator Pilot
                </h3>
                <p className="text-[#374151]">
                  Our team will reach out straightaway to understand your needs
                  and showcase how we can help.
                </p>
              </div>

              {showThankYou ? (
                <div
                  className={`text-center py-10 transition-opacity duration-500 ${
                    animateThankYou ? "opacity-0" : "opacity-100"
                  }`}
                >
                  <div className="w-16 h-16 mx-auto bg-[#E54C4C]/10 rounded-full flex items-center justify-center mb-4">
                    <Check className="text-[#E54C4C]" size={32} />
                  </div>
                  <h4 className="text-xl font-bold text-[#111827] mb-2">
                    We're on it!
                  </h4>
                  <p className="text-[#374151] mb-6">
                    Our team will ring you shortly. We're excited to discuss how
                    Blitzo can transform your logistics operations.
                  </p>
                  <button
                    onClick={resetForm}
                    className="px-5 py-2 border border-[#4ECAFC]/30 text-[#4ECAFC] hover:bg-[#EDE9FE] text-[#7C3AED] font-semibold rounded-lg transition-all duration-300"
                  >
                    Submit another enquiry
                  </button>
                </div>
              ) : (
                <form
                  onSubmit={handleSubmit}
                  className={`transition-opacity duration-500 ${
                    animateThankYou ? "opacity-0" : "opacity-100"
                  }`}
                >
                  <div className="space-y-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <label
                          htmlFor="firstName"
                          className="block text-sm font-medium text-[#374151] mb-1"
                        >
                          First Name*
                        </label>
                        <input
                          type="text"
                          id="firstName"
                          value={first}
                          onChange={(e) => setFirst(e.target.value)}
                          className="w-full px-4 py-2 bg-[#F9FAFB] border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 rounded-lg text-[#111827] focus:outline-none focus:ring-1 focus:ring-[#4ECAFC] focus:border-[#4ECAFC]"
                          required
                          disabled={isSubmitting}
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="lastName"
                          className="block text-sm font-medium text-[#374151] mb-1"
                        >
                          Last Name*
                        </label>
                        <input
                          type="text"
                          id="lastName"
                          value={last}
                          onChange={(e) => setLast(e.target.value)}
                          className="w-full px-4 py-2 bg-[#F9FAFB] border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 rounded-lg text-[#111827] focus:outline-none focus:ring-1 focus:ring-[#4ECAFC] focus:border-[#4ECAFC]"
                          required
                          disabled={isSubmitting}
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="company"
                        className="block text-sm font-medium text-[#374151] mb-1"
                      >
                        Company Name*
                      </label>
                      <input
                        type="text"
                        id="company"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        className="w-full px-4 py-2 bg-[#F9FAFB] border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 rounded-lg text-[#111827] focus:outline-none focus:ring-1 focus:ring-[#4ECAFC] focus:border-[#4ECAFC]"
                        required
                        disabled={isSubmitting}
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-[#374151] mb-1"
                      >
                        Work Email*
                      </label>
                      <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full px-4 py-2 bg-[#F9FAFB] border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 rounded-lg text-[#111827] focus:outline-none focus:ring-1 focus:ring-[#4ECAFC] focus:border-[#4ECAFC]"
                        required
                        disabled={isSubmitting}
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-[#374151] mb-1"
                      >
                        Mobile Number
                      </label>
                      <input
                        type="tel"
                        id="phone"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                        className="w-full px-4 py-2 bg-[#F9FAFB] border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 rounded-lg text-[#111827] focus:outline-none focus:ring-1 focus:ring-[#4ECAFC] focus:border-[#4ECAFC]"
                        disabled={isSubmitting}
                      />
                    </div>

                    <div>
                      <label
                        htmlFor="communicationVolume"
                        className="block text-sm font-medium text-[#374151] mb-1"
                      >
                        Approximately how many daily calls, emails and texts do
                        you handle?
                      </label>
                      <select
                        id="communicationVolume"
                        value={volume}
                        onChange={(e) => setVolume(e.target.value)}
                        className="w-full px-4 py-2 bg-[#F9FAFB] border border-[#E5E7EB] shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 shadow-md shadow-[#E5E7EB]/40 rounded-lg text-[#111827] focus:outline-none focus:ring-1 focus:ring-[#4ECAFC] focus:border-[#4ECAFC]"
                        disabled={isSubmitting}
                      >
                        <option value="">Select an option</option>
                        <option value="small">Less than 50</option>
                        <option value="medium">50 - 200</option>
                        <option value="large">200 - 500</option>
                        <option value="enterprise">500+</option>
                      </select>
                    </div>
                  </div>

                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`w-full mt-6 px-6 py-4 bg-gradient-to-r from-[#DC2626] to-[#F87171] text-[#111827] rounded-lg shadow-lg shadow-[#E54C4C]/20 flex items-center justify-center hover:shadow-[#E54C4C]/40 transition-all duration-300 group ${
                      isSubmitting ? "opacity-90 cursor-not-allowed" : ""
                    }`}
                  >
                    {isSubmitting ? (
                      <>
                        <Loader className="animate-spin mr-2" size={18} />
                        <span className="font-medium">Processing...</span>
                      </>
                    ) : (
                      <>
                        <span className="font-medium">Submit</span>
                        <ArrowRight
                          className="ml-2 group-hover:translate-x-1 transition-transform"
                          size={18}
                        />
                      </>
                    )}
                  </button>

                  <div className="flex items-center justify-center mt-4 space-x-4">
                    <div className="flex items-center">
                      <Phone className="text-[#52525B] mr-1" size={14} />
                      <span className="text-xs text-[#52525B]">24/7 Calls</span>
                    </div>
                    <div className="flex items-center">
                      <MessageSquare
                        className="text-[#52525B] mr-1"
                        size={14}
                      />
                      <span className="text-xs text-[#52525B]">
                        Multi-channel
                      </span>
                    </div>
                    <div className="flex items-center">
                      <Truck className="text-[#52525B] mr-1" size={14} />
                      <span className="text-xs text-[#52525B]">
                        AI Fine-Tuned for Logistics
                      </span>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
// Main component
const BlitzoOperate = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Handle hash links on page load
    const hash = window.location.hash.replace("#", "");
    if (hash) {
      setTimeout(() => {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 500); // Small delay to ensure page is fully loaded
    }

    // Initialize AOS
    AOS.init({
      duration: 1000,
      easing: "ease-out",
      once: true,
    });
  }, []);

  const scrollToCta = () => {
    const ctaSection = document.getElementById("cta-section");
    if (ctaSection) {
      ctaSection.scrollIntoView({ behavior: "smooth" });
      // Update URL hash without jumping
      window.history.pushState(null, "#cta-section");
    }
  };

  return (
    <>
      <Helmet>
        <title>AI Workers. Built for Logistics.</title>
        <meta
          name="description"
          content="AI agents that handle calls, emails, and rate negotiations 24/7, saving your freight forwarding team over 4 hours daily. Join top UK freight forwarders already using Blitzo."
        />
        {/* Open Graph tags for social sharing */}
        <meta
          property="og:title"
          content="Blitzo Operator | AI Workers for Logistics"
        />
        <meta
          property="og:description"
          content="AI agents handle calls, emails, and rate negotiations 24/7, saving your team 4+ hours daily. 100% calls answered, 3.8x more cost effective, +12% higher margin."
        />
        <meta property="og:site_name" content="Blitzo Operator" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://blitzo.co.uk/operator" />
        <meta property="og:locale" content="en_GB" />
        {/* Create a custom OG image */}
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Blitzo Operator | AI Haulage Communication"
        />
        <meta
          name="twitter:description"
          content="AI-powered voice automation for freight forwarding. Handle rate negotiations, POD tracking, and delivery slot bookings with AI that never sleeps."
        />
        {/* SEO-specific keywords and tags */}
        <meta
          name="keywords"
          content="AI logistics automation, freight forwarding AI, brokerage, TMS, carrier automation, brokerage automation, haulage, voice automation, AI freight negotiation, logistics automation, carrier automation, broker automation"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://blitzo.co.uk/operator" />
      </Helmet>
      <div className="min-h-screen bg-[#F9FAFB]">
        <OperatorNavbar />

        <HeroSection />

        {/* Performance Stats Section */}
        <PerformanceStats />
        <div id="live-examples">
          <VoiceCommunicationSamples scrollToCta={scrollToCta} />
        </div>
        <div id="how-we-work">
          <UseCaseTabs />
        </div>

        <div id="integrations">
          <IntegrationSection />
        </div>

        <div id="cta-section">
          <PilotCTASection />
        </div>

        {/* Footer */}
        <OperatorFooter />
      </div>
    </>
  );
};

export default BlitzoOperate;
