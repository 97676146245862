import React, { useEffect } from "react";
import {
  Clock,
  Package,
  ChevronRight,
  Shield,
  Globe,
  ArrowUpRight,
  Check,
  Code,
  PhoneCall,
} from "lucide-react";
import AOS from "aos";
import "aos/dist/aos.css";
import styled from "styled-components";
import googlePlayBadge from "../Images/google-play-badge.png";
import appStoreBadge from "../Images/app-store-badge.png";
import shopifyLogo from "../Images/shopify_glyph.png";
import BrandSlider from "../components/BrandSlider";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import CTA from "../components/CTA";
import { getStoreLink } from "../utils/deviceDetection";
import TrustPilot from "../Images/TrustPilot.png";
import { useNavigate } from "react-router-dom";

const TypewriterText = styled.div`
  overflow: hidden;
  border-right: 2px solid black;
  white-space: nowrap;
  margin: 0 auto;
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;

  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  @keyframes blink-caret {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: black;
    }
  }
`;

const Home = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-out",
      once: true,
    });
  }, []);

  const navigate = useNavigate();

  const handleBookNow = () => {
    navigate("/business/book");
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Navbar */}
      <Navbar />

      {/* Hero Section */}
      <div className="relative pt-16 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-white via-gray-50 to-gray-100 z-0" />
        <div className="absolute inset-0 pattern-bg opacity-5 z-0" />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-32 relative z-10">
          {/* Alert Banner */}
          <div className="mb-8 sm:mb-16 max-w-3xl mx-auto px-6 sm:px-10 py-3 sm:py-5 rounded-full bg-black/5 backdrop-blur-sm border border-black/10 text-black text-center overflow-hidden text-[10px] sm:text-lg md:text-xl tracking-[0.15em] sm:tracking-[0.2em] flex items-center hover:bg-black/10 transition-all">
            <TypewriterText className="mx-auto font-light">
              LONDON'S PREMIER FASHION LOGISTICS PARTNER
            </TypewriterText>
          </div>

          {/* Hero Main Content */}
          <div className="text-center px-4 sm:px-0">
            <h1 className="text-[2.5rem] sm:text-5xl md:text-6xl lg:text-7xl font-light text-gray-900 mb-8 sm:mb-12 leading-[1.1] sm:leading-none tracking-[0.1em]">
              <span className="block mb-2 sm:mb-0">ELEVATE YOUR</span>
              <span className="text-[#E54C4C] font-extralight italic tracking-wide block text-[2rem] sm:text-5xl md:text-6xl lg:text-7xl mb-2 sm:mb-0">
                fashion logistics
              </span>
              <span className="block">WITH STYLE</span>
            </h1>
            <p className="text-lg sm:text-xl text-gray-600 mb-12 sm:mb-16 max-w-2xl mx-auto font-extralight tracking-[0.1em] leading-relaxed px-4 sm:px-0">
              Trusted by London's leading fashion houses, PR agencies, and
              stylists. Delivering luxury with speed, precision and care.
            </p>

            <div className="max-w-8xl mx-auto">
              <div className="flex flex-col space-y-12">
                {/* Combined Row for CTAs, Badges, and Brands */}
                <div className="flex flex-col lg:flex-row justify-between items-center gap-16 px-4">
                  {/* Left - Badges and Integrations in 2x2 Grid */}
                  <div className="hidden sm:grid grid-cols-2 gap-6 lg:w-1/4">
                    <a
                      href="https://apps.apple.com/gb/app/blitzo/id6502848319"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="transform hover:scale-105 transition-transform"
                    >
                      <img
                        src={appStoreBadge}
                        alt="Download on App Store"
                        className="h-12"
                      />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.blitzo&pcampaignid=web_share"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="transform hover:scale-105 transition-transform"
                    >
                      <img
                        src={googlePlayBadge}
                        alt="Get it on Google Play"
                        className="h-12"
                      />
                    </a>
                    <a
                      href="https://apps.shopify.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center gap-2 px-4 py-3 bg-black/5 backdrop-blur-sm rounded-xl hover:bg-black/10 transition-all transform hover:scale-105"
                    >
                      <img
                        src={shopifyLogo}
                        alt="Shopify Plugin"
                        className="h-12"
                      />
                      <span className="text-lg text-gray-800 font-light">
                        Shopify
                      </span>
                    </a>
                    <a
                      href="https://api.blitzo.co.uk/v1/docs"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center gap-2 px-4 py-3 bg-black/5 backdrop-blur-sm rounded-xl hover:bg-black/10 transition-all transform hover:scale-105"
                    >
                      <Code className="text-gray-800" size={36} />
                      <span className="text-lg text-gray-800 font-light">
                        API
                      </span>
                    </a>
                  </div>

                  {/* Middle Side - CTAs */}
                  <div className="flex flex-col sm:flex-row gap-6 justify-center">
                    <button
                      onClick={handleBookNow}
                      className="bg-black text-white px-10 py-5 rounded-full hover:bg-black/90 transition-all shadow-xl shadow-black/10 flex items-center justify-center group text-lg tracking-wide"
                    >
                      Book Now
                      <ChevronRight
                        className="ml-2 group-hover:translate-x-1 transition-transform"
                        size={20}
                      />
                    </button>
                    <button
                      onClick={() =>
                        (window.location.href = "mailto:support@blitzo.co.uk")
                      }
                      className="border-2 border-black bg-transparent text-black px-10 py-5 rounded-full hover:bg-black hover:text-white transition-colors flex items-center justify-center text-lg tracking-wide"
                    >
                      Enterprise <ArrowUpRight className="ml-2" size={18} />
                    </button>
                  </div>

                  {/* Right Side - Brand Slider */}
                  <div className="lg:w-1/4">
                    <BrandSlider />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Stats Section */}
      <div className="border-y border-black/5 bg-white/50 backdrop-blur-sm py-16 sm:py-32">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 sm:gap-16">
            <div
              className="text-center group hover:scale-105 transition-transform duration-300"
              data-aos="fade-up"
            >
              <div className="text-6xl md:text-7xl font-light text-black mb-4 group-hover:text-[#E54C4C] transition-colors">
                9
                <span className="text-2xl text-black/60 ml-2 font-extralight group-hover:text-[#E54C4C]/60">
                  min
                </span>
              </div>
              <div className="space-y-2">
                <p className="text-gray-500 text-sm md:text-base uppercase tracking-[0.3em] font-extralight">
                  Average
                </p>
                <p className="text-gray-900 text-sm md:text-base uppercase tracking-[0.3em] font-light">
                  Pickup Time
                </p>
              </div>
            </div>

            <div
              className="text-center group hover:scale-105 transition-transform duration-300"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="text-6xl md:text-7xl font-light text-black mb-4 group-hover:text-[#E54C4C] transition-colors">
                45
                <span className="text-2xl text-black/60 ml-2 font-extralight group-hover:text-[#E54C4C]/60">
                  min
                </span>
              </div>
              <div className="space-y-2">
                <p className="text-gray-500 text-sm md:text-base uppercase tracking-[0.3em] font-extralight">
                  Average
                </p>
                <p className="text-gray-900 text-sm md:text-base uppercase tracking-[0.3em] font-light">
                  Delivery Time
                </p>
              </div>
            </div>

            <div
              className="text-center group hover:scale-105 transition-transform duration-300"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="text-6xl md:text-7xl font-light text-black mb-4 group-hover:text-[#E54C4C] transition-colors">
                £1M
                <span className="text-2xl text-black/60 ml-2 font-extralight group-hover:text-[#E54C4C]/60">
                  +
                </span>
              </div>
              <div className="space-y-2">
                <p className="text-gray-500 text-sm md:text-base uppercase tracking-[0.3em] font-extralight">
                  Worth of
                </p>
                <p className="text-gray-900 text-sm md:text-base uppercase tracking-[0.3em] font-light">
                  Fashion Delivered
                </p>
              </div>
            </div>

            <div
              className="text-center group hover:scale-105 transition-transform duration-300"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="text-6xl md:text-7xl font-light text-black mb-4 group-hover:text-[#E54C4C] transition-colors">
                £5
                <span className="text-2xl text-black/60 ml-2 font-extralight group-hover:text-[#E54C4C]/60">
                  *
                </span>
              </div>
              <div className="space-y-2">
                <p className="text-gray-500 text-sm md:text-base uppercase tracking-[0.3em] font-extralight">
                  Starting
                </p>
                <p className="text-gray-900 text-sm md:text-base uppercase tracking-[0.3em] font-light">
                  *Price Per Stop
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Core Advantages Section */}
      <div className="py-20 sm:py-40 bg-gradient-to-b from-[#0a0a0a] via-[#111111] to-[#141414] text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16 sm:mb-24" data-aos="fade-up">
            <h2 className="text-3xl sm:text-4xl md:text-5xl font-light mb-6 sm:mb-8 tracking-[0.2em] px-4 sm:px-0">
              REDEFINING FASHION LOGISTICS
            </h2>
            <p className="text-base sm:text-lg text-gray-300 max-w-2xl mx-auto font-normal tracking-[0.1em] leading-relaxed px-4 sm:px-0">
              Where speed meets style. Experience delivery tailored for the
              fashion industry.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 sm:gap-12 px-4 sm:px-0">
            <div
              className="p-8 rounded-2xl bg-white/5 backdrop-blur-sm hover:bg-white/10 transition-all duration-300"
              data-aos="fade-up"
              data-aos-delay="0"
            >
              <div className="bg-white/10 w-16 h-16 rounded-xl flex items-center justify-center mb-8">
                <Clock className="text-white" size={32} />
              </div>
              <h3 className="text-2xl font-normal mb-4 tracking-wide">
                QUICK PICKUP
              </h3>
              <p className="text-gray-300 font-normal leading-relaxed">
                London's fastest pickup service. Perfect for time-critical
                fashion deliveries and last-minute returns.
              </p>
            </div>

            <div
              className="p-8 rounded-2xl bg-white/5 backdrop-blur-sm hover:bg-white/10 transition-all duration-300"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="bg-white/10 w-16 h-16 rounded-xl flex items-center justify-center mb-8">
                <Shield className="text-white" size={32} />
              </div>
              <h3 className="text-2xl font-normal mb-4 tracking-wide">
                LUXURY CARE
              </h3>
              <p className="text-gray-300 font-normal leading-relaxed">
                Specialized in handling high-value garments. Every piece treated
                with the care it deserves.
              </p>
            </div>

            <div
              className="p-8 rounded-2xl bg-white/5 backdrop-blur-sm hover:bg-white/10 transition-all duration-300"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="bg-white/10 w-16 h-16 rounded-xl flex items-center justify-center mb-8">
                <Globe className="text-white" size={32} />
              </div>
              <h3 className="text-2xl font-normal mb-4 tracking-wide">
                MULTIDROP
              </h3>
              <p className="text-gray-300 font-normal leading-relaxed">
                Seamless multidropfor fashion shoots and retail logistics.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Testimonial Section */}
      <div className="py-16 sm:py-32 bg-gradient-to-br from-[#0a0a0a] via-[#0f0f0f] to-[#141414] text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative rounded-3xl bg-white/[0.07] backdrop-blur-sm p-8 sm:p-16">
            <div className="relative z-10">
              <div className="flex flex-col md:flex-row gap-8 sm:gap-12 items-center">
                <div className="w-24 h-24 sm:w-32 sm:h-32 md:w-40 md:h-40 overflow-hidden rounded-full border-4 border-white/10">
                  <div className="w-full h-full bg-[#B8CEF9] flex items-center justify-center text-[#1a237e] text-3xl sm:text-4xl font-normal">
                    DW
                  </div>
                </div>
                <div className="flex-1 text-center md:text-left">
                  <blockquote className="text-xl sm:text-2xl md:text-3xl font-light text-white mb-4 sm:mb-6 leading-relaxed italic">
                    "A game-changer for fashion logistics. Finally, a service
                    that understands the unique needs of our industry."
                  </blockquote>
                  <cite className="block">
                    <span className="text-lg sm:text-xl font-light text-white/90 block">
                      Delaney Williams
                    </span>
                    <span className="text-gray-400 font-light">
                      Fashion Director
                    </span>
                  </cite>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Fashion Benefits Section */}
      <div className="py-16 sm:py-32 bg-gradient-to-b from-[#141414] via-[#0f0f0f] to-[#080808] text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12 sm:mb-20">
            <h2 className="text-3xl sm:text-4xl md:text-5xl font-light mb-4 sm:mb-6 tracking-wide px-4 sm:px-0">
              TAILORED FOR FASHION
            </h2>
            <p className="text-base sm:text-lg text-gray-300 max-w-2xl mx-auto font-normal tracking-wide px-4 sm:px-0">
              Specialized solutions for the fashion industry's unique delivery
              needs.
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 sm:gap-12">
            <div
              className="p-6 sm:p-12 rounded-3xl bg-white/[0.07] backdrop-blur-sm"
              data-aos="fade-right"
            >
              <h3 className="text-2xl font-normal mb-6 tracking-wide">
                MULTIDROP EXCELLENCE
              </h3>
              <p className="text-gray-300 mb-8 font-normal leading-relaxed">
                Seamlessly manage multiple drop offs and deliveries. Perfect for
                fashion shoots and events.
              </p>
              <ul className="space-y-4">
                <li className="flex items-center text-gray-200 font-normal">
                  <Check className="text-white mr-3" size={20} />
                  <span>Up to 20 stops per booking</span>
                </li>
                <li className="flex items-center text-gray-200 font-normal">
                  <Check className="text-white mr-3" size={20} />
                  <span>Real-time tracking & notifications</span>
                </li>
                <li className="flex items-center text-gray-200 font-normal">
                  <Check className="text-white mr-3" size={20} />
                  <span>Flexible scheduling options</span>
                </li>
              </ul>
            </div>
            <div
              className="p-6 sm:p-12 rounded-3xl bg-white/[0.07] backdrop-blur-sm"
              data-aos="fade-left"
            >
              <h3 className="text-2xl font-normal mb-6 tracking-wide">
                PREMIUM SERVICE, ACCESSIBLE PRICING
              </h3>
              <p className="text-gray-300 mb-8 font-normal leading-relaxed">
                Luxury service without the luxury price tag. Transparent pricing
                designed for fashion professionals.
              </p>
              <ul className="space-y-4">
                <li className="flex items-center text-gray-200 font-normal">
                  <Check className="text-white mr-3" size={20} />
                  <span>Starting at £3.49 per delivery</span>
                </li>
                <li className="flex items-center text-gray-200 font-normal">
                  <Check className="text-white mr-3" size={20} />
                  <span>Starting Trial discount</span>
                </li>
                <li className="flex items-center text-gray-200 font-normal">
                  <Check className="text-white mr-3" size={20} />
                  <span>No hidden fees</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <CTA />
      <Footer />
    </div>
  );
};

export default Home;
