import React from "react";
import { Menu, ArrowUpRight } from "lucide-react";
import { Link } from "react-router-dom";

// Import the logo from the same location as used in other components
const logoImage = require("../Images/logo.png");

const AthenaNavbar = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <nav className="fixed w-full backdrop-blur-lg bg-[#0E1420]/95 border-b border-[#2A3143] z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-20">
          <div className="flex items-center">
            <button
              onClick={scrollToTop}
              className="flex items-center focus:outline-none"
            >
              <img src={logoImage} alt="Blitzo" className="h-10 w-auto" />
              <div className="ml-3 flex items-center">
                <span className="text-2xl font-semibold text-white">
                  Blitzo
                </span>
                <span className="ml-2 text-2xl bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600 font-semibold">
                  ATHENA
                </span>
              </div>
            </button>
          </div>

          <div className="hidden md:flex items-center space-x-8">
            <button
              onClick={() => scrollToSection("free-up-your-time")}
              className="text-gray-300 hover:text-blue-400 transition-colors text-sm font-medium bg-transparent border-none cursor-pointer"
            >
              Features
            </button>
            <button
              onClick={() => scrollToSection("impact-section")}
              className="text-gray-300 hover:text-blue-400 transition-colors text-sm font-medium bg-transparent border-none cursor-pointer"
            >
              Impact
            </button>
            <button
              onClick={() => scrollToSection("use-cases-section")}
              className="text-gray-300 hover:text-blue-400 transition-colors text-sm font-medium bg-transparent border-none cursor-pointer"
            >
              Use Cases
            </button>
            <button
              onClick={() => scrollToSection("integration-section")}
              className="text-gray-300 hover:text-blue-400 transition-colors text-sm font-medium bg-transparent border-none cursor-pointer"
            >
              Integrations
            </button>

            <div className="flex items-center">
              <button
                onClick={() => scrollToSection("contact-section")}
                className="bg-gradient-to-r from-blue-600 to-purple-600 text-white px-6 py-2 rounded-lg hover:from-blue-700 hover:to-purple-700 transition-all duration-300 shadow-lg shadow-blue-600/20 flex items-center text-sm font-medium"
              >
                Speak To Us <ArrowUpRight className="ml-2" size={16} />
              </button>
            </div>
          </div>

          <div className="md:hidden">
            <button className="text-gray-300" aria-label="Toggle menu">
              <Menu size={28} />
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default AthenaNavbar;
