import { db } from "./firebase-config";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";

const USERS_COLLECTION = "users";
const DOCUMENT_COLLECTION = "userDocuments";

const getUserInfo = async (userID) => {
  try {
    const userRef = doc(db, USERS_COLLECTION, userID);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      const userData = userDoc.data();
      return {
        name: userData.personalInfo?.name || userData.name,
        phoneNumber: userData.personalInfo?.phoneNumber || userData.phoneNumber,
      };
    } else {
      console.error(`No such user with ID: ${userID}`);
      return null;
    }
  } catch (error) {
    console.error(`Error fetching user info for ID ${userID}:`, error);
    return null;
  }
};

const getUsers = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, USERS_COLLECTION));
    const users = [];
    querySnapshot.forEach((doc) => {
      users.push({
        id: doc.id,
        ...doc.data(),
      });
    });
    return users;
  } catch (e) {
    console.error("Error fetching users:", e);
    return [];
  }
};

const getUserDocument = async (userID) => {
  try {
    const docRef = doc(db, DOCUMENT_COLLECTION, userID);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.error(`No document found for user ID: ${userID}`);
      return null;
    }
  } catch (error) {
    return null;
  }
};

export { getUserInfo, getUsers, getUserDocument };
