import React, { useEffect, useState } from "react";
import DarkNavbar from "./DarkNavbar";
import Footer from "./footer";
import { useNavigate } from "react-router-dom";
import BottomCTA from "./BottomCta";
import { blogPosts } from "./Blogs/BlogData";
import styled from "styled-components";
import { ArrowRight, Calendar, MapPin, Clock } from "lucide-react";
import AOS from "aos";
import "aos/dist/aos.css";

// Gradient text component
const GradientText = styled.span`
  background: linear-gradient(90deg, #e54c4c 0%, #ff6b6b 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
`;

// Blue gradient text component
const BlueGradient = styled.span`
  background: linear-gradient(90deg, #4ecafc 0%, #6a8cff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
`;

// Network Animation for background
const NetworkAnimation = () => {
  return (
    <div className="absolute inset-0 z-0 overflow-hidden opacity-40">
      <div className="absolute inset-0 opacity-10">
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `
              linear-gradient(to right, rgba(255,255,255,0.05) 1px, transparent 1px),
              linear-gradient(to bottom, rgba(255,255,255,0.05) 1px, transparent 1px)
            `,
            backgroundSize: "20px 20px",
          }}
        ></div>
      </div>

      {/* Animated dots */}
      <div className="absolute inset-0">
        {[...Array(15)].map((_, i) => (
          <div
            key={`red-${i}`}
            className="absolute w-1 h-1 rounded-full bg-[#E54C4C]/40"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animation: `float ${
                3 + Math.random() * 4
              }s infinite ease-in-out ${Math.random() * 2}s`,
            }}
          ></div>
        ))}
        {[...Array(10)].map((_, i) => (
          <div
            key={`blue-${i}`}
            className="absolute w-1 h-1 rounded-full bg-[#4ECAFC]/40"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animation: `float ${
                2 + Math.random() * 3
              }s infinite ease-in-out ${Math.random() * 2}s`,
            }}
          ></div>
        ))}
      </div>

      {/* Radial gradients */}
      <div
        className="absolute inset-0 opacity-40"
        style={{
          background:
            "radial-gradient(50% 50% at 50% 50%, rgba(229,76,76,0.08) 0%, rgba(10,13,20,0) 100%)",
        }}
      ></div>
      <div
        className="absolute inset-0 opacity-20"
        style={{
          background:
            "radial-gradient(70% 70% at 30% 30%, rgba(78,202,252,0.1) 0%, rgba(10,13,20,0) 100%)",
        }}
      ></div>

      {/* Glowing orbs */}
      <div
        className="absolute w-96 h-96 rounded-full"
        style={{
          background:
            "radial-gradient(circle, rgba(229,76,76,0.1) 0%, rgba(10,13,20,0) 70%)",
          left: "30%",
          top: "40%",
          filter: "blur(50px)",
          animation: "pulseGlow 8s infinite ease-in-out",
        }}
      ></div>
      <div
        className="absolute w-96 h-96 rounded-full"
        style={{
          background:
            "radial-gradient(circle, rgba(78,202,252,0.08) 0%, rgba(10,13,20,0) 70%)",
          right: "25%",
          top: "30%",
          filter: "blur(50px)",
          animation: "pulseGlow 10s infinite ease-in-out 2s",
        }}
      ></div>

      <style jsx>{`
        @keyframes float {
          0%,
          100% {
            transform: translateY(0) translateX(0);
          }
          50% {
            transform: translateY(-10px) translateX(5px);
          }
        }
        @keyframes pulseGlow {
          0%,
          100% {
            opacity: 0.3;
            transform: scale(1);
          }
          50% {
            opacity: 0.8;
            transform: scale(1.3);
          }
        }
      `}</style>
    </div>
  );
};

function Blog() {
  const navigate = useNavigate();
  const [hoveredPost, setHoveredPost] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <div className="min-h-screen flex flex-col bg-[#080A12] text-white">
      <DarkNavbar />

      {/* Hero Section */}
      <div className="relative pt-32 pb-8 overflow-hidden">
        <NetworkAnimation />

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div data-aos="fade-up">
            <div className="mb-4 inline-block px-4 py-1.5 rounded-full bg-[#E54C4C]/10 border border-[#E54C4C]/20 text-[#E54C4C] text-sm">
              Blitzo Insights
            </div>
            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              Latest <GradientText>Blog</GradientText> Posts
            </h1>
            <p className="text-gray-300 max-w-2xl mb-12">
              Explore our insights on same-hour delivery, logistics innovations,
              and how businesses are transforming their operations with Blitzo.
            </p>
          </div>
        </div>

        {/* Glowing orbs */}
        <div className="absolute top-0 left-0 w-64 h-64 rounded-full bg-[#4ECAFC]/10 blur-3xl opacity-30 animate-pulse-slow"></div>
        <div className="absolute bottom-0 right-0 w-64 h-64 rounded-full bg-[#E54C4C]/10 blur-3xl opacity-30 animate-pulse-slow-delay"></div>
      </div>

      {/* Blog Content */}
      <div className="flex-1 pb-24 relative z-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Featured Post */}
          <div className="mb-16" data-aos="fade-up">
            <div
              className="group relative grid grid-cols-1 lg:grid-cols-2 gap-8 bg-[#0F121B]/80 border border-[#262D40] hover:border-[#414D75] rounded-xl p-6 cursor-pointer transition-all duration-300 hover:shadow-xl shadow-lg"
              onClick={() => navigate(blogPosts[0].path)}
              onMouseEnter={() => setHoveredPost(0)}
              onMouseLeave={() => setHoveredPost(null)}
            >
              {/* Gradient glow effect on hover */}
              <div
                className={`absolute inset-0 bg-gradient-to-br from-[#E54C4C]/5 to-[#4ECAFC]/5 rounded-xl opacity-0 group-hover:opacity-100 transition-opacity duration-500 -z-10`}
              ></div>

              <div className="aspect-[4/3] relative overflow-hidden rounded-lg">
                <img
                  src={blogPosts[0].media}
                  alt={blogPosts[0].title}
                  className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-105"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-[#080A12]/80 to-transparent opacity-50"></div>
              </div>

              <div className="flex flex-col justify-center lg:pr-6">
                <div className="flex items-center mb-4 text-sm text-gray-400">
                  <div className="flex items-center mr-4">
                    <Calendar size={14} className="mr-1 text-[#E54C4C]" />
                    <span>{blogPosts[0].date}</span>
                  </div>
                  <div className="flex items-center">
                    <MapPin size={14} className="mr-1 text-[#4ECAFC]" />
                    <span>{blogPosts[0].location}</span>
                  </div>
                </div>

                <h2 className="text-2xl font-bold mb-4 text-white group-hover:text-[#E54C4C] transition-colors">
                  {blogPosts[0].title}
                </h2>

                <p className="text-gray-300 mb-6 line-clamp-3">
                  {blogPosts[0].excerpt}
                </p>

                <div className="flex items-center text-[#E54C4C] font-medium group-hover:translate-x-1 transition-transform">
                  Read More <ArrowRight size={16} className="ml-2" />
                </div>
              </div>
            </div>
          </div>

          {/* Grid of Other Posts */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {blogPosts.slice(1).map((post, index) => (
              <div
                key={index}
                className="group bg-[#0F121B]/80 border border-[#262D40] hover:border-[#414D75] rounded-xl p-6 cursor-pointer transition-all duration-300 shadow-lg hover:shadow-xl relative"
                onClick={() => post.path && navigate(post.path)}
                onMouseEnter={() => setHoveredPost(index + 1)}
                onMouseLeave={() => setHoveredPost(null)}
                data-aos="fade-up"
                data-aos-delay={(index % 3) * 100}
              >
                {/* Gradient glow effect on hover */}
                <div
                  className={`absolute inset-0 bg-gradient-to-br from-[#E54C4C]/5 to-[#4ECAFC]/5 rounded-xl opacity-0 group-hover:opacity-100 transition-opacity duration-500 -z-10`}
                ></div>

                <div className="aspect-video relative overflow-hidden rounded-lg mb-6">
                  <img
                    src={post.media}
                    alt={post.title}
                    className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-105"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-[#080A12]/70 to-transparent opacity-50"></div>
                </div>

                <div className="flex items-center mb-3 text-xs text-gray-400">
                  <div className="flex items-center mr-3">
                    <Calendar size={12} className="mr-1 text-[#E54C4C]" />
                    <span>{post.date}</span>
                  </div>
                  <div className="flex items-center">
                    <MapPin size={12} className="mr-1 text-[#4ECAFC]" />
                    <span>{post.location}</span>
                  </div>
                </div>

                <h2 className="text-xl font-bold mb-3 text-white group-hover:text-[#E54C4C] transition-colors">
                  {post.title}
                </h2>

                <p className="text-gray-400 text-sm mb-4 line-clamp-2">
                  {post.excerpt}
                </p>

                <div className="flex items-center text-[#E54C4C] text-sm font-medium opacity-0 group-hover:opacity-100 transition-opacity group-hover:translate-x-1 transition-transform">
                  Read More <ArrowRight size={14} className="ml-1" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <BottomCTA
        title="Stay Updated with <GradientText>Blitzo</GradientText> News"
        description="Subscribe to our newsletter for the latest updates on same-hour delivery, logistics innovations, and industry insights."
        businessOption={{
          title: "Subscribe to Newsletter",
          description:
            "Get weekly updates about same-hour delivery innovations, client success stories, and Blitzo product announcements.",
          features: [
            "Weekly delivery insights",
            "Industry case studies",
            "Product feature announcements",
          ],
          buttonText: "Subscribe Now",
        }}
        enterpriseOption={{
          title: "Request Custom Content",
          description:
            "Looking for specific information or a custom case study for your industry? Our content team can help.",
          features: [
            "Industry-specific research",
            "Custom case studies",
            "White papers and reports",
          ],
          buttonText: "Contact Us",
        }}
      />

      <Footer />

      {/* Custom animations */}
      <style jsx>{`
        @keyframes pulse-slow {
          0%,
          100% {
            transform: scale(1);
            opacity: 0.3;
          }
          50% {
            transform: scale(1.1);
            opacity: 0.5;
          }
        }
        @keyframes pulse-slow-delay {
          0%,
          100% {
            transform: scale(1);
            opacity: 0.3;
          }
          50% {
            transform: scale(1.15);
            opacity: 0.6;
          }
        }
        .animate-pulse-slow {
          animation: pulse-slow 15s infinite ease-in-out;
        }
        .animate-pulse-slow-delay {
          animation: pulse-slow-delay 20s infinite ease-in-out 2s;
        }
      `}</style>
    </div>
  );
}

export default Blog;
