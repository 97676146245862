import React, { useState } from "react";
import { Share2, Check } from "lucide-react";

interface ShareButtonProps {
  className?: string;
}

const ShareButton: React.FC<ShareButtonProps> = ({ className = "" }) => {
  const [showCopied, setShowCopied] = useState(false);

  const handleShare = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      setShowCopied(true);
      setTimeout(() => setShowCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy URL:", err);
    }
  };

  return (
    <button
      onClick={handleShare}
      className={`px-4 py-2 rounded-lg bg-gray-800 hover:bg-gray-700 
        transition-all text-white flex items-center gap-2 group ${className}`}
    >
      {showCopied ? (
        <>
          <Check size={18} className="text-green-400" />
          <span>Live Tracking URL Copied!</span>
        </>
      ) : (
        <>
          <Share2 size={18} />
          <span>Share Live Tracking</span>
        </>
      )}
    </button>
  );
};

export default ShareButton;
