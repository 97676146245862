import React, { useState, useEffect, useRef } from "react";
import {
  ArrowUpRight,
  ChevronRight,
  Zap,
  Shield,
  Users,
  Package,
  BarChart,
  Brain,
  Clock,
  Check,
  Layers,
  Bot,
  ArrowRight,
  Settings,
  Smartphone,
  Sparkles,
  Globe,
  Code,
  Truck,
  Car,
  CloudLightning,
  Bike,
  MessageSquare,
} from "lucide-react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import Footer from "../components/footer";

// Brands for social proof
import GQ from "../Images/Brands/GQ.png";
import Vogue from "../Images/Brands/Vogue_logo.png";
import Harpers from "../Images/Brands/Harpers-Bazaar-logo.png";
import Crack from "../Images/Brands/CrackLogo.png";
import CPConcept from "../Images/Brands/CP_Logo.png";
import MindTheCake from "../Images/Brands/MindTheCake_Logo.jpeg";
import BurstingBuds from "../Images/Brands/Bursting-Buds.jpg";

import PremiumFleetPricing from "../components/PremiumFleetPricing";

// Import DarkNavbar
import DarkNavbar from "../components/DarkNavbar";
import HowItWorks from "../components/HowItWorks";
import BusinessImpactNarrative from "../components/BusinessImpactNarrative";
import FleetSolutions from "../components/FleetSolutions";
import BottomCTA from "../components/BottomCta";
import SpeakToSalesModal from "../components/SpeakToSalesModal";

// AI Agent Name Variable
const AI_AGENT_NAME = "Blitzo Intelligence"; // Name for our AI operations platform

// Animated text typing effect
const TypewriterText = styled.div`
  overflow: hidden;
  border-right: 2px solid #e54c4c;
  white-space: nowrap;
  margin: 0 auto;
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;

  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  @keyframes blink-caret {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: #e54c4c;
    }
  }
`;

// Gradient text component
const GradientText = styled.span`
  background: linear-gradient(90deg, #e54c4c 0%, #ff6b6b 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
`;

// Blue gradient text component
const BlueGradient = styled.span`
  background: linear-gradient(90deg, #4ecafc 0%, #6a8cff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
`;

// Strikethrough styling
const Strikethrough = styled.span`
  position: relative;
  color: rgba(255, 255, 255, 0.5);

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 2px;
    background: #e54c4c;
    transform: rotate(-5deg);
  }
`;

// Animated count up component
const CountUp = ({ end, duration = 2000, prefix = "", suffix = "" }) => {
  const [count, setCount] = useState(0);
  const countRef = useRef(null);

  useEffect(() => {
    let startTime;
    let animationFrame;

    const animate = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;
      const percentage = Math.min(progress / duration, 1);
      setCount(Math.floor(percentage * end));

      if (percentage < 1) {
        animationFrame = requestAnimationFrame(animate);
      }
    };

    animationFrame = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrame);
  }, [end, duration]);

  return (
    <span>
      {prefix}
      {count}
      {suffix}
    </span>
  );
};
// Orbiting Text Component
const OrbitingTextSphere = () => {
  // Array of futuristic benefits/features to display
  const orbitTexts = [
    "Operations that run themselves",
    "No rebound deliveries",
    "Direct bookings through sales channels",
    "Automated dispatch & routing",
    "Zero operational overhead",
    "Premium white-glove service",
  ];

  return (
    <div className="absolute inset-0 w-full h-full overflow-hidden pointer-events-none">
      {/* Using a wider container to push items further to the sides */}
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[200%] h-[200%]">
        {/* Orbiting texts */}
        {orbitTexts.map((text, index) => {
          // Using much wider orbits to push to edges
          const rotationSpeed = 45 + index * 3; // Even slower rotation
          const initialRotation = (index * 60) % 360;
          const orbitDistance = 300 + ((index * 30) % 100); // Much larger orbit radius

          // Randomize vertical position more dramatically
          const yOffset =
            (index % 2 === 0 ? -1 : 1) * (100 + ((index * 50) % 150));

          // Calculate initial position based on initialRotation
          const initialX =
            Math.cos((initialRotation * Math.PI) / 180) * orbitDistance;
          const initialY =
            Math.sin((initialRotation * Math.PI) / 180) * orbitDistance +
            yOffset;

          return (
            <div
              key={index}
              className="absolute whitespace-nowrap px-2 py-0.5 rounded-full text-[10px]"
              style={{
                left: `calc(50% + ${initialX}px)`,
                top: `calc(50% + ${initialY}px)`,
                transform: "translate(-50%, -50%)",
                background: "rgba(18, 24, 36, 0.7)", // Darker background for better readability
                border:
                  index % 2 === 0
                    ? "1px solid rgba(229, 76, 76, 0.15)"
                    : "1px solid rgba(78, 202, 252, 0.15)",
                color:
                  index % 2 === 0
                    ? "rgba(229, 76, 76, 0.7)"
                    : "rgba(78, 202, 252, 0.7)",
                opacity: 0.6, // Much lower opacity
                animation: `orbitAnim${index} ${rotationSpeed}s infinite linear, 
                           pulseAnim${index} 6s infinite ease-in-out`,
                zIndex: 5, // Lower z-index to stay behind content
                backdropFilter: "blur(1px)",
              }}
            >
              {text}
            </div>
          );
        })}
      </div>

      {/* Styles for orbital animations with initial positioning */}
      <style jsx>{`
        ${orbitTexts
          .map((_, index) => {
            const initialRotation = (index * 60) % 360;
            const orbitDistance = 300 + ((index * 30) % 100);
            const yOffset =
              (index % 2 === 0 ? -1 : 1) * (100 + ((index * 50) % 150));

            return `
            @keyframes orbitAnim${index} {
              0% {
                transform: translate(-50%, -50%) rotate(${initialRotation}deg) translateX(${orbitDistance}px) rotate(-${initialRotation}deg);
              }
              100% {
                transform: translate(-50%, -50%) rotate(${
                  initialRotation + 360
                }deg) translateX(${orbitDistance}px) rotate(-${
              initialRotation + 360
            }deg);
              }
            }
            @keyframes pulseAnim${index} {
              0%, 100% {
                opacity: 0.6;
                filter: blur(0px);
              }
              50% {
                opacity: 0.9;
                filter: blur(0.5px);
              }
            }
          `;
          })
          .join("\n")}
      `}</style>
    </div>
  );
};

// Futuristic Network Animation
const NetworkAnimation = () => (
  <div className="absolute inset-0 z-0 overflow-hidden">
    {/* Grid lines */}
    <div className="absolute inset-0 opacity-10">
      <div
        className="absolute top-0 left-0 right-0 bottom-0"
        style={{
          backgroundImage: `
           linear-gradient(to right, rgba(255,255,255,0.05) 1px, transparent 1px),
           linear-gradient(to bottom, rgba(255,255,255,0.05) 1px, transparent 1px)
         `,
          backgroundSize: "20px 20px",
        }}
      ></div>
    </div>

    {/* Animated dots and connections */}
    <div className="absolute inset-0">
      {/* This would ideally be a proper canvas animation - using simple elements for now */}
      {[...Array(15)].map((_, i) => (
        <div
          key={i}
          className="absolute w-1 h-1 rounded-full bg-[#E54C4C]/40"
          style={{
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
            animation: `float ${3 + Math.random() * 4}s infinite ease-in-out ${
              Math.random() * 2
            }s`,
          }}
        ></div>
      ))}

      {/* Blue dots */}
      {[...Array(10)].map((_, i) => (
        <div
          key={i}
          className="absolute w-1 h-1 rounded-full bg-[#4ECAFC]/40"
          style={{
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
            animation: `float ${2 + Math.random() * 3}s infinite ease-in-out ${
              Math.random() * 2
            }s`,
          }}
        ></div>
      ))}
    </div>

    {/* Radial gradient layers */}
    <div
      className="absolute inset-0 opacity-40"
      style={{
        background:
          "radial-gradient(50% 50% at 50% 50%, rgba(229, 76, 76, 0.08) 0%, rgba(10, 13, 20, 0) 100%)",
      }}
    ></div>
    <div
      className="absolute inset-0 opacity-20"
      style={{
        background:
          "radial-gradient(70% 70% at 30% 30%, rgba(78, 202, 252, 0.1) 0%, rgba(10, 13, 20, 0) 100%)",
      }}
    ></div>

    {/* Moving glow effect */}
    <div
      className="absolute w-56 h-56 rounded-full"
      style={{
        background:
          "radial-gradient(circle, rgba(229,76,76,0.1) 0%, rgba(10,13,20,0) 70%)",
        left: "30%",
        top: "40%",
        filter: "blur(30px)",
        animation: "pulseGlow 8s infinite ease-in-out",
      }}
    ></div>
    <div
      className="absolute w-72 h-72 rounded-full"
      style={{
        background:
          "radial-gradient(circle, rgba(78,202,252,0.08) 0%, rgba(10,13,20,0) 70%)",
        right: "25%",
        top: "30%",
        filter: "blur(40px)",
        animation: "pulseGlow 10s infinite ease-in-out 2s",
      }}
    ></div>

    {/* Add the styles for animations */}
    <style jsx>{`
      @keyframes float {
        0%,
        100% {
          transform: translateY(0) translateX(0);
        }
        50% {
          transform: translateY(-10px) translateX(5px);
        }
      }

      @keyframes pulseGlow {
        0%,
        100% {
          opacity: 0.3;
          transform: scale(1);
        }
        50% {
          opacity: 0.8;
          transform: scale(1.2);
        }
      }
    `}</style>

    {/* Add the orbiting text component */}
  </div>
);

// Home Component
// ...existing code...

const Home = () => {
  const [portalView, setPortalView] = useState("business");
  const [isScrolled, setIsScrolled] = useState(false);

  const [showSalesModal, setShowSalesModal] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-out",
      once: true,
    });

    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const togglePortal = () => {
    setPortalView(portalView === "business" ? "consumer" : "business");
  };

  const handleBookDemo = () => {
    window.location.href = "https://blitzo.co.uk/business/demo";
  };

  const navigate = useNavigate();

  const navigateTo = (path: string) => {
    navigate(path);
  };

  return (
    <div className="min-h-screen bg-[#080A12]">
      {/* Fixed Portal Toggle Button */}
      {/* <div className="fixed right-6 top-24 z-50">
        <div className="relative inline-block w-56">
          <div
            className="flex items-center justify-between bg-[#0F121B] border border-[#262D40] rounded-full shadow-md px-4 py-2 cursor-pointer"
            onClick={togglePortal}
          >
            <span className="text-sm font-medium text-white">
              {portalView === "business" ? "Enterprise Portal" : "Consumer App"}
            </span>
            <div className="ml-2 p-1 rounded-full bg-[#E54C4C]/10">
              <ArrowUpRight className="text-[#E54C4C]" size={14} />
            </div>
          </div>
          <div className="absolute -bottom-10 right-4 bg-[#0F121B] p-2 rounded-lg shadow-lg text-xs text-gray-300 opacity-75">
            <TypewriterText>
              Switch to {portalView === "business" ? "Consumer" : "Enterprise"}
            </TypewriterText>
          </div>
        </div>
      </div> */}

      {/* Dark Navbar */}
      <DarkNavbar />

      {portalView === "business" ? (
        <>
          <SpeakToSalesModal
            isOpen={showSalesModal}
            onClose={() => setShowSalesModal(false)}
          />
          {/* BUSINESS PORTAL CONTENT */}

          {/* Hero Section */}

          {/* Hero Section */}
          <div className="relative min-h-screen flex flex-col justify-center overflow-hidden bg-gradient-to-br from-[#080A12] via-[#0C0E18] to-[#0F121B]">
            {/* Futuristic Network Animation Background - keep but reduce opacity */}
            <NetworkAnimation />

            {/* Add subtle OrbitingTextSphere here */}
            <OrbitingTextSphere />

            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 md:pt-32 pb-12 relative z-10">
              <div className="flex flex-col items-center">
                {/* Eyebrow Alert */}
                {/* <div
                  className="mb-8 max-w-max mx-auto px-4 py-1.5 rounded-full bg-[#E54C4C]/10 border border-[#E54C4C]/20 text-[#E54C4C] text-center text-xs"
                  data-aos="fade-down"
                >
                  <div className="flex items-center">
                    <div className="w-1.5 h-1.5 rounded-full bg-[#E54C4C] mr-2"></div>
                    <TypewriterText>Powered by {AI_AGENT_NAME}</TypewriterText>
                  </div>
                </div> */}

                {/* Main Content */}
                <div
                  className="text-center max-w-4xl mx-auto"
                  data-aos="fade-up"
                >
                  <div className="flex items-center justify-center text-base sm:text-lg text-gray-400 mb-3">
                    <Strikethrough>Same-Day Delivery</Strikethrough>
                    <span className="ml-3 text-white">
                      is yesterday's standard
                    </span>
                  </div>

                  <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-white mb-5 leading-tight tracking-tight">
                    <GradientText>Same-Hour Delivery.</GradientText>
                    <br />
                    <BlueGradient>Zero Operational Interference.</BlueGradient>
                  </h1>
                </div>
              </div>
            </div>

            {/* Vehicle Showcase & CTA Section - Enhanced Version */}
            <div className="relative z-10 w-full max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 pb-24 mt-0">
              {/* Animated glow background for the entire section */}
              <div className="absolute inset-x-0 -top-10 -bottom-20 bg-gradient-to-b from-[#E54C4C]/5 to-[#4ECAFC]/5 filter blur-2xl rounded-3xl opacity-70"></div>

              <div
                className="relative bg-[#0F121B]/70 backdrop-blur-md rounded-xl border border-[#262D40] shadow-2xl overflow-hidden"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                {/* Decorative borders in corners */}
                <div className="absolute top-0 left-0 w-16 h-16 border-t-2 border-l-2 border-[#E54C4C]/30 rounded-tl-lg"></div>
                <div className="absolute top-0 right-0 w-16 h-16 border-t-2 border-r-2 border-[#4ECAFC]/30 rounded-tr-lg"></div>
                <div className="absolute bottom-0 left-0 w-16 h-16 border-b-2 border-l-2 border-[#4ECAFC]/30 rounded-bl-lg"></div>
                <div className="absolute bottom-0 right-0 w-16 h-16 border-b-2 border-r-2 border-[#E54C4C]/30 rounded-br-lg"></div>

                {/* Content */}
                <div className="p-8 md:p-10">
                  <div className="grid grid-cols-1 lg:grid-cols-12 gap-8 items-center">
                    {/* Left side: CTA content & buttons */}
                    <div className="lg:col-span-5 space-y-6">
                      <div className="flex items-center">
                        <div className="h-6 w-1 bg-gradient-to-b from-[#E54C4C] to-[#4ECAFC] rounded-full mr-3"></div>
                        <h3 className="text-2xl font-semibold text-white">
                          Same-Hour Delivery for Every Business
                        </h3>
                      </div>

                      <p className="text-gray-300 text-base leading-relaxed">
                        Connect your store to our premium network of 1000+
                        trained Riders and deliver in{" "}
                        <span className="text-white font-medium">
                          under 60 minutes
                        </span>{" "}
                        with zero operational overhead.
                      </p>

                      {/* Primary & Secondary CTAs */}
                      <div className="flex flex-col sm:flex-row gap-4 pt-2">
                        <div className="relative group">
                          {/* Animated Gradient Border & Glow */}
                          <div className="absolute -inset-0.5 bg-gradient-to-r from-[#E54C4C] to-[#FF6B6B] rounded-lg blur opacity-30 group-hover:opacity-100 transition duration-500"></div>

                          <button
                            onClick={() => navigateTo("/business")}
                            className="relative w-full px-6 py-3 bg-gradient-to-r from-[#E54C4C] to-[#FF6B6B] text-white rounded-lg shadow-lg shadow-[#E54C4C]/20 flex items-center justify-center group-hover:shadow-[#E54C4C]/40 transition-all duration-300 font-medium"
                          >
                            Try Blitzo for Business
                            <ChevronRight
                              className="ml-2 group-hover:translate-x-1 transition-transform"
                              size={18}
                            />
                          </button>
                        </div>

                        <button
                          onClick={() => setShowSalesModal((prev) => !prev)}
                          className="px-6 py-3 bg-transparent border border-[#262D40] hover:border-[#4ECAFC] text-white rounded-lg flex items-center justify-center group transition-all duration-300"
                        >
                          Speak to Sales
                          <ArrowRight
                            className="ml-2 group-hover:translate-x-1 transition-transform text-[#4ECAFC]"
                            size={18}
                          />
                        </button>
                      </div>
                    </div>

                    {/* Right side: Premium vehicle showcase */}
                    <div className="lg:col-span-7">
                      <div className="relative">
                        {/* Connection lines animation */}
                        <div className="absolute inset-0 opacity-20">
                          <div className="absolute left-1/4 top-0 w-px h-full bg-gradient-to-b from-transparent via-[#E54C4C]/50 to-transparent"></div>
                          <div className="absolute left-1/2 top-0 w-px h-full bg-gradient-to-b from-transparent via-[#4ECAFC]/50 to-transparent"></div>
                          <div className="absolute left-3/4 top-0 w-px h-full bg-gradient-to-b from-transparent via-[#E54C4C]/50 to-transparent"></div>
                        </div>

                        <p className="text-sm text-gray-400 mb-5 text-center uppercase tracking-wider font-medium">
                          UK-Wide Network of Trained Riders
                        </p>

                        <div className="grid grid-cols-4 gap-4">
                          {/* Enhanced Vehicle Components - with staggered appearance animations */}
                          <div data-aos="fade-up" data-aos-delay="300">
                            <VehicleTypeEnhanced
                              icon={Bike}
                              name="Bicycle"
                              info="Eco-friendly"
                            />
                          </div>
                          <div data-aos="fade-up" data-aos-delay="400">
                            <VehicleTypeEnhanced
                              icon={CloudLightning}
                              name="E-Bike"
                              info="Quick & Green"
                            />
                          </div>
                          <div data-aos="fade-up" data-aos-delay="500">
                            <VehicleTypeEnhanced
                              icon={Car}
                              name="Car"
                              info="Medium loads"
                            />
                          </div>
                          <div data-aos="fade-up" data-aos-delay="600">
                            <VehicleTypeEnhanced
                              icon={Truck}
                              name="Van"
                              info="Large orders"
                            />
                          </div>
                        </div>

                        {/* Status indicator */}
                        <div className="mt-8 flex items-center justify-center">
                          <div className="w-2 h-2 rounded-full bg-[#4ECAFC] animate-pulse mr-2"></div>
                          <span className="text-xs text-[#4ECAFC]">
                            Powered by {AI_AGENT_NAME}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Subtle wave separator to transition to stats/expectations section */}
            <div className="absolute bottom-0 left-0 right-0 opacity-50">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1440 320"
                className="w-full"
              >
                <path
                  fill="#0A0C16"
                  fillOpacity="1"
                  d="M0,224L80,213.3C160,203,320,181,480,186.7C640,192,800,224,960,229.3C1120,235,1280,213,1360,202.7L1440,192L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
                ></path>
              </svg>
            </div>
          </div>

          {/* Social Proof Section */}
          <EnterpriseProof
            onPress={() => {
              navigateTo("/blog");
            }}
          />

          <HowItWorks />
          <BusinessImpactNarrative />
          <PremiumFleetPricing />

          {/* Solution Tabs Section */}

          {/* Custom animated accent - commented since it needs custom implementation */}
          {/* 
              CUSTOM ASSET: 3D wireframe sphere or cube that slowly rotates in the background
              Implementation: Three.js rotating wireframe sphere with subtle red/blue gradients
              Position: Absolute positioned in the bottom right of the section
              Size: About 400x400px but mostly transparent/wireframe so not distracting
            */}

          <FleetSolutions />

          <BottomCTA />
          <Footer />
        </>
      ) : (
        // Consumer view content would go here
        <div className="py-32 text-center">
          <h2 className="text-3xl font-bold text-white mb-4">
            Consumer App Coming Soon
          </h2>
        </div>
      )}
    </div>
  );
};

// Enhanced Vehicle Type Component
const VehicleTypeEnhanced = ({ icon: Icon, name, info }) => (
  <div
    className="flex flex-col items-center group"
    data-aos="fade-up"
    data-aos-delay={50 * Math.random() * 10}
  >
    <div className="relative mb-3">
      {/* Animated glow background */}
      <div className="absolute -inset-1 bg-gradient-to-r from-[#E54C4C]/20 to-[#4ECAFC]/20 rounded-full blur-md group-hover:opacity-100 opacity-70 transition-opacity duration-300"></div>

      {/* Icon container */}
      <div className="relative w-16 h-16 bg-[#0F121B] rounded-full flex items-center justify-center border border-[#262D40] shadow-md transform group-hover:scale-105 transition-transform duration-300">
        <Icon
          className="text-[#E54C4C] group-hover:text-[#ff6b6b] transition-colors"
          size={24}
        />
      </div>

      {/* Status indicator */}
      <div className="absolute top-0 right-0 w-3 h-3 bg-[#4ECAFC] rounded-full border-2 border-[#0F121B] shadow-lg"></div>
    </div>
    <span className="font-medium text-sm text-white">{name}</span>
    <span className="text-xs text-gray-400 mt-1">{info}</span>
  </div>
);

// Enhanced Enterprise Social Proof Component
// Enhanced and Unified Enterprise Social Proof Component
const EnterpriseProof = ({ onPress }) => {
  return (
    <div className="bg-[#080A12] py-24 relative overflow-hidden">
      {/* Unified background element that spans the entire section */}
      <div className="absolute inset-0">
        {/* Dynamic grid background */}
        <div className="absolute inset-0 opacity-5">
          <div
            className="absolute inset-0"
            style={{
              backgroundImage: `linear-gradient(to right, rgba(255,255,255,0.07) 1px, transparent 1px), 
                              linear-gradient(to bottom, rgba(255,255,255,0.07) 1px, transparent 1px)`,
              backgroundSize: "40px 40px",
            }}
          ></div>
        </div>

        {/* Connecting "data stream" visual elements - CUSTOM ASSET PLACEHOLDER */}
        <div className="absolute inset-x-0 top-1/2 h-1 bg-gradient-to-r from-transparent via-[#E54C4C]/20 to-transparent"></div>
        <div className="absolute inset-y-0 left-1/2 w-1 bg-gradient-to-b from-transparent via-[#4ECAFC]/20 to-transparent"></div>

        {/* Accent orbs positioned to frame content sections */}
        <div className="absolute top-24 right-24 w-96 h-96 rounded-full bg-[#E54C4C]/5 filter blur-[80px] animate-pulse-slow"></div>
        <div className="absolute bottom-24 left-24 w-96 h-96 rounded-full bg-[#4ECAFC]/5 filter blur-[80px] animate-pulse-slow-delay"></div>
      </div>

      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        {/* Section title with unified design */}
        <div className="relative text-center mb-16" data-aos="fade-up">
          {/* CUSTOM ASSET: Decorative floating element behind heading */}
          <div className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 w-32 h-32 opacity-20">
            {/* Placeholder for custom decorative element */}
            <div className="w-full h-full rounded-full border border-[#E54C4C]/30 animate-spin-slow"></div>
          </div>

          <h2 className="text-3xl md:text-5xl font-bold text-white mb-4 leading-tight">
            Delivering For <GradientText>Brilliant Brands</GradientText>{" "}
          </h2>

          <p className="text-base text-gray-300 max-w-3xl mx-auto">
            We drive metrics that matter for these brands, enabling instant
            gratification for their customers and leading to{" "}
            <span className="text-white font-medium">
              retention rates like never before
            </span>
            .
          </p>
        </div>

        {/* UNIFIED CONTENT CONTAINER: frames all section content with consistent styling */}
        <div
          className="relative bg-[#0A0D16]/60 backdrop-blur-sm rounded-2xl border border-[#262D40] overflow-hidden p-6 md:p-10 shadow-[0_10px_50px_rgba(0,0,0,0.25)]"
          data-aos="fade-up"
        >
          {/* Decorative corner accents */}
          <div className="absolute top-0 left-0 w-16 h-16 border-t-2 border-l-2 border-[#E54C4C]/30 rounded-tl-lg"></div>
          <div className="absolute top-0 right-0 w-16 h-16 border-t-2 border-r-2 border-[#4ECAFC]/30 rounded-tr-lg"></div>
          <div className="absolute bottom-0 left-0 w-16 h-16 border-b-2 border-l-2 border-[#4ECAFC]/30 rounded-bl-lg"></div>
          <div className="absolute bottom-0 right-0 w-16 h-16 border-b-2 border-r-2 border-[#E54C4C]/30 rounded-br-lg"></div>

          {/* Enhanced brand showcase */}
          <div className="mb-10">
            <div className="relative">
              {/* Logos container */}
              <div className="relative bg-[#0F121B]/90 backdrop-blur-lg rounded-xl border border-[#262D40] p-8 md:p-10">
                <div className="grid grid-cols-2 md:grid-cols-5 gap-6 md:gap-10 items-center">
                  {[
                    { logo: GQ, name: "GQ", scale: "scale-75" },
                    { logo: Vogue, name: "Vogue", scale: "scale-75" },
                    {
                      logo: Harpers,
                      name: "Harper's Bazaar",
                      scale: "scale-150",
                    },
                    { logo: Crack, name: "Crack Magazine", scale: "scale-150" },
                    { logo: CPConcept, name: "CP Concept", scale: "scale-150" },
                  ].map((brand, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-center group relative py-4"
                      data-aos="fade-up"
                      data-aos-delay={100 + index * 50}
                    >
                      <div className="absolute inset-0 bg-gradient-to-br from-[#E54C4C]/0 to-[#4ECAFC]/0 group-hover:from-[#E54C4C]/10 group-hover:to-[#4ECAFC]/10 rounded-xl -m-3 transition-all duration-300 opacity-0 group-hover:opacity-100 shadow-xl"></div>
                      <div
                        className={`transform transition-all duration-700 ease-in-out hover:translate-y-[-8px] ${brand.scale}`}
                      >
                        <div className="w-full h-16 flex items-center justify-center filter brightness-0 invert opacity-60 group-hover:opacity-100 transition-all duration-500">
                          <img
                            src={brand.logo}
                            alt={brand.name}
                            className="max-h-12 max-w-full object-contain"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* VISUAL CONNECTOR: Animated data flow between logo section and metrics */}
          <div className="relative py-6 my-4">
            <div className="absolute inset-0 flex items-center justify-center">
              {/* CUSTOM ASSET PLACEHOLDER: Animated data flow visualization */}
              <div className="w-1/2 h-0.5 bg-gradient-to-r from-[#E54C4C]/20 via-[#FFF]/10 to-[#4ECAFC]/20"></div>
            </div>

            {/* Connector text with icon */}
            <div className="relative bg-[#0A0D16] px-6 mx-auto w-max rounded-full border border-[#262D40] shadow-lg">
              <div className="flex items-center space-x-2 py-2">
                <BarChart className="text-[#E54C4C] w-6 h-6" />
                <p className="text-white text-xl font-medium">
                  Our impact in <GradientText>numbers</GradientText>
                </p>
              </div>
            </div>
          </div>

          {/* Stats cards - using common styling to unify */}
          <div className="mb-16">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-8">
              {[
                {
                  value: "1000+",
                  countEnd: 1000,
                  suffix: "+",
                  label: "Trained riders across the UK",
                  color: "#E54C4C",
                  icon: Users,
                },
                {
                  value: "9min",
                  countEnd: 9,
                  suffix: "min",
                  label: "Average pickup time",
                  color: "#4ECAFC",
                  icon: Clock,
                },
                {
                  value: "£1M+",
                  countEnd: 1,
                  prefix: "£",
                  suffix: "M+",
                  label: "Worth of items delivered",
                  color: "#4ECAFC",
                  icon: Package,
                },
                {
                  value: "8 hrs/week",
                  countEnd: 8,
                  suffix: "hrs",
                  label: "Man hours freed per week",
                  color: "#E54C4C",
                  icon: Zap,
                },
              ].map((stat, index) => (
                <div
                  key={index}
                  className="relative group"
                  data-aos="fade-up"
                  data-aos-delay={150 + index * 50}
                >
                  <div className="bg-[#0F121B]/90 rounded-xl border border-[#262D40] p-6 h-full group-hover:border-[#414D75] transition-all duration-300 shadow-lg hover:shadow-xl">
                    {/* Icon in top-right corner */}
                    <div className="absolute top-4 right-4">
                      <div className="w-8 h-8 rounded-full bg-[#0A0D16] border border-[#262D40] flex items-center justify-center">
                        <stat.icon style={{ color: stat.color }} size={16} />
                      </div>
                    </div>

                    <div className="pt-2">
                      <div className="text-5xl font-bold text-white mb-2 group-hover:text-[${stat.color}] transition-colors duration-300">
                        {stat.prefix && (
                          <span className="text-2xl mr-1">{stat.prefix}</span>
                        )}
                        <CountUp
                          end={stat.countEnd}
                          suffix={stat.suffix}
                          duration={2500}
                        />
                      </div>
                      <div
                        className="w-12 h-0.5 mb-3"
                        style={{
                          background: `linear-gradient(to right, transparent, ${stat.color}, transparent)`,
                        }}
                      ></div>
                      <p className="text-gray-300 text-sm">{stat.label}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Final section with benefit summary and CTA */}
          <div className="relative pt-6 border-t border-[#262D40]">
            <div className="max-w-3xl mx-auto text-center pb-4">
              <p className="text-gray-300 mb-8">
                By reducing operational overhead and instant gratification with
                deliveries, our businesses have seen
                <span className="text-white font-medium">
                  {" "}
                  significant increases in customer satisfaction, repeat
                  purchases, and operational efficiency
                </span>
                . See how {AI_AGENT_NAME} can transform your logistics
                operations.
              </p>

              {/* CTA button with visual enhancement */}
              <div className="relative inline-block">
                {/* Animated shadow/glow effect */}
                <div className="absolute -inset-1 bg-gradient-to-r from-[#E54C4C]/20 to-[#ff6b6b]/20 rounded-lg blur-sm opacity-70 group-hover:opacity-100 transition-opacity duration-300"></div>

                <button
                  onClick={() =>
                    onPress ??
                    (window.location.href = "https://blitzo.co.uk/blog")
                  }
                  className="relative inline-flex items-center px-6 py-3 bg-gradient-to-r from-[#E54C4C] to-[#ff6b6b] text-white rounded-lg hover:from-[#ff6b6b] hover:to-[#E54C4C] transition-all duration-300 shadow-lg shadow-[#E54C4C]/20 font-medium"
                >
                  <span>See Success Stories</span>
                  <ArrowRight
                    className="ml-2 group-hover:translate-x-1 transition-transform"
                    size={16}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Custom animations */}
      <style jsx>{`
        @keyframes pulse-slow {
          0%,
          100% {
            opacity: 0.3;
            transform: scale(1);
          }
          50% {
            opacity: 0.6;
            transform: scale(1.1);
          }
        }
        @keyframes pulse-slow-delay {
          0%,
          100% {
            opacity: 0.3;
            transform: scale(1);
          }
          50% {
            opacity: 0.5;
            transform: scale(1.15);
          }
        }
        @keyframes spin-slow {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
        .animate-pulse-slow {
          animation: pulse-slow 8s infinite ease-in-out;
        }
        .animate-pulse-slow-delay {
          animation: pulse-slow-delay 12s infinite ease-in-out;
        }
        .animate-spin-slow {
          animation: spin-slow 20s linear infinite;
        }
      `}</style>
    </div>
  );
};

export default Home;
