import React, { useEffect, useState, useRef } from "react";
import DarkNavbar from "../components/DarkNavbar";
import Footer from "../components/footer";
import BottomCTA from "../components/BottomCta";
import SpeakToSalesModal from "../components/SpeakToSalesModal";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  Check,
  Smartphone,
  Stethoscope,
  Wrench,
  Activity,
  Shield,
  UserCheck,
  Zap,
  ChevronRight,
  ArrowRight,
  Brain,
  ChevronDown,
  CheckCircle,
  Calendar,
  Trophy,
  Percent,
  Star,
  BarChart,
} from "lucide-react";

// Gradient text component
const GradientText = styled.span`
  background: linear-gradient(90deg, #e54c4c 0%, #ff6b6b 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
`;

// Blue gradient text component
const BlueGradient = styled.span`
  background: linear-gradient(90deg, #4ecafc 0%, #6a8cff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
`;

// Animated Gradient Border
const AnimatedBorder = styled.div`
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #0f121b;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-image: conic-gradient(
      transparent,
      rgba(229, 76, 76, 0.3),
      transparent 30%
    );
    animation: rotate 7s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 2px;
    top: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background: #0f121b;
    border-radius: 14px;
  }

  @keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }
`;

// Blue Animated Gradient Border
const BlueAnimatedBorder = styled(AnimatedBorder)`
  &::before {
    background-image: conic-gradient(
      transparent,
      rgba(78, 202, 252, 0.3),
      transparent 30%
    );
  }
`;

// Network Animation
const NetworkAnimation = () => {
  return (
    <div className="absolute inset-0 z-0 overflow-hidden opacity-40">
      <div className="absolute inset-0 opacity-10">
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `
              linear-gradient(to right, rgba(255,255,255,0.05) 1px, transparent 1px),
              linear-gradient(to bottom, rgba(255,255,255,0.05) 1px, transparent 1px)
            `,
            backgroundSize: "20px 20px",
          }}
        ></div>
      </div>

      {/* Animated dots */}
      <div className="absolute inset-0">
        {[...Array(25)].map((_, i) => (
          <div
            key={`red-${i}`}
            className="absolute w-1 h-1 rounded-full bg-[#E54C4C]/40"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animation: `float ${
                3 + Math.random() * 4
              }s infinite ease-in-out ${Math.random() * 2}s`,
            }}
          ></div>
        ))}
        {[...Array(20)].map((_, i) => (
          <div
            key={`blue-${i}`}
            className="absolute w-1 h-1 rounded-full bg-[#4ECAFC]/40"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animation: `float ${
                2 + Math.random() * 3
              }s infinite ease-in-out ${Math.random() * 2}s`,
            }}
          ></div>
        ))}
      </div>

      {/* Radial gradients */}
      <div
        className="absolute inset-0 opacity-40"
        style={{
          background:
            "radial-gradient(50% 50% at 50% 50%, rgba(229,76,76,0.08) 0%, rgba(10,13,20,0) 100%)",
        }}
      ></div>
      <div
        className="absolute inset-0 opacity-20"
        style={{
          background:
            "radial-gradient(70% 70% at 30% 30%, rgba(78,202,252,0.1) 0%, rgba(10,13,20,0) 100%)",
        }}
      ></div>

      {/* Glowing orbs */}
      <div
        className="absolute w-96 h-96 rounded-full"
        style={{
          background:
            "radial-gradient(circle, rgba(229,76,76,0.1) 0%, rgba(10,13,20,0) 70%)",
          left: "30%",
          top: "40%",
          filter: "blur(50px)",
          animation: "pulseGlow 8s infinite ease-in-out",
        }}
      ></div>
      <div
        className="absolute w-96 h-96 rounded-full"
        style={{
          background:
            "radial-gradient(circle, rgba(78,202,252,0.08) 0%, rgba(10,13,20,0) 70%)",
          right: "25%",
          top: "30%",
          filter: "blur(50px)",
          animation: "pulseGlow 10s infinite ease-in-out 2s",
        }}
      ></div>

      <style jsx>{`
        @keyframes float {
          0%,
          100% {
            transform: translateY(0) translateX(0);
          }
          50% {
            transform: translateY(-15px) translateX(8px);
          }
        }
        @keyframes pulseGlow {
          0%,
          100% {
            opacity: 0.3;
            transform: scale(1);
          }
          50% {
            opacity: 0.8;
            transform: scale(1.3);
          }
        }
      `}</style>
    </div>
  );
};

// Case Study Card Component
const CaseStudyCard = ({ icon: Icon, title, description, metrics, color }) => {
  return (
    <BlueAnimatedBorder>
      <div className="p-6 bg-[#0F121B]/80 rounded-xl h-full">
        <div className="mb-5">
          <div
            className={`w-14 h-14 bg-[${color}]/10 rounded-xl flex items-center justify-center mb-4`}
          >
            <Icon className={`text-[${color}]`} size={24} />
          </div>
          <h3 className="text-xl font-bold text-white mb-2">{title}</h3>
          <p className="text-gray-300 mb-4">{description}</p>
        </div>

        <div className="space-y-3 border-t border-[#262D40] pt-4">
          {metrics.map((metric, i) => (
            <div key={i} className="flex justify-between items-center">
              <span className="text-gray-400 text-sm">{metric.label}</span>
              <span className={`text-[${color}] font-medium`}>
                {metric.value}
              </span>
            </div>
          ))}
        </div>
      </div>
    </BlueAnimatedBorder>
  );
};

// Capability Card Component
const CapabilityCard = ({
  icon: Icon,
  title,
  description,
  color = "#4ECAFC",
}) => (
  <div className="bg-[#0F121B]/80 border border-[#262D40] hover:border-[#414D75] p-6 rounded-xl transition-all duration-300 h-full group">
    <div
      className={`w-14 h-14 bg-[${color}]/10 rounded-xl flex items-center justify-center mb-4 transform group-hover:scale-110 transition-transform duration-500`}
    >
      <Icon className={`text-[${color}]`} size={28} />
    </div>
    <h3 className="text-lg font-semibold text-white mb-2 group-hover:text-[#4ECAFC] transition-colors">
      {title}
    </h3>
    <p className="text-gray-400 text-sm">{description}</p>
  </div>
);

// FAQ Accordion Component
const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-[#262D40] last:border-b-0">
      <button
        className="w-full py-4 text-left flex justify-between items-center focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-white font-medium">{question}</span>
        <ChevronDown
          className={`text-[#4ECAFC] transition-transform duration-300 ${
            isOpen ? "transform rotate-180" : ""
          }`}
          size={18}
        />
      </button>
      <div
        className={`overflow-hidden transition-all duration-300 ${
          isOpen ? "max-h-96 opacity-100 pb-4" : "max-h-0 opacity-0"
        }`}
      >
        <p className="text-gray-400">{answer}</p>
      </div>
    </div>
  );
};

// Testimonial Component
const Testimonial = ({ quote, company, role, image }) => (
  <div className="bg-[#0F121B]/60 border border-[#262D40] p-6 rounded-xl">
    <p className="text-gray-300 italic mb-4">"{quote}"</p>
    <div className="flex items-center">
      <div className="mr-3">
        <div className="w-10 h-10 bg-[#4ECAFC]/20 rounded-full flex items-center justify-center">
          {image ? (
            <img
              src={image}
              alt={company}
              className="w-10 h-10 rounded-full object-cover"
            />
          ) : (
            <div className="w-6 h-6 rounded-full bg-[#4ECAFC]/30 flex items-center justify-center">
              <UserCheck className="text-[#4ECAFC]" size={14} />
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="text-white font-medium">{company}</div>
        <div className="text-gray-400 text-sm">{role}</div>
      </div>
    </div>
  </div>
);

// Timeline Component
const TimelineItem = ({ title, description, icon: Icon, color, isLast }) => (
  <div className="flex">
    <div className="flex flex-col items-center mr-4">
      <div
        className={`w-10 h-10 rounded-full bg-[${color}]/20 flex items-center justify-center`}
      >
        <Icon className={`text-[${color}]`} size={18} />
      </div>
      {!isLast && <div className="w-0.5 h-full bg-[#262D40]"></div>}
    </div>
    <div className="pb-8">
      <h4 className="text-white font-medium mb-1">{title}</h4>
      <p className="text-gray-400 text-sm">{description}</p>
    </div>
  </div>
);

const DoorstepInteractions = () => {
  const [activeTab, setActiveTab] = useState("tech");
  const [showSalesModal, setShowSalesModal] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({
      duration: 1000,
      once: true,
      mirror: false,
      anchorPlacement: "top-bottom",
    });
  }, []);

  // Use Case Data
  const useCases = {
    tech: {
      title: "Technology Diagnostics",
      description:
        "Our specially trained riders can assess & diagnose devices at the customer's doorstep.",
      icon: Smartphone,
      color: "#E54C4C",
      features: [
        "Device health assessment",
        "On-the-spot diagnostics",
        "Instant payment processing",
        "Secure device collection",
      ],
      metrics: [
        { label: "Average Processing Time", value: "10 min" },
        { label: "Customer Satisfaction", value: "98%" },
        { label: "Success Rate", value: "99.7%" },
      ],
    },
    health: {
      title: "Health & Wellness Checks",
      description:
        "Trained riders can conduct basic health assessments, sample collections, and monitoring device setups.",
      icon: Stethoscope,
      color: "#4ECAFC",
      features: [
        "Basic vitals collection",
        "Sample collection for labs",
        "Medical device setup assistance",
        "Telehealth setup support",
      ],
      metrics: [
        { label: "Average Visit Duration", value: "15 min" },
        { label: "Patient Satisfaction", value: "96%" },
        { label: "Successful Sample Collections", value: "99.2%" },
        { label: "Cost Savings vs Clinic Visit", value: "£120" },
      ],
    },
    mechanical: {
      title: "Vehicle & Equipment Services",
      description:
        "Our specialized riders perform quick vehicle diagnostics, tire checks, and basic repairs at your location.",
      icon: Wrench,
      color: "#E54C4C",
      features: [
        "Tire pressure & condition checks",
        "Basic vehicle diagnostics",
        "Small parts replacement",
        "Software updates for modern vehicles",
      ],
      metrics: [
        { label: "Average Service Time", value: "18 min" },
        { label: "Customer Satisfaction", value: "97%" },
        { label: "First-Time Fix Rate", value: "92%" },
        { label: "Cost Savings vs. Garage Visit", value: "£90" },
      ],
    },
  };

  return (
    <div className="min-h-screen bg-[#080A12] text-white">
      <SpeakToSalesModal
        isOpen={showSalesModal}
        onClose={() => setShowSalesModal(false)}
      />
      <DarkNavbar />

      {/* Hero Section */}
      <div className="relative pt-32 pb-24 overflow-hidden">
        <NetworkAnimation />

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="text-center" data-aos="fade-up">
            <div className="mb-4 inline-block px-4 py-1.5 rounded-full bg-[#E54C4C]/10 border border-[#E54C4C]/20 text-[#E54C4C] text-sm">
              Gig 2.0 Platform
            </div>
            <h1 className="text-4xl md:text-6xl font-bold text-white mb-6">
              Custom <GradientText>Doorstep</GradientText> Interactions
            </h1>
            <p className="mt-6 max-w-2xl mx-auto text-xl text-gray-300">
              Beyond delivery—our professionally trained riders perform
              specialized tasks at your customers' doorstep with white-glove
              service.
            </p>

            <div className="mt-12 flex flex-wrap justify-center gap-4">
              <button
                onClick={() => setShowSalesModal(true)}
                className="px-8 py-3.5 bg-gradient-to-r from-[#E54C4C] to-[#FF6B6B] text-white rounded-lg font-medium flex items-center shadow-lg shadow-[#E54C4C]/20 hover:shadow-xl hover:shadow-[#E54C4C]/30 transition-all duration-300"
              >
                Speak to Sales
                <ChevronRight
                  className="ml-2 group-hover:translate-x-1 transition-transform"
                  size={18}
                />
              </button>
            </div>
          </div>
        </div>

        {/* Glowing orbs */}
        <div className="absolute top-0 left-0 w-64 h-64 rounded-full bg-[#4ECAFC]/10 blur-3xl opacity-30 animate-pulse-slow"></div>
        <div className="absolute bottom-0 right-0 w-64 h-64 rounded-full bg-[#E54C4C]/10 blur-3xl opacity-30 animate-pulse-slow-delay"></div>
      </div>

      {/* What is Gig 2.0 Section */}
      <div className="py-24 bg-[#0A0D16] relative">
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top,_#E54C4C08,transparent_50%)]"></div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
            <div className="order-2 lg:order-1" data-aos="fade-right">
              <div className="mb-4 inline-block text-[#4ECAFC] text-sm font-medium tracking-wider uppercase">
                Beyond Simple Delivery
              </div>
              <h2 className="text-3xl md:text-4xl font-bold text-white mb-6">
                What is <BlueGradient>Gig 2.0</BlueGradient>?
              </h2>

              <p className="text-gray-300 mb-6">
                Traditional gig rider platforms focus solely on
                transportation—moving items from A to B. Blitzo's Gig 2.0
                solution transforms the gig riders into highly skilled
                specialists who can perform complex tasks right at your
                customers' doorstep.
              </p>

              <p className="text-gray-300 mb-8">
                We rigorously train, equip, and manage your fleet to handle
                specialized interactions that traditionally required dedicated
                in-store staff or specialized technicians.
              </p>

              <div className="space-y-4 mb-8">
                <div className="flex items-center text-gray-300">
                  <div className="w-6 h-6 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mr-3">
                    <Check className="text-[#E54C4C]" size={14} />
                  </div>
                  <span>Technical training for specialized tasks</span>
                </div>
                <div className="flex items-center text-gray-300">
                  <div className="w-6 h-6 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mr-3">
                    <Check className="text-[#E54C4C]" size={14} />
                  </div>
                  <span>Equipment provisioning and management</span>
                </div>
                <div className="flex items-center text-gray-300">
                  <div className="w-6 h-6 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mr-3">
                    <Check className="text-[#E54C4C]" size={14} />
                  </div>
                  <span>White-glove customer interactions</span>
                </div>
                <div className="flex items-center text-gray-300">
                  <div className="w-6 h-6 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mr-3">
                    <Check className="text-[#E54C4C]" size={14} />
                  </div>
                  <span>Zero operational overhead</span>
                </div>
              </div>
            </div>

            <div className="order-1 lg:order-2" data-aos="fade-left">
              <AnimatedBorder>
                <div className="bg-[#0F121B]/80 rounded-xl p-8 h-full">
                  <div className="relative" style={{ paddingBottom: "56.25%" }}>
                    <div className="absolute inset-0 bg-gradient-to-br from-[#E54C4C]/10 to-[#4ECAFC]/10 rounded-lg flex items-center justify-center">
                      <div className="text-center">
                        <div className="w-20 h-20 bg-[#0F121B]/80 rounded-full flex items-center justify-center mb-4 mx-auto border border-[#E54C4C]">
                          <Zap className="text-[#E54C4C]" size={32} />
                        </div>
                        <h3 className="text-xl font-bold text-white mb-1">
                          Gig Economy Evolution
                        </h3>
                        <p className="text-gray-400 text-sm">
                          Enhanced rider capabilities for premium service
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-3 gap-4 mt-8">
                    <div className="text-center p-4 bg-[#080A12] rounded-lg">
                      <div className="text-2xl font-bold text-[#E54C4C] mb-1">
                        1000+
                      </div>
                      <div className="text-xs text-gray-400">
                        Trained Specialists
                      </div>
                    </div>
                    <div className="text-center p-4 bg-[#080A12] rounded-lg">
                      <div className="text-2xl font-bold text-[#4ECAFC] mb-1">
                        97%
                      </div>
                      <div className="text-xs text-gray-400">
                        Task Success Rate
                      </div>
                    </div>
                    <div className="text-center p-4 bg-[#080A12] rounded-lg">
                      <div className="text-2xl font-bold text-[#E54C4C] mb-1">
                        10-20m
                      </div>
                      <div className="text-xs text-gray-400">
                        Avg. Task Time
                      </div>
                    </div>
                  </div>
                </div>
              </AnimatedBorder>
            </div>
          </div>
        </div>
      </div>

      {/* Use Cases Section */}
      <div className="py-24 bg-[#080A12] relative overflow-hidden">
        <NetworkAnimation />

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="text-center mb-16" data-aos="fade-up">
            <div className="mb-4 inline-block px-4 py-1.5 rounded-full bg-[#E54C4C]/10 border border-[#E54C4C]/20 text-[#E54C4C] text-sm">
              Case Studies
            </div>
            <h2 className="text-3xl md:text-5xl font-bold text-white mb-6">
              Real-World <GradientText>Applications</GradientText>
            </h2>
            <p className="text-gray-300 max-w-2xl mx-auto">
              Our specialized riders are transforming how businesses deliver
              value by performing complex tasks at the customer's doorstep.
            </p>
          </div>

          {/* Case Study Navigation */}
          <div className="flex justify-center mb-16" data-aos="fade-up">
            <div className="inline-flex bg-[#0F121B]/70 backdrop-blur-sm p-2 rounded-full border border-[#262D40] shadow-2xl">
              {Object.keys(useCases).map((key) => (
                <button
                  key={key}
                  onClick={() => setActiveTab(key)}
                  className={`px-6 py-3 rounded-full text-base font-medium transition-all duration-500 ${
                    activeTab === key
                      ? "bg-gradient-to-r from-[#E54C4C] to-[#FF6B6B] text-white shadow-lg shadow-[#E54C4C]/20"
                      : "text-gray-300 hover:text-white"
                  }`}
                >
                  {useCases[key].title.split(" ")[0]}
                </button>
              ))}
            </div>
          </div>

          {/* Case Study Content */}
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-12 items-start max-w-6xl mx-auto">
            <div className="lg:col-span-5" data-aos="fade-right">
              <CaseStudyCard
                icon={useCases[activeTab].icon}
                title={useCases[activeTab].title}
                description={useCases[activeTab].description}
                metrics={useCases[activeTab].metrics}
                color={useCases[activeTab].color}
              />
            </div>

            <div className="lg:col-span-7" data-aos="fade-left">
              <div className="pl-0 lg:pl-8">
                <h3 className="text-2xl md:text-3xl font-bold mb-6 text-white">
                  How It <BlueGradient>Works</BlueGradient>
                </h3>

                <div className="mb-8">
                  <div className="space-y-0 mb-8">
                    <TimelineItem
                      title="Specialized Training"
                      description="Riders receive comprehensive training specific to your technical requirements, including hands-on practice and certification."
                      icon={Brain}
                      color="#E54C4C"
                    />
                    <TimelineItem
                      title="Equipment Provisioning"
                      description="We provide and manage all necessary diagnostic tools, devices, and systems to ensure riders have everything they need."
                      icon={Wrench}
                      color="#4ECAFC"
                    />
                    <TimelineItem
                      title="Doorstep Interaction"
                      description="Riders arrive at the customer's location, perform the required technical tasks with professional courtesy and expertise."
                      icon={Shield}
                      color="#E54C4C"
                    />
                    <TimelineItem
                      title="Real-Time Support"
                      description="Our AI system monitors interactions and provides real-time assistance for complex scenarios, ensuring task success."
                      icon={Activity}
                      color="#4ECAFC"
                      isLast={true}
                    />
                  </div>
                </div>

                <div className="space-y-5">
                  {useCases[activeTab].features.map((feature, index) => (
                    <div
                      key={index}
                      className="flex items-center text-gray-300 text-base"
                    >
                      <div className="w-6 h-6 rounded-full bg-[#4ECAFC]/10 flex items-center justify-center mr-3">
                        <CheckCircle className="text-[#4ECAFC]" size={14} />
                      </div>
                      <span>{feature}</span>
                    </div>
                  ))}
                </div>

                <div className="mt-8">
                  <button
                    onClick={() => setShowSalesModal(true)}
                    className="relative px-8 py-3.5 bg-[#0A0D16] border border-[#4ECAFC]/30 text-white rounded-lg hover:border-[#4ECAFC] flex items-center group-hover:bg-[#0A0D16]/80 transition-all duration-300 text-base font-medium"
                  >
                    Speak to Us
                    <ArrowRight
                      className="ml-2 group-hover:translate-x-1 transition-transform text-[#4ECAFC]"
                      size={18}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Business Benefits Section */}
      <div className="py-24 bg-[#0A0D16] relative">
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top,_#4ECAFC08,transparent_50%)]"></div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="text-center mb-16" data-aos="fade-up">
            <div className="mb-4 inline-block text-[#4ECAFC] text-sm font-medium tracking-wider uppercase">
              Business Impact
            </div>
            <h2 className="text-3xl md:text-5xl font-bold text-white mb-6">
              White Glove Same-Hour{" "}
              <BlueGradient>Doorstep Interactions</BlueGradient>
            </h2>
            <p className="text-gray-300 max-w-2xl mx-auto">
              By enabling specialized doorstep interactions, businesses can
              unlock new revenue streams, improve customer retention & spend,
              and create strong moat, all whilst having zero operational
              overhead.
            </p>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div className="bg-[#0F121B]/80 rounded-xl p-8">
              <div className="w-16 h-16 bg-[#E54C4C]/10 rounded-full flex items-center justify-center mb-4">
                <Calendar className="text-[#E54C4C]" size={24} />
              </div>
              <h3 className="text-xl font-bold text-white mb-2">
                Time Savings
              </h3>
              <p className="text-gray-300">
                Reduce customer wait times and improve basket checkout rates
                with doorstep interactions.
              </p>
            </div>
            <div className="bg-[#0F121B]/80 rounded-xl p-8">
              <div className="w-16 h-16 bg-[#4ECAFC]/10 rounded-full flex items-center justify-center mb-4">
                <Trophy className="text-[#4ECAFC]" size={24} />
              </div>
              <h3 className="text-xl font-bold text-white mb-2">
                Customer Satisfaction
              </h3>
              <p className="text-gray-300">
                Enhance customer loyalty and trust by providing premium services
                at their convenience.
              </p>
            </div>
            <div className="bg-[#0F121B]/80 rounded-xl p-8">
              <div className="w-16 h-16 bg-[#E54C4C]/10 rounded-full flex items-center justify-center mb-4">
                <Percent className="text-[#E54C4C]" size={24} />
              </div>
              <h3 className="text-xl font-bold text-white mb-2">
                Cost Savings
              </h3>
              <p className="text-gray-300">
                Reduce operational overhead and increase revenue with efficient
                doorstep interactions.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Capabilities Section */}
      <div className="py-24 bg-[#080A12] relative">
        <NetworkAnimation />

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="text-center mb-16" data-aos="fade-up">
            <div className="mb-4 inline-block px-4 py-1.5 rounded-full bg-[#4ECAFC]/10 border border-[#4ECAFC]/20 text-[#4ECAFC] text-sm">
              Services
            </div>
            <h2 className="text-3xl md:text-5xl font-bold text-white mb-6">
              Specialized <BlueGradient>Capabilities</BlueGradient>
            </h2>
            <p className="text-gray-300 max-w-2xl mx-auto">
              Our riders are equipped with specialized skills and tools to
              perform complex tasks at the customer's doorstep.
            </p>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <CapabilityCard
              icon={Smartphone}
              title="Technology Diagnostics"
              description="Assess & diagnos devices at the customer's doorstep."
              color="#E54C4C"
            />
            <CapabilityCard
              icon={Stethoscope}
              title="Health & Wellness Checks"
              description="Conduct basic health assessments, sample collections, and monitoring device setups."
              color="#4ECAFC"
            />
            <CapabilityCard
              icon={Wrench}
              title="Vehicle & Equipment Services"
              description="Perform quick vehicle diagnostics, tire checks, and basic repairs at your location."
              color="#E54C4C"
            />
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="py-24 bg-[#0A0D16] relative">
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top,_#4ECAFC08,transparent_50%)]"></div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="text-center mb-16" data-aos="fade-up">
            <div className="mb-4 inline-block text-[#4ECAFC] text-sm font-medium tracking-wider uppercase">
              Questions & Answers
            </div>
            <h2 className="text-3xl md:text-5xl font-bold text-white mb-6">
              Frequently Asked <BlueGradient>Questions</BlueGradient>
            </h2>
            <p className="text-gray-300 max-w-2xl mx-auto">
              Learn more about our specialized doorstep interactions and how
              they can benefit your business.
            </p>
          </div>

          <div className="max-w-3xl mx-auto">
            <div className="space-y-4">
              <FAQItem
                question="How do I get started with Gig 2.0?"
                answer="Simply reach out to our sales team to discuss your requirements and get started with our specialized services."
              />
              <FAQItem
                question="What kind of tasks can your riders perform?"
                answer="Our riders are trained to perform a variety of tasks, including technology diagnostics, health checks, and vehicle services. We won't say no to any custom requests, so please reach out to discuss your requirements."
              />
              <FAQItem
                question="How are your riders trained and certified?"
                answer="Our riders undergo rigorous training and certification programs to ensure they are equipped to handle specialized tasks."
              />
              <FAQItem
                question="What are the benefits of doorstep interactions?"
                answer="Doorstep interactions can help reduce operational costs, improve customer satisfaction, and unlock new revenue streams."
              />
            </div>
          </div>
        </div>
      </div>

      {/* Testimonials Section */}
      <div className="py-24 bg-[#080A12] relative">
        <NetworkAnimation />

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="text-center mb-16" data-aos="fade-up">
            <div className="mb-4 inline-block px-4 py-1.5 rounded-full bg-[#E54C4C]/10 border border-[#E54C4C]/20 text-[#E54C4C] text-sm">
              Testimonials
            </div>
            <h2 className="text-3xl md:text-5xl font-bold text-white mb-6">
              What Our <GradientText>Customers</GradientText> Say
            </h2>
            <p className="text-gray-300 max-w-2xl mx-auto">
              Hear from our customers about their experience with our
              specialized doorstep interactions.
            </p>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <Testimonial
              quote="Blitzo's professional riders have transformed how we deliver value to our customers. Same-Hour Pickups & Technical Diagnostics have significantly boosted retention & spend on our platform."
              company="Anonymous Retailer"
              role="CEO"
            />
            <Testimonial
              quote="We're able to offer doorstep customisations to our elderly customers at scale, creating immense value. Truly a game-changer for our business."
              company="HealthTech Subscriptions Company"
              role="Head of Operations"
            />
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="py-24 bg-[#0A0D16] relative">
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top,_#E54C4C08,transparent_50%)]"></div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="text-center" data-aos="fade-up">
            <h2 className="text-3xl md:text-5xl font-bold text-white mb-6">
              Ready to <BlueGradient>Get Started</BlueGradient>?
            </h2>
            <p className="text-gray-300 max-w-2xl mx-auto mb-8">
              Transform your operations with Blitzo's specialized doorstep
              interactions. Speak to our sales team to learn more.
            </p>
            <div className="flex justify-center">
              <button
                onClick={() => setShowSalesModal(true)}
                className="px-8 py-3.5 bg-gradient-to-r from-[#E54C4C] to-[#FF6B6B] text-white rounded-lg font-medium flex items-center shadow-lg shadow-[#E54C4C]/20 hover:shadow-xl hover:shadow-[#E54C4C]/30 transition-all duration-300"
              >
                Speak to Sales
                <ChevronRight
                  className="ml-2 group-hover:translate-x-1 transition-transform"
                  size={18}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DoorstepInteractions;
