import React, { useState } from "react";
import {
  MessageSquareText,
  Mailbox,
  Phone,
  DoorClosed,
  Building2,
  PenLine,
} from "lucide-react";

interface SpecialInstructionsProps {
  value: string;
  onChange: (value: string) => void;
}

interface QuickInstruction {
  id: string;
  label: string;
  icon: React.FC<{ size?: number; className?: string }>;
  text: string;
}

const quickInstructions: QuickInstruction[] = [
  {
    id: "safe-place",
    label: "Leave at Safe Place",
    icon: Mailbox,
    text: "Leave at a safe place if no answer",
  },
  {
    id: "neighbor",
    label: "Neighbour",
    icon: DoorClosed,
    text: "Leave with neighbour if no answer",
  },
];

const SpecialInstructions: React.FC<SpecialInstructionsProps> = ({
  value,
  onChange,
}) => {
  const [selectedChips, setSelectedChips] = useState<string[]>([]);

  const handleChipClick = (instruction: QuickInstruction) => {
    const isSelected = selectedChips.includes(instruction.id);
    let newSelected: string[];
    let newText: string;

    if (isSelected) {
      newSelected = selectedChips.filter((id) => id !== instruction.id);
      newText = value.replace(instruction.text + "\n", "").trim();
    } else {
      newSelected = [...selectedChips, instruction.id];
      newText = value ? `${value}\n${instruction.text}` : instruction.text;
    }

    setSelectedChips(newSelected);
    onChange(newText);
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-2">
        <MessageSquareText size={18} className="text-gray-400" />
        <h3 className="font-medium text-white text-xl">Special Instructions</h3>
      </div>

      <div className="flex flex-wrap gap-2">
        {quickInstructions.map((instruction) => (
          <button
            key={instruction.id}
            onClick={() => handleChipClick(instruction)}
            className={`group flex items-center gap-2 px-3 py-1.5 rounded-lg 
              border transition-all duration-300 ${
                selectedChips.includes(instruction.id)
                  ? "bg-blue-500/10 border-blue-500/40 text-blue-400"
                  : "bg-gray-800/40 border-gray-700 text-gray-300 hover:bg-gray-800/60 hover:border-gray-600"
              }`}
          >
            <instruction.icon
              size={16}
              className={
                selectedChips.includes(instruction.id)
                  ? "text-blue-400"
                  : "text-gray-400 group-hover:text-gray-300"
              }
            />
            <span className="text-sm font-medium">{instruction.label}</span>
          </button>
        ))}
      </div>

      <div className="relative">
        <div className="absolute top-3 left-3">
          <PenLine size={16} className="text-gray-500" />
        </div>
        <textarea
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder="Add any additional delivery instructions..."
          className="w-full h-16 pl-10 pr-4 py-2.5 bg-gray-800/40 border border-gray-700 
            rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500/40 focus:border-blue-500/40
            placeholder-gray-500 text-gray-200 text-sm transition-all resize-none"
        />
        <div className="absolute bottom-2 right-2 text-xs text-gray-500">
          {value.length}/100
        </div>
      </div>
    </div>
  );
};

export default SpecialInstructions;
