// src/web-app/components/PrivateRoute.tsx
import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

interface PrivateRouteProps {
  element: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div className="text-white">Checking auth...</div>;
  }

  if (!user) {
    return <Navigate to="/business/signup" replace />;
  }

  return <>{element}</>;
};

export default PrivateRoute;
