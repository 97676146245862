import React, { useEffect, useState } from "react";
import DarkNavbar from "../components/DarkNavbar";
import Footer from "../components/footer";
import BottomCTA from "../components/BottomCta";
import SpeakToSalesModal from "../components/SpeakToSalesModal";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  Check,
  Cake,
  CakeSlice,
  Flower2,
  Wine,
  PillBottle,
  ChevronRight,
  ArrowRight,
  Clock,
  Star,
  Zap,
  CheckCircle,
  Smartphone,
  Store,
  TrendingUp,
  BarChart,
  ExternalLink,
  ShoppingBag,
  Truck,
  Package,
  UserCheck,
  Receipt,
  PieChart,
  Settings,
} from "lucide-react";
import FleetSolutions from "../components/FleetSolutions";
import { useNavigate } from "react-router-dom";

// Gradient text component
const GradientText = styled.span`
  background: linear-gradient(90deg, #e54c4c 0%, #ff6b6b 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
`;

// Blue gradient text component
const BlueGradient = styled.span`
  background: linear-gradient(90deg, #4ecafc 0%, #6a8cff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
`;

// Animated Gradient Border
const AnimatedBorder = styled.div`
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #0f121b;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-image: conic-gradient(
      transparent,
      rgba(229, 76, 76, 0.3),
      transparent 30%
    );
    animation: rotate 7s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 2px;
    top: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background: #0f121b;
    border-radius: 14px;
  }

  @keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }
`;

// Blue Animated Gradient Border
const BlueAnimatedBorder = styled(AnimatedBorder)`
  &::before {
    background-image: conic-gradient(
      transparent,
      rgba(78, 202, 252, 0.3),
      transparent 30%
    );
  }
`;

// Network Animation
const NetworkAnimation = () => {
  return (
    <div className="absolute inset-0 z-0 overflow-hidden opacity-40">
      <div className="absolute inset-0 opacity-10">
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `
              linear-gradient(to right, rgba(255,255,255,0.05) 1px, transparent 1px),
              linear-gradient(to bottom, rgba(255,255,255,0.05) 1px, transparent 1px)
            `,
            backgroundSize: "20px 20px",
          }}
        ></div>
      </div>

      {/* Animated dots */}
      <div className="absolute inset-0">
        {[...Array(25)].map((_, i) => (
          <div
            key={`red-${i}`}
            className="absolute w-1 h-1 rounded-full bg-[#E54C4C]/40"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animation: `float ${
                3 + Math.random() * 4
              }s infinite ease-in-out ${Math.random() * 2}s`,
            }}
          ></div>
        ))}
        {[...Array(20)].map((_, i) => (
          <div
            key={`blue-${i}`}
            className="absolute w-1 h-1 rounded-full bg-[#4ECAFC]/40"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animation: `float ${
                2 + Math.random() * 3
              }s infinite ease-in-out ${Math.random() * 2}s`,
            }}
          ></div>
        ))}
      </div>

      {/* Radial gradients */}
      <div
        className="absolute inset-0 opacity-40"
        style={{
          background:
            "radial-gradient(50% 50% at 50% 50%, rgba(229,76,76,0.08) 0%, rgba(10,13,20,0) 100%)",
        }}
      ></div>
      <div
        className="absolute inset-0 opacity-20"
        style={{
          background:
            "radial-gradient(70% 70% at 30% 30%, rgba(78,202,252,0.1) 0%, rgba(10,13,20,0) 100%)",
        }}
      ></div>

      {/* Glowing orbs */}
      <div
        className="absolute w-96 h-96 rounded-full"
        style={{
          background:
            "radial-gradient(circle, rgba(229,76,76,0.1) 0%, rgba(10,13,20,0) 70%)",
          left: "30%",
          top: "40%",
          filter: "blur(50px)",
          animation: "pulseGlow 8s infinite ease-in-out",
        }}
      ></div>
      <div
        className="absolute w-96 h-96 rounded-full"
        style={{
          background:
            "radial-gradient(circle, rgba(78,202,252,0.08) 0%, rgba(10,13,20,0) 70%)",
          right: "25%",
          top: "30%",
          filter: "blur(50px)",
          animation: "pulseGlow 10s infinite ease-in-out 2s",
        }}
      ></div>

      <style jsx>{`
        @keyframes float {
          0%,
          100% {
            transform: translateY(0) translateX(0);
          }
          50% {
            transform: translateY(-15px) translateX(8px);
          }
        }
        @keyframes pulseGlow {
          0%,
          100% {
            opacity: 0.3;
            transform: scale(1);
          }
          50% {
            opacity: 0.8;
            transform: scale(1.3);
          }
        }
      `}</style>
    </div>
  );
};

// Business Category Card Component
const BusinessCategoryCard = ({
  icon: Icon,
  title,
  description,
  stats,
  color = "#E54C4C",
}) => (
  <AnimatedBorder>
    <div className="bg-[#0F121B]/80 p-6 rounded-xl h-full flex flex-col">
      <div className="mb-5">
        <div
          className={`w-14 h-14 bg-[${color}]/10 rounded-xl flex items-center justify-center mb-4`}
        >
          <Icon className={`text-[${color}]`} size={24} />
        </div>
        <h3 className="text-xl font-bold text-white mb-2">{title}</h3>
        <p className="text-gray-300 mb-4">{description}</p>
      </div>

      <div className="space-y-3 border-t border-[#262D40] pt-4 mt-auto">
        {stats.map((stat, i) => (
          <div key={i} className="flex justify-between items-center">
            <span className="text-gray-400 text-sm">{stat.label}</span>
            <span className={`text-[${color}] font-medium`}>{stat.value}</span>
          </div>
        ))}
      </div>
    </div>
  </AnimatedBorder>
);

// Feature Card Component
const FeatureCard = ({ icon: Icon, title, description, color = "#E54C4C" }) => (
  <div className="bg-[#0F121B]/80 border border-[#262D40] hover:border-[#414D75] p-6 rounded-xl transition-all duration-300 h-full group">
    <div
      className={`w-14 h-14 bg-[${color}]/10 rounded-xl flex items-center justify-center mb-4 transform group-hover:scale-110 transition-transform duration-500`}
    >
      <Icon className={`text-[${color}]`} size={28} />
    </div>
    <h3 className="text-lg font-semibold text-white mb-2 group-hover:text-[#E54C4C] transition-colors">
      {title}
    </h3>
    <p className="text-gray-400 text-sm">{description}</p>
  </div>
);

// Testimonial Component
const Testimonial = ({ quote, company, role, businessType, image }) => (
  <div className="bg-[#0F121B]/60 border border-[#262D40] p-6 rounded-xl">
    <div className="flex items-center mb-3">
      <div className="w-6 h-6 bg-[#E54C4C]/10 rounded-full flex items-center justify-center mr-2">
        <Store className="text-[#E54C4C]" size={14} />
      </div>
      <span className="text-xs text-gray-400">{businessType}</span>
    </div>

    <div className="flex mb-4">
      {[...Array(5)].map((_, i) => (
        <Star key={i} className="text-[#E54C4C]" size={16} />
      ))}
    </div>

    <p className="text-gray-300 italic mb-4">"{quote}"</p>

    <div className="flex items-center">
      <div className="mr-3">
        <div className="w-10 h-10 bg-[#4ECAFC]/20 rounded-full flex items-center justify-center">
          {image ? (
            <img
              src={image}
              alt={company}
              className="w-10 h-10 rounded-full object-cover"
            />
          ) : (
            <div className="w-6 h-6 rounded-full bg-[#4ECAFC]/30 flex items-center justify-center">
              <UserCheck className="text-[#4ECAFC]" size={14} />
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="text-white font-medium">{company}</div>
        <div className="text-gray-400 text-sm">{role}</div>
      </div>
    </div>
  </div>
);

// Step Card Component
const StepCard = ({
  number,
  title,
  description,
  icon: Icon,
  color = "#E54C4C",
}) => (
  <div className="flex flex-col items-center text-center p-6 bg-[#0F121B]/80 border border-[#262D40] rounded-xl hover:border-[#414D75] transition-all duration-300 group">
    <div className="mb-4 relative">
      <div
        className={`w-16 h-16 rounded-full flex items-center justify-center bg-[${color}]/10 group-hover:scale-110 transition-transform duration-500`}
      >
        <Icon className={`text-[${color}]`} size={30} />
      </div>
      <div className="absolute -top-2 -right-2 w-8 h-8 rounded-full bg-[#E54C4C] flex items-center justify-center text-white font-bold">
        {number}
      </div>
    </div>
    <h3 className="text-lg font-semibold text-white mb-2">{title}</h3>
    <p className="text-gray-400 text-sm">{description}</p>
  </div>
);

// Stats Card Component
const StatsCard = ({ value, label, icon: Icon, color = "#E54C4C" }) => (
  <div className="bg-[#0F121B]/80 rounded-xl p-6 border border-[#262D40] hover:border-[#414D75] transition-all duration-300 text-center group">
    <div className="flex items-center justify-center mb-3">
      <Icon className={`text-[${color}]`} size={24} />
    </div>
    <div
      className={`text-3xl lg:text-4xl font-bold mb-2 text-[${color}] group-hover:scale-110 transition-transform duration-300`}
    >
      {value}
    </div>
    <div className="text-gray-300 text-sm">{label}</div>
  </div>
);

const LocalBusinesses = () => {
  const [activeTab, setActiveTab] = useState("cake");
  const [showSalesModal, setShowSalesModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({
      duration: 1000,
      once: true,
      mirror: false,
      anchorPlacement: "top-bottom",
    });
  }, []);

  // Business Categories Data
  const businessCategories = {
    cake: {
      title: "Cake Shops & Bakeries",
      description:
        "Deliver fresh cakes, pastries, and baked goods to your customers while they're still at their best.",
      icon: Cake,
      color: "#E54C4C",
      stats: [
        { label: "Average Delivery Time", value: "42 min" },
        { label: "Delivery Success Rate", value: "100%" },
        { label: "Order Value Increase", value: "28%" },
      ],
      testimonial: {
        quote:
          "Our cakes are arriving in perfect condition, right on time. Customers love that they can get their celebration cakes delivered within the hour, and our sales have increased substantially since partnering with Blitzo.",
        company: "Mind the Cake",
        role: "Borebardhe, Founder",
        businessType: "Cake Shop",
      },
    },
    florist: {
      title: "Florists",
      description:
        "Deliver beautiful bouquets and floral arrangements instantly, keeping flowers fresh and customers delighted.",
      icon: Flower2,
      color: "#4ECAFC",
      stats: [
        { label: "Average Delivery Time", value: "37 min" },
        { label: "Delivery Success Rate", value: "100%" },
        { label: "Order Value Increase", value: "32%" },
      ],
      testimonial: {
        quote:
          "We've seen a dramatic increase in special occasion orders since offering same-hour delivery. The care Blitzo's delivery specialists take with our delicate arrangements is impressive.",
        company: "House of Flowers, Fulham",
        role: "Lolita, Owner",
        businessType: "Florist",
      },
    },
    wine: {
      title: "Wine & Spirits Stores",
      description:
        "Offer premium same-hour delivery for wine, champagne, and spirits for impromptu celebrations and dinner parties.",
      icon: Wine,
      color: "#E54C4C",
      stats: [
        { label: "Average Delivery Time", value: "25 min" },
        { label: "Delivery Success Rate", value: "100%" },
        { label: "Order Value Increase", value: "8%" },
      ],
      testimonial: {
        quote:
          "Our customers love the convenience of having premium wines delivered to their dinner parties or events within the hour. Age verification is handled professionally and our basket sizes have gone up dramatically.",
        company: "Majestic Vines",
        role: "Store Manager",
        businessType: "Wine Store",
      },
    },
    pharmacy: {
      title: "Pharmacies",
      description:
        "Deliver prescriptions, medications, and healthcare products to customers at home when they need them most.",
      icon: PillBottle,
      color: "#4ECAFC",
      stats: [
        { label: "Average Delivery Time", value: "16 min" },
        { label: "Delivery Success Rate", value: "92%" },
        { label: "Customer Retention", value: "43%" },
      ],
      testimonial: {
        quote:
          "Being able to deliver medications to our customers quickly has transformed our service offering. Blitzo handles each delivery with the care and privacy our customers expect.",
        company: "City Pharmacy",
        role: "Owner (Pilot)",
        businessType: "Pharmacy",
      },
    },
  };

  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-[#080A12] text-white">
      <SpeakToSalesModal
        isOpen={showSalesModal}
        onClose={() => setShowSalesModal(false)}
      />
      <DarkNavbar />

      {/* Hero Section */}
      <div className="relative pt-32 pb-24 overflow-hidden">
        <NetworkAnimation />

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="text-center" data-aos="fade-up">
            <div className="mb-4 inline-block px-4 py-1.5 rounded-full bg-[#E54C4C]/10 border border-[#E54C4C]/20 text-[#E54C4C] text-sm">
              For Local Businesses
            </div>
            <h1 className="text-4xl md:text-6xl font-bold text-white mb-6">
              <GradientText>Same-Hour Delivery</GradientText> For Your Local
              Business
            </h1>
            <p className="mt-6 max-w-2xl mx-auto text-xl text-gray-300">
              Focus on what you do best, and let us handle the delivery
              logistics. Our plug-and-play solution requires zero operational
              overhead.
            </p>

            <div className="mt-12 flex flex-wrap justify-center gap-4">
              <button
                onClick={() => {
                  navigate("/business");
                }}
                className="px-8 py-3.5 bg-gradient-to-r from-[#E54C4C] to-[#FF6B6B] text-white rounded-lg font-medium flex items-center shadow-lg shadow-[#E54C4C]/20 hover:shadow-xl hover:shadow-[#E54C4C]/30 transition-all duration-300"
              >
                Get Started
                <ChevronRight
                  className="ml-2 group-hover:translate-x-1 transition-transform"
                  size={18}
                />
              </button>
            </div>
          </div>
        </div>

        {/* Glowing orbs */}
        <div className="absolute top-0 left-0 w-64 h-64 rounded-full bg-[#4ECAFC]/10 blur-3xl opacity-30 animate-pulse-slow"></div>
        <div className="absolute bottom-0 right-0 w-64 h-64 rounded-full bg-[#E54C4C]/10 blur-3xl opacity-30 animate-pulse-slow-delay"></div>
      </div>

      {/* Simple Steps Section */}
      <div className="py-24 bg-[#0A0D16] relative">
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top,_#E54C4C08,transparent_50%)]"></div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="text-center mb-16" data-aos="fade-up">
            <div className="mb-4 inline-block text-[#4ECAFC] text-sm font-medium tracking-wider uppercase">
              Getting Started is Simple
            </div>
            <h2 className="text-3xl md:text-5xl font-bold text-white mb-6">
              Three Steps to <BlueGradient>Same-Hour Delivery</BlueGradient>
            </h2>
            <p className="text-gray-300 max-w-2xl mx-auto">
              Our seamless integration process gets you up and running in
              minutes, not weeks.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <StepCard
              number="1"
              title="Connect Your Store"
              description="Start with Blitzo for Business in seconds, or easily integrate with your existing e-commerce platform or POS system. We support API, Shopify, WooCommerce, Wix, and more."
              icon={Store}
              color="#E54C4C"
            />
            <StepCard
              number="2"
              title="Configure Delivery Settings"
              description="Set your delivery radius, hours, and special handling requirements. Our system adapts to your business needs. You can also enable Blitzo Intelligence to save upto 8 man hours per week on operations."
              icon={Settings}
              color="#4ECAFC"
            />
            <StepCard
              number="3"
              title="Start Delivering"
              description="Our fleet of professional riders is ready to handle your orders with care and deliver them within the hour."
              icon={Truck}
              color="#E54C4C"
            />
          </div>
        </div>
      </div>

      {/* Business Categories Section */}
      <div className="py-24 bg-[#080A12] relative overflow-hidden">
        <NetworkAnimation />

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="text-center mb-16" data-aos="fade-up">
            <div className="mb-4 inline-block px-4 py-1.5 rounded-full bg-[#E54C4C]/10 border border-[#E54C4C]/20 text-[#E54C4C] text-sm">
              Industry Solutions
            </div>
            <h2 className="text-3xl md:text-5xl font-bold text-white mb-6">
              Tailored for <GradientText>Your Business</GradientText>
            </h2>
            <p className="text-gray-300 max-w-2xl mx-auto">
              Discover how local businesses like yours are transforming their
              customer experience with same-hour delivery.
            </p>
          </div>

          {/* Business Category Navigation */}
          <div className="flex justify-center mb-16" data-aos="fade-up">
            <div className="inline-flex bg-[#0F121B]/70 backdrop-blur-sm p-2 rounded-full border border-[#262D40] shadow-2xl overflow-x-auto max-w-full flex-wrap justify-center">
              {Object.keys(businessCategories).map((key) => (
                <button
                  key={key}
                  onClick={() => setActiveTab(key)}
                  className={`px-6 py-3 rounded-full text-base font-medium transition-all duration-500 whitespace-nowrap ${
                    activeTab === key
                      ? "bg-gradient-to-r from-[#E54C4C] to-[#FF6B6B] text-white shadow-lg shadow-[#E54C4C]/20"
                      : "text-gray-300 hover:text-white"
                  }`}
                >
                  {businessCategories[key].title.split(" ")[0]}
                </button>
              ))}
            </div>
          </div>

          {/* Business Category Content */}
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-12 items-start max-w-6xl mx-auto">
            <div className="lg:col-span-5" data-aos="fade-right">
              <BusinessCategoryCard
                icon={businessCategories[activeTab].icon}
                title={businessCategories[activeTab].title}
                description={businessCategories[activeTab].description}
                stats={businessCategories[activeTab].stats}
                color={businessCategories[activeTab].color}
              />
            </div>

            <div className="lg:col-span-7" data-aos="fade-left">
              <div className="pl-0 lg:pl-8">
                <h3 className="text-2xl md:text-3xl font-bold mb-6 text-white">
                  How We Help{" "}
                  <BlueGradient>
                    {businessCategories[activeTab].title}
                  </BlueGradient>
                </h3>

                <div className="space-y-4 mb-8">
                  <div className="flex items-center text-gray-300">
                    <div className="w-6 h-6 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mr-3">
                      <Check className="text-[#E54C4C]" size={14} />
                    </div>
                    <span>
                      Zero operational overhead - we handle all logistics
                    </span>
                  </div>
                  <div className="flex items-center text-gray-300">
                    <div className="w-6 h-6 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mr-3">
                      <Check className="text-[#E54C4C]" size={14} />
                    </div>
                    <span>Seamless integration with your existing systems</span>
                  </div>
                  <div className="flex items-center text-gray-300">
                    <div className="w-6 h-6 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mr-3">
                      <Check className="text-[#E54C4C]" size={14} />
                    </div>
                    <span>
                      Premium same-hour delivery with real-time tracking
                    </span>
                  </div>
                  <div className="flex items-center text-gray-300">
                    <div className="w-6 h-6 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mr-3">
                      <Check className="text-[#E54C4C]" size={14} />
                    </div>
                    <span>Special handling for your unique products</span>
                  </div>
                </div>

                <Testimonial
                  quote={businessCategories[activeTab].testimonial.quote}
                  company={businessCategories[activeTab].testimonial.company}
                  role={businessCategories[activeTab].testimonial.role}
                  businessType={
                    businessCategories[activeTab].testimonial.businessType
                  }
                />

                <div className="mt-8">
                  <button
                    onClick={() => setShowSalesModal(true)}
                    className="relative px-8 py-3.5 bg-[#0A0D16] border border-[#4ECAFC]/30 text-white rounded-lg hover:border-[#4ECAFC] flex items-center group-hover:bg-[#0A0D16]/80 transition-all duration-300 text-base font-medium"
                  >
                    Learn More
                    <ArrowRight
                      className="ml-2 group-hover:translate-x-1 transition-transform text-[#4ECAFC]"
                      size={18}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Benefits Section */}
      <div className="py-24 bg-[#0A0D16] relative">
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top,_#4ECAFC08,transparent_50%)]"></div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="text-center mb-16" data-aos="fade-up">
            <div className="mb-4 inline-block text-[#4ECAFC] text-sm font-medium tracking-wider uppercase">
              Business Advantages
            </div>
            <h2 className="text-3xl md:text-5xl font-bold text-white mb-6">
              Boost Your <BlueGradient>Local Business</BlueGradient>
            </h2>
            <p className="text-gray-300 max-w-2xl mx-auto">
              Same-hour delivery isn't just convenient—it's a powerful tool to
              grow your local business.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <FeatureCard
              icon={TrendingUp}
              title="Increased Order Volume"
              description="Businesses offering same-hour delivery see an average 37% increase in order volume within the first month."
              color="#E54C4C"
            />
            <FeatureCard
              icon={ShoppingBag}
              title="Larger Basket Sizes"
              description="Customers spend 28% more per order when same-hour delivery is available at checkout."
              color="#4ECAFC"
            />
            <FeatureCard
              icon={Star}
              title="Enhanced Customer Loyalty"
              description="Satisfaction scores increase by 42% when customers experience the convenience of same-hour delivery."
              color="#E54C4C"
            />
            <FeatureCard
              icon={Receipt}
              title="Simplified Operations"
              description="No need to hire drivers or build complex logistics systems—we handle everything for you."
              color="#4ECAFC"
            />
            <FeatureCard
              icon={PieChart}
              title="Expanded Market Reach"
              description="Reach customers beyond your immediate vicinity, expanding your addressable market by up to 400%."
              color="#E54C4C"
            />
            <FeatureCard
              icon={Smartphone}
              title="Digital Transformation"
              description="Join the digital economy with minimal effort through our easy integration with your existing systems."
              color="#4ECAFC"
            />
          </div>
        </div>
      </div>

      {/* Stats Section */}
      <div className="py-24 bg-[#080A12] relative overflow-hidden">
        <NetworkAnimation />

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="text-center mb-20" data-aos="fade-up">
            <div className="mb-4 inline-block text-[#4ECAFC] text-sm font-medium tracking-wider uppercase">
              Performance Metrics
            </div>
            <h2 className="text-3xl md:text-5xl font-bold text-white mb-6">
              Real Results from <BlueGradient>Local Businesses</BlueGradient>
            </h2>
            <p className="text-gray-300 max-w-2xl mx-auto">
              Discover how same-hour delivery is transforming the customer
              experience and driving growth for local businesses.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <StatsCard
              value="37%"
              label="Order Volume Increase"
              icon={TrendingUp}
              color="#E54C4C"
            />
            <StatsCard
              value="28%"
              label="Basket Size Growth"
              icon={ShoppingBag}
              color="#4ECAFC"
            />
            <StatsCard
              value="160%"
              label="Increase in Processing Capacity"
              icon={Clock}
              color="#E54C4C"
            />
          </div>
        </div>
      </div>

      {/* Fleet Solutions Section */}
      <FleetSolutions />

      {/* Bottom CTA Section */}
      <BottomCTA />
      <Footer />
    </div>
  );
};

export default LocalBusinesses;
