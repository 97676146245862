// src/web-app/contexts/AuthProvider.tsx

import React, { useEffect, useState } from "react";
import { onAuthStateChanged, User } from "firebase/auth";
import { auth } from "../../../Firebase/firebase-config";
// ^ "auth" is the Firebase Auth instance from your `auth.ts` file
//   If it's a named export, adjust accordingly.

import {
  loginWithGoogle,
  logout,
  loginEmailPassword,
  signUpEmailPassword,
} from "../../firebase/auth";

import { AuthContext, IAuthContext } from "../AuthContext";

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  // Listen for changes in auth state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  // Wrap your existing auth.ts functions
  const handleSignUpWithEmail = async (email: string, password: string) => {
    const createdUser = await signUpEmailPassword(email, password);
    return createdUser;
  };

  const handleLoginWithEmail = async (email: string, password: string) => {
    const loggedInUser = await loginEmailPassword(email, password);
    return loggedInUser;
  };

  const handleLoginWithGoogle = async () => {
    const googleUser = await loginWithGoogle();
    return googleUser;
  };

  const handleLogout = async () => {
    await logout();
  };

  // The value to be provided to context consumers
  const contextValue: IAuthContext = {
    user,
    loading,
    signUpWithEmail: handleSignUpWithEmail,
    loginWithEmail: handleLoginWithEmail,
    loginWithGoogle: handleLoginWithGoogle,
    logout: handleLogout,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
