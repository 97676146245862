import React, { useEffect, useState, useCallback, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Polyline,
} from "@react-google-maps/api";
import {
  getActiveOrder,
  subscribeToActiveOrder,
  fetchDistanceInMilesAndDurationInMinutes,
  getRider,
} from "../Firebase/order";
import "./Tracker.css";
import bike from "../Icons/bike.svg";
import box from "../Icons/box.svg";
import dropoff from "../Icons/dropoff.svg";
import bikeBox from "../Icons/bike_box.svg";
import logoImage from "../Images/logo.png";

const defaultCenter = { lat: 51.509865, lng: -0.118092 };
const googleMapsAPIKey = "AIzaSyDc8ZE2eaxXND5WTbOeZ5BZc008_ykqv2M";
const MINIMUM_DISTANCE_FOR_UPDATE = 0.05; // 50 meters in kilometers
const SUPPORT_NUMBER = "+447810778980";

const calculateDistance = (point1, point2) => {
  if (!point1 || !point2) return 0;

  const R = 6371; // Earth's radius in kilometers
  const dLat = ((point2.latitude - point1.latitude) * Math.PI) / 180;
  const dLon = ((point2.longitude - point1.longitude) * Math.PI) / 180;

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((point1.latitude * Math.PI) / 180) *
      Math.cos((point2.latitude * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};

const Tracker = () => {
  const { orderId, view } = useParams();
  const isAdmin = view === "admin";
  const [order, setOrder] = useState(null);
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [metrics, setMetrics] = useState({
    distance: 0,
    duration: 0,
    lastFetchTime: 0,
    lastStatus: null,
    lastLocation: null,
    displayDistance: 0, // Add separate display values that only update on significant changes
    displayDuration: 0,
  });
  const [rider, setRider] = useState<any>(null);
  const mapRef = useRef<any>(null);
  const lastFetchRef = useRef<number | null>(null);

  const onLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const getStatusText = (status) => {
    switch (status) {
      case "pending":
        return "Finding a Blitzer";
      case "accepted":
        return "Heading to Pickup";
      case "pickedUp":
        return "En Route to Dropoff";
      case "delivered":
        return "Delivered";
      default:
        return "Loading...";
    }
  };

  const shouldUpdate = (currentLocation, lastLocation, status, lastStatus) => {
    // Always update if status has changed
    if (status !== lastStatus) return true;

    // Always update if we don't have a last location
    if (!lastLocation) return true;

    // Check if we've moved enough distance
    const distance = calculateDistance(currentLocation, lastLocation);
    return distance >= MINIMUM_DISTANCE_FOR_UPDATE;
  };

  const fetchMetrics = useCallback(
    async (orderData) => {
      if (
        !orderData?.assignment?.blitzerLocation ||
        !orderData?.pickupLocation ||
        !orderData?.dropoffLocation
      ) {
        return;
      }

      const currentLocation = orderData.assignment.blitzerLocation;
      const currentStatus = orderData.status;

      // Check if we should update based on movement or status change
      if (
        !shouldUpdate(
          currentLocation,
          metrics.lastLocation,
          currentStatus,
          metrics.lastStatus
        )
      ) {
        return;
      }

      try {
        const currentTime = Date.now();

        // Prevent multiple fetches within 5 seconds
        if (lastFetchRef.current && currentTime - lastFetchRef.current < 5000) {
          return;
        }

        lastFetchRef.current = currentTime;

        const destination =
          currentStatus === "accepted"
            ? orderData.pickupLocation
            : orderData.dropoffLocation;

        const newMetrics = await fetchDistanceInMilesAndDurationInMinutes(
          currentLocation,
          destination
        );

        if (newMetrics.duration || newMetrics.distance) {
          setMetrics((prev) => ({
            ...prev,
            lastFetchTime: currentTime,
            lastStatus: currentStatus,
            lastLocation: currentLocation,
            // Only update display values when we have new data
            displayDistance: newMetrics.distance || prev.displayDistance,
            displayDuration: newMetrics.duration || prev.displayDuration,
            // Keep the raw values for comparison
            distance: newMetrics.distance,
            duration: newMetrics.duration,
          }));
        }
      } catch (error) {
        console.error("Error fetching metrics:", error);
      }
    },
    [metrics.lastLocation, metrics.lastStatus]
  );

  const updateMapBounds = useCallback((orderData) => {
    if (orderData && mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds();

      // Add points to bounds
      if (orderData.assignment) {
        bounds.extend(
          new window.google.maps.LatLng(
            orderData.assignment.blitzerLocation.latitude,
            orderData.assignment.blitzerLocation.longitude
          )
        );
      }
      if (orderData.pickupLocation) {
        bounds.extend(
          new window.google.maps.LatLng(
            orderData.pickupLocation.latitude,
            orderData.pickupLocation.longitude
          )
        );
      }
      if (orderData.dropoffLocation) {
        bounds.extend(
          new window.google.maps.LatLng(
            orderData.dropoffLocation.latitude,
            orderData.dropoffLocation.longitude
          )
        );
      }

      // Add padding to bounds
      const padding = {
        top: 100,
        right: 50,
        bottom: 200, // Increased bottom padding for metrics
        left: 50,
      };

      mapRef.current.fitBounds(bounds, padding);
    }
  }, []);

  // Add support button handler
  const handleSupportClick = useCallback(() => {
    window.location.href = `tel:${SUPPORT_NUMBER}`;
  }, []);

  const fetchAndSetRider = useCallback(async () => {
    if (order?.assignment?.blitzerId) {
      const riderData = await getRider(order.assignment.blitzerId);
      setRider(riderData);
    }
  }, [order]);

  useEffect(() => {
    let unsubscribe;

    const fetchOrderAndSubscribe = async () => {
      const orderData = await getActiveOrder(orderId);
      setOrder(orderData);
      if (orderData) {
        fetchMetrics(orderData);
      }

      unsubscribe = subscribeToActiveOrder(orderId, (updatedOrderData) => {
        setOrder(updatedOrderData);
        fetchAndSetRider();
        fetchMetrics(updatedOrderData);
      });
    };

    fetchOrderAndSubscribe();

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [orderId, fetchMetrics]);

  useEffect(() => {
    if (order) {
      updateMapBounds(order);
    }
  }, [order, updateMapBounds]);

  const riderLocation = order?.assignment?.blitzerLocation;
  const pickupLocation = order?.pickupLocation;
  const dropoffLocation = order?.dropoffLocation;

  const getEtaText = () => {
    if (!order || order.status === "delivered") return "Delivered";
    if (order.status === "pending") return "Finding Blitzer...";
    if (metrics.displayDuration === 0) return "Calculating...";

    const minutes = Math.max(1, Math.ceil(metrics.displayDuration));
    if (order.status === "accepted") {
      return `${minutes} minute${minutes !== 1 ? "s" : ""} to pickup`;
    } else if (order.status === "pickedUp") {
      return `${minutes} minute${minutes !== 1 ? "s" : ""} to delivery`;
    }
    return `${minutes} minute${minutes !== 1 ? "s" : ""}`;
  };

  const getCurvedPath = useCallback((start, end) => {
    if (!start || !end) return [];

    // Convert to LatLng objects
    const startPoint = { lat: start.latitude, lng: start.longitude };
    const endPoint = { lat: end.latitude, lng: end.longitude };

    // Calculate the distance between points to adjust curve height dynamically
    const distance = Math.sqrt(
      Math.pow(endPoint.lat - startPoint.lat, 2) +
        Math.pow(endPoint.lng - startPoint.lng, 2)
    );

    // Make curve height proportional to distance
    const offsetDistance = distance * 0.2; // 20% of the total distance

    // Calculate midpoint
    const midPoint = {
      lat: (startPoint.lat + endPoint.lat) / 2,
      lng: (startPoint.lng + endPoint.lng) / 2,
    };

    // Calculate angle between points
    const angle = Math.atan2(
      endPoint.lat - startPoint.lat,
      endPoint.lng - startPoint.lng
    );

    // Create control point with increased offset
    const controlPoint = {
      lat: midPoint.lat + Math.cos(angle + Math.PI / 2) * offsetDistance,
      lng: midPoint.lng + Math.sin(angle + Math.PI / 2) * offsetDistance,
    };

    // Generate curve points using quadratic Bezier
    const curvePoints = [];
    const steps = 50; // Increased steps for smoother curve

    for (let i = 0; i <= steps; i++) {
      const t = i / steps;

      // Quadratic Bezier curve formula
      const lat =
        Math.pow(1 - t, 2) * startPoint.lat +
        2 * (1 - t) * t * controlPoint.lat +
        Math.pow(t, 2) * endPoint.lat;

      const lng =
        Math.pow(1 - t, 2) * startPoint.lng +
        2 * (1 - t) * t * controlPoint.lng +
        Math.pow(t, 2) * endPoint.lng;

      curvePoints.push({ lat, lng });
    }

    return curvePoints;
  }, []);

  const getPathOptions = useCallback(
    () => ({
      geodesic: true,
      strokeColor: "#E54C4C", // Updated to Blitzo primary color
      strokeOpacity: 0,
      strokeWeight: 2,
      icons: [
        {
          icon: {
            path: "M 0,-1 0,1",
            strokeOpacity: 1,
            strokeWeight: 2.5,
            scale: 3,
          },
          offset: "0",
          repeat: "10px",
        },
      ],
    }),
    []
  );

  const AdminInfo = ({ order }) => {
    if (!order?.pickupLocation || !order?.dropoffLocation) return null;

    return (
      <div className="admin-info">
        <div className="admin-card">
          <h3>Pickup Location</h3>
          <p>{order.pickupLocation.locationName}</p>
          <div className="location-image">
            <img
              src={`https://maps.googleapis.com/maps/api/streetview?size=400x200&location=${order.pickupLocation.latitude},${order.pickupLocation.longitude}&key=${googleMapsAPIKey}`}
              alt="Pickup location street view"
            />
          </div>
          <div className="location-details">
            <p>
              <strong>Detailed Location:</strong>{" "}
              {order.pickupLocation.detailedLocation}
            </p>
            <p>
              <strong>Coordinates:</strong> {order.pickupLocation.latitude},{" "}
              {order.pickupLocation.longitude}
            </p>
          </div>
        </div>

        <div className="admin-card">
          <h3>Dropoff Location</h3>
          <p>{order.dropoffLocation.locationName}</p>
          <div className="location-image">
            <img
              src={`https://maps.googleapis.com/maps/api/streetview?size=400x200&location=${order.dropoffLocation.latitude},${order.dropoffLocation.longitude}&key=${googleMapsAPIKey}`}
              alt="Dropoff location street view"
            />
          </div>
          <div className="location-details">
            <p>
              <strong>Detailed Location:</strong>{" "}
              {order.dropoffLocation.detailedLocation}
            </p>
            <p>
              <strong>Coordinates:</strong> {order.dropoffLocation.latitude},{" "}
              {order.dropoffLocation.longitude}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="tracker-container">
      <div className="tracker-header">
        <a className="logo">
          <img src={logoImage} alt="Blitzo Logo" />
          <h2>Blitzo</h2>
        </a>
      </div>
      <button onClick={handleSupportClick} className="support-button">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
        </svg>
        <span>Support</span>
      </button>
      {rider && order && (
        <div className="rider-info">
          <div className="rider-avatar">
            {rider.personalInfo.name.charAt(0)}
          </div>
          <div className="rider-details">
            <div className="rider-name">
              {rider.personalInfo.name.split(" ")[0]}
            </div>
            <div className="rider-status">{getStatusText(order.status)}</div>
          </div>
          <button
            className="call-rider"
            onClick={() =>
              (window.location.href = `tel:${rider.personalInfo.phoneNumber}`)
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
            Call Rider
          </button>
        </div>
      )}
      <div className="map-container">
        <GoogleMap
          options={{
            styles: mapStyle,
            mapTypeControlOptions: { mapTypeIds: [] },
            streetViewControl: false,
            fullscreenControl: false,
            zoomControl: false,
            mapTypeControl: false,
          }}
          onLoad={onLoad}
          mapContainerStyle={{
            height: "100%",
            width: "100%",
          }}
          center={mapCenter}
        >
          {/* Curved path */}
          {riderLocation && order && (
            <Polyline
              path={getCurvedPath(
                riderLocation,
                order.status === "accepted" ? pickupLocation : dropoffLocation
              )}
              options={getPathOptions()}
            />
          )}

          {/* Markers with updated styling */}
          {riderLocation && (
            <Marker
              position={{
                lat: riderLocation.latitude,
                lng: riderLocation.longitude,
              }}
              icon={{
                url: order.status === "accepted" ? bike : bikeBox,
                scaledSize: new window.google.maps.Size(40, 40),
              }}
            />
          )}
          {pickupLocation && order.status !== "pickedUp" && (
            <Marker
              position={{
                lat: pickupLocation.latitude,
                lng: pickupLocation.longitude,
              }}
              icon={{
                url: box,
                scaledSize: new window.google.maps.Size(40, 40),
              }}
            />
          )}
          {dropoffLocation && (
            <Marker
              position={{
                lat: dropoffLocation.latitude,
                lng: dropoffLocation.longitude,
              }}
              icon={{
                url: dropoff,
                scaledSize: new window.google.maps.Size(40, 40),
              }}
            />
          )}
        </GoogleMap>
      </div>
      {isAdmin && <AdminInfo order={order} />}
      <div className="info-overlay">
        <div className="cards-container">
          <div className="info-card">
            <h2>Order ID</h2>
            <p>{orderId}</p>
          </div>
          <div className="info-card">
            <h2>Distance</h2>
            <p>
              {metrics.displayDistance === 0
                ? "-"
                : `${metrics.displayDistance.toFixed(2)} mi`}
            </p>
          </div>
          <div className="info-card eta">
            <h2>ETA</h2>
            <p>{getEtaText()}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tracker;

export const mapStyle = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.local",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
];
