import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BlitzoOrder, OrderStatus } from "../../types/BlitzoOrder";
import {
  CircleCheck,
  Clock,
  Bike,
  XCircle,
  Package,
  Calendar,
  Route,
  ArrowRight,
} from "lucide-react";
import ActiveOrderContext from "../../contexts/ActiveOrderContext";
import BusinessNavbar from "../../components/BusinessNavbar";
import ScheduledOrderModal from "../../components/resuable/ScheduledOrderModal";

const TrackingHome: React.FC = () => {
  const { activeOrders, scheduledOrders } = useContext(ActiveOrderContext);
  const [showActive, setShowActive] = useState(true);
  const navigate = useNavigate();

  return (
    <>
      <BusinessNavbar />

      <div className="min-h-screen bg-gradient-to-br from-gray-900 via-gray-900 to-gray-800 text-white pt-20 px-6 pb-10">
        <div className="max-w-6xl mx-auto">
          {/* Header Section */}
          <div className="flex items-center justify-between mb-8">
            <div>
              <h1 className="text-3xl font-bold bg-gradient-to-r from-white to-gray-400 bg-clip-text text-transparent">
                Track Orders
              </h1>
              <p className="text-gray-400 mt-1">
                Monitor your deliveries in real-time
              </p>
            </div>
            <div className="flex items-center gap-2 bg-gray-800/50 p-1 rounded-lg border border-gray-700">
              <button
                onClick={() => setShowActive(true)}
                className={`flex items-center gap-2 px-4 py-2 rounded-md transition-all duration-300
                  ${
                    showActive
                      ? "bg-red-500 text-white shadow-lg"
                      : "text-gray-400 hover:text-white hover:bg-gray-700"
                  }`}
              >
                <Route size={18} />
                <span className="text-sm font-medium">Active</span>
              </button>
              <button
                onClick={() => setShowActive(false)}
                className={`flex items-center gap-2 px-4 py-2 rounded-md transition-all duration-300
                  ${
                    !showActive
                      ? "bg-red-500 text-white shadow-lg"
                      : "text-gray-400 hover:text-white hover:bg-gray-700"
                  }`}
              >
                <Calendar size={18} />
                <span className="text-sm font-medium">Scheduled</span>
              </button>
            </div>
          </div>

          {/* Order Cards Grid */}
          <div className="grid gap-4">
            {showActive ? (
              activeOrders?.length ? (
                activeOrders.map((order) => (
                  <OrderCard key={order.id} order={order} navigate={navigate} />
                ))
              ) : (
                <NoOrders message="No active orders" />
              )
            ) : scheduledOrders?.length ? (
              scheduledOrders.map((order) => (
                <OrderCard key={order.id} order={order} navigate={navigate} />
              ))
            ) : (
              <NoOrders message="No scheduled orders" />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const OrderCard: React.FC<{ order: BlitzoOrder; navigate: any }> = ({
  order,
  navigate,
}) => {
  const statusDetails = getStatusDetails(order.status);
  const isScheduled = order.schedule && !order.schedule.isNow;
  const [showScheduleModal, setShowScheduleModal] = useState(false);

  const formatScheduleTime = (date: Date) => {
    //convert fb timestamp to date, it has seconds and nanoseconds
    const fbDate = date as any;
    return fbDate.toDate().toLocaleString("en-GB", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };

  return (
    <div
      onClick={() => {
        if (isScheduled) {
          setShowScheduleModal(true);
          return;
        }
        navigate(`/business/track/${order.id}`);
      }}
      className="group bg-gray-900/50 hover:bg-gray-800/80 rounded-2xl border border-gray-800 
          hover:border-gray-700 transition-all duration-300 cursor-pointer overflow-hidden"
    >
      {showScheduleModal && (
        <ScheduledOrderModal
          order={order}
          onClose={() => setShowScheduleModal(false)}
        />
      )}
      {/* Header with Status or Schedule */}
      <div className="px-5 pt-5 pb-3 flex items-center justify-between">
        <div className="flex items-center gap-3">
          {isScheduled ? (
            <>
              <div className="p-2 rounded-xl bg-blue-500/10 border border-blue-500/20">
                <Calendar className="text-blue-400" size={20} />
              </div>
              <div>
                <div className="flex items-center gap-2 mb-1">
                  <span className="text-sm font-medium text-blue-400">
                    Scheduled Delivery
                  </span>
                  <span className="text-gray-600">•</span>
                  <span className="text-sm text-gray-500 font-medium">
                    #{order.id.slice(0, 8)}
                  </span>
                </div>
                <span className="text-sm text-gray-400">
                  {formatScheduleTime(
                    order.schedule.scheduledFor ?? new Date()
                  )}
                </span>
              </div>
            </>
          ) : (
            <>
              <div
                className={`p-2 rounded-xl bg-${statusDetails.color}/10 
                  border border-${statusDetails.color}/20`}
              >
                {statusDetails.icon}
              </div>
              <div>
                <div className="flex items-center gap-2 mb-1">
                  <span
                    className={`text-sm font-medium text-${statusDetails.color}`}
                  >
                    {statusDetails.label}
                  </span>
                  <span className="text-gray-600">•</span>
                  <span className="text-sm text-gray-500 font-medium">
                    #{order.id.slice(0, 8)}
                  </span>
                </div>
                <SegmentedProgress status={order.status} />
              </div>
            </>
          )}
        </div>

        <div className="flex items-center gap-4">
          {order.fee?.total && (
            <div className="text-right">
              <span className="text-xs text-gray-400">Total</span>
              <p className="text-sm font-medium text-white">
                £{order.fee.total.toFixed(2)}
              </p>
            </div>
          )}
          <ArrowRight
            size={20}
            className="text-gray-500 group-hover:text-gray-400 
                transform group-hover:translate-x-1 transition-all"
          />
        </div>
      </div>

      {/* Location Details */}
      <div className="px-5 py-4 bg-gray-800/50 border-t border-gray-800/80">
        <div className="grid grid-cols-[1fr,auto,1fr] gap-4 items-start">
          {/* Pickup */}
          <div>
            <div className="flex items-center gap-2 mb-1.5">
              <div className="w-2 h-2 rounded-full bg-emerald-500" />
              <span className="text-sm font-medium text-gray-400">Pickup</span>
            </div>
            <h3 className="font-medium text-white mb-1">
              {order.pickupLocation.locationName}
            </h3>
            <p className="text-sm text-gray-500 line-clamp-2">
              {order.pickupLocation.detailedLocation}
            </p>
          </div>

          {/* Connector */}
          <div className="flex flex-col items-center gap-1 pt-6">
            <div className="w-[1px] h-12 bg-gradient-to-b from-emerald-500/50 to-blue-500/50" />
          </div>

          {/* Dropoff */}
          <div>
            <div className="flex items-center gap-2 mb-1.5">
              <div className="w-2 h-2 rounded-full bg-blue-500" />
              <span className="text-sm font-medium text-gray-400">Dropoff</span>
            </div>
            <h3 className="font-medium text-white mb-1">
              {order.dropoffLocation.locationName}
            </h3>
            <p className="text-sm text-gray-500 line-clamp-2">
              {order.dropoffLocation.detailedLocation}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const SegmentedProgress: React.FC<{ status: OrderStatus }> = ({ status }) => {
  const segments = [
    {
      active: ["pending", "accepted", "pickedUp", "delivered"].includes(status),
      color: "emerald",
    },
    {
      active: ["accepted", "pickedUp", "delivered"].includes(status),
      color: "emerald",
    },
    { active: ["pickedUp", "delivered"].includes(status), color: "emerald" },
    { active: ["delivered"].includes(status), color: "emerald" },
  ];

  const getActiveSegmentIndex = () => {
    switch (status) {
      case "pending":
        return 0;
      case "accepted":
        return 1;
      case "pickedUp":
        return 2;
      case "delivered":
        return 3;
      default:
        return -1;
    }
  };

  const activeIndex = getActiveSegmentIndex();

  return (
    <div className="flex gap-1">
      {segments.map((segment, index) => (
        <div
          key={index}
          className={`h-1 rounded-full w-8 transition-all duration-500 
              ${
                segment.active
                  ? `bg-${segment.color}-500 ${
                      index === activeIndex ? "animate-pulse" : ""
                    }`
                  : "bg-gray-700"
              }`}
        />
      ))}
    </div>
  );
};

const getStatusDetails = (status: OrderStatus) => {
  switch (status) {
    case "pending":
      return {
        label: "Finding Blitzer",
        color: "emerald",
        icon: <Clock className="text-emerald-500" size={20} />,
      };
    case "accepted":
      return {
        label: "Heading to Pickup",
        color: "blue",
        icon: <Bike className="text-blue-500" size={20} />,
      };
    case "pickedUp":
      return {
        label: "En Route",
        color: "purple",
        icon: <Package className="text-purple-500" size={20} />,
      };
    case "delivered":
      return {
        label: "Delivered",
        color: "indigo",
        icon: <CircleCheck className="text-indigo-500" size={20} />,
      };
    case "cancelled":
      return {
        label: "Cancelled",
        color: "red",
        icon: <XCircle className="text-red-500" size={20} />,
      };
    default:
      return {
        label: "Unknown",
        color: "gray",
        icon: <Clock className="text-gray-500" size={20} />,
      };
  }
};

const NoOrders = ({ message }: { message: string }) => (
  <div className="text-center py-16 px-4 rounded-xl bg-gray-800/50 border border-gray-700">
    <Package size={48} className="mx-auto mb-4 text-gray-600" />
    <p className="text-lg font-medium text-gray-400">{message}</p>
    <p className="mt-2 text-sm text-gray-500">
      Your orders will appear here once created
    </p>
  </div>
);

export default TrackingHome;
