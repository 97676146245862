import React, { useState, useEffect } from "react";
import {
  Bike,
  CloudLightning,
  Car,
  Truck,
  ArrowRight,
  MessageSquare,
  Sparkles,
} from "lucide-react";

const PremiumFleetPricing = () => {
  // State for distance toggle
  const [selectedDistance, setSelectedDistance] = useState("short");
  const [animatedElements, setAnimatedElements] = useState({
    title: false,
    subtitle: false,
    toggle: false,
    cards: [false, false, false, false],
    ctas: [false, false],
  });

  // Vehicle data
  const vehicles = [
    {
      type: "Bicycle",
      icon: Bike,
      color: "#4ECAFC",
      badgeText: "Eco-Friendly",
      maxSize: "45 x 45 x 45 cm",
      pricing: {
        short: "£4.89",
        medium: "£6.49",
        long: "£8.99",
      },
    },
    {
      type: "E-Bike",
      icon: CloudLightning,
      color: "#4ECAFC",
      badgeText: "Fast + Green",
      maxSize: "50 x 50 x 50 cm",
      pricing: {
        short: "£5.99",
        medium: "£7.79",
        long: "£9.99",
      },
    },
    {
      type: "Car",
      icon: Car,
      color: "#E54C4C",
      badgeText: "Batch Deliveries",
      maxSize: "90 x 60 x 60 cm",
      pricing: {
        short: "£9.21",
        medium: "£11.49",
        long: "£14.99",
      },
    },
    {
      type: "Van",
      icon: Truck,
      color: "#E54C4C",
      badgeText: "Heavy-Duty",
      maxSize: "200 x 150 x 120 cm",
      pricing: {
        short: "£10.49",
        medium: "£14.99",
        long: "£19.99",
      },
    },
  ];

  // Distance labels mapping
  const distanceLabels = {
    short: "0-3 miles",
    medium: "3-6 miles",
    long: "6+ miles",
  };

  // Entrance animations sequence
  useEffect(() => {
    // Staggered animations for elements
    const titleTimeout = setTimeout(() => {
      setAnimatedElements((prev) => ({ ...prev, title: true }));
    }, 300);

    const subtitleTimeout = setTimeout(() => {
      setAnimatedElements((prev) => ({ ...prev, subtitle: true }));
    }, 500);

    const toggleTimeout = setTimeout(() => {
      setAnimatedElements((prev) => ({ ...prev, toggle: true }));
    }, 700);

    // Staggered animations for cards
    const cardTimeouts = vehicles.map((_, index) =>
      setTimeout(() => {
        setAnimatedElements((prev) => {
          const updatedCards = [...prev.cards];
          updatedCards[index] = true;
          return { ...prev, cards: updatedCards };
        });
      }, 900 + index * 200)
    );

    // Staggered animations for CTAs
    const ctaTimeouts = [0, 1].map((index) =>
      setTimeout(() => {
        setAnimatedElements((prev) => {
          const updatedCtas = [...prev.ctas];
          updatedCtas[index] = true;
          return { ...prev, ctas: updatedCtas };
        });
      }, 1700 + index * 200)
    );

    return () => {
      clearTimeout(titleTimeout);
      clearTimeout(subtitleTimeout);
      clearTimeout(toggleTimeout);
      cardTimeouts.forEach((timeout) => clearTimeout(timeout));
      ctaTimeouts.forEach((timeout) => clearTimeout(timeout));
    };
  }, []);

  return (
    <section className="py-20 w-full relative overflow-hidden">
      {/* Animated Background */}
      <div className="absolute inset-0 bg-gradient-to-b from-[#0A0C16] to-[#080A12] z-0">
        {/* Grid pattern */}
        <div
          className="absolute inset-0 opacity-5"
          style={{
            backgroundImage: `linear-gradient(to right, rgba(255,255,255,0.07) 1px, transparent 1px), 
                             linear-gradient(to bottom, rgba(255,255,255,0.07) 1px, transparent 1px)`,
            backgroundSize: "40px 40px",
          }}
        ></div>

        {/* Animated glow effects */}
        <div className="absolute top-0 left-1/4 w-[500px] h-[500px] rounded-full bg-[#4ECAFC]/5 mix-blend-screen filter blur-[100px] animate-pulse-slow"></div>
        <div className="absolute bottom-0 right-1/4 w-[500px] h-[500px] rounded-full bg-[#E54C4C]/5 mix-blend-screen filter blur-[100px] animate-pulse-slow-delay"></div>

        {/* Animated dots and particles */}
        <div className="absolute inset-0 overflow-hidden opacity-20">
          {[...Array(20)].map((_, i) => (
            <div
              key={i}
              className="absolute w-1 h-1 rounded-full bg-white"
              style={{
                top: `${Math.random() * 100}%`,
                left: `${Math.random() * 100}%`,
                opacity: 0.3 + Math.random() * 0.7,
                animation: `float ${
                  3 + Math.random() * 5
                }s infinite ease-in-out ${Math.random() * 5}s`,
              }}
            ></div>
          ))}
        </div>
      </div>

      {/* Main content */}
      <div className="max-w-[1280px] mx-auto px-8 relative z-10">
        {/* Header section */}
        <div className="text-center mb-16">
          <h2
            className="text-3xl md:text-5xl font-bold text-white mb-4 leading-tight"
            style={{
              opacity: animatedElements.title ? 1 : 0,
              transform: animatedElements.title
                ? "translateY(0)"
                : "translateY(20px)",
            }}
          >
            <span className="text-white">Our </span>
            <span className="text-[#E54C4C]">Premium Fleet</span>
          </h2>
          <p
            className="text-xl text-gray-300 mx-auto mb-12 transition-all duration-1000 delay-100 transform"
            style={{
              opacity: animatedElements.subtitle ? 1 : 0,
              transform: animatedElements.subtitle
                ? "translateY(0)"
                : "translateY(20px)",
            }}
          >
            Plug & Play into our trained{" "}
            <span className="text-[#4ECAFC]">Rider</span> network for
            <span className="text-[#E54C4C]"> Same Hour Delivery</span> at an{" "}
            <span className="text-[#4ECAFC]">affordable price</span>
          </p>

          {/* Distance selector */}
          <div
            className="inline-flex bg-[#0C0E18] p-1 rounded-full border border-[#1E2436] shadow-xl transition-all duration-1000 delay-200 transform"
            style={{
              opacity: animatedElements.toggle ? 1 : 0,
              transform: animatedElements.toggle
                ? "translateY(0)"
                : "translateY(20px)",
            }}
          >
            {Object.entries(distanceLabels).map(([key, label]) => (
              <button
                key={key}
                onClick={() => setSelectedDistance(key)}
                className={`px-12 py-3 rounded-full text-base font-medium transition-all duration-300 ${
                  selectedDistance === key
                    ? "bg-gradient-to-r from-[#E54C4C] to-[#FF6B6B] text-white shadow-lg"
                    : "text-gray-400 hover:text-white"
                }`}
              >
                {label}
              </button>
            ))}
          </div>
        </div>

        {/* Vehicle cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-10">
          {vehicles.map((vehicle, index) => (
            <div
              key={index}
              className="relative bg-[#0C0E18] rounded-xl border border-[#1A2035] overflow-hidden h-full transition-all duration-500 transform hover:border-[#3A4563] hover:shadow-xl hover:shadow-[rgba(78,202,252,0.07)] hover:-translate-y-1 group"
              style={{
                opacity: animatedElements.cards[index] ? 1 : 0,
                transform: animatedElements.cards[index]
                  ? "translateY(0)"
                  : "translateY(40px)",
              }}
            >
              {/* Hover glow effect */}
              <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-700">
                <div
                  className="absolute inset-0 opacity-10"
                  style={{
                    background: `radial-gradient(circle at center, ${vehicle.color}40 0%, transparent 70%)`,
                  }}
                ></div>
              </div>

              {/* Badge */}
              <div className="relative pt-6 px-6">
                <div
                  className="absolute top-6 right-6 px-3 py-1 rounded-full text-xs font-medium"
                  style={{
                    color: vehicle.color,
                    backgroundColor: `${vehicle.color}15`,
                    borderWidth: "1px",
                    borderColor: `${vehicle.color}30`,
                  }}
                >
                  {vehicle.badgeText}
                </div>
              </div>

              {/* Icon and title */}
              <div className="px-6 pt-2 pb-8">
                <div className="mt-4 mb-6 flex items-center">
                  <div
                    className="w-11 h-11 rounded-full flex items-center justify-center mr-3 transition-all duration-300 group-hover:shadow-md"
                    style={{
                      backgroundColor: `${vehicle.color}15`,
                      boxShadow: `0 0 0 rgba(${
                        vehicle.color === "#4ECAFC"
                          ? "78, 202, 252"
                          : "229, 76, 76"
                      }, 0)`,
                    }}
                  >
                    <vehicle.icon
                      size={22}
                      color={vehicle.color}
                      className="group-hover:scale-110 transition-transform duration-300"
                    />
                  </div>
                  <h3 className="text-2xl font-bold text-white group-hover:text-[#FFFFFF] transition-colors duration-300">
                    {vehicle.type}
                  </h3>
                </div>

                {/* Max size info */}
                <div className="mb-3">
                  <div className="text-xs uppercase tracking-wider text-gray-500 font-medium mb-2">
                    MAX SIZE
                  </div>
                  <div className="text-base text-white">{vehicle.maxSize}</div>
                </div>
              </div>

              {/* Price section */}
              <div className="bg-[#080A12] border-t border-[#1A2035] p-6 text-center relative overflow-hidden">
                {/* Price flash animation on change */}
                <div className="text-sm text-gray-400 mb-2">Starting from</div>
                <div className="text-3xl font-bold relative">
                  {/* Price with gradient effect */}
                  <span className="bg-gradient-to-r from-white via-white to-gray-300 bg-clip-text text-transparent">
                    {vehicle.pricing[selectedDistance]}
                  </span>
                  <div className="absolute inset-0 flex items-center justify-center">
                    <div
                      className="w-full h-full absolute opacity-0"
                      key={`${vehicle.type}-${selectedDistance}`}
                      style={{
                        animation: "price-flash 1s ease-out forwards",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* CSS Animations */}
      <style jsx>{`
        /* Pulse animations for background glows */
        @keyframes pulse-slow {
          0%,
          100% {
            opacity: 0.3;
            transform: scale(1);
          }
          50% {
            opacity: 0.6;
            transform: scale(1.1);
          }
        }

        @keyframes pulse-slow-delay {
          0%,
          100% {
            opacity: 0.3;
            transform: scale(1);
          }
          50% {
            opacity: 0.7;
            transform: scale(1.2);
          }
        }

        /* Price change flash animation */
        @keyframes price-flash {
          0% {
            opacity: 0.5;
            background-color: rgba(255, 255, 255, 0.2);
            transform: scale(0.95);
          }
          50% {
            opacity: 0.2;
            transform: scale(1.05);
          }
          100% {
            opacity: 0;
            transform: scale(1);
          }
        }

        /* Floating animation for background particles */
        @keyframes float {
          0%,
          100% {
            transform: translateY(0) translateX(0);
          }
          50% {
            transform: translateY(-10px) translateX(5px);
          }
        }

        .animate-pulse-slow {
          animation: pulse-slow 15s infinite ease-in-out;
        }

        .animate-pulse-slow-delay {
          animation: pulse-slow-delay 18s infinite ease-in-out 3s;
        }
      `}</style>
    </section>
  );
};

export default PremiumFleetPricing;
