import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  ChevronRight,
  Check,
  Code,
  Truck,
  Car,
  CloudLightning,
  Bike,
  ArrowRight,
} from "lucide-react";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
import Shopify from "../Images/shopify_glyph.png";
import Woo from "../Images/Woo_logo_white.png";
import Wix from "../Images/Wix_White.png";
import { useNavigate } from "react-router-dom";
import SpeakToSalesModal from "./SpeakToSalesModal";

// ------------------------------
// Animated Gradient Border
// ------------------------------
const AnimatedBorder = styled.div`
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #0f121b;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-image: conic-gradient(
      transparent,
      rgba(229, 76, 76, 0.3),
      transparent 30%
    );
    animation: rotate 7s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 2px;
    top: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background: #0f121b;
    border-radius: 14px;
  }

  @keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }
`;

// Blue Animated Gradient Border
const BlueAnimatedBorder = styled(AnimatedBorder)`
  &::before {
    background-image: conic-gradient(
      transparent,
      rgba(78, 202, 252, 0.3),
      transparent 30%
    );
  }
`;

// ------------------------------
// Gradient Text Components
// ------------------------------
const GradientTextStyled = styled.span`
  background: linear-gradient(90deg, #e54c4c 0%, #ff6b6b 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
`;

const BlueGradient = styled.span`
  background: linear-gradient(90deg, #4ecafc 0%, #6a8cff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
`;

// ------------------------------
// Improved Multiline Typewriter
// ------------------------------
const MultilineTypeWriter = styled.div`
  font-family: monospace;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  white-space: pre-wrap;

  &::after {
    content: "";
    position: absolute;
    right: -2px;
    top: 0;
    height: 100%;
    width: 2px;
    background-color: #e54c4c;
    animation: blink-caret 0.75s step-end infinite;
  }

  &.typing {
    animation: typing 4s steps(60, end) 1;
    animation-fill-mode: forwards;
    width: 0;
  }

  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  @keyframes blink-caret {
    from,
    to {
      background-color: transparent;
    }
    50% {
      background-color: #e54c4c;
    }
  }
`;

// ------------------------------
// Tab Content Component
// ------------------------------
const SolutionTabContent = ({ content, isActive }) => (
  <div
    className={`transition-all duration-700 ${
      isActive
        ? "opacity-100 translate-y-0"
        : "opacity-0 translate-y-8 absolute pointer-events-none"
    }`}
  >
    {content}
  </div>
);

// ------------------------------
// Integration Platform Component
// ------------------------------
const IntegrationPlatform = ({ icon: Icon, name, isImage = false }) => (
  <div className="bg-[#0F121B]/80 px-4 py-4 rounded-lg border border-[#262D40] flex items-center justify-center hover:border-[#414D75] hover:shadow-lg transition-all duration-300 group">
    {isImage ? (
      <img
        src={Icon}
        alt={name}
        className={`transition-transform duration-500 group-hover:scale-110 object-contain ${
          name === "WooCommerce" ? "w-14 h-14" : "w-10 h-10"
        }`}
      />
    ) : (
      <Icon
        className="text-[#E54C4C] transition-transform duration-500 group-hover:scale-110"
        size={36}
      />
    )}
    {name === "API" && (
      <span className="ml-2 text-sm font-medium text-gray-300 group-hover:text-white transition-colors">
        {name}
      </span>
    )}
  </div>
);

// ------------------------------
// Vehicle Type Component
// ------------------------------
const VehicleType = ({ icon: Icon, name }) => (
  <div className="flex flex-col items-center group cursor-pointer">
    <div className="relative mb-3">
      <div className="absolute -inset-2 bg-gradient-to-r from-[#E54C4C]/20 to-[#4ECAFC]/20 rounded-full blur-md opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
      <div className="relative w-16 h-16 bg-[#0F121B] rounded-full flex items-center justify-center border border-[#262D40] transform group-hover:scale-110 transition-all duration-500">
        <Icon
          className="text-gray-400 group-hover:text-[#E54C4C] transition-colors"
          size={26}
        />
      </div>
    </div>
    <span className="text-sm text-gray-400 group-hover:text-white transition-colors">
      {name}
    </span>
  </div>
);

// ------------------------------
// Rotating Orbs (Memoized)
// ------------------------------
const RotatingOrbs = React.memo(() => {
  // Precompute random positions only once
  const orbData = useMemo(() => {
    return [...Array(5)].map((_, i) => ({
      size: [500, 400, 300, 200, 100][i],
      borderColor: i % 2 === 0 ? "#E54C4C" : "#4ECAFC",
      animationClass:
        i % 2 === 0 ? "animate-spin-slow" : "animate-spin-reverse",
    }));
  }, []);

  return (
    <div className="absolute top-0 right-0 w-full h-full overflow-hidden opacity-30 pointer-events-none">
      <div className="absolute top-1/4 right-1/4">
        {orbData.map((orb, i) => (
          <div
            key={i}
            className={`w-[${orb.size}px] h-[${orb.size}px] rounded-full border`}
            style={{
              borderColor: `${orb.borderColor}/20`,
            }}
          >
            {/* The animation classes should be defined in your CSS */}
          </div>
        ))}
      </div>
    </div>
  );
});

// ------------------------------
// Network Animation (Memoized)
// ------------------------------
const NetworkAnimation = React.memo(() => {
  const redDots = useMemo(() => {
    return [...Array(25)].map(() => ({
      top: Math.random() * 100,
      left: Math.random() * 100,
      duration: 3 + Math.random() * 4,
      delay: Math.random() * 2,
    }));
  }, []);
  const blueDots = useMemo(() => {
    return [...Array(20)].map(() => ({
      top: Math.random() * 100,
      left: Math.random() * 100,
      duration: 2 + Math.random() * 3,
      delay: Math.random() * 2,
    }));
  }, []);

  return (
    <div className="absolute inset-0 z-0 overflow-hidden opacity-40">
      <div className="absolute inset-0 opacity-10">
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `
              linear-gradient(to right, rgba(255,255,255,0.05) 1px, transparent 1px),
              linear-gradient(to bottom, rgba(255,255,255,0.05) 1px, transparent 1px)
            `,
            backgroundSize: "20px 20px",
          }}
        ></div>
      </div>
      <div className="absolute inset-0">
        {redDots.map((dot, i) => (
          <div
            key={`red-${i}`}
            className="absolute w-1 h-1 rounded-full bg-[#E54C4C]/40"
            style={{
              top: `${dot.top}%`,
              left: `${dot.left}%`,
              animation: `float ${dot.duration}s infinite ease-in-out ${dot.delay}s`,
            }}
          ></div>
        ))}
        {blueDots.map((dot, i) => (
          <div
            key={`blue-${i}`}
            className="absolute w-1 h-1 rounded-full bg-[#4ECAFC]/40"
            style={{
              top: `${dot.top}%`,
              left: `${dot.left}%`,
              animation: `float ${dot.duration}s infinite ease-in-out ${dot.delay}s`,
            }}
          ></div>
        ))}
      </div>
      <div
        className="absolute inset-0 opacity-40"
        style={{
          background:
            "radial-gradient(50% 50% at 50% 50%, rgba(229,76,76,0.08) 0%, rgba(10,13,20,0) 100%)",
        }}
      ></div>
      <div
        className="absolute inset-0 opacity-20"
        style={{
          background:
            "radial-gradient(70% 70% at 30% 30%, rgba(78,202,252,0.1) 0%, rgba(10,13,20,0) 100%)",
        }}
      ></div>
      <div
        className="absolute w-96 h-96 rounded-full"
        style={{
          background:
            "radial-gradient(circle, rgba(229,76,76,0.1) 0%, rgba(10,13,20,0) 70%)",
          left: "30%",
          top: "40%",
          filter: "blur(50px)",
          animation: "pulseGlow 8s infinite ease-in-out",
        }}
      ></div>
      <div
        className="absolute w-96 h-96 rounded-full"
        style={{
          background:
            "radial-gradient(circle, rgba(78,202,252,0.08) 0%, rgba(10,13,20,0) 70%)",
          right: "25%",
          top: "30%",
          filter: "blur(50px)",
          animation: "pulseGlow 10s infinite ease-in-out 2s",
        }}
      ></div>
      <RotatingOrbs />
      <style jsx>{`
        @keyframes float {
          0%,
          100% {
            transform: translateY(0) translateX(0);
          }
          50% {
            transform: translateY(-15px) translateX(8px);
          }
        }
        @keyframes pulseGlow {
          0%,
          100% {
            opacity: 0.3;
            transform: scale(1);
          }
          50% {
            opacity: 0.8;
            transform: scale(1.3);
          }
        }
      `}</style>
    </div>
  );
});

// Wrap heavy components with React.memo to avoid re-renders
const MemoizedRotatingOrbs = React.memo(() => <RotatingOrbs />);
const MemoizedNetworkAnimation = React.memo(() => <NetworkAnimation />);

// ------------------------------
// FleetSolutions Component
// ------------------------------
const FleetSolutions = () => {
  const [activeTab, setActiveTab] = useState("deploy");
  const [isTyping, setIsTyping] = useState(false);
  const commandRef = useRef(null);
  const navigate = useNavigate();

  const [showSalesModal, setShowSalesModal] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-out",
      once: true,
      mirror: false,
      anchorPlacement: "top-bottom",
    });

    if (activeTab === "build") {
      setTimeout(() => {
        setIsTyping(true);
      }, 300);
    } else {
      setIsTyping(false);
    }
  }, [activeTab]);

  const navigateToPage = (page: string) => {
    navigate(page);
  };

  const navigateToBusinessPage = () => {
    navigate("/business");
  };

  return (
    <div className="bg-[#080A12] py-10 relative overflow-hidden">
      <SpeakToSalesModal
        isOpen={showSalesModal}
        onClose={() => setShowSalesModal(false)}
      />
      {/* Background decoration */}
      <MemoizedNetworkAnimation />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <div className="text-center mb-10" data-aos="fade-up">
          <h2 className="text-4xl md:text-5xl font-bold text-white mb-5 tracking-tight">
            Choose Your <GradientTextStyled>Solution</GradientTextStyled>
          </h2>
          <p className="text-gray-300 max-w-3xl mx-auto text-lg">
            Whether you need an instant plug-and-play solution or a fully
            customized fleet, we've got you covered with our industry-leading
            technology and AI-powered logistics platform.
          </p>
        </div>

        {/* Tabs Navigation */}
        <div
          className="flex justify-center mb-16"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="inline-flex bg-[#0F121B]/70 backdrop-blur-sm p-2 rounded-full border border-[#262D40] shadow-2xl">
            <button
              onClick={() => setActiveTab("deploy")}
              className={`px-8 py-3.5 rounded-full text-base font-medium transition-all duration-500 ${
                activeTab === "deploy"
                  ? "bg-gradient-to-r from-[#E54C4C] to-[#FF6B6B] text-white shadow-lg shadow-[#E54C4C]/20"
                  : "text-gray-300 hover:text-white"
              }`}
            >
              Deploy Our Fleet
            </button>
            <button
              onClick={() => setActiveTab("build")}
              className={`px-8 py-3.5 rounded-full text-base font-medium transition-all duration-500 ${
                activeTab === "build"
                  ? "bg-gradient-to-r from-[#4ECAFC] to-[#6A8CFF] text-white shadow-lg shadow-[#4ECAFC]/20"
                  : "text-gray-300 hover:text-white"
              }`}
            >
              Build Custom Fleet
            </button>
          </div>
        </div>

        {/* Tab Content */}
        <div
          className="relative min-h-[550px]"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <SolutionTabContent
            isActive={activeTab === "deploy"}
            content={
              <div className="grid grid-cols-1 lg:grid-cols-12 gap-12 items-start max-w-6xl mx-auto">
                <div className="lg:col-span-6">
                  <AnimatedBorder>
                    <div className="bg-[#0F121B]/80 rounded-xl h-full p-8 pb-10">
                      <div className="mb-8">
                        <p className="text-sm text-gray-400 mb-4 uppercase tracking-wider font-medium">
                          Connect with
                        </p>
                        <div className="grid grid-cols-2 gap-3 mb-6">
                          <IntegrationPlatform
                            icon={Shopify}
                            name="Shopify"
                            isImage={true}
                          />
                          <IntegrationPlatform
                            icon={Woo}
                            name="WooCommerce"
                            isImage={true}
                          />
                          <IntegrationPlatform
                            icon={Wix}
                            name="Wix"
                            isImage={true}
                          />
                          <IntegrationPlatform icon={Code} name="API" />
                        </div>
                        <div className="flex items-center text-gray-300 text-base">
                          <ArrowRight
                            className="text-[#E54C4C] mr-2"
                            size={18}
                          />
                          <span>Need other integrations? Speak to us</span>
                        </div>
                      </div>

                      <div>
                        <p className="text-sm text-gray-400 mb-4 uppercase tracking-wider font-medium">
                          Fleet options
                        </p>
                        <div className="flex justify-between space-x-4 mb-4">
                          <VehicleType icon={Bike} name="Bicycle" />
                          <VehicleType icon={CloudLightning} name="E-Bike" />
                          <VehicleType icon={Car} name="Car" />
                          <VehicleType icon={Truck} name="Van" />
                        </div>
                      </div>
                    </div>
                  </AnimatedBorder>
                </div>

                <div className="lg:col-span-6">
                  <div className="pl-0 lg:pl-8">
                    <h2 className="text-3xl md:text-4xl font-semibold mb-5 text-white leading-tight">
                      Plug &amp; Play{" "}
                      <GradientTextStyled>Same‑Hour</GradientTextStyled>{" "}
                    </h2>
                    <p className="text-gray-300 mb-8 leading-relaxed text-lg">
                      Perfect for SMBs and growing retailers. Tap into our
                      network of 1000+ trained specialists and start delivering
                      in under 60 minutes with zero operational overhead.
                    </p>
                    <div className="space-y-5 mb-10">
                      <div className="flex items-center text-gray-300 text-base">
                        <div className="w-6 h-6 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mr-3">
                          <Check className="text-[#E54C4C]" size={14} />
                        </div>
                        <span>Zero operational overhead</span>
                      </div>
                      <div className="flex items-center text-gray-300 text-base">
                        <div className="w-6 h-6 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mr-3">
                          <Check className="text-[#E54C4C]" size={14} />
                        </div>
                        <span>Sub-60 minute delivery</span>
                      </div>
                      <div className="flex items-center text-gray-300 text-base">
                        <div className="w-6 h-6 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mr-3">
                          <Check className="text-[#E54C4C]" size={14} />
                        </div>
                        <span>Fully automated operations</span>
                      </div>
                      <div className="flex items-center text-gray-300 text-base">
                        <div className="w-6 h-6 rounded-full bg-[#E54C4C]/10 flex items-center justify-center mr-3">
                          <Check className="text-[#E54C4C]" size={14} />
                        </div>
                        <span>White-labelled / Co-Branded tracking links</span>
                      </div>
                    </div>
                    <div className="relative inline-block group">
                      <div className="absolute -inset-1 bg-gradient-to-r from-[#E54C4C]/20 to-[#FF6B6B]/20 rounded-lg blur-md opacity-70 group-hover:opacity-100 transition-opacity duration-300"></div>
                      <button
                        onClick={navigateToBusinessPage}
                        className="relative bg-gradient-to-r from-[#E54C4C] to-[#FF6B6B] text-white px-8 py-3.5 rounded-lg hover:from-[#FF6B6B] hover:to-[#E54C4C] transition-all duration-300 shadow-xl shadow-[#E54C4C]/10 flex items-center group text-base font-medium"
                      >
                        Try Blitzo for Business
                        <ChevronRight
                          className="ml-2 group-hover:translate-x-1 transition-transform"
                          size={18}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            }
          />

          <SolutionTabContent
            isActive={activeTab === "build"}
            content={
              <div className="grid grid-cols-1 lg:grid-cols-12 gap-12 items-start max-w-6xl mx-auto">
                {/* Left column */}
                <div className="lg:col-span-6">
                  <BlueAnimatedBorder>
                    <div className="bg-[#0F121B]/80 rounded-xl h-full p-8 pb-10">
                      <div className="bg-[#0A0D16] rounded-xl p-5 border border-[#262D40] mb-8 hover:border-[#4ECAFC]/30 transition-all duration-300 hover:shadow-lg group">
                        <div className="flex justify-between items-center mb-3">
                          <span className="text-white text-base font-medium">
                            Fleet Configuration
                          </span>
                          <div className="flex space-x-2">
                            <div className="w-2 h-2 rounded-full bg-red-500"></div>
                            <div className="w-2 h-2 rounded-full bg-yellow-500"></div>
                            <div className="w-2 h-2 rounded-full bg-green-500"></div>
                          </div>
                        </div>
                        <div className="space-y-4 my-5">
                          <div className="text-sm font-medium text-gray-400 mb-1">
                            Command Prompt
                          </div>
                          <div
                            className="bg-[#080A12] p-4 rounded border border-[#262D40] text-sm text-gray-300 font-mono min-h-[80px]"
                            ref={commandRef}
                          >
                            <span className="text-[#4ECAFC]">{">"}</span>{" "}
                            {isTyping ? (
                              <MultilineTypeWriter>
                                Hey Blitzo, find me 400 drivers in London with
                                cars on an independent contract that can be
                                trained for a technical doorstep experience.
                              </MultilineTypeWriter>
                            ) : (
                              "Hey Blitzo, find me 400 drivers in London with cars on an independent contract that can be trained for a technical doorstep experience."
                            )}
                          </div>
                          <div className="flex items-center">
                            <div className="w-2.5 h-2.5 rounded-full bg-[#4ECAFC] mr-2 animate-pulse"></div>
                            <span className="text-sm text-[#4ECAFC]">
                              Processing request...
                            </span>
                          </div>
                        </div>
                        <div className="space-y-4 mt-6">
                          <div className="space-y-1.5">
                            <div className="flex justify-between items-center">
                              <span className="text-gray-400 text-sm">
                                Vehicle Mix
                              </span>
                              <span className="text-xs text-[#4ECAFC]">
                                Optimizing
                              </span>
                            </div>
                            <div className="flex space-x-1.5">
                              <div className="h-2.5 w-12 bg-[#E54C4C]/70 rounded-sm"></div>
                              <div className="h-2.5 w-12 bg-[#4ECAFC]/70 rounded-sm"></div>
                              <div className="h-2.5 w-12 bg-[#E54C4C]/70 rounded-sm"></div>
                              <div className="h-2.5 w-12 bg-[#4ECAFC]/70 rounded-sm animate-pulse"></div>
                            </div>
                          </div>
                          <div className="space-y-1.5">
                            <div className="flex justify-between items-center">
                              <span className="text-gray-400 text-sm">
                                Geographic Coverage
                              </span>
                              <span className="text-xs text-[#4ECAFC]">
                                Mapping London
                              </span>
                            </div>
                            <div className="flex space-x-1.5">
                              <div className="h-2.5 w-12 bg-[#E54C4C]/70 rounded-sm"></div>
                              <div className="h-2.5 w-12 bg-[#4ECAFC]/70 rounded-sm"></div>
                              <div className="h-2.5 w-12 bg-[#E54C4C]/70 rounded-sm animate-pulse"></div>
                              <div className="h-2.5 w-12 bg-[#4ECAFC]/70 rounded-sm"></div>
                            </div>
                          </div>
                          <div className="space-y-1.5">
                            <div className="flex justify-between items-center">
                              <span className="text-gray-400 text-sm">
                                Training Requirements
                              </span>
                              <span className="text-xs text-[#4ECAFC]">
                                Training Plan
                              </span>
                            </div>
                            <div className="flex space-x-1.5">
                              <div className="h-2.5 w-12 bg-[#E54C4C]/70 rounded-sm"></div>
                              <div className="h-2.5 w-12 bg-[#4ECAFC]/70 rounded-sm animate-pulse"></div>
                              <div className="h-2.5 w-12 bg-[#E54C4C]/70 rounded-sm"></div>
                              <div className="h-2.5 w-12 bg-[#4ECAFC]/70 rounded-sm"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </BlueAnimatedBorder>
                </div>
                <div className="lg:col-span-6">
                  <div className="pl-0 lg:pl-8">
                    <h2 className="text-3xl md:text-4xl font-semibold mb-5 text-white leading-tight">
                      Custom <BlueGradient>Fleet</BlueGradient> Configuration
                    </h2>
                    <p className="text-gray-300 mb-8 leading-relaxed text-lg">
                      Build a fleet that meets your unique business needs. Our
                      AI-powered logistics platform lets you customize every
                      aspect of your delivery operation.
                    </p>
                    <div className="space-y-5 mb-10">
                      <div className="flex items-center text-gray-300 text-base">
                        <div className="w-6 h-6 rounded-full bg-[#4ECAFC]/10 flex items-center justify-center mr-3">
                          <Check className="text-[#4ECAFC]" size={14} />
                        </div>
                        <span>Custom vehicle mix</span>
                      </div>
                      <div className="flex items-center text-gray-300 text-base">
                        <div className="w-6 h-6 rounded-full bg-[#4ECAFC]/10 flex items-center justify-center mr-3">
                          <Check className="text-[#4ECAFC]" size={14} />
                        </div>
                        <span>Geographic coverage</span>
                      </div>
                      <div className="flex items-center text-gray-300 text-base">
                        <div className="w-6 h-6 rounded-full bg-[#4ECAFC]/10 flex items-center justify-center mr-3">
                          <Check className="text-[#4ECAFC]" size={14} />
                        </div>
                        <span>Specialized handling</span>
                      </div>
                      <div className="flex items-center text-gray-300 text-base">
                        <div className="w-6 h-6 rounded-full bg-[#4ECAFC]/10 flex items-center justify-center mr-3">
                          <Check className="text-[#4ECAFC]" size={14} />
                        </div>
                        <span>AI fleet optimization</span>
                      </div>
                    </div>
                    <div className="relative inline-block group">
                      <div className="absolute -inset-1 bg-gradient-to-r from-[#E54C4C]/20 to-[#FF6B6B]/20 rounded-lg blur-md opacity-70 group-hover:opacity-100 transition-opacity duration-300"></div>
                      <button
                        onClick={() => setShowSalesModal(true)}
                        className="relative px-8 py-3.5 bg-[#0A0D16] border border-[#4ECAFC]/30 text-white rounded-lg hover:border-[#4ECAFC] flex items-center group-hover:bg-[#0A0D16]/80 transition-all duration-300 text-base font-medium"
                      >
                        Speak to Sales
                        <ArrowRight
                          className="ml-2 group-hover:translate-x-1 transition-transform text-[#4ECAFC]"
                          size={18}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default FleetSolutions;
