import { createContext } from "react";
import { BlitzoOrder } from "../types/BlitzoOrder";

type ActiveOrderContextType = {
  activeOrders: BlitzoOrder[] | null;
  setActiveOrders: (orders: BlitzoOrder[] | null) => void;
  fetchAndSetActiveOrders: () => Promise<void>;
  cancelOrder: (
    orderId: string,
    collectionName: "activeOrders" | "scheduledOrders"
  ) => Promise<void>;
  setScheduledOrders: (orders: BlitzoOrder[]) => void;
  scheduledOrders: BlitzoOrder[] | null;
  fetchAndSetScheduledOrders: () => Promise<void>;
};

const ActiveOrderContext = createContext<ActiveOrderContextType>({
  activeOrders: null,
  setActiveOrders: () => {},
  fetchAndSetActiveOrders: async () => {},
  cancelOrder: async () => {},
  setScheduledOrders: () => {},
  scheduledOrders: null,
  fetchAndSetScheduledOrders: async () => {},
});

export default ActiveOrderContext;
