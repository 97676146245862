import React, { useEffect, useState } from "react";
import {
  Mail,
  Phone,
  Users,
  Building2,
  ArrowUpRight,
  MessageSquare,
  ChevronRight,
  Headphones,
  ArrowRight,
} from "lucide-react";
import DarkNavbar from "../components/DarkNavbar";
import Footer from "../components/footer";
import AOS from "aos";
import "aos/dist/aos.css";
import styled from "styled-components";
import SpeakToSalesModal from "../components/SpeakToSalesModal";

// Styled components for gradient text effects
const GradientText = styled.span`
  background: linear-gradient(90deg, #e54c4c 0%, #ff6b6b 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
`;

const BlueGradient = styled.span`
  background: linear-gradient(90deg, #4ecafc 0%, #6a8cff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
`;

// Animated gradient border
const AnimatedBorder = styled.div`
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #0f121b;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-image: conic-gradient(
      transparent,
      rgba(229, 76, 76, 0.3),
      transparent 30%
    );
    animation: rotate 7s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 2px;
    top: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background: #0f121b;
    border-radius: 14px;
  }

  @keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }
`;

// Blue variant of the animated gradient border
const BlueAnimatedBorder = styled(AnimatedBorder)`
  &::before {
    background-image: conic-gradient(
      transparent,
      rgba(78, 202, 252, 0.3),
      transparent 30%
    );
  }
`;

// Network Animation Component
const NetworkAnimation = () => {
  return (
    <div className="absolute inset-0 z-0 overflow-hidden opacity-40">
      <div className="absolute inset-0 opacity-10">
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `
              linear-gradient(to right, rgba(255,255,255,0.05) 1px, transparent 1px),
              linear-gradient(to bottom, rgba(255,255,255,0.05) 1px, transparent 1px)
            `,
            backgroundSize: "20px 20px",
          }}
        ></div>
      </div>

      {/* Animated dots */}
      <div className="absolute inset-0">
        {[...Array(25)].map((_, i) => (
          <div
            key={`red-${i}`}
            className="absolute w-1 h-1 rounded-full bg-[#E54C4C]/40"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animation: `float ${
                3 + Math.random() * 4
              }s infinite ease-in-out ${Math.random() * 2}s`,
            }}
          ></div>
        ))}
        {[...Array(20)].map((_, i) => (
          <div
            key={`blue-${i}`}
            className="absolute w-1 h-1 rounded-full bg-[#4ECAFC]/40"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animation: `float ${
                2 + Math.random() * 3
              }s infinite ease-in-out ${Math.random() * 2}s`,
            }}
          ></div>
        ))}
      </div>

      {/* Radial gradients */}
      <div
        className="absolute inset-0 opacity-40"
        style={{
          background:
            "radial-gradient(50% 50% at 50% 50%, rgba(229,76,76,0.08) 0%, rgba(10,13,20,0) 100%)",
        }}
      ></div>
      <div
        className="absolute inset-0 opacity-20"
        style={{
          background:
            "radial-gradient(70% 70% at 30% 30%, rgba(78,202,252,0.1) 0%, rgba(10,13,20,0) 100%)",
        }}
      ></div>

      {/* Glowing orbs */}
      <div
        className="absolute w-96 h-96 rounded-full"
        style={{
          background:
            "radial-gradient(circle, rgba(229,76,76,0.1) 0%, rgba(10,13,20,0) 70%)",
          left: "30%",
          top: "40%",
          filter: "blur(50px)",
          animation: "pulseGlow 8s infinite ease-in-out",
        }}
      ></div>
      <div
        className="absolute w-96 h-96 rounded-full"
        style={{
          background:
            "radial-gradient(circle, rgba(78,202,252,0.08) 0%, rgba(10,13,20,0) 70%)",
          right: "25%",
          top: "30%",
          filter: "blur(50px)",
          animation: "pulseGlow 10s infinite ease-in-out 2s",
        }}
      ></div>

      <style jsx>{`
        @keyframes float {
          0%,
          100% {
            transform: translateY(0) translateX(0);
          }
          50% {
            transform: translateY(-15px) translateX(8px);
          }
        }
        @keyframes pulseGlow {
          0%,
          100% {
            opacity: 0.3;
            transform: scale(1);
          }
          50% {
            opacity: 0.8;
            transform: scale(1.3);
          }
        }
      `}</style>
    </div>
  );
};

// Contact Method Card Component
const ContactMethodCard = ({
  icon: Icon,
  title,
  description,
  action,
  link,
  delay = 0,
  iconColor = "#E54C4C",
  animatedBorder = false,
}) => {
  const CardContent = () => (
    <div className="bg-[#0F121B]/80 p-8 rounded-xl h-full flex flex-col">
      <Icon className={`text-[${iconColor}] mb-6 h-10 w-10`} />
      <h2 className="text-xl font-bold text-white mb-3">{title}</h2>
      <p className="text-gray-300 mb-6 flex-grow">{description}</p>
      <a
        href={link}
        className={`text-lg font-medium text-[${iconColor}] hover:text-[${iconColor}]/80 transition-colors flex items-center group`}
      >
        {action}
        <ArrowRight
          className="ml-2 group-hover:translate-x-1 transition-transform"
          size={18}
        />
      </a>
    </div>
  );

  return (
    <div data-aos="fade-up" data-aos-delay={delay}>
      {animatedBorder ? (
        iconColor === "#E54C4C" ? (
          <AnimatedBorder>
            <CardContent />
          </AnimatedBorder>
        ) : (
          <BlueAnimatedBorder>
            <CardContent />
          </BlueAnimatedBorder>
        )
      ) : (
        <div className="bg-[#0F121B]/80 p-8 rounded-xl border border-[#262D40] hover:border-[#414D75] transition-all duration-300 shadow-sm hover:shadow-xl h-full hover:scale-[1.02] flex flex-col">
          <Icon className={`text-[${iconColor}] mb-6 h-10 w-10`} />
          <h2 className="text-xl font-bold text-white mb-3">{title}</h2>
          <p className="text-gray-300 mb-6 flex-grow">{description}</p>
          <a
            href={link}
            className={`text-lg font-medium text-[${iconColor}] hover:text-[${iconColor}]/80 transition-colors flex items-center group`}
          >
            {action}
            <ArrowRight
              className="ml-2 group-hover:translate-x-1 transition-transform"
              size={18}
            />
          </a>
        </div>
      )}
    </div>
  );
};

const Contact = () => {
  const [showSalesModal, setShowSalesModal] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
      easing: "ease-out",
    });
  }, []);

  return (
    <div className="min-h-screen bg-[#080A12] text-white">
      <SpeakToSalesModal
        isOpen={showSalesModal}
        onClose={() => setShowSalesModal(false)}
      />
      <DarkNavbar />

      {/* Hero Section */}
      <div className="relative pt-40 pb-24 overflow-hidden">
        <NetworkAnimation />

        <div className="max-w-7xl mx-auto px-6 lg:px-8 relative z-10">
          <div className="text-center" data-aos="fade-up">
            <div className="mb-4 inline-block px-4 py-1.5 rounded-full bg-[#E54C4C]/10 border border-[#E54C4C]/20 text-[#E54C4C] text-sm">
              We're Here To Help
            </div>
            <h1 className="text-4xl md:text-6xl font-bold text-white mb-8">
              Let's <GradientText>Connect</GradientText>
            </h1>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto leading-relaxed">
              We believe in building relationships, not just delivering
              packages. Whether you need support, have business inquiries, or
              want to explore our Fleet as a Service solutions, we're just a
              message away.
            </p>
          </div>
        </div>

        {/* Glowing orbs */}
        <div className="absolute top-0 left-0 w-64 h-64 rounded-full bg-[#4ECAFC]/10 blur-3xl opacity-30 animate-pulse-slow"></div>
        <div className="absolute bottom-0 right-0 w-64 h-64 rounded-full bg-[#E54C4C]/10 blur-3xl opacity-30 animate-pulse-slow-delay"></div>
      </div>

      {/* Contact Methods Grid */}
      <div className="max-w-7xl mx-auto px-6 lg:px-8 py-20 relative z-10">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* General Support */}
          <ContactMethodCard
            icon={Headphones}
            title="General Support"
            description="Need help with a delivery or have questions about our service? Our friendly support team is here for you 24/7."
            action="Email Support"
            link="mailto:support@blitzo.co.uk"
            delay={100}
            iconColor="#E54C4C"
          />

          {/* Business Inquiries */}
          <ContactMethodCard
            icon={Building2}
            title="Business Inquiries"
            description="Looking to integrate Blitzo into your business? Want to discuss PoS integrations or same-hour delivery solutions?"
            action="Contact Sales"
            link="#"
            delay={200}
            iconColor="#4ECAFC"
            onClick={() => setShowSalesModal(true)}
          />

          {/* Enterprise Solutions */}
          <ContactMethodCard
            icon={Users}
            title="Enterprise Solutions"
            description="Need a custom fleet solution or want to discuss large-scale deployment? Our enterprise team can help design the perfect solution."
            action="Speak to Enterprise"
            link="#"
            delay={300}
            iconColor="#E54C4C"
            onClick={() => setShowSalesModal(true)}
          />
        </div>
      </div>

      {/* Live Chat Section */}
      <div className="bg-[#0A0D16] py-20 relative overflow-hidden">
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top,_#4ECAFC08,transparent_50%)]"></div>

        <div className="max-w-7xl mx-auto px-6 lg:px-8 relative z-10">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
            <div data-aos="fade-right">
              <div className="mb-4 inline-block text-[#4ECAFC] text-sm font-medium tracking-wider uppercase">
                Prefer to Talk?
              </div>
              <h2 className="text-3xl md:text-4xl font-bold text-white mb-6">
                Chat With an <BlueGradient>Expert</BlueGradient>
              </h2>

              <p className="text-gray-300 mb-8">
                We're always ready for a conversation. Our team of delivery
                experts and account managers are standing by to answer your
                questions immediately.
              </p>

              <div className="space-y-6 mb-8">
                <div className="flex items-start gap-4">
                  <div className="w-10 h-10 rounded-full bg-[#4ECAFC]/10 flex items-center justify-center flex-shrink-0 mt-1">
                    <Phone className="text-[#4ECAFC]" size={20} />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-white mb-1">
                      Give Us a Call
                    </h3>
                    <p className="text-gray-300">
                      Speak directly with our support team during business
                      hours.
                    </p>
                    <a
                      href="tel:+447553848371"
                      className="text-[#4ECAFC] hover:text-[#4ECAFC]/80 transition-colors mt-2 inline-block font-medium"
                    >
                      +44 755 384 8371
                    </a>
                  </div>
                </div>

                <div className="flex items-start gap-4">
                  <div className="w-10 h-10 rounded-full bg-[#4ECAFC]/10 flex items-center justify-center flex-shrink-0 mt-1">
                    <MessageSquare className="text-[#4ECAFC]" size={20} />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-white mb-1">
                      Live Chat
                    </h3>
                    <p className="text-gray-300">
                      Need immediate assistance? Start a live chat with our team
                      right now.
                    </p>
                  </div>
                </div>
              </div>

              <button
                onClick={() => setShowSalesModal(true)}
                className="px-8 py-3.5 bg-gradient-to-r from-[#4ECAFC] to-[#6A8CFF] text-white rounded-lg font-medium flex items-center shadow-lg shadow-[#4ECAFC]/20 hover:shadow-xl hover:shadow-[#4ECAFC]/30 transition-all duration-300"
              >
                Start a Conversation
                <ChevronRight
                  className="ml-2 group-hover:translate-x-1 transition-transform"
                  size={18}
                />
              </button>
            </div>

            <div data-aos="fade-left">
              <BlueAnimatedBorder>
                <div className="bg-[#0F121B]/80 rounded-xl p-8">
                  <div className="relative" style={{ paddingBottom: "100%" }}>
                    <div className="absolute inset-0 bg-gradient-to-br from-[#4ECAFC]/10 to-[#E54C4C]/5 rounded-lg flex items-center justify-center">
                      <div className="p-6 text-center">
                        <div className="w-20 h-20 bg-[#0F121B]/80 rounded-full flex items-center justify-center mb-6 mx-auto border border-[#4ECAFC]">
                          <Headphones className="text-[#4ECAFC]" size={32} />
                        </div>
                        <h3 className="text-2xl font-bold text-white mb-4">
                          24/7 Support
                        </h3>
                        <p className="text-gray-300 mb-6">
                          Our team is always available to help with any
                          questions or issues. We pride ourselves on fast,
                          helpful responses.
                        </p>
                        <div className="flex justify-center gap-4">
                          <div className="text-center p-4 bg-[#080A12] rounded-lg w-full">
                            <div className="text-xl font-bold text-[#4ECAFC] mb-1">
                              &lt; 1 min
                            </div>
                            <div className="text-xs text-gray-400">
                              Average Response Time
                            </div>
                          </div>
                          <div className="text-center p-4 bg-[#080A12] rounded-lg w-full">
                            <div className="text-xl font-bold text-[#E54C4C] mb-1">
                              98.5%
                            </div>
                            <div className="text-xs text-gray-400">
                              Satisfaction Rate
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </BlueAnimatedBorder>
            </div>
          </div>
        </div>
      </div>

      {/* London Office Section */}
      <div className="py-20 bg-[#080A12] relative">
        <NetworkAnimation />
      </div>

      {/* Final CTA Section */}
      <div className="relative bg-[#0A0D16] py-24 overflow-hidden">
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top,_#E54C4C08,transparent_50%)]"></div>

        <div className="max-w-7xl mx-auto px-6 lg:px-8 relative z-10">
          <div className="text-center" data-aos="fade-up">
            <div className="mb-4 inline-block text-[#E54C4C] text-sm font-medium tracking-wider uppercase">
              Let's Work Together
            </div>
            <h2 className="text-3xl md:text-5xl font-bold text-white mb-6">
              Ready to <GradientText>Transform</GradientText> Your Delivery
              Experience?
            </h2>
            <p className="text-gray-300 max-w-2xl mx-auto mb-10">
              Join hundreds of businesses in London who have already discovered
              the power of same-hour delivery with Blitzo.
            </p>

            <div className="flex flex-wrap justify-center gap-6">
              <button
                onClick={() => setShowSalesModal(true)}
                className="px-8 py-3.5 bg-gradient-to-r from-[#E54C4C] to-[#FF6B6B] text-white rounded-lg font-medium flex items-center shadow-lg shadow-[#E54C4C]/20 hover:shadow-xl hover:shadow-[#E54C4C]/30 transition-all duration-300"
              >
                Speak to Sales
                <ChevronRight
                  className="ml-2 group-hover:translate-x-1 transition-transform"
                  size={18}
                />
              </button>

              <a
                href="mailto:support@blitzo.co.uk"
                className="px-8 py-3.5 bg-[#0F121B] border border-gray-700 text-white rounded-lg font-medium flex items-center hover:border-gray-500 transition-all duration-300"
              >
                Email Support
                <ArrowUpRight
                  className="ml-2 group-hover:translate-x-1 transition-transform"
                  size={18}
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Animations for fade and pulse effects */}
      <style jsx>{`
        @keyframes pulse-slow {
          0%,
          100% {
            transform: scale(1);
            opacity: 0.3;
          }
          50% {
            transform: scale(1.1);
            opacity: 0.5;
          }
        }

        @keyframes pulse-slow-delay {
          0%,
          100% {
            transform: scale(1);
            opacity: 0.3;
          }
          50% {
            transform: scale(1.15);
            opacity: 0.6;
          }
        }
      `}</style>

      <Footer />
    </div>
  );
};

export default Contact;
