import React from "react";
import { useNavigate } from "react-router-dom";
import SideBar from "../../components/SideBar";
import "./RiderDashboard.css";

const RiderDashboard = () => {
  const navigate = useNavigate();

  return (
    <div className="adminWrapper">
      <div>
        <SideBar />
      </div>
      <div className="mainContent">
        <h1>Rider Dashboard</h1>
        <button
          className="active-orders-button"
          onClick={() => navigate("/admin/active-orders")}
        >
          Active Orders
        </button>
      </div>
    </div>
  );
};

export default RiderDashboard;
