// src/web-app/contexts/AuthContext.ts

import { createContext, useContext } from "react";
import type { User } from "firebase/auth";

// The shape of the context value
export interface IAuthContext {
  user: User | null;
  loading: boolean;
  // Methods from your auth.ts
  signUpWithEmail: (email: string, password: string) => Promise<User | null>;
  loginWithEmail: (email: string, password: string) => Promise<User | null>;
  loginWithGoogle: () => Promise<User | null>;
  logout: () => Promise<void>;
}

// Create the context with a default of undefined
export const AuthContext = createContext<IAuthContext | undefined>(undefined);

// Convenience hook to use the context
export function useAuth() {
  const ctx = useContext(AuthContext);
  if (!ctx) {
    throw new Error("useAuth must be used inside an AuthProvider");
  }
  return ctx;
}
